import {
  IconButton,
  Menu,
  MenuButton,
  MenuGroup,
  MenuList,
} from '@chakra-ui/react';
import { FiBookOpen } from 'react-icons/fi';
import { MenuItemWithIcon } from './menu-item-with-icon';
import { useMethodologyMenuItems } from './use-methodoloy-menu-items';

export const MethodologyMenu = () => {
  const { showMenu, menuItems } = useMethodologyMenuItems();

  if (!showMenu) return null;
  return (
    <Menu placement="right-end" autoSelect={false} offset={[0, 4]}>
      <MenuButton
        data-testid="methodology-menu"
        as={IconButton}
        icon={<FiBookOpen />}
        fontSize="18px"
        variant="nav"
      />
      <MenuList>
        <MenuGroup title="Methodology">
          {menuItems.map((menuItemProps) => (
            <MenuItemWithIcon
              key={menuItemProps.id}
              {...menuItemProps}
            ></MenuItemWithIcon>
          ))}
        </MenuGroup>
      </MenuList>
    </Menu>
  );
};
