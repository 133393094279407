import {
  AISearchFilter,
  AISearchPreviousCurrentFilter,
  GetSchoolInfoQuery,
  GetSchoolInfoQueryVariables,
  TalentDiscoveryAiFilterSearchResponse,
} from '@revelio/data-access';
import {
  ValidValueTypes,
  SelectionList,
  SelectionCategories,
  TreeItem,
  CompanyResultItem,
  GET_SCHOOL_INFO,
  SchoolItem,
} from '@revelio/filtering';
import {
  TalentDiscoveryFilterStates,
  TalentDiscoveryFreeTextState,
  TalentDiscoverySchoolSearchFilterState,
} from '../../../td-filter-reducer';
import { isFreeTextFilter, isRangeFilter, isTreeFilter } from '../../../types';
import { isEqual } from 'lodash';
import { fetchCompanyMapping } from '../../../../../deliverables/company-selection/company-mapping/upload-companies/file-to-company-mapping';
import { Client, OperationResult } from 'urql';
import { processFilterGroup } from './process-filter-group';
import { TREE_FILTER_LIMIT } from '../../tree-filter';
import { TDResponseFilterToSelectionListMap } from './ai-filter-response-mappings';
import { getUnknownFilterExplanation } from './get-unknown-filter-explanation';

export const deserialiseApiToFilterState = async (
  response: TalentDiscoveryAiFilterSearchResponse,
  selectionLists: SelectionList<ValidValueTypes>[],
  gqlClient: Client
): Promise<{
  filterState: TalentDiscoveryFilterStates[];
  unknownFilters: {
    relevantPromptText: string;
    explanation: string;
    name: string;
  }[];
}> => {
  const { filters } = response;

  const unknownFilters: {
    relevantPromptText: string;
    explanation: string;
    name: string;
  }[] = [];

  const filterState = Object.keys(filters).reduce(
    (deserialisedFilters, key, index) => {
      if (isFreeTextFilter(key)) {
        if (!filters[key]) {
          return deserialisedFilters;
        }

        if (key === 'name') {
          // TODO: probably should check if the name is valid via api search... otherwise add it to unknown filters
          const nameFilterValue = filters[key];
          return [
            ...deserialisedFilters,
            {
              id: Date.now().toString() + index,
              name: key,
              text: [nameFilterValue.name],
            },
          ];
        }

        const keywordsFilters = (filters[key] as AISearchFilter[][])?.reduce(
          (multiKeywords, keywordValue, keywordIndex) => {
            return [
              ...multiKeywords,
              {
                id: Date.now().toString() + index + keywordIndex,
                name: key,
                text: keywordValue.map((keyword) => keyword.name),
              },
            ];
          },
          [] as TalentDiscoveryFreeTextState[]
        );

        return [...deserialisedFilters, ...keywordsFilters];
      }

      if (isTreeFilter(key)) {
        if (filters[key]?.length === 0) {
          return deserialisedFilters;
        }

        const listsWithCurrentNonCurrent = [
          'geography',
          'skill',
          'role',
          'industry',
          'seniority',
          'flight_risk',
          'prestige',
          'remote_suitability',
        ];

        const filterSelectionLists = TDResponseFilterToSelectionListMap[key];
        if (filterSelectionLists) {
          const supportedKey =
            key as keyof typeof TDResponseFilterToSelectionListMap;
          const relevantSelectionLists = selectionLists.filter((list) =>
            filterSelectionLists.includes(list.id as SelectionCategories)
          );

          const filterTreeItems = (() => {
            if (['skill'].includes(key)) {
              return (filters[supportedKey] as AISearchFilter[][]).reduce(
                (skillGroups, skillGroup) => {
                  const {
                    processedFilters,
                    unknownFilters: unknownFiltersGroup,
                  } = processFilterGroup({
                    group: skillGroup,
                    relevantSelectionLists,
                    treeFilterKey: key,
                  });

                  unknownFilters.push(...unknownFiltersGroup);

                  if (!Object.keys(processedFilters).length) {
                    return skillGroups;
                  }

                  return [...skillGroups, processedFilters];
                },
                [] as Record<string, TreeItem>[]
              );
            }

            const { processedFilters, unknownFilters: unknownFiltersGroup } =
              processFilterGroup({
                group: filters[supportedKey] as AISearchFilter[],
                relevantSelectionLists,
                treeFilterKey: key,
              });

            unknownFilters.push(...unknownFiltersGroup);

            return [processedFilters];
          })();

          if (
            !filterTreeItems.filter((item) => Object.keys(item).length).length
          ) {
            return deserialisedFilters;
          }

          const newFilters = filterTreeItems
            .map((treeItems, treeItemsIndex) => ({
              name: key,
              id: Date.now().toString() + index + treeItemsIndex,
              treeItems: treeItems,
              ...(listsWithCurrentNonCurrent.includes(key)
                ? {
                    isCurrent:
                      response?.filters[key]?.some(
                        (filter) =>
                          (filter as AISearchPreviousCurrentFilter)?.isCurrent
                      ) ?? true,
                  }
                : {}),
            }))
            .slice(0, TREE_FILTER_LIMIT);

          return [...deserialisedFilters, ...newFilters];
        }
      }

      if (isRangeFilter(key)) {
        const filter = filters[key];
        if (!filter?.range?.length || isEqual(filter?.range, [null, null])) {
          return deserialisedFilters;
        }

        const salaryTotalValue = filter?.range as [number, number];
        const minSalary = salaryTotalValue[0];
        const maxSalary = salaryTotalValue[1];

        const salaryRange = (() => {
          if (minSalary === null || minSalary === undefined) {
            return {
              end_value: salaryTotalValue[1],
            };
          }

          if (maxSalary === null || maxSalary === undefined) {
            return {
              start_value: minSalary,
            };
          }

          return {
            start_value: minSalary,
            end_value: maxSalary,
          };
        })();

        return [
          ...deserialisedFilters,
          {
            isCurrent: filter?.isCurrent ?? true,
            name: key,
            ...salaryRange,
          },
        ];
      }

      return deserialisedFilters;
    },
    [] as TalentDiscoveryFilterStates[]
  );

  if (response.filters.company?.length) {
    const csvHeader = 'COMPANY_NAME\n';
    const csvContent = response.filters.company
      .map((company) => company.name)
      .join('\n');
    const csvData = csvHeader + csvContent;

    const file = new File([csvData], 'mappedCompanylist.csv', {
      type: 'text/csv',
    });

    const companyMapping = await fetchCompanyMapping(file);
    const verifiedMappings = companyMapping.filter(
      (company) => company.response !== null
    );

    const missingCompanyMappings = companyMapping.filter(
      (company) => company.response === null
    );
    missingCompanyMappings.forEach((mapping) => {
      const missingCompanyName = mapping.request.company_name;
      unknownFilters.push({
        name: missingCompanyName,
        explanation: getUnknownFilterExplanation(missingCompanyName, 'Company'),
        relevantPromptText:
          response.filters.company?.find(
            (company) => company.name === missingCompanyName
          )?.relevantPromptText || '',
      });
    });

    if (verifiedMappings.length) {
      const companyResultItems = verifiedMappings.reduce(
        (filterResultItems, mappedCompany) => {
          return {
            ...filterResultItems,
            [mappedCompany.response.rcid]:
              mappedCompany.response as CompanyResultItem,
          };
        },
        {}
      );

      filterState.push({
        name: 'company',
        isCurrent:
          response.filters.company.some((company) => company.isCurrent) ?? true,
        companyResultItems,
      });
    }
  }

  if (response.filters.school) {
    const schoolSearchResults = await Promise.all(
      response.filters.school.map(async (school) => {
        const schoolResult = await fetchSchoolSearchResults(gqlClient, {
          name: school.name,
          page: 1,
        });

        if (!schoolResult.data?.schoolInfo?.length) {
          unknownFilters.push({
            name: school.name,
            explanation: getUnknownFilterExplanation(school.name, 'School'),
            relevantPromptText: school.relevantPromptText,
          });
        }

        return schoolResult;
      })
    );

    const validSchoolResults = schoolSearchResults
      .filter((result) => result.data?.schoolInfo?.length)
      .map((result) => result.data?.schoolInfo?.[0]);

    const schoolResultItems = validSchoolResults.reduce(
      (acc, school) => {
        if (!school) return acc;
        return {
          ...acc,
          [school.rsid as string]: {
            label: school.name,
            name: school.name,
            rsid: school.rsid,
            primary_name: school.name,
          } as SchoolItem,
        };
      },
      {} as TalentDiscoverySchoolSearchFilterState['schoolResultItems']
    );

    if (Object.keys(schoolResultItems || {}).length > 0) {
      filterState.push({
        name: 'rsid',
        schoolResultItems,
      });
    }
  }

  return { filterState, unknownFilters };
};

const fetchSchoolSearchResults = async (
  gqlClient: Client,
  vars: GetSchoolInfoQueryVariables
): Promise<OperationResult<GetSchoolInfoQuery>> => {
  return gqlClient
    .query<GetSchoolInfoQuery, GetSchoolInfoQueryVariables>(
      GET_SCHOOL_INFO,
      vars
    )
    .toPromise()
    .catch((e) => {
      console.log('error:', e);
      return e;
    });
};
