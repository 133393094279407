import { D3ChartNames } from '@revelio/d3';
import { FilterItem } from '@revelio/filtering';
import { findIndex } from 'lodash';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const sortBoxPlotData = (plotData: any, primaryEntities: FilterItem[]) => {
  const [entities, dataPoints] = plotData;

  const sortedEntities = defaultSortPlotData(entities, primaryEntities);

  return [sortedEntities, dataPoints];
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const sortKDEPlotData = (plotData: any, primaryEntities: FilterItem[]) => {
  const sortedData = new Array(plotData.length);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  plotData.forEach((ent: any) => {
    if (!ent) return;

    const { metadata } = ent;

    const { id: outerId, level } = metadata;

    const index = findIndex(
      primaryEntities,
      // TODO: TYPE THIS
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (primaryEnt: any) => {
        const { id: innerId, selectionListId, isActive = true } = primaryEnt;

        return (
          isActive && `${level}__${outerId}` == `${selectionListId}__${innerId}`
        );
      }
    );

    if (index !== -1) {
      sortedData[index] = ent;
    }
  });

  return sortedData;
};

export const defaultSortPlotData = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  plotData: any,
  primaryEntities: FilterItem[]
) => {
  const sortedData = new Array(plotData.length);
  // TODO: TYPE THIS
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  plotData.forEach((ent: any) => {
    if (!ent) return;
    const { id, metadata } = ent;

    const [, outerNumericalId] = id?.toString().split('__') || [];

    const outerId = outerNumericalId ?? id;

    const index = findIndex(
      primaryEntities,

      (primaryEnt: FilterItem) => {
        const { id: innerId, label, isActive = true } = primaryEnt;

        return (
          isActive &&
          outerId == innerId &&
          // TODO: Temp solution to try to get a match, long/short name mappings
          // seems to be mixed up at the moment
          [metadata.longName, metadata.shortName].some((str) => {
            return label
              ?.toLowerCase()
              .includes(str?.toString()?.toLowerCase());
          })
        );
      }
    );

    if (index !== -1) {
      sortedData[index] = ent;
    }
  });

  return sortedData;
};

export const PlotDataSortingFunctionLookup: {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key in D3ChartNames]?: (plotData: any, primaryEntities: FilterItem[]) => any;
} = {
  [D3ChartNames.BoxPlot]: sortBoxPlotData,
  [D3ChartNames.KDE]: sortKDEPlotData,
};
