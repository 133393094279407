import styles from './line-tooltip.module.css';

export type LineTooltipProps = {
  title: string;
  rows: {
    label: string;
    color: string;
    value: string;
  }[];
};
export const LineTooltip = ({ title, rows }: LineTooltipProps) => {
  return (
    <div>
      <div className={styles.tooltipTitle}>{title}</div>
      {rows.map((row, i) => (
        <div key={i} className={styles.tooltipRow}>
          <div className={styles.tooltipLabel}>
            <div className={styles.tooltipColor} style={{ backgroundColor: row.color }} />
            {row.label}
          </div>
          <div className={styles.tooltipValue}>{row.value}</div>
        </div>
      ))}
    </div>
  );
};
