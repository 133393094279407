import { BarData, KdeData } from '@revelio/replots';

export type SheetData = {
  [key: string]: string | number;
};

type ConvertBarToSheetProps = {
  data: BarData[];
  dimensionName: string;
  metricName: string;
};
export const convertBarToSheet = ({
  data,
  dimensionName,
  metricName,
}: ConvertBarToSheetProps): SheetData[] =>
  data.map((d) => ({
    [dimensionName]: d.label,
    [metricName]: d.value,
  }));

type ConvertKdeToSheetProps = {
  data: KdeData[];
  dimensionName: string;
  metricName: string;
};
export const convertKdeToSheet = ({
  data,
  dimensionName,
}: ConvertKdeToSheetProps): SheetData[] =>
  data.flatMap((d) =>
    d.values.map(
      (v): SheetData => ({
        [dimensionName]: d.label,
        value: v.value,
        density: v.kde,
        cdf: v.cdf,
      })
    )
  );
