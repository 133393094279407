import {
  Divider,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalOverlay,
} from '@chakra-ui/react';
import { ActionModalControlPanel, write } from '@revelio/core';
import { BinaryOperation } from '@revelio/data-access';
import { cloneDeep, isBoolean, isEqual, isNumber } from 'lodash';
import { Path, UseFormReturn, useForm, useWatch } from 'react-hook-form';
import { Condition } from '../dataset-conditions/conditions.model';
import { useEffect, useMemo } from 'react';
import {
  deliverablesStore,
  getDatasetFilterByName,
  updateDraftDeliverable,
} from '../../deliverables.repository';
import { DatasetFilter, Deliverable } from '../../deliverables.model';
import { FilterConditions } from './filter-conditions';
import { getEntity } from '@ngneat/elf-entities';
import {
  DEFAULT_FILTER_NAME,
  DEFAULT_FORM_VALUES,
  DatasetFilterForm,
} from './filter.model';
import { produce } from 'immer';
import { EMPTY_VALUE_MESSAGE } from '../dataset-conditions/dataset-conditions-form-helpers';
import { CustomStepName } from '../custom-step-name';

const useSetDefaultFilterNameOnFirstEdit = ({
  entityId,
  control,
  setValue,
  currentFilterName,
}: UseFormReturn<DatasetFilterForm> & {
  entityId: Deliverable['id'];
  currentFilterName: string;
}) => {
  const formValues = useWatch({ control });
  useEffect(() => {
    if (
      currentFilterName !== DEFAULT_FILTER_NAME ||
      isEqual(formValues, DEFAULT_FORM_VALUES)
    ) {
      return;
    }

    const datasetFiltersLength = deliverablesStore.query(getEntity(entityId))
      ?.pipeline.filters?.length;
    setValue('datasetFilterName', `filter_${(datasetFiltersLength || 0) + 1}`);
  }, [entityId, formValues, currentFilterName, setValue]);
};

export const FilterDefinitionModal = ({
  isOpen,
  setIsOpen,
  existingFilters,
  entityId,
}: {
  isOpen: boolean | string;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean | string>>;
  existingFilters: DatasetFilter[];
  entityId: Deliverable['id'];
}) => {
  const formProps = useForm<DatasetFilterForm>({
    defaultValues: cloneDeep(DEFAULT_FORM_VALUES),
  });

  const {
    register,
    formState: { errors, isSubmitSuccessful },
    reset,
    handleSubmit,
    setError,
  } = formProps;

  const datasetFilterBeingEdited = useMemo(
    () =>
      isBoolean(isOpen)
        ? undefined
        : cloneDeep(
            getDatasetFilterByName({ name: isOpen as string, entityId })
          ),
    [isOpen, entityId]
  );
  useEffect(() => {
    // form has to be reset since it's always mounted depending on if adding new column vs editing existing column
    if (!datasetFilterBeingEdited || isSubmitSuccessful) {
      reset(cloneDeep(DEFAULT_FORM_VALUES));
      return;
    }

    reset({
      datasetFilterName: datasetFilterBeingEdited.name,
      conditions: datasetFilterBeingEdited.conditions,
      binary: datasetFilterBeingEdited.binary,
    });
  }, [datasetFilterBeingEdited, isOpen, isSubmitSuccessful, reset]);

  const datasetFilterName = useWatch({
    control: formProps.control,
    name: 'datasetFilterName',
  });
  useSetDefaultFilterNameOnFirstEdit({
    entityId,
    currentFilterName: datasetFilterName,
    ...formProps,
  });

  const onSubmit = handleSubmit((formData) => {
    const conditionValueErrors: { [key in Path<DatasetFilterForm>]?: string } =
      {};
    formData.conditions.forEach((condition, i) => {
      if (!condition.value || !condition.value.length) {
        const error = EMPTY_VALUE_MESSAGE;
        const fieldPath = `conditions.${i}.value` as const;
        setError(fieldPath, {
          message: error,
        });
        conditionValueErrors[fieldPath] = error;
      }
    });

    const hasValidationError = Object.keys(conditionValueErrors).length;
    if (hasValidationError) {
      return;
    }

    updateDraftDeliverable(
      entityId,
      write<Deliverable>((state) => {
        const newDatasetFilter: DatasetFilter = {
          name: formData.datasetFilterName,
          conditions: cloneDeep(formData.conditions as Condition[]),
          binary: formData.binary as BinaryOperation,
        };

        state.pipeline.filters = produce(
          state.pipeline.filters || [],
          (draft) => {
            const datasetFilterEditedIndex = state.pipeline.filters?.findIndex(
              (x) => x.name === datasetFilterBeingEdited?.name
            );

            if (
              datasetFilterBeingEdited &&
              isNumber(datasetFilterEditedIndex)
            ) {
              draft[datasetFilterEditedIndex] = newDatasetFilter;
            } else {
              draft.push(newDatasetFilter);
            }
          }
        );
      })
    );
    setIsOpen(false);
  });

  return (
    <Modal
      id="filter-definition-modal"
      isOpen={!!isOpen}
      onClose={() => setIsOpen(false)}
      size="2xl"
      isCentered
      closeOnOverlayClick={true}
    >
      <ModalOverlay />
      <ModalContent>
        <form onSubmit={onSubmit}>
          <CustomStepName<DatasetFilterForm>
            id="dataset-filter-name"
            fieldPath="datasetFilterName"
            register={register}
            customName={datasetFilterName}
            existingCustomNames={existingFilters.map((x) => x.name)}
            currentCustomName={isOpen}
            nameError={errors.datasetFilterName}
          />

          <Divider orientation="horizontal" />

          <ModalBody>
            <FilterConditions entityId={entityId} formProps={formProps} />
          </ModalBody>

          <ModalFooter mt="45px">
            <ActionModalControlPanel
              submitText={
                datasetFilterBeingEdited ? 'Edit Filter' : 'Create Filter'
              }
              onClose={() => setIsOpen(false)}
              onCancel={() => setIsOpen(false)}
              onSubmit={onSubmit}
              onReset={() => reset(cloneDeep(DEFAULT_FORM_VALUES))}
            />
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  );
};
