import { useMutation } from '@tanstack/react-query';
import { addSourceWhaleCandidates } from '../source-whale-api';
import { useToast } from '@chakra-ui/react';
import { get } from 'lodash';
import pluralize from 'pluralize';

export const useSourceWhaleCandidates = ({
  onSuccess,
  onError,
}: {
  onSuccess: () => void;
  onError: (error: string) => void;
}) => {
  const toast = useToast();

  return useMutation({
    mutationFn: addSourceWhaleCandidates,
    onSuccess: (response, variables) => {
      const candidateIds = get(response, 'candidateIds', []);
      const requestCount = variables.candidates.length;
      const successCount = candidateIds.length;

      if (successCount === 0) {
        throw new Error('Failed to export any candidates to SourceWhale');
      }

      if (successCount < requestCount) {
        toast({
          title: `Exported ${pluralize('candidate', successCount, true)} out of ${pluralize('candidate', requestCount, true)} to SourceWhale`,
          status: 'warning',
          position: 'top-right',
        });
      } else {
        if (variables.campaignId && response.url) {
          window.open(response.url, '_blank');
        }

        toast({
          title: `Successfully exported ${pluralize('candidate', successCount, true)} to SourceWhale`,
          description: variables.campaignId
            ? 'Opening SourceWhale campaign in new tab...'
            : undefined,
          status: 'success',
          position: 'top-right',
        });
      }
      onSuccess();
    },
    onError: (error) => {
      console.error(error);
      onError(
        'An error occurred while adding candidates to SourceWhale. Please try again later.'
      );
    },
  });
};
