import { useMemo } from 'react';
import { useQuery } from 'urql';
import {
  generateReportEntityMap,
  IReportEntityMap,
  SpecialReportCategories,
} from '../entity-configuration/utils';
import { GetReportConfig } from '../report-builder-operations';

const getCategories = (config: IReportEntityMap) => {
  // Categories determine which groups the reports will be in. There are two sepecial categories:
  // * Featured: This category will always appear on top of the list
  // * Additional: Report configrations that don't have any categories will be grouped into this category which appears at the bottom of the list
  if (!config) return [];
  const unsortedCategories = Array.from(
    new Set(
      Object.values(config)
        .map((config) =>
          !config.categories || config.categories.length > 0
            ? config.categories
            : ['additional']
        )
        .flat()
    )
  ) as string[];
  return unsortedCategories.sort((a, b) => {
    const order: Record<string, number> = {
      [SpecialReportCategories.Featured]: -1,
      [SpecialReportCategories.Additional]: 1,
    };
    return (order[a] || 0) - (order[b] || 0) || a.localeCompare(b);
  });
};

export const useReportBuilderConfig = () => {
  const [{ data, fetching: loading, error }] = useQuery({
    query: GetReportConfig,
  });

  const config: IReportEntityMap = useMemo(() => {
    return data?.reports_validation
      ? generateReportEntityMap(data?.reports_validation)
      : {};
  }, [data]);

  const categories = useMemo(() => {
    return getCategories(config);
  }, [config]);

  return {
    config,
    categories,
    loading,
    error,
  };
};
