import noData from '../../assets/no-data.svg';
import styles from './no-data.module.css';

export const NoData = () => {
  return (
    <div className={styles.noDataContainer}>
      <img src={noData} alt="No data" className={styles.noDataImage} />
      <div className={styles.noDataText}>
        No data available. Please adjust filters and try again.
      </div>
    </div>
  );
};
