import { Flex } from '@chakra-ui/layout';
import { Menu, MenuButton } from '@chakra-ui/menu';
import { Box, Icon, IconButton, Portal, Tag } from '@chakra-ui/react';
import { DeliveryStatus } from '@revelio/data-access';
import { FiMoreVertical } from 'react-icons/fi';
import { tagStatusColorMap } from '../../deliverables/status/utils';
import { ReportMenuList } from '../components/report-menu-list';
import { useAllReports } from '../hooks/use-all-reports';

export const ReportsStatusColumn = ({
  viewReportLink,
  value,
  report,
  refreshReports,
}: {
  viewReportLink: string | null;
  report: ReturnType<typeof useAllReports>['reports'][number] | null;
  value: string;
  refreshReports: () => void;
}) => {
  const showMenu =
    [DeliveryStatus.Finished, DeliveryStatus.Failed].includes(
      value as DeliveryStatus
    ) && report;

  const stopPropagation = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
  };

  const tagStatus =
    value === DeliveryStatus.Scheduled ? DeliveryStatus.Running : value;

  return (
    <Flex justifyContent="space-between">
      <Tag
        size="md"
        variant="solid"
        bgColor={tagStatusColorMap[tagStatus]?.bgColor || 'gray.100'}
        color={tagStatusColorMap[tagStatus]?.color || 'gray.600'}
        fontWeight="semibold"
      >
        {tagStatus}
      </Tag>

      {showMenu && (
        <Menu placement="left-start">
          <MenuButton
            as={IconButton}
            icon={
              <Icon
                as={FiMoreVertical}
                data-testid="reports_status_menu_button"
              />
            }
            size="xs"
            colorScheme="gray"
            onClick={stopPropagation}
          >
            Actions
          </MenuButton>
          <Portal>
            <Box onClick={stopPropagation}>
              <ReportMenuList
                viewReportLink={viewReportLink}
                reportId={report.id}
                reportType={report.reportType}
                report
                onActionSucceeded={refreshReports}
              />
            </Box>
          </Portal>
        </Menu>
      )}
    </Flex>
  );
};
