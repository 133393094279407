import { isArray, split } from 'lodash';
import {
  FilterItem,
  Item,
  SelectionCategories,
  SelectionList,
  SelectionListIdNames,
} from '../../engine/filters.model';
import { swapKeysWithValues, write } from '@revelio/core';
import { DEFAULT_SELECTION_LIST_PARENT_MAP } from '../../engine/filters.engine';
import Fuse, { IFuseOptions } from 'fuse.js';
import {
  GEOGRAPHY_GRANULARITY_FILTERS,
  ROLE_GRANULARITY_FILTERS,
  SKILL_GRANULARITY_FILTERS,
} from '../../engine/filters.constants';

export const findSelectionListItemByItemId = ({
  itemId,
  selectionLists,
}: {
  itemId: string; // includes selection list in the id e.g. "role_k50.48"
  selectionLists: SelectionList[];
}) => {
  const [itemSelectionlistId, itemValueIdToFind] = split(itemId, '.');

  const selectionListWithItem = selectionLists.find(
    (list) => list.id === itemSelectionlistId
  );

  if (!selectionListWithItem) {
    return null;
  }

  const selectionListValueById = selectionListWithItem.value.find(
    (item) => Number(item.id) === Number(itemValueIdToFind)
  ) as Item;

  if (!selectionListValueById) {
    return null;
  }

  return {
    item: selectionListValueById,
    id: itemId,
    parentId:
      selectionListWithItem.parent &&
      selectionListValueById[selectionListWithItem.parent as string] &&
      selectionListWithItem.parent +
        '.' +
        selectionListValueById[selectionListWithItem.parent as string],
    children: [],
    selectionListId: selectionListWithItem.id as SelectionListIdNames,
  };
};

export const findSelectionListItemByLabel = ({
  labelToFind,
  selectionList,
}: {
  labelToFind: string;
  selectionList: SelectionList;
}) => {
  const keysToSearch = (() => {
    if (
      SKILL_GRANULARITY_FILTERS.includes(
        selectionList.id as SelectionCategories
      )
    ) {
      return ['label', 'topSkills'];
    }

    if (
      ROLE_GRANULARITY_FILTERS.includes(selectionList.id as SelectionCategories)
    ) {
      return ['label', 'topCleanedTitles', 'topRawTitles'];
    }

    return ['label'];
  })();

  const options: IFuseOptions<FilterItem> = {
    keys: keysToSearch,
    threshold: GEOGRAPHY_GRANULARITY_FILTERS.includes(
      selectionList.id as SelectionCategories
    )
      ? 0.5
      : 0.2,
    includeScore: true,
    ignoreLocation: true,
    minMatchCharLength: 2,
  };

  const fuse = new Fuse(selectionList.value, options);
  const results = fuse.search(labelToFind);

  if (results.length === 0) {
    return null;
  }

  const selectionListValueByLabel = results[0].item;

  const itemId = selectionList.id + '.' + selectionListValueByLabel.id;
  return {
    item: selectionListValueByLabel,
    id: itemId,
    parentId:
      selectionListValueByLabel.parentId &&
      selectionList.parent + '.' + selectionListValueByLabel.parentId,
    children: [],
    selectionListId: selectionList.id as SelectionListIdNames,
    closeness_score: results[0].score,
  };
};

export const getDefaultParentChildSelectionListMap = ({
  isDashboardFilterRoles,
}: {
  isDashboardFilterRoles: GetFilterChildSelectionListIdsArgs['isDashboardFilterRoles'];
}) => {
  const defaultSelectionListMap = write<
    typeof DEFAULT_SELECTION_LIST_PARENT_MAP
  >((selectionListMap) => {
    if (isDashboardFilterRoles) {
      // getting children of dashboard filters
      delete selectionListMap[SelectionCategories.ROLE_K50];
      delete selectionListMap[SelectionCategories.ROLE_K500];
    } else {
      // getting children of sub filters
      delete selectionListMap[SelectionCategories.ROLE_K150];
      delete selectionListMap[SelectionCategories.ROLE_K1500];
    }
  })(DEFAULT_SELECTION_LIST_PARENT_MAP);

  return swapKeysWithValues(defaultSelectionListMap);
};

type GetFilterChildSelectionListIdsArgs = {
  selectedFilterValues: string[];
  selectionLists: SelectionList[] | undefined;
  selectedFilterSelectionListId?: SelectionCategories | undefined;
  noSelectedFilterSelectionListId: SelectionCategories;
  isDashboardFilterRoles: boolean;
};
export const getFilterChildSelectionListIds = ({
  selectedFilterValues,
  selectionLists,
  selectedFilterSelectionListId,
  noSelectedFilterSelectionListId,
  isDashboardFilterRoles,
}: GetFilterChildSelectionListIdsArgs) => {
  const parentChildMap = getDefaultParentChildSelectionListMap({
    isDashboardFilterRoles,
  });

  return (selectionLists as SelectionList[])
    .find((list) => {
      const childSelectionListId = selectedFilterSelectionListId
        ? parentChildMap[selectedFilterSelectionListId]
        : noSelectedFilterSelectionListId;
      return list.id === childSelectionListId;
    })
    ?.value.filter((ent) => {
      if (selectedFilterSelectionListId) {
        const childSelectionListValue = (ent as any)[
          selectedFilterSelectionListId
        ];
        return selectedFilterValues.includes(
          isArray(childSelectionListValue)
            ? childSelectionListValue[0]
            : childSelectionListValue
        );
      }

      return true;
    })
    .map((entity) => entity.id) as string[];
};
