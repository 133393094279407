import { TalentDiscoveryUser } from '@revelio/data-access';
import { components } from './source-whale.schema';

export type SWCandidate =
  components['schemas']['CandidatesAdd']['candidates'][number];
export type SWCandidates = components['schemas']['CandidatesAdd']['candidates'];
export type SWCandidatesAdd = components['schemas']['CandidatesAdd'];
export type SWCandidatesModify = components['schemas']['CandidatesModify'];

export type SourceWhaleCampaign = {
  campaignId: string;
  campaignName: string;
};

export type SourceWhaleProject = {
  projectId: string;
  projectName: string;
};

type SWMetaData = {
  foundBy?: string;
  source?: string;
};

export type TDUsersInfo = {
  users: TalentDiscoveryUser[];
} & SWMetaData;

export function mapTDUsersToSWCandidates({
  users,
  foundBy,
  source,
}: TDUsersInfo): SWCandidate[] {
  return users.map((user) => mapTDUserToSWCandidate(user, foundBy, source));
}

function mapTDUserToSWCandidate(
  user: TalentDiscoveryUser,
  foundBy?: string,
  source?: string
): SWCandidate {
  const {
    first_name,
    last_name,
    email,
    personal_email,
    phone_number,
    current_company,
    history,
    education,
    current_title,
    location,
    linkedin_url,
    ethnicity,
    gender,
  } = user;

  return {
    name: `${first_name} ${last_name}`.trim(),
    firstName: first_name,
    lastName: last_name,
    emails: [personal_email, email].filter(Boolean),
    phones: phone_number ? [phone_number] : [],
    company: current_company,
    previousCompany:
      history?.find((h) => h.current_company !== current_company)
        ?.current_company || '',
    school: education,
    role: current_title,
    location: location,
    companyLocation: location,
    socialLinks: [linkedin_url].filter(Boolean),
    links: [linkedin_url].filter(Boolean),
    dominantRace: ethnicity,
    gender: gender,
    source: source || '',
    foundBy: foundBy || '',
  };
}
