import { KdeData } from '@revelio/replots';
import { KdeSalaryData } from './types';

export const getKdeData = (
  salaryData: KdeSalaryData,
  primaryCompanyName: string
): KdeData[] =>
  salaryData.value.map(
    (entity): KdeData => ({
      label: primaryCompanyName,
      values: entity.value.map((v, i) => ({
        value: entity.metadata?.salaries?.[i],
        kde: v,
        cdf: entity.metadata?.cdf?.[i] / 100,
      })),
    })
  );
