import { graphql } from '@revelio/data-access';

export const CreateReportsMutation = graphql(`
  mutation createReports($input: CreateReportRequest!) {
    createReports(input: $input) {
      user_id
      report_id
    }
  }
`);

export const DeleteReportMutation = graphql(`
  mutation DeleteReport($id: String!) {
    deleteReport(id: $id)
  }
`);

export const RefreshReportMutation = graphql(`
  mutation RefreshReport($id: String!) {
    refreshReport(id: $id)
  }
`);

export const GetReportConfig = graphql(`
  query GetReportConfig {
    reports_validation {
      report_validation {
        id
        report_type
        label
        description
        sample_link
        category
        main_entity {
          selection_list
          limit
          required
          label
          description
        }
        comparison_entities {
          selection_list
          limit
          required
          label
          description
        }
        filters {
          selection_list
          limit
          required
          label
          description
        }
      }
    }
  }
`);
