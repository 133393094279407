import { DASH_META_ROOT, getAuthDashMetaCsrfToken } from '@revelio/auth';
import {
  SWCandidate,
  SourceWhaleCampaign,
  SourceWhaleProject,
} from './source-whale.model';

async function handleResponse<T>(response: Response): Promise<T> {
  if (response.status >= 200 && response.status <= 299) {
    const data = await response.json();
    return data;
  } else if (response.status >= 500 && response.status <= 599) {
    const errorData = await response.json();
    throw new Error(errorData.message || 'An error occurred');
  } else {
    throw new Error(response.statusText);
  }
}

export const getSourceWhaleCampaigns = async ({
  apiKey,
}: {
  apiKey: string;
}) => {
  const csrfToken = getAuthDashMetaCsrfToken();
  if (!csrfToken) throw new Error('Missing CSRF token');

  return handleResponse<{
    campaigns: { campaigns: SourceWhaleCampaign[] };
  }>(
    await fetch(`${DASH_META_ROOT}/api/source-whale/campaigns/list`, {
      method: 'GET',
      credentials: 'include',
      headers: {
        'x-surf-token-dash-meta': csrfToken,
        'Content-Type': 'application/json',
        'request-id': crypto.randomUUID(),
        'source-whale-api-key': apiKey,
      },
    })
  );
};

export const getSourceWhaleProjects = async ({
  apiKey,
}: {
  apiKey: string;
}) => {
  const csrfToken = getAuthDashMetaCsrfToken();
  if (!csrfToken) throw new Error('Missing CSRF token');

  return handleResponse<{ projects: SourceWhaleProject[] }>(
    await fetch(`${DASH_META_ROOT}/api/source-whale/projects/list`, {
      method: 'GET',
      credentials: 'include',
      headers: {
        'x-surf-token-dash-meta': csrfToken,
        'Content-Type': 'application/json',
        'request-id': crypto.randomUUID(),
        'source-whale-api-key': apiKey,
      },
    })
  );
};

export const addSourceWhaleCandidates = async ({
  apiKey,
  candidates,
  campaignId,
  projectIds,
}: {
  apiKey: string;
  candidates: SWCandidate[];
  campaignId?: string;
  projectIds?: string[];
}) => {
  const csrfToken = getAuthDashMetaCsrfToken();
  if (!csrfToken) throw new Error('Missing CSRF token');

  const endpoint = campaignId ? 'candidates/add-to-campaign' : 'candidates/add';

  return handleResponse<{
    candidateIds: string[];
    url?: string;
  }>(
    await fetch(`${DASH_META_ROOT}/api/source-whale/${endpoint}`, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'x-surf-token-dash-meta': csrfToken,
        'Content-Type': 'application/json',
        'request-id': crypto.randomUUID(),
        'source-whale-api-key': apiKey,
      },
      body: JSON.stringify({
        candidates,
        ...(campaignId && { campaignId, sendImmediately: false }),
        ...(projectIds && { projectIds }),
      }),
    })
  );
};
