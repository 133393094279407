import {
  clearAdapativeRoleTaxonomySelectionListInUnsupportedPage,
  FilterContainerName,
  ScreenerFilterOption,
  ScreenerFilters,
  ScreenerFiltersProps,
  useScreenerFilter,
} from '@revelio/filtering';
import { Flex, Card } from '@chakra-ui/react';
import { PageTitles } from '@revelio/core';
import DashboardPage from '../DashboardPage';
import { useUserTrialDetails } from '@revelio/auth';
import { PlotDataLagWarning } from '@revelio/composed';
import { ScreenerDimension, TrialType, View } from '@revelio/data-access';
import { DownloadScreenerButton } from './download-screener-button';
import { ScreenerTable } from './screener-table/screener-table';
import { useEffect, useState, useCallback, useMemo } from 'react';
import {
  loadAllScreenerStateFromUrl,
  useSyncFiltersWithUrl,
} from './useSyncFiltersWithUrl';
import { ScreenerSorting } from '@revelio/filtering';

export interface ScreenerProps {
  title: PageTitles[];
  filterOptions: ScreenerFilterOption[];
  view: ScreenerDimension;
  sortingDisabledColumns?: string[];
  savedSetsView: View;
}

export const Screener = ({
  title,
  filterOptions,
  view,
  sortingDisabledColumns,
  savedSetsView,
}: ScreenerProps) => {
  const { isTrialUser, trialType } = useUserTrialDetails();
  const isDataLagged = isTrialUser && trialType === TrialType.ONE_YEAR_DATA_LAG;

  const { state, dispatch } = useScreenerFilter();

  const sorting = state.sorting;
  const setSorting = useCallback(
    (sortingToUpdate: ScreenerSorting | undefined) => {
      dispatch({ type: 'UPDATE_SORTING', sorting: sortingToUpdate });
    },
    [dispatch]
  );

  const defaultColumns = useMemo(() => {
    const primaryEntity = (() => {
      if (view === ScreenerDimension.Company) {
        return 'Company Name';
      }

      if (view === ScreenerDimension.Geography) {
        return 'Metro Area';
      }

      return 'Role';
    })();

    return [
      primaryEntity,
      'Headcount',
      'Growth Rate',
      'Salary',
      'Attrition Rate',
      'Hiring Rate',
      'Tenure',
    ];
  }, [view]);

  // Selected columns state and setter
  const [savedCheckedColumns, setSavedCheckedColumns] =
    useState<string[]>(defaultColumns);

  // Pass sorting and selected columns to the hook
  useSyncFiltersWithUrl(
    state,
    dispatch,
    view,
    sorting,
    setSorting,
    savedCheckedColumns,
    setSavedCheckedColumns
  );

  useEffect(() => {
    clearAdapativeRoleTaxonomySelectionListInUnsupportedPage();
  }, []);

  const onSubmitSavedFilterSet: ScreenerFiltersProps['onSubmitSavedFilterSet'] =
    (value) => {
      const view = value?.entity.view;
      const filters = value?.entity.filters.split('?')[1];
      loadAllScreenerStateFromUrl(
        dispatch,
        view,
        setSorting,
        setSavedCheckedColumns,
        new URLSearchParams(filters)
      );
    };

  return (
    <DashboardPage title={title} loading={false}>
      {/* Need to pass this custom property so DashboardPage renders the filters in the right grid position.
            Should remove this constraint from DashboardPage */}
      <Flex
        {...{ [FilterContainerName]: [FilterContainerName] }}
        width="full"
        justifyContent="space-between"
      >
        <ScreenerFilters
          savedSetsView={savedSetsView}
          filterOptions={filterOptions}
          onSubmitSavedFilterSet={onSubmitSavedFilterSet}
        />
        {isDataLagged && <PlotDataLagWarning persistent />}
        <DownloadScreenerButton view={view} />
      </Flex>

      <Card height="100%" padding="16px" variant="unstyled" borderRadius="10px">
        <ScreenerTable
          view={view}
          sortingDisabledColumns={sortingDisabledColumns}
          sorting={sorting}
          setSorting={setSorting}
          savedCheckedColumns={savedCheckedColumns}
          setSavedCheckedColumns={setSavedCheckedColumns}
        />
      </Card>
    </DashboardPage>
  );
};
