export const NodeConstants = {
  width: 120,
  height: 50,
  borderRadius: 4,
} as const;

export const ScaleConstants = {
  min: 0.2,
  max: 3,
} as const;
