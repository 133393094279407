import { Text } from '@chakra-ui/react';

import { FormatType, KdeChart } from '@revelio/replots';

import { PlotCard, convertKdeToSheet } from '../../../../shared/components';
import { getKdeData } from './get-kde-data';
import { getLegacyKdeChartProps } from './get-legacy-kde-chart-props';
import { KdeSalaryData } from './types';

interface SalaryDistributionProps {
  salaryData: KdeSalaryData | undefined;
  primaryCompanyRCID: number;
  primaryCompanyName: string | null | undefined;
  loading?: boolean;
}

export const SalaryDistribution = ({
  salaryData,
  primaryCompanyRCID,
  primaryCompanyName,
  loading = false,
}: SalaryDistributionProps) => {
  const kdeData =
    salaryData && primaryCompanyName
      ? getKdeData(salaryData, primaryCompanyName)
      : [];

  const legacyChartProps =
    salaryData && primaryCompanyName
      ? getLegacyKdeChartProps({
          salaryData,
          primaryCompanyRCID,
          primaryCompanyName,
        })
      : undefined;

  return (
    <PlotCard
      title="Salary Distribution"
      info={
        <Text color="white">
          The distribution of total compensation. More information on the
          compensation model can be found{' '}
          <Text
            as="span"
            color="green.300"
            _hover={{ textDecoration: 'underline' }}
          >
            <a
              href="https://www.data-dictionary.reveliolabs.com/methodology.html#salary"
              target="_blank"
              rel="noreferrer"
            >
              here
            </a>
          </Text>
          .
        </Text>
      }
      menu={['expand', 'generate-link', 'download-png', 'download-data']}
      exportData={convertKdeToSheet({
        data: kdeData,
        dimensionName: 'Company',
        metricName: 'Salary Distribution',
      })}
      legacyChartProps={legacyChartProps}
    >
      <KdeChart
        data={kdeData}
        format={FormatType.CURRENCY}
        colors={['#5BD992']}
        loading={loading}
      />
    </PlotCard>
  );
};
