import { graphql } from '@revelio/data-access';

export const GET_SCREENER_DATA = graphql(`
  query GetScreenerData($filters: ScreenerReq!) {
    screener(filters: $filters) {
      pagination {
        total_results
      }
      entities {
        short_name
        long_name
        entity_id
        headcount
        inflow
        outflow
        hiring
        attrition
        growth
        growth_yoy
        salary
        tenure

        entity_info {
          company_info {
            description
            email
            founder_api
            founder_black
            founder_female
            founder_hispanic
            founder_male
            founder_multiple
            founder_native
            founder_white
            funding_stage
            hq_country
            hq_metro_area
            hq_region
            hq_state
            is_public
            last_funding_amount
            last_funding_year
            linkedin_url
            phone_number
            rcid
            rics_k10
            rics_k50
            rics_k400
            total_funding_amount
            ultimate_parent_name
            ultimate_parent_rcid
            valuation
            website
            year_founded
          }
        }
      }
    }
  }
`);
