import { Tooltip, Icon } from '@chakra-ui/react';
import { FiInfo } from 'react-icons/fi';
import { PAGE_CONTAINER_CLASS_NAME } from '../utils/constants';
import { ReactNode } from 'react';

export const TreeTooltip = ({ label }: { label: ReactNode }) => {
  return (
    <Tooltip
      label={label}
      placement="top"
      hasArrow
      bg="navyBlue.500"
      padding="12px"
      fontSize="12px"
      maxWidth="330px"
      borderRadius="4px"
      modifiers={[
        {
          name: 'preventOverflow',
          options: {
            boundary: document.querySelector(`.${PAGE_CONTAINER_CLASS_NAME}`),
            padding: { left: 10 },
          },
        },
      ]}
    >
      <span>
        <Icon height="12px" as={FiInfo} />
      </span>
    </Tooltip>
  );
};
