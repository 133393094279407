import { useMemo } from 'react';
import {
  CardListSelectController,
  CardListSelectControllerProps,
} from '@revelio/layout';
import { FeatureFlag } from '@revelio/core';
import { useUnleashFlag } from '../../../../hooks/unleash/useUnleashFlag';

export const ReportCardListSelectController = (
  props: Omit<CardListSelectControllerProps<string>, 'variant' | 'gridProps'>
) => {
  const isTighterSelectionCardsEnabled = useUnleashFlag(
    FeatureFlag.ReportBuilderTighterSelectionCards
  );

  const variant = isTighterSelectionCardsEnabled ? 'report' : 'dataset';
  const gridProps = useMemo(
    () => ({
      spacing: isTighterSelectionCardsEnabled ? '10px' : 3,
      columns: 2,
      gridAutoFlow: 'row',
    }),
    [isTighterSelectionCardsEnabled]
  );

  return (
    <CardListSelectController<string>
      {...props}
      variant={variant}
      gridProps={gridProps}
    />
  );
};
