import { Tooltip } from '@chakra-ui/react';
import {
  AISearchFilter,
  TalentDiscoveryAiFilterSearchResponse,
} from '@revelio/data-access';
import { useState } from 'react';

interface Props {
  prompt: string;
  response: TalentDiscoveryAiFilterSearchResponse;
  textareaRef: React.RefObject<HTMLTextAreaElement>;
}

export const ResponseHighlightedOverlay = ({
  prompt,
  response,
  textareaRef,
}: Props) => {
  const segments = getHighlightedSegments({ prompt, response });

  const [hoveredPromptIndex, setHoveredPromptIndex] = useState<number | null>(
    null
  );

  const handleClick = (index: number) => {
    if (textareaRef.current) {
      textareaRef.current.focus();
      textareaRef.current.setSelectionRange(index, index);
    }
  };

  return (
    <span
      style={{
        userSelect: 'none',
      }}
      onClick={() => hoveredPromptIndex && handleClick(hoveredPromptIndex)}
    >
      {prompt.split('').map((char, index) => {
        const matchingSegments = segments.filter(
          (segment) => index >= segment.startIndex && index < segment.endIndex
        );

        const isMatched = !matchingSegments.some(
          (segment) => !segment.isMatched
        );

        const matchingSegment = matchingSegments.find(
          (segment) => segment.isMatched === isMatched
        );

        const style = matchingSegment
          ? {
              borderBottom: isMatched ? '1px dotted green' : '1px dotted red',
              color: isMatched ? 'green' : 'red',
              cursor: 'text',
            }
          : { cursor: 'text' };

        const sharedHighlightCharProps = {
          style,
          onMouseOver: (e: React.MouseEvent) => {
            e.stopPropagation();
            setHoveredPromptIndex(index);
          },
          onMouseLeave: (e: React.MouseEvent) => {
            e.stopPropagation();
            setHoveredPromptIndex(null);
          },
        };

        if (
          matchingSegment?.explanation &&
          Math.floor(
            (matchingSegment.startIndex + matchingSegment.endIndex) / 2
          ) === index
        ) {
          return (
            <Tooltip
              key={index}
              label={matchingSegment?.explanation}
              hasArrow
              isDisabled={!matchingSegment?.explanation}
              isOpen={
                hoveredPromptIndex !== null &&
                matchingSegment.startIndex < hoveredPromptIndex &&
                matchingSegment.endIndex > hoveredPromptIndex
              }
            >
              <span {...sharedHighlightCharProps}>{char}</span>
            </Tooltip>
          );
        }

        return (
          <span key={index} {...sharedHighlightCharProps}>
            {char}
          </span>
        );
      })}
    </span>
  );
};

interface HighlightInfo {
  text: string; // only for debugging purposes to make it more readable when logging segments
  isMatched: boolean;
  startIndex: number;
  endIndex: number;
  explanation?: string;
}

const getHighlightedSegments = ({
  prompt,
  response,
}: {
  prompt: string;
  response: TalentDiscoveryAiFilterSearchResponse;
}): HighlightInfo[] => {
  const segments: HighlightInfo[] = [];

  // Helper function to process a single filter
  const processFilter = ({ relevantPromptText }: AISearchFilter) => {
    const index = prompt
      .toLowerCase()
      .indexOf(relevantPromptText.toLowerCase());

    if (index !== -1) {
      segments.push({
        text: relevantPromptText,
        isMatched: true,
        startIndex: index,
        endIndex: index + relevantPromptText.length,
      });
    }
  };

  // Process all known filters
  Object.entries(response.filters).forEach(([filterType, filterValue]) => {
    if (!filterValue) return;

    if (Array.isArray(filterValue)) {
      // Handle nested arrays (like skills and keywords)
      if (filterValue.length > 0 && Array.isArray(filterValue[0])) {
        (filterValue as AISearchFilter[][]).forEach((filterGroup) => {
          filterGroup.forEach(processFilter);
        });
      } else {
        // Handle regular arrays
        filterValue.forEach((v) => processFilter(v as AISearchFilter));
      }
    } else {
      // Handle single filters (like RangeFilter)
      processFilter(filterValue as AISearchFilter);
    }
  });

  // Handle unknown filters
  response.unknownFilters?.forEach(({ relevantPromptText, explanation }) => {
    const index = prompt
      .toLowerCase()
      .indexOf(relevantPromptText.toLowerCase());

    if (index !== -1) {
      segments.push({
        text: relevantPromptText,
        isMatched: false,
        explanation: explanation,
        startIndex: index,
        endIndex: index + relevantPromptText.length,
      });
    }
  });

  return segments;
};
