import { useCallback, useEffect } from 'react';
import { useMutation } from 'urql';
import { useForm } from 'react-hook-form';
import {
  Box,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  IconButton,
  Input,
  Popover,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { FiBookmark } from 'react-icons/fi';
import { selectEntity } from '@ngneat/elf-entities';
import { useObservable } from '@ngneat/react-rxjs';
import {
  ModelVersions,
  Pipeline,
  PipelineColumnType,
  PipelineConfig,
} from '@revelio/data-access';
import {
  CustomColumn,
  DatasetFilter,
  Deliverable,
  Deliverable as StateDeliverable,
} from '../../deliverables.model';
import { isEqual, noop } from 'lodash';

import { useGetLoggedInUser } from '@revelio/auth';
import {
  deliverablesStore,
  getOrderedCombinedColumns,
  getPipelineType,
} from '../../deliverables.repository';
import {
  DeletePipelineConfiguration,
  SavePipelineConfigurationMutation,
  usePipelineConfigurationsByUser,
} from './saved-pipeline-configuration.gql';
import {
  NEW_PIPELINE_CONFIGURATION_SELECT_OPTION,
  PipelineConfigurationSelectOption,
  applyPipelineConfiguration,
  globalPipelinConfigurationColumnsMap,
} from './saved-pipeline-configuration-select-helpers';
import { WarningTwoIcon } from '@chakra-ui/icons';
import { areSelectedColumnsIdenticalToColumnConfiguration } from '../columns.model';
import { removeEmptyValuesAndTypename } from '../../deliverables.model';
import { ActionModalControlPanel } from '@revelio/core';
import { produce } from 'immer';
import { WritableDraft } from 'immer/dist/internal';
import { serializeCustomColumnMultiGranularitySelectionListVariables } from '../custom/serialize-custom-columns.api';
import { serializeFilterMultiGranularitySelectionListVariables } from '../filters/serialize-filters.api';

export const SavePipelineConfiguration = ({
  entityId,
  isRevelioAdmin,
  pipelineConfigurationValue,
  setSelectedPipelineConfiguration,
}: {
  entityId: StateDeliverable['id'];
  isRevelioAdmin: boolean;
  pipelineConfigurationValue: PipelineConfigurationSelectOption;
  setSelectedPipelineConfiguration: React.Dispatch<
    React.SetStateAction<PipelineConfigurationSelectOption | null | undefined>
  >;
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const pipelineType = getPipelineType({ entityId });
  const [deliverable] = useObservable(
    deliverablesStore.pipe(selectEntity(entityId))
  );
  const [result, savePipelineConfiguration] = useMutation(
    SavePipelineConfigurationMutation
  );
  const [, deletePipelineConfiguration] = useMutation(
    DeletePipelineConfiguration
  );
  const {
    register,
    formState: { errors },
    handleSubmit,
    setValue,
  } = useForm<{ name: string }>({
    defaultValues: { name: pipelineConfigurationValue.label },
  });
  useEffect(() => {
    if (
      !isEqual(
        pipelineConfigurationValue,
        NEW_PIPELINE_CONFIGURATION_SELECT_OPTION
      )
    ) {
      setValue('name', pipelineConfigurationValue.label);
    } else {
      setValue('name', '');
    }
  }, [pipelineConfigurationValue, setValue]);
  const { loggedInUser } = useGetLoggedInUser();

  const selectedCreateNewOption =
    pipelineConfigurationValue === NEW_PIPELINE_CONFIGURATION_SELECT_OPTION;

  // const [tabIndex, setTabIndex] = useState(0);
  // const onCreateNewConfigurationTab = tabIndex === 1;
  const isGlobalPipelineConfiguration = globalPipelinConfigurationColumnsMap({
    entityId,
  })[pipelineConfigurationValue.value];

  const [{ data: pipelineConfigurations }, refetchConfigs] =
    usePipelineConfigurationsByUser();
  const selectedSavedPipelineConfiguration =
    pipelineConfigurations?.pipelineConfiguration?.find(
      (config) => config?.name === pipelineConfigurationValue.value
    );
  const canDelete = !!selectedSavedPipelineConfiguration?.id;
  // !onCreateNewConfigurationTab &&
  // const isEditingExistingSavedConfiguration =
  //   !selectedCreateNewOption && !isGlobalPipelineConfiguration;

  const otherDuplicateConfig = pipelineConfigurations?.pipelineConfiguration
    ?.filter((config) => config?.id !== selectedSavedPipelineConfiguration?.id)
    .find((saved) =>
      isConfigurationUnchangedFromSaved({
        entityId,
        current: {
          modelVersions: deliverable?.model_versions,
          pipeline: deliverable?.pipeline as Pipeline,
        },
        saved: {
          modelVersions: saved?.model_versions as ModelVersions,
          pipeline: saved?.pipeline as Pipeline,
        },
      })
    );

  const configUnchangedFromSaved = isConfigurationUnchangedFromSaved({
    entityId,
    current: {
      modelVersions: deliverable?.model_versions,
      pipeline: deliverable?.pipeline as Pipeline,
    },
    saved: {
      modelVersions:
        selectedSavedPipelineConfiguration?.model_versions as ModelVersions,
      pipeline: selectedSavedPipelineConfiguration?.pipeline as Pipeline,
    },
  });

  const savePipelineConfigToApi = useCallback(
    (name: string) => {
      const pipelineWithSerialisedCustomSteps = produce<
        Deliverable['pipeline'],
        WritableDraft<PipelineConfig['pipeline']>
      >(deliverable?.pipeline || {}, (draft) => {
        if (draft?.custom_columns) {
          draft.custom_columns =
            serializeCustomColumnMultiGranularitySelectionListVariables({
              customColumns: deliverable?.pipeline
                .custom_columns as CustomColumn[],
            });
        }

        if (draft?.filters) {
          draft.filters = serializeFilterMultiGranularitySelectionListVariables(
            {
              filters: deliverable?.pipeline.filters as DatasetFilter[],
            }
          );
        }
      });
      savePipelineConfiguration({
        configuration: {
          name,
          dashboard_global: false,
          user_id: loggedInUser.id,
          client_id: loggedInUser.client_name,
          model_versions: deliverable?.model_versions,
          pipeline: pipelineWithSerialisedCustomSteps as Pipeline,
        },
      });

      onClose();
      setTimeout(() => {
        refetchConfigs({ requestPolicy: 'network-only' });
      }, 1000);
      setSelectedPipelineConfiguration({
        label: pipelineConfigurationValue.label,
        value: pipelineConfigurationValue.label,
      });
    },
    [
      deliverable?.model_versions,
      deliverable?.pipeline,
      loggedInUser.client_name,
      loggedInUser.id,
      onClose,
      pipelineConfigurationValue.label,
      refetchConfigs,
      savePipelineConfiguration,
      setSelectedPipelineConfiguration,
    ]
  );
  return (
    <Popover placement="bottom-start" isOpen={isOpen} onClose={onClose}>
      <PopoverTrigger>
        <IconButton
          as="div"
          borderLeft="0"
          borderColor="gray.200"
          borderRadius="0"
          variant="outline"
          aria-label={`Save ${pipelineType} Column Set`}
          icon={
            <FiBookmark
              fill={
                isGlobalPipelineConfiguration || configUnchangedFromSaved
                  ? 'rgb(43, 108, 176)'
                  : 'none'
              }
            />
          }
          onClick={onOpen}
        />
      </PopoverTrigger>

      <PopoverContent cursor="default">
        {/* {!isEditingExistingSavedConfiguration && ( */}
        <PopoverHeader>
          {isGlobalPipelineConfiguration ? (
            <Flex alignItems="center" ml="3" flexDir="column">
              <Flex alignItems="center" justifyContent="center">
                <WarningTwoIcon
                  color="red.400"
                  w={4}
                  h={4}
                  mr="5px"
                  ml="10px"
                />{' '}
                Save Configuration
              </Flex>
              <Text fontSize="12px" color="red.400">
                Cannot save duplicate "{pipelineConfigurationValue.label}"
                configuration
              </Text>
            </Flex>
          ) : (
            'Save Configuration'
          )}
        </PopoverHeader>
        {/* )} */}

        {/* {isEditingExistingSavedConfiguration && (
          <Tabs
            isFitted
            variant="line-green"
            index={tabIndex}
            onChange={setTabIndex}
            position="relative"
            mt="2"
            mr="5"
          >
            <TabList mb="1em">
              <Tab position="relative">Edit</Tab>
              <Tab position="relative">New</Tab>
            </TabList>
          </Tabs>
        )} */}
        <PopoverCloseButton as="div" />
        <PopoverBody pl={0} mr="3">
          <FormControl
            id="pipeline_configuration_name"
            isInvalid={!!errors.name}
            ml="3"
          >
            <FormLabel fontSize="sm" color="text.primary" fontWeight="semibold">
              Name
            </FormLabel>
            <Input
              {...register('name', {
                required: 'name is required.',
                minLength: {
                  value: 1,
                  message: 'Minimum length should be 1',
                },
                validate: {
                  nameUnique: (value) => {
                    if (
                      pipelineConfigurations?.pipelineConfiguration?.find(
                        (saved) => saved?.name === value
                      )
                    ) {
                      return 'Name must be unique.';
                    }

                    return true;
                  },
                },
              })}
              placeholder="Configuration Name"
              isDisabled={
                !selectedCreateNewOption && !!isGlobalPipelineConfiguration
              }
              onKeyUp={(event) => event.preventDefault()}
            />
            <FormErrorMessage>
              {errors.name && errors.name.message}
            </FormErrorMessage>
          </FormControl>

          {(otherDuplicateConfig || configUnchangedFromSaved) && (
            <Flex
              alignItems="center"
              justifyContent="center"
              color="orange"
              fontSize="14px"
            >
              <WarningTwoIcon color="orange" w={3} h={3} mr="5px" ml="10px" />{' '}
              Duplicate settings to{' '}
              {otherDuplicateConfig?.name
                ? `"${otherDuplicateConfig?.name}"`
                : 'current configuration'}
            </Flex>
          )}

          <Box pl="3" mt="20px">
            <ActionModalControlPanel
              cancelText="Cancel"
              resetText="Delete"
              submitText="Save as new"
              includeReset={canDelete}
              menuHandlers={[
                {
                  text: `Save edits to ${pipelineConfigurationValue.label}`,
                  handler: () => {
                    savePipelineConfigToApi(pipelineConfigurationValue.label);
                  },
                },
              ]}
              handlersAreDisabled={
                selectedCreateNewOption || !!isGlobalPipelineConfiguration
              }
              customProps={
                !canDelete
                  ? {
                      containerProps: {
                        justifyContent: 'flex-end',
                      },
                    }
                  : undefined
              }
              onClose={noop}
              onCancel={onClose}
              onReset={() => {
                deletePipelineConfiguration({
                  id: selectedSavedPipelineConfiguration?.id as string,
                });
                onClose();
                setTimeout(() => {
                  refetchConfigs({ requestPolicy: 'network-only' });
                }, 1000);

                setSelectedPipelineConfiguration(
                  NEW_PIPELINE_CONFIGURATION_SELECT_OPTION
                );
                applyPipelineConfiguration({
                  entityId,
                  columns: [],
                });
              }}
              // additionalCTA={
              //   <Button
              //     borderRadius="4px"
              //     fontSize="12px"
              //     fontWeight="600"
              //     colorScheme="green"
              //     variant="outline"
              //     size="sm"
              //     isLoading={false}
              //     isDisabled={
              //       isEditingExistingSavedConfiguration &&
              //       !onCreateNewConfigurationTab
              //     }
              //     onClick={noop}
              //   >
              //     Edit
              //   </Button>
              // }
              submitIsDisabled={
                !!isGlobalPipelineConfiguration ||
                otherDuplicateConfig ||
                configUnchangedFromSaved
              }
              onSubmit={handleSubmit((formData) => {
                savePipelineConfigToApi(formData.name);
              })}
              submitIsLoading={result.fetching}
              isInsideButton
            />
          </Box>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
};

const isConfigurationUnchangedFromSaved = ({
  entityId,
  current,
  saved,
}: {
  entityId: StateDeliverable['id'];
  current: {
    modelVersions?: ModelVersions;
    pipeline: Pipeline;
  };
  saved: {
    modelVersions?: ModelVersions;
    pipeline: Pipeline;
  };
}) => {
  const { columns: currentColumns, ...nonColumnConfig } =
    current.pipeline || {};
  const { columns: savedColumns, ...savedNonColumnConfig } =
    saved.pipeline || {};
  return (
    isEqual(current.modelVersions, saved.modelVersions) &&
    areSelectedColumnsIdenticalToColumnConfiguration({
      entityId,
      selectedPipelineColumns: (currentColumns as PipelineColumnType[]) || [],
      pipelineColumnsToCompare: getOrderedCombinedColumns({
        entityId,
        columnIds: (savedColumns as PipelineColumnType[]) || [],
      }),
    }) &&
    isEqual(
      removeEmptyValuesAndTypename(nonColumnConfig), // api return null for empty properties rather than jndefined
      removeEmptyValuesAndTypename(savedNonColumnConfig)
    )
  );
};
