import { GetTalentDiscoveryMapStatsQueryVariables } from '../../gql/graphql';

export const TALENT_DISCOVERY_FIELDS = [
  'company',
  'country',
  'msa',
  'gender',
  'ethnicity',
  'seniority',
  'highest_degree',
  'flight_risk',
  'prestige',
  'remote_suitability',
  'job_category',
  'role_k150',
  'role_k1500',
] as const;
export type TalentDiscoveryField = (typeof TALENT_DISCOVERY_FIELDS)[number];

export type GetTalentDiscoveryTooltipPayload =
  GetTalentDiscoveryMapStatsQueryVariables;

export interface TalentDiscoveryTooltipData {
  growth: string | number;
  marketTightness: string | number;
  postings: number;
  salaryDist: number[];
  timeToFill: string | number;
  headcount: number;
}

export type TalentDiscoveryMutateResponse =
  | { success: true; message: string }
  | { success: false; error: string };

// First define the metadata schema with literal types
export const TYPE_DEFINITIONS = {
  AISearchFilter: {
    description: 'Basic filter type with name and relevance',
    structure: {
      name: 'string',
      relevantPromptText: 'string',
    },
  },
  AISearchPreviousCurrentFilter: {
    description:
      'Filter that can be marked as current or previous which extends AISearchFilter',
    structure: {
      name: 'string',
      relevantPromptText: 'string',
      isCurrent: 'boolean',
    },
  },
  RangeFilter: {
    description: 'Filter for numeric ranges',
    structure: {
      range: '[number | null, number | null]',
      relevantPromptText: 'string',
      isCurrent: 'boolean',
    },
  },
} as const;

// Type generation helpers
type TypeFromString<T extends string> = T extends 'string'
  ? string
  : T extends 'boolean'
    ? boolean
    : T extends '[number | null, number | null]'
      ? [number | null, number | null]
      : never;

type GenerateType<T extends Record<string, string>> = {
  [K in keyof T]: TypeFromString<T[K]>;
};

// Generate the actual types from the schema
export type AISearchFilter = GenerateType<
  (typeof TYPE_DEFINITIONS)['AISearchFilter']['structure']
>;
export type AISearchPreviousCurrentFilter = GenerateType<
  (typeof TYPE_DEFINITIONS)['AISearchPreviousCurrentFilter']['structure']
>;
export type RangeFilter = GenerateType<
  (typeof TYPE_DEFINITIONS)['RangeFilter']['structure']
>;

// The rest of your schema can now reference both the types and the definitions
export const FILTER_SCHEMA = {
  metadata: {
    typeDefinitions: TYPE_DEFINITIONS,
  },
  filters: {
    company: {
      type: 'Array<AISearchPreviousCurrentFilter>',
      description: 'Array of company names',
    },
    industry: {
      type: 'Array<AISearchPreviousCurrentFilter>',
      description: 'Array of industry names',
    },
    highest_degree: {
      type: 'Array<AISearchFilter>',
      description: 'Array of education levels',
      values: [
        'High School',
        'Associate',
        'Bachelor',
        'MBA',
        'Master',
        'Doctor',
      ],
    },
    ethnicity: {
      type: 'Array<AISearchFilter>',
      description: 'Array of ethnicities',
      values: ['White', 'Asian', 'Hispanic', 'Black', 'Native', 'Multiple'],
    },
    flight_risk: {
      type: 'Array<AISearchPreviousCurrentFilter>',
      description: 'Array of flight risk levels',
      values: ['Very Low', 'Low', 'Medium', 'High', 'Very High'],
    },
    gender: {
      type: 'Array<AISearchFilter>',
      description: 'Array of gender categories',
      values: ['Male', 'Female'],
    },
    geography: {
      type: 'Array<AISearchPreviousCurrentFilter>',
      description: 'Array of locations',
    },
    name: { type: 'AISearchFilter', description: 'Single name string' },
    prestige: {
      type: 'Array<AISearchPreviousCurrentFilter>',
      description: 'Array of prestige levels',
      values: ['Very Low', 'Low', 'Medium', 'High', 'Very High'],
    },
    remote_suitability: {
      type: 'Array<AISearchPreviousCurrentFilter>',
      description: 'Array of remote work suitability levels',
      values: ['Very Low', 'Low', 'Medium', 'High', 'Very High'],
    },
    role: {
      type: 'Array<AISearchPreviousCurrentFilter>',
      description: 'Array of job roles',
    },
    school: {
      type: 'Array<AISearchFilter>',
      description: 'Array of school names',
    },
    seniority: {
      type: 'Array<AISearchPreviousCurrentFilter>',
      description: 'Array of seniority levels',
      values: [
        'Entry Level',
        'Junior',
        'Associate',
        'Manager',
        'Director',
        'Executive',
        'Senior Executive',
      ],
    },
    salary_total: {
      type: 'RangeFilter',
      description: 'Tuple of min and max salary',
    },
    skill: {
      type: 'Array<Array<AISearchFilter>>',
      description: 'Array of skill arrays',
    },
    keywords: {
      type: 'Array<Array<AISearchFilter>>',
      description: 'Array of keyword arrays',
    },
  },
  unknownFilters: {
    type: 'Array<UnknownFilter>',
    description: "Optional array of unknown filters that couldn't be mapped",
  },
} as const;

type SchemaTypeMap = {
  AISearchFilter: AISearchFilter;
  RangeFilter: RangeFilter;
  'Array<AISearchFilter>': AISearchFilter[];
  'Array<AISearchPreviousCurrentFilter>': AISearchPreviousCurrentFilter[];
  'Array<Array<AISearchFilter>>': AISearchFilter[][];
};

type UnknownFilter = {
  explanation: string;
} & AISearchFilter;

// Generate the type from the schema
export type TalentDiscoveryAiFilterSearchResponse = {
  filters: {
    [K in keyof (typeof FILTER_SCHEMA)['filters']]?: SchemaTypeMap[(typeof FILTER_SCHEMA)['filters'][K]['type']];
  };
  unknownFilters: UnknownFilter[];
};
