import {
  GetTalentDiscoveryTooltipPayload,
  TalentDiscoveryV1_5Filter,
} from '@revelio/data-access';
import { TalentDiscoveryFilterState } from '../filters/td-filter-reducer';
import { serialiseTalentDiscoveryFilters } from './serialiseTalentDiscoveryFilters';
import {
  RICS_FILTERS,
  SelectionCategories,
  SelectionList,
  ValidValueTypes,
} from '@revelio/filtering';
import { getFilterChildSelectionListIds } from '@revelio/filtering';

export const serialiseTooltipFilters = async (
  filters: TalentDiscoveryFilterState['filters'],
  selectionLists: SelectionList<ValidValueTypes>[]
): Promise<GetTalentDiscoveryTooltipPayload> => {
  const allSerialisedSelectedFilters = await serialiseTalentDiscoveryFilters(
    filters,
    true
  );

  const supportedTooltipSerialisedFiltersExcludingRole = Object.entries(
    allSerialisedSelectedFilters
  ).reduce((acc, [key, value]) => {
    // these serialised filters normally include current vs previous, tooltip only takes into account ids
    if (
      [
        SelectionCategories.COMPANY,
        SelectionCategories.SENIORITY,
        ...RICS_FILTERS,
      ].includes(key as SelectionCategories)
    ) {
      return { ...acc, [key]: (value as { ids: number[] })?.ids };
    }

    if ([SelectionCategories.ETHNICITY].includes(key as SelectionCategories)) {
      return { ...acc, [key]: value };
    }

    return acc;
  }, {} as GetTalentDiscoveryTooltipPayload);

  return {
    ...supportedTooltipSerialisedFiltersExcludingRole,
    // tooltip sources don't support cross granularity role filtes (e.g. role_k7 + role_k150 filter)
    ...getHomogenisedRoleFilterIds({
      serialisedSelectedFilters: allSerialisedSelectedFilters,
      selectionLists,
    }),
  };
};

const getHomogenisedRoleFilterIds = ({
  serialisedSelectedFilters,
  selectionLists,
}: {
  serialisedSelectedFilters: TalentDiscoveryV1_5Filter;
  selectionLists: SelectionList<ValidValueTypes>[];
}) => {
  const jobCategoryIds = (
    serialisedSelectedFilters.job_category?.ids || []
  ).map((id) => id.toString());
  const roleK150Ids = (serialisedSelectedFilters.role_k150?.ids || []).map(
    (id) => id.toString()
  );
  const roleK1500Ids = (serialisedSelectedFilters.role_k1500?.ids || []).map(
    (id) => id.toString()
  );

  const jobCategoryIdsToRoleK150Ids = getFilterChildSelectionListIds({
    selectedFilterValues: jobCategoryIds ?? [],
    selectionLists,
    selectedFilterSelectionListId: SelectionCategories.JOB_CATEGORY,
    noSelectedFilterSelectionListId: SelectionCategories.JOB_CATEGORY,
    isDashboardFilterRoles: true,
  });

  const roleK150IdsToRoleK1500Ids = getFilterChildSelectionListIds({
    selectedFilterValues: [...jobCategoryIdsToRoleK150Ids, ...roleK150Ids],
    selectionLists,
    selectedFilterSelectionListId: SelectionCategories.ROLE_K150,
    noSelectedFilterSelectionListId: SelectionCategories.ROLE_K1500,
    isDashboardFilterRoles: true,
  });

  // Limiting to 300 as api request exponentially grows (~6 seconds for 300)
  const selectedRoleK1500Ids = [
    ...roleK150IdsToRoleK1500Ids,
    ...roleK1500Ids,
  ].slice(0, 300);

  return selectedRoleK1500Ids.length
    ? { [SelectionCategories.ROLE_K1500]: selectedRoleK1500Ids }
    : {};
};
