import { RefObject, useEffect, useRef, useState } from 'react';

export const useResizeObserver = (
  ref?: RefObject<HTMLDivElement>
): {
  containerRef: RefObject<HTMLDivElement>;
  width: number;
  height: number;
} => {
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);
  const containerRef = useRef<HTMLDivElement>(null);

  /** ======== Resize Observer ======== */
  useEffect(() => {
    const container = ref?.current || containerRef.current;
    if (!container) return;

    const resizeObserver = new ResizeObserver(() => {
      const { width, height } = container.getBoundingClientRect();
      setWidth(width);
      setHeight(height);
    });
    resizeObserver.observe(container);
    return () => {
      resizeObserver.disconnect();
    };
  }, [ref]);

  return { containerRef: ref || containerRef, width, height };
};
