// const k50IdLabels: {
//     "17": "",
// }
export const k50IdLabels = {
  '0': 'Aerospace and Defense',
  '1': 'Agricultural Services',
  '2': 'Apparel Retail',
  '3': 'Architectural Design Services',
  '4': 'Automation Solutions',
  '5': 'Automotive Services',
  '6': 'Biotech and Healthcare Services',
  '7': 'Business Services',
  '8': 'Commercial Aviation',
  '9': 'Consulting and Advisory Services',
  '10': 'Consumer Technology Distribution',
  '11': 'Culture and Entertainment',
  '12': 'Design and Printing Services',
  '13': 'Digital Commerce Services',
  '14': 'Education Services',
  '15': 'Electronics Manufacturing',
  '16': 'Energy and Resources',
  '17': 'Engineering and Construction Services',
  '18': 'Environmental Services',
  '19': 'Facility Management Services',
  '20': 'Finance',
  '21': 'Fitness and Wellness Services',
  '22': 'Food and Beverage',
  '23': 'Food and Hospitality Services',
  '24': 'Healthcare and Wellness Services',
  '25': 'Hospitality and Tourism Management',
  '26': 'Human Resources Services',
  '27': 'Humanitarian Services',
  '28': 'IT Consulting Services',
  '29': 'Industrial Manufacturing',
  '30': 'Information Technology Services',
  '31': 'Language Services',
  '32': 'Legal Services',
  '33': 'Logistics and Transportation',
  '34': 'Marketing and Advertising Services',
  '35': 'Materials Manufacturing',
  '36': 'Media and Entertainment',
  '37': 'Packaging Services',
  '38': 'Pharmaceuticals',
  '39': 'Professional Development Services',
  '40': 'Professional and Trade Associations',
  '41': 'Public Sector Management',
  '42': 'Quality Assurance and Testing Services',
  '43': 'Real Estate and Development',
  '44': 'Research and Development',
  '45': 'Retail and Consumer Goods',
  '46': 'Sports Management',
  '47': 'Telecommunications Services',
  '48': 'Wellness Products',
};
export const ricsK50SimiliaryScoreMatrix: {
  [ricsIndex: string]: {
    [ricsIndex: string]: number; // similarity score
  };
} = {
  '1': {
    '1': 1.0,
    '2': 0.733254,
    '3': 0.690019,
    '4': 0.755291,
    '5': 0.870512,
    '6': 0.820422,
    '7': 0.788033,
    '8': 0.785049,
    '9': 0.839815,
    '10': 0.793373,
    '11': 0.798677,
    '12': 0.729465,
    '13': 0.749942,
    '14': 0.747044,
    '15': 0.680493,
    '16': 0.876365,
    '17': 0.877186,
    '18': 0.869987,
    '19': 0.875653,
    '20': 0.872997,
    '21': 0.781105,
    '22': 0.695404,
    '23': 0.653414,
    '24': 0.633609,
    '25': 0.67563,
    '26': 0.679279,
    '27': 0.747189,
    '28': 0.68423,
    '29': 0.825494,
    '30': 0.856179,
    '31': 0.850238,
    '32': 0.694507,
    '33': 0.65611,
    '34': 0.845628,
    '35': 0.719745,
    '36': 0.765327,
    '37': 0.699254,
    '39': 0.755091,
    '40': 0.693456,
    '41': 0.758179,
    '42': 0.809269,
    '43': 0.786538,
    '44': 0.757979,
    '45': 0.707105,
    '46': 0.787516,
    '47': 0.673589,
    '48': 0.73167,
    '49': 0.811979,
    '50': 0.686584,
  },
  '2': {
    '1': 0.733254,
    '2': 1.0,
    '3': 0.796183,
    '4': 0.667121,
    '5': 0.821669,
    '6': 0.757681,
    '7': 0.770293,
    '8': 0.715862,
    '9': 0.600874,
    '10': 0.744521,
    '11': 0.734332,
    '12': 0.650492,
    '13': 0.704361,
    '14': 0.755281,
    '15': 0.589417,
    '16': 0.795964,
    '17': 0.86968,
    '18': 0.836583,
    '19': 0.872644,
    '20': 0.74707,
    '21': 0.777504,
    '22': 0.663822,
    '23': 0.928786,
    '24': 0.748645,
    '25': 0.66113,
    '26': 0.68217,
    '27': 0.701355,
    '28': 0.669532,
    '29': 0.707451,
    '30': 0.848023,
    '31': 0.741204,
    '32': 0.555976,
    '33': 0.589861,
    '34': 0.781049,
    '35': 0.686708,
    '36': 0.891674,
    '37': 0.618685,
    '39': 0.809115,
    '40': 0.818897,
    '41': 0.655375,
    '42': 0.753089,
    '43': 0.726858,
    '44': 0.860923,
    '45': 0.736946,
    '46': 0.720958,
    '47': 0.844798,
    '48': 0.608907,
    '49': 0.667588,
    '50': 0.851954,
  },
  '3': {
    '1': 0.690019,
    '2': 0.796183,
    '3': 1.0,
    '4': 0.702517,
    '5': 0.781037,
    '6': 0.843659,
    '7': 0.693264,
    '8': 0.807373,
    '9': 0.65441,
    '10': 0.681809,
    '11': 0.837088,
    '12': 0.765804,
    '13': 0.854876,
    '14': 0.823978,
    '15': 0.564727,
    '16': 0.799551,
    '17': 0.738275,
    '18': 0.791286,
    '19': 0.734699,
    '20': 0.729107,
    '21': 0.729473,
    '22': 0.741447,
    '23': 0.879263,
    '24': 0.827618,
    '25': 0.638563,
    '26': 0.833899,
    '27': 0.71533,
    '28': 0.631144,
    '29': 0.70244,
    '30': 0.816701,
    '31': 0.750385,
    '32': 0.66958,
    '33': 0.510169,
    '34': 0.738779,
    '35': 0.80835,
    '36': 0.807699,
    '37': 0.771309,
    '39': 0.862956,
    '40': 0.760713,
    '41': 0.695081,
    '42': 0.687721,
    '43': 0.613989,
    '44': 0.797806,
    '45': 0.757848,
    '46': 0.558356,
    '47': 0.909622,
    '48': 0.732873,
    '49': 0.702599,
    '50': 0.936545,
  },
  '4': {
    '1': 0.755291,
    '2': 0.667121,
    '3': 0.702517,
    '4': 1.0,
    '5': 0.703988,
    '6': 0.672013,
    '7': 0.695433,
    '8': 0.70021,
    '9': 0.573414,
    '10': 0.766218,
    '11': 0.655336,
    '12': 0.743452,
    '13': 0.823522,
    '14': 0.660274,
    '15': 0.574598,
    '16': 0.700256,
    '17': 0.722989,
    '18': 0.872177,
    '19': 0.771889,
    '20': 0.748194,
    '21': 0.701454,
    '22': 0.584125,
    '23': 0.630664,
    '24': 0.663571,
    '25': 0.592201,
    '26': 0.717144,
    '27': 0.70106,
    '28': 0.594957,
    '29': 0.73687,
    '30': 0.707964,
    '31': 0.73952,
    '32': 0.624979,
    '33': 0.68885,
    '34': 0.660422,
    '35': 0.773918,
    '36': 0.671634,
    '37': 0.656788,
    '39': 0.708818,
    '40': 0.616658,
    '41': 0.645135,
    '42': 0.691586,
    '43': 0.689322,
    '44': 0.685375,
    '45': 0.837658,
    '46': 0.674159,
    '47': 0.639677,
    '48': 0.604164,
    '49': 0.613913,
    '50': 0.674932,
  },
  '5': {
    '1': 0.870512,
    '2': 0.821669,
    '3': 0.781037,
    '4': 0.703988,
    '5': 1.0,
    '6': 0.870849,
    '7': 0.807864,
    '8': 0.811795,
    '9': 0.732459,
    '10': 0.765731,
    '11': 0.8755,
    '12': 0.603244,
    '13': 0.776083,
    '14': 0.800374,
    '15': 0.508685,
    '16': 0.949168,
    '17': 0.904486,
    '18': 0.91214,
    '19': 0.889266,
    '20': 0.854028,
    '21': 0.769534,
    '22': 0.60283,
    '23': 0.787792,
    '24': 0.687977,
    '25': 0.613626,
    '26': 0.655109,
    '27': 0.733245,
    '28': 0.521494,
    '29': 0.832622,
    '30': 0.957917,
    '31': 0.866845,
    '32': 0.647786,
    '33': 0.549141,
    '34': 0.843473,
    '35': 0.722288,
    '36': 0.894001,
    '37': 0.63888,
    '39': 0.919778,
    '40': 0.768972,
    '41': 0.610989,
    '42': 0.699165,
    '43': 0.62607,
    '44': 0.863198,
    '45': 0.69464,
    '46': 0.654316,
    '47': 0.802442,
    '48': 0.55132,
    '49': 0.821449,
    '50': 0.786596,
  },
  '6': {
    '1': 0.820422,
    '2': 0.757681,
    '3': 0.843659,
    '4': 0.672013,
    '5': 0.870849,
    '6': 1.0,
    '7': 0.681851,
    '8': 0.805,
    '9': 0.800718,
    '10': 0.71971,
    '11': 0.84699,
    '12': 0.632754,
    '13': 0.742229,
    '14': 0.781446,
    '15': 0.521085,
    '16': 0.870646,
    '17': 0.85913,
    '18': 0.878115,
    '19': 0.787888,
    '20': 0.80839,
    '21': 0.797084,
    '22': 0.651068,
    '23': 0.773959,
    '24': 0.729711,
    '25': 0.592367,
    '26': 0.745839,
    '27': 0.757578,
    '28': 0.542732,
    '29': 0.748881,
    '30': 0.910783,
    '31': 0.780185,
    '32': 0.540583,
    '33': 0.54137,
    '34': 0.861251,
    '35': 0.731828,
    '36': 0.817013,
    '37': 0.657267,
    '39': 0.810312,
    '40': 0.685681,
    '41': 0.621879,
    '42': 0.680737,
    '43': 0.641167,
    '44': 0.750806,
    '45': 0.779417,
    '46': 0.548464,
    '47': 0.859616,
    '48': 0.668316,
    '49': 0.798169,
    '50': 0.793087,
  },
  '7': {
    '1': 0.788033,
    '2': 0.770293,
    '3': 0.693264,
    '4': 0.695433,
    '5': 0.807864,
    '6': 0.681851,
    '7': 1.0,
    '8': 0.801046,
    '9': 0.638767,
    '10': 0.803914,
    '11': 0.721823,
    '12': 0.628917,
    '13': 0.724062,
    '14': 0.806878,
    '15': 0.621397,
    '16': 0.788384,
    '17': 0.738661,
    '18': 0.765413,
    '19': 0.841956,
    '20': 0.813498,
    '21': 0.792053,
    '22': 0.717608,
    '23': 0.707735,
    '24': 0.653606,
    '25': 0.868301,
    '26': 0.63409,
    '27': 0.784103,
    '28': 0.702448,
    '29': 0.826469,
    '30': 0.735028,
    '31': 0.85711,
    '32': 0.690087,
    '33': 0.660424,
    '34': 0.700807,
    '35': 0.751503,
    '36': 0.720363,
    '37': 0.653208,
    '39': 0.750222,
    '40': 0.907246,
    '41': 0.715219,
    '42': 0.760011,
    '43': 0.703998,
    '44': 0.762539,
    '45': 0.701692,
    '46': 0.794009,
    '47': 0.709247,
    '48': 0.577416,
    '49': 0.718329,
    '50': 0.813782,
  },
  '8': {
    '1': 0.785048,
    '2': 0.715863,
    '3': 0.807373,
    '4': 0.70021,
    '5': 0.811795,
    '6': 0.805,
    '7': 0.801046,
    '8': 1.0,
    '9': 0.729966,
    '10': 0.853041,
    '11': 0.852545,
    '12': 0.623701,
    '13': 0.796861,
    '14': 0.897188,
    '15': 0.565249,
    '16': 0.794829,
    '17': 0.79555,
    '18': 0.81336,
    '19': 0.809519,
    '20': 0.826523,
    '21': 0.83277,
    '22': 0.611186,
    '23': 0.751153,
    '24': 0.740311,
    '25': 0.675751,
    '26': 0.74792,
    '27': 0.880997,
    '28': 0.615846,
    '29': 0.917463,
    '30': 0.796686,
    '31': 0.906951,
    '32': 0.762114,
    '33': 0.641366,
    '34': 0.830796,
    '35': 0.820498,
    '36': 0.753395,
    '37': 0.698622,
    '39': 0.771156,
    '40': 0.760194,
    '41': 0.716611,
    '42': 0.687172,
    '43': 0.673822,
    '44': 0.780592,
    '45': 0.799427,
    '46': 0.591467,
    '47': 0.814373,
    '48': 0.593342,
    '49': 0.851346,
    '50': 0.789834,
  },
  '9': {
    '1': 0.839815,
    '2': 0.600874,
    '3': 0.65441,
    '4': 0.573414,
    '5': 0.73246,
    '6': 0.800718,
    '7': 0.638767,
    '8': 0.729966,
    '9': 1.0,
    '10': 0.672192,
    '11': 0.676458,
    '12': 0.663382,
    '13': 0.598424,
    '14': 0.703586,
    '15': 0.521435,
    '16': 0.723887,
    '17': 0.769399,
    '18': 0.744433,
    '19': 0.76131,
    '20': 0.832989,
    '21': 0.755332,
    '22': 0.692888,
    '23': 0.658093,
    '24': 0.760965,
    '25': 0.592919,
    '26': 0.783842,
    '27': 0.647785,
    '28': 0.549258,
    '29': 0.665937,
    '30': 0.745557,
    '31': 0.713076,
    '32': 0.589503,
    '33': 0.568788,
    '34': 0.915148,
    '35': 0.634949,
    '36': 0.663826,
    '37': 0.657403,
    '39': 0.676556,
    '40': 0.551543,
    '41': 0.578393,
    '42': 0.713299,
    '43': 0.657862,
    '44': 0.59954,
    '45': 0.663074,
    '46': 0.516752,
    '47': 0.687309,
    '48': 0.712239,
    '49': 0.779557,
    '50': 0.625221,
  },
  '10': {
    '1': 0.793373,
    '2': 0.744521,
    '3': 0.681809,
    '4': 0.766218,
    '5': 0.765731,
    '6': 0.71971,
    '7': 0.803914,
    '8': 0.853041,
    '9': 0.672192,
    '10': 1.0,
    '11': 0.713606,
    '12': 0.62978,
    '13': 0.735463,
    '14': 0.81314,
    '15': 0.623202,
    '16': 0.694931,
    '17': 0.763778,
    '18': 0.809803,
    '19': 0.832205,
    '20': 0.83182,
    '21': 0.899421,
    '22': 0.609511,
    '23': 0.693626,
    '24': 0.668018,
    '25': 0.672161,
    '26': 0.670283,
    '27': 0.920308,
    '28': 0.6927,
    '29': 0.888102,
    '30': 0.72579,
    '31': 0.874616,
    '32': 0.738,
    '33': 0.853224,
    '34': 0.775259,
    '35': 0.808354,
    '36': 0.66991,
    '37': 0.643879,
    '39': 0.704304,
    '40': 0.692813,
    '41': 0.755116,
    '42': 0.823534,
    '43': 0.782099,
    '44': 0.715648,
    '45': 0.823459,
    '46': 0.686172,
    '47': 0.699486,
    '48': 0.626473,
    '49': 0.733395,
    '50': 0.687466,
  },
  '11': {
    '1': 0.798677,
    '2': 0.734332,
    '3': 0.837088,
    '4': 0.655336,
    '5': 0.8755,
    '6': 0.84699,
    '7': 0.721823,
    '8': 0.852545,
    '9': 0.676458,
    '10': 0.713606,
    '11': 1.0,
    '12': 0.629353,
    '13': 0.799046,
    '14': 0.850916,
    '15': 0.499197,
    '16': 0.903543,
    '17': 0.827631,
    '18': 0.81773,
    '19': 0.777721,
    '20': 0.786103,
    '21': 0.74435,
    '22': 0.564164,
    '23': 0.752985,
    '24': 0.643689,
    '25': 0.590757,
    '26': 0.67368,
    '27': 0.74685,
    '28': 0.55665,
    '29': 0.839896,
    '30': 0.855742,
    '31': 0.86667,
    '32': 0.714393,
    '33': 0.485221,
    '34': 0.771933,
    '35': 0.794752,
    '36': 0.803703,
    '37': 0.718678,
    '39': 0.826691,
    '40': 0.763002,
    '41': 0.64916,
    '42': 0.646492,
    '43': 0.613349,
    '44': 0.835774,
    '45': 0.693397,
    '46': 0.615578,
    '47': 0.81785,
    '48': 0.59716,
    '49': 0.897329,
    '50': 0.811882,
  },
  '12': {
    '1': 0.729465,
    '2': 0.650492,
    '3': 0.765804,
    '4': 0.743452,
    '5': 0.603244,
    '6': 0.632754,
    '7': 0.628917,
    '8': 0.623701,
    '9': 0.663382,
    '10': 0.62978,
    '11': 0.629353,
    '12': 1.0,
    '13': 0.849493,
    '14': 0.692534,
    '15': 0.790125,
    '16': 0.648486,
    '17': 0.620853,
    '18': 0.691597,
    '19': 0.672029,
    '20': 0.674927,
    '21': 0.633888,
    '22': 0.836248,
    '23': 0.688782,
    '24': 0.750547,
    '25': 0.677626,
    '26': 0.845658,
    '27': 0.615615,
    '28': 0.803948,
    '29': 0.566965,
    '30': 0.621423,
    '31': 0.653441,
    '32': 0.740489,
    '33': 0.628064,
    '34': 0.651706,
    '35': 0.780435,
    '36': 0.598822,
    '37': 0.899948,
    '39': 0.692377,
    '40': 0.565119,
    '41': 0.825265,
    '42': 0.799765,
    '43': 0.797128,
    '44': 0.58783,
    '45': 0.709153,
    '46': 0.702842,
    '47': 0.716604,
    '48': 0.91085,
    '49': 0.633303,
    '50': 0.738029,
  },
  '13': {
    '1': 0.749942,
    '2': 0.704361,
    '3': 0.854876,
    '4': 0.823521,
    '5': 0.776083,
    '6': 0.742229,
    '7': 0.724062,
    '8': 0.796861,
    '9': 0.598424,
    '10': 0.735463,
    '11': 0.799046,
    '12': 0.849493,
    '13': 1.0,
    '14': 0.8289,
    '15': 0.61467,
    '16': 0.78768,
    '17': 0.70113,
    '18': 0.807051,
    '19': 0.756717,
    '20': 0.735213,
    '21': 0.696268,
    '22': 0.665987,
    '23': 0.728463,
    '24': 0.71116,
    '25': 0.610687,
    '26': 0.760963,
    '27': 0.753718,
    '28': 0.655563,
    '29': 0.761736,
    '30': 0.754978,
    '31': 0.822199,
    '32': 0.830802,
    '33': 0.607188,
    '34': 0.690093,
    '35': 0.917206,
    '36': 0.709221,
    '37': 0.884835,
    '39': 0.859453,
    '40': 0.68944,
    '41': 0.750096,
    '42': 0.711367,
    '43': 0.681523,
    '44': 0.741835,
    '45': 0.759753,
    '46': 0.644257,
    '47': 0.805454,
    '48': 0.699679,
    '49': 0.738936,
    '50': 0.82008,
  },
  '14': {
    '1': 0.747043,
    '2': 0.755281,
    '3': 0.823978,
    '4': 0.660274,
    '5': 0.800374,
    '6': 0.781446,
    '7': 0.806878,
    '8': 0.897188,
    '9': 0.703586,
    '10': 0.81314,
    '11': 0.850916,
    '12': 0.692534,
    '13': 0.8289,
    '14': 1.0,
    '15': 0.547989,
    '16': 0.804634,
    '17': 0.757942,
    '18': 0.754579,
    '19': 0.764722,
    '20': 0.769834,
    '21': 0.834784,
    '22': 0.676135,
    '23': 0.766621,
    '24': 0.741689,
    '25': 0.62029,
    '26': 0.738092,
    '27': 0.828898,
    '28': 0.620306,
    '29': 0.892156,
    '30': 0.751588,
    '31': 0.948347,
    '32': 0.774371,
    '33': 0.573376,
    '34': 0.782882,
    '35': 0.898372,
    '36': 0.725696,
    '37': 0.806464,
    '39': 0.782913,
    '40': 0.733548,
    '41': 0.74009,
    '42': 0.746343,
    '43': 0.639352,
    '44': 0.716945,
    '45': 0.766905,
    '46': 0.609052,
    '47': 0.822147,
    '48': 0.642084,
    '49': 0.859689,
    '50': 0.80996,
  },
  '15': {
    '1': 0.680493,
    '2': 0.589417,
    '3': 0.564727,
    '4': 0.574598,
    '5': 0.508685,
    '6': 0.521085,
    '7': 0.621397,
    '8': 0.565249,
    '9': 0.521435,
    '10': 0.623202,
    '11': 0.499197,
    '12': 0.790125,
    '13': 0.61467,
    '14': 0.547989,
    '15': 1.0,
    '16': 0.545547,
    '17': 0.559922,
    '18': 0.574014,
    '19': 0.600746,
    '20': 0.527576,
    '21': 0.605756,
    '22': 0.800908,
    '23': 0.537879,
    '24': 0.553985,
    '25': 0.760647,
    '26': 0.607973,
    '27': 0.616375,
    '28': 0.887558,
    '29': 0.555701,
    '30': 0.542345,
    '31': 0.577075,
    '32': 0.590934,
    '33': 0.60042,
    '34': 0.551902,
    '35': 0.545832,
    '36': 0.527543,
    '37': 0.634137,
    '39': 0.51617,
    '40': 0.55178,
    '41': 0.927139,
    '42': 0.790728,
    '43': 0.860476,
    '44': 0.517732,
    '45': 0.561241,
    '46': 0.794087,
    '47': 0.564836,
    '48': 0.80326,
    '49': 0.545454,
    '50': 0.579853,
  },
  '16': {
    '1': 0.876365,
    '2': 0.795964,
    '3': 0.799551,
    '4': 0.700256,
    '5': 0.949168,
    '6': 0.870646,
    '7': 0.788383,
    '8': 0.794829,
    '9': 0.723887,
    '10': 0.694931,
    '11': 0.903543,
    '12': 0.648486,
    '13': 0.78768,
    '14': 0.804634,
    '15': 0.545548,
    '16': 1.0,
    '17': 0.90548,
    '18': 0.88681,
    '19': 0.854023,
    '20': 0.809402,
    '21': 0.761932,
    '22': 0.608139,
    '23': 0.772422,
    '24': 0.668532,
    '25': 0.614579,
    '26': 0.637031,
    '27': 0.680321,
    '28': 0.550082,
    '29': 0.802923,
    '30': 0.953136,
    '31': 0.852402,
    '32': 0.656318,
    '33': 0.523043,
    '34': 0.803012,
    '35': 0.737795,
    '36': 0.906497,
    '37': 0.714457,
    '39': 0.886937,
    '40': 0.78881,
    '41': 0.637203,
    '42': 0.718581,
    '43': 0.644903,
    '44': 0.845842,
    '45': 0.67722,
    '46': 0.711867,
    '47': 0.786473,
    '48': 0.598258,
    '49': 0.852367,
    '50': 0.791356,
  },
  '17': {
    '1': 0.877186,
    '2': 0.86968,
    '3': 0.738275,
    '4': 0.722989,
    '5': 0.904486,
    '6': 0.85913,
    '7': 0.73866,
    '8': 0.79555,
    '9': 0.769399,
    '10': 0.763778,
    '11': 0.827631,
    '12': 0.620853,
    '13': 0.70113,
    '14': 0.757942,
    '15': 0.559922,
    '16': 0.90548,
    '17': 1.0,
    '18': 0.942322,
    '19': 0.916677,
    '20': 0.841711,
    '21': 0.800058,
    '22': 0.597228,
    '23': 0.814779,
    '24': 0.692751,
    '25': 0.605025,
    '26': 0.674694,
    '27': 0.748258,
    '28': 0.596174,
    '29': 0.794619,
    '30': 0.943377,
    '31': 0.814254,
    '32': 0.587731,
    '33': 0.584528,
    '34': 0.881174,
    '35': 0.674387,
    '36': 0.928944,
    '37': 0.623763,
    '39': 0.816386,
    '40': 0.745134,
    '41': 0.637319,
    '42': 0.729956,
    '43': 0.73417,
    '44': 0.867831,
    '45': 0.75402,
    '46': 0.7008,
    '47': 0.768422,
    '48': 0.590413,
    '49': 0.828705,
    '50': 0.752449,
  },
  '18': {
    '1': 0.869987,
    '2': 0.836583,
    '3': 0.791286,
    '4': 0.872177,
    '5': 0.91214,
    '6': 0.878115,
    '7': 0.765413,
    '8': 0.81336,
    '9': 0.744433,
    '10': 0.809804,
    '11': 0.81773,
    '12': 0.691597,
    '13': 0.807051,
    '14': 0.754579,
    '15': 0.574014,
    '16': 0.88681,
    '17': 0.942322,
    '18': 1.0,
    '19': 0.92797,
    '20': 0.892739,
    '21': 0.815078,
    '22': 0.629667,
    '23': 0.797346,
    '24': 0.74418,
    '25': 0.655483,
    '26': 0.745311,
    '27': 0.785792,
    '28': 0.615763,
    '29': 0.803724,
    '30': 0.935546,
    '31': 0.824114,
    '32': 0.619118,
    '33': 0.666256,
    '34': 0.863105,
    '35': 0.74886,
    '36': 0.890827,
    '37': 0.658176,
    '39': 0.860358,
    '40': 0.73616,
    '41': 0.650371,
    '42': 0.745919,
    '43': 0.741611,
    '44': 0.850308,
    '45': 0.857972,
    '46': 0.675621,
    '47': 0.811081,
    '48': 0.622565,
    '49': 0.800524,
    '50': 0.783503,
  },
  '19': {
    '1': 0.875653,
    '2': 0.872644,
    '3': 0.734699,
    '4': 0.771889,
    '5': 0.889266,
    '6': 0.787888,
    '7': 0.841956,
    '8': 0.809519,
    '9': 0.76131,
    '10': 0.832205,
    '11': 0.777721,
    '12': 0.672029,
    '13': 0.756717,
    '14': 0.764722,
    '15': 0.600746,
    '16': 0.854023,
    '17': 0.916677,
    '18': 0.92797,
    '19': 1.0,
    '20': 0.915478,
    '21': 0.815391,
    '22': 0.678161,
    '23': 0.810817,
    '24': 0.752746,
    '25': 0.742732,
    '26': 0.718688,
    '27': 0.790986,
    '28': 0.673515,
    '29': 0.810308,
    '30': 0.895694,
    '31': 0.838311,
    '32': 0.667551,
    '33': 0.686286,
    '34': 0.868806,
    '35': 0.721181,
    '36': 0.859843,
    '37': 0.640212,
    '39': 0.831116,
    '40': 0.800404,
    '41': 0.677905,
    '42': 0.80356,
    '43': 0.789299,
    '44': 0.870054,
    '45': 0.769455,
    '46': 0.761458,
    '47': 0.797911,
    '48': 0.625777,
    '49': 0.787455,
    '50': 0.799534,
  },
  '20': {
    '1': 0.872997,
    '2': 0.74707,
    '3': 0.729107,
    '4': 0.748194,
    '5': 0.854028,
    '6': 0.80839,
    '7': 0.813498,
    '8': 0.826523,
    '9': 0.832989,
    '10': 0.83182,
    '11': 0.786103,
    '12': 0.674927,
    '13': 0.735213,
    '14': 0.769834,
    '15': 0.527576,
    '16': 0.809402,
    '17': 0.841711,
    '18': 0.892739,
    '19': 0.915478,
    '20': 1.0,
    '21': 0.847148,
    '22': 0.688896,
    '23': 0.732612,
    '24': 0.747959,
    '25': 0.745437,
    '26': 0.770983,
    '27': 0.791109,
    '28': 0.664172,
    '29': 0.795973,
    '30': 0.819447,
    '31': 0.838384,
    '32': 0.669979,
    '33': 0.734909,
    '34': 0.864361,
    '35': 0.720892,
    '36': 0.742472,
    '37': 0.658608,
    '39': 0.778623,
    '40': 0.707005,
    '41': 0.633922,
    '42': 0.76801,
    '43': 0.754202,
    '44': 0.774316,
    '45': 0.820237,
    '46': 0.614814,
    '47': 0.766936,
    '48': 0.674248,
    '49': 0.817037,
    '50': 0.757985,
  },
  '21': {
    '1': 0.781105,
    '2': 0.777504,
    '3': 0.729473,
    '4': 0.701454,
    '5': 0.769534,
    '6': 0.797084,
    '7': 0.792053,
    '8': 0.83277,
    '9': 0.755332,
    '10': 0.899421,
    '11': 0.74435,
    '12': 0.633888,
    '13': 0.696268,
    '14': 0.834784,
    '15': 0.605756,
    '16': 0.761932,
    '17': 0.800058,
    '18': 0.815078,
    '19': 0.815391,
    '20': 0.847148,
    '21': 1.0,
    '22': 0.654919,
    '23': 0.756995,
    '24': 0.760136,
    '25': 0.693219,
    '26': 0.712501,
    '27': 0.803157,
    '28': 0.686297,
    '29': 0.816566,
    '30': 0.761923,
    '31': 0.846915,
    '32': 0.629476,
    '33': 0.795428,
    '34': 0.813718,
    '35': 0.76252,
    '36': 0.738117,
    '37': 0.671645,
    '39': 0.720639,
    '40': 0.714056,
    '41': 0.689018,
    '42': 0.833763,
    '43': 0.779074,
    '44': 0.676655,
    '45': 0.868093,
    '46': 0.647167,
    '47': 0.781767,
    '48': 0.649811,
    '49': 0.80473,
    '50': 0.722721,
  },
  '22': {
    '1': 0.695404,
    '2': 0.663822,
    '3': 0.741447,
    '4': 0.584125,
    '5': 0.60283,
    '6': 0.651068,
    '7': 0.717608,
    '8': 0.611186,
    '9': 0.692888,
    '10': 0.609511,
    '11': 0.564164,
    '12': 0.836248,
    '13': 0.665987,
    '14': 0.676135,
    '15': 0.800908,
    '16': 0.608139,
    '17': 0.597228,
    '18': 0.629667,
    '19': 0.678161,
    '20': 0.688896,
    '21': 0.654919,
    '22': 1.0,
    '23': 0.689127,
    '24': 0.782,
    '25': 0.82388,
    '26': 0.816957,
    '27': 0.642563,
    '28': 0.811683,
    '29': 0.583371,
    '30': 0.605383,
    '31': 0.649924,
    '32': 0.565915,
    '33': 0.492899,
    '34': 0.638823,
    '35': 0.641937,
    '36': 0.576362,
    '37': 0.709503,
    '39': 0.623633,
    '40': 0.619263,
    '41': 0.832183,
    '42': 0.79018,
    '43': 0.733578,
    '44': 0.541553,
    '45': 0.62724,
    '46': 0.644567,
    '47': 0.700686,
    '48': 0.887306,
    '49': 0.589222,
    '50': 0.774032,
  },
  '23': {
    '1': 0.653413,
    '2': 0.928786,
    '3': 0.879263,
    '4': 0.630664,
    '5': 0.787792,
    '6': 0.773959,
    '7': 0.707735,
    '8': 0.751153,
    '9': 0.658094,
    '10': 0.693626,
    '11': 0.752985,
    '12': 0.688782,
    '13': 0.728463,
    '14': 0.766621,
    '15': 0.537879,
    '16': 0.772422,
    '17': 0.814779,
    '18': 0.797346,
    '19': 0.810817,
    '20': 0.732612,
    '21': 0.756995,
    '22': 0.689127,
    '23': 1.0,
    '24': 0.884893,
    '25': 0.641714,
    '26': 0.811217,
    '27': 0.682294,
    '28': 0.615038,
    '29': 0.659841,
    '30': 0.82829,
    '31': 0.700551,
    '32': 0.594279,
    '33': 0.556277,
    '34': 0.796401,
    '35': 0.707243,
    '36': 0.879298,
    '37': 0.685934,
    '39': 0.851341,
    '40': 0.796961,
    '41': 0.603043,
    '42': 0.693861,
    '43': 0.666439,
    '44': 0.840467,
    '45': 0.73098,
    '46': 0.585329,
    '47': 0.913055,
    '48': 0.644719,
    '49': 0.677461,
    '50': 0.902958,
  },
  '24': {
    '1': 0.633609,
    '2': 0.748645,
    '3': 0.827618,
    '4': 0.663571,
    '5': 0.687977,
    '6': 0.729711,
    '7': 0.653606,
    '8': 0.740311,
    '9': 0.760965,
    '10': 0.668018,
    '11': 0.643689,
    '12': 0.750547,
    '13': 0.71116,
    '14': 0.741689,
    '15': 0.553985,
    '16': 0.668532,
    '17': 0.692751,
    '18': 0.74418,
    '19': 0.752746,
    '20': 0.747959,
    '21': 0.760136,
    '22': 0.782,
    '23': 0.884893,
    '24': 1.0,
    '25': 0.653522,
    '26': 0.932003,
    '27': 0.65159,
    '28': 0.590313,
    '29': 0.623559,
    '30': 0.722733,
    '31': 0.677194,
    '32': 0.567241,
    '33': 0.561497,
    '34': 0.791094,
    '35': 0.699234,
    '36': 0.71814,
    '37': 0.709517,
    '39': 0.747964,
    '40': 0.638188,
    '41': 0.591422,
    '42': 0.698953,
    '43': 0.65188,
    '44': 0.631233,
    '45': 0.775494,
    '46': 0.476047,
    '47': 0.872534,
    '48': 0.729363,
    '49': 0.657535,
    '50': 0.796579,
  },
  '25': {
    '1': 0.67563,
    '2': 0.661131,
    '3': 0.638563,
    '4': 0.592201,
    '5': 0.613626,
    '6': 0.592367,
    '7': 0.868301,
    '8': 0.675751,
    '9': 0.592919,
    '10': 0.672161,
    '11': 0.590757,
    '12': 0.677626,
    '13': 0.610687,
    '14': 0.62029,
    '15': 0.760647,
    '16': 0.614579,
    '17': 0.605024,
    '18': 0.655483,
    '19': 0.742732,
    '20': 0.745437,
    '21': 0.693219,
    '22': 0.82388,
    '23': 0.641714,
    '24': 0.653522,
    '25': 1.0,
    '26': 0.671313,
    '27': 0.704404,
    '28': 0.862303,
    '29': 0.628065,
    '30': 0.600501,
    '31': 0.655004,
    '32': 0.565331,
    '33': 0.632202,
    '34': 0.621151,
    '35': 0.608386,
    '36': 0.585272,
    '37': 0.584722,
    '39': 0.603427,
    '40': 0.806665,
    '41': 0.756189,
    '42': 0.738246,
    '43': 0.791837,
    '44': 0.625488,
    '45': 0.64957,
    '46': 0.748782,
    '47': 0.68694,
    '48': 0.691501,
    '49': 0.629119,
    '50': 0.769024,
  },
  '26': {
    '1': 0.679279,
    '2': 0.68217,
    '3': 0.833899,
    '4': 0.717144,
    '5': 0.655109,
    '6': 0.745839,
    '7': 0.63409,
    '8': 0.74792,
    '9': 0.783842,
    '10': 0.670283,
    '11': 0.67368,
    '12': 0.845657,
    '13': 0.760963,
    '14': 0.738092,
    '15': 0.607973,
    '16': 0.637031,
    '17': 0.674694,
    '18': 0.745311,
    '19': 0.718688,
    '20': 0.770983,
    '21': 0.712501,
    '22': 0.816957,
    '23': 0.811217,
    '24': 0.932003,
    '25': 0.671313,
    '26': 1.0,
    '27': 0.687744,
    '28': 0.647445,
    '29': 0.626911,
    '30': 0.680738,
    '31': 0.679958,
    '32': 0.608992,
    '33': 0.575455,
    '34': 0.784979,
    '35': 0.72473,
    '36': 0.652989,
    '37': 0.748815,
    '39': 0.716393,
    '40': 0.590156,
    '41': 0.667126,
    '42': 0.672182,
    '43': 0.69093,
    '44': 0.641889,
    '45': 0.810202,
    '46': 0.491464,
    '47': 0.833632,
    '48': 0.814072,
    '49': 0.664473,
    '50': 0.802685,
  },
  '27': {
    '1': 0.747189,
    '2': 0.701355,
    '3': 0.71533,
    '4': 0.70106,
    '5': 0.733245,
    '6': 0.757578,
    '7': 0.784103,
    '8': 0.880997,
    '9': 0.647785,
    '10': 0.920308,
    '11': 0.74685,
    '12': 0.615615,
    '13': 0.753718,
    '14': 0.828898,
    '15': 0.616376,
    '16': 0.680321,
    '17': 0.748258,
    '18': 0.785792,
    '19': 0.790986,
    '20': 0.791109,
    '21': 0.803157,
    '22': 0.642563,
    '23': 0.682294,
    '24': 0.65159,
    '25': 0.704404,
    '26': 0.687744,
    '27': 1.0,
    '28': 0.698402,
    '29': 0.886149,
    '30': 0.715528,
    '31': 0.865479,
    '32': 0.747869,
    '33': 0.718181,
    '34': 0.753056,
    '35': 0.823852,
    '36': 0.639667,
    '37': 0.659943,
    '39': 0.676396,
    '40': 0.721622,
    '41': 0.792251,
    '42': 0.740473,
    '43': 0.728235,
    '44': 0.713687,
    '45': 0.794527,
    '46': 0.619205,
    '47': 0.72863,
    '48': 0.623005,
    '49': 0.756419,
    '50': 0.733758,
  },
  '28': {
    '1': 0.684229,
    '2': 0.669532,
    '3': 0.631144,
    '4': 0.594957,
    '5': 0.521494,
    '6': 0.542732,
    '7': 0.702448,
    '8': 0.615846,
    '9': 0.549258,
    '10': 0.6927,
    '11': 0.55665,
    '12': 0.803948,
    '13': 0.655563,
    '14': 0.620306,
    '15': 0.887558,
    '16': 0.550082,
    '17': 0.596174,
    '18': 0.615763,
    '19': 0.673515,
    '20': 0.664172,
    '21': 0.686297,
    '22': 0.811683,
    '23': 0.615038,
    '24': 0.590313,
    '25': 0.862303,
    '26': 0.647444,
    '27': 0.698402,
    '28': 1.0,
    '29': 0.576819,
    '30': 0.538427,
    '31': 0.617898,
    '32': 0.625963,
    '33': 0.681714,
    '34': 0.602577,
    '35': 0.645662,
    '36': 0.533484,
    '37': 0.673981,
    '39': 0.551563,
    '40': 0.646989,
    '41': 0.892261,
    '42': 0.84447,
    '43': 0.916156,
    '44': 0.570923,
    '45': 0.646264,
    '46': 0.781013,
    '47': 0.643952,
    '48': 0.81153,
    '49': 0.597993,
    '50': 0.682447,
  },
  '29': {
    '1': 0.825494,
    '2': 0.707451,
    '3': 0.70244,
    '4': 0.73687,
    '5': 0.832622,
    '6': 0.748881,
    '7': 0.826469,
    '8': 0.917463,
    '9': 0.665937,
    '10': 0.888102,
    '11': 0.839896,
    '12': 0.566965,
    '13': 0.761736,
    '14': 0.892156,
    '15': 0.555701,
    '16': 0.802923,
    '17': 0.794619,
    '18': 0.803724,
    '19': 0.810308,
    '20': 0.795973,
    '21': 0.816566,
    '22': 0.583371,
    '23': 0.659841,
    '24': 0.623559,
    '25': 0.628065,
    '26': 0.626911,
    '27': 0.886149,
    '28': 0.576819,
    '29': 1.0,
    '30': 0.772242,
    '31': 0.972668,
    '32': 0.75434,
    '33': 0.612676,
    '34': 0.757383,
    '35': 0.828576,
    '36': 0.710418,
    '37': 0.654482,
    '39': 0.726906,
    '40': 0.725105,
    '41': 0.731982,
    '42': 0.699589,
    '43': 0.639805,
    '44': 0.738693,
    '45': 0.732946,
    '46': 0.660498,
    '47': 0.686532,
    '48': 0.540764,
    '49': 0.855684,
    '50': 0.705429,
  },
  '30': {
    '1': 0.856179,
    '2': 0.848023,
    '3': 0.816701,
    '4': 0.707964,
    '5': 0.957917,
    '6': 0.910783,
    '7': 0.735028,
    '8': 0.796686,
    '9': 0.745557,
    '10': 0.725791,
    '11': 0.855742,
    '12': 0.621423,
    '13': 0.754978,
    '14': 0.751588,
    '15': 0.542345,
    '16': 0.953136,
    '17': 0.943377,
    '18': 0.935546,
    '19': 0.895694,
    '20': 0.819447,
    '21': 0.761923,
    '22': 0.605383,
    '23': 0.82829,
    '24': 0.722733,
    '25': 0.600501,
    '26': 0.680737,
    '27': 0.715528,
    '28': 0.538427,
    '29': 0.772242,
    '30': 1.0,
    '31': 0.800844,
    '32': 0.593798,
    '33': 0.546031,
    '34': 0.865616,
    '35': 0.687267,
    '36': 0.947236,
    '37': 0.633376,
    '39': 0.901277,
    '40': 0.762753,
    '41': 0.61486,
    '42': 0.702591,
    '43': 0.655781,
    '44': 0.874099,
    '45': 0.717014,
    '46': 0.666845,
    '47': 0.832897,
    '48': 0.591437,
    '49': 0.803561,
    '50': 0.797349,
  },
  '31': {
    '1': 0.850237,
    '2': 0.741204,
    '3': 0.750385,
    '4': 0.73952,
    '5': 0.866846,
    '6': 0.780185,
    '7': 0.85711,
    '8': 0.906951,
    '9': 0.713076,
    '10': 0.874616,
    '11': 0.866671,
    '12': 0.653441,
    '13': 0.822199,
    '14': 0.948347,
    '15': 0.577076,
    '16': 0.852402,
    '17': 0.814254,
    '18': 0.824114,
    '19': 0.838311,
    '20': 0.838384,
    '21': 0.846915,
    '22': 0.649924,
    '23': 0.700551,
    '24': 0.677194,
    '25': 0.655004,
    '26': 0.679958,
    '27': 0.865479,
    '28': 0.617898,
    '29': 0.972667,
    '30': 0.800844,
    '31': 1.0,
    '32': 0.792589,
    '33': 0.631676,
    '34': 0.785736,
    '35': 0.865396,
    '36': 0.738271,
    '37': 0.749061,
    '39': 0.781419,
    '40': 0.741852,
    '41': 0.752866,
    '42': 0.752949,
    '43': 0.676374,
    '44': 0.74819,
    '45': 0.760579,
    '46': 0.687804,
    '47': 0.743955,
    '48': 0.612766,
    '49': 0.89179,
    '50': 0.754846,
  },
  '32': {
    '1': 0.694507,
    '2': 0.555976,
    '3': 0.66958,
    '4': 0.624979,
    '5': 0.647786,
    '6': 0.540583,
    '7': 0.690087,
    '8': 0.762114,
    '9': 0.589503,
    '10': 0.738,
    '11': 0.714392,
    '12': 0.740489,
    '13': 0.830802,
    '14': 0.774371,
    '15': 0.590934,
    '16': 0.656318,
    '17': 0.587731,
    '18': 0.619118,
    '19': 0.667551,
    '20': 0.669979,
    '21': 0.629476,
    '22': 0.565915,
    '23': 0.594279,
    '24': 0.567241,
    '25': 0.565331,
    '26': 0.608992,
    '27': 0.747869,
    '28': 0.625963,
    '29': 0.75434,
    '30': 0.593798,
    '31': 0.792589,
    '32': 1.0,
    '33': 0.666761,
    '34': 0.605808,
    '35': 0.822118,
    '36': 0.557031,
    '37': 0.851829,
    '39': 0.692593,
    '40': 0.651867,
    '41': 0.744931,
    '42': 0.690517,
    '43': 0.623812,
    '44': 0.661975,
    '45': 0.573566,
    '46': 0.646914,
    '47': 0.601096,
    '48': 0.622422,
    '49': 0.717103,
    '50': 0.676054,
  },
  '33': {
    '1': 0.65611,
    '2': 0.589861,
    '3': 0.510169,
    '4': 0.68885,
    '5': 0.549141,
    '6': 0.54137,
    '7': 0.660424,
    '8': 0.641366,
    '9': 0.568789,
    '10': 0.853224,
    '11': 0.485221,
    '12': 0.628064,
    '13': 0.607188,
    '14': 0.573376,
    '15': 0.60042,
    '16': 0.523043,
    '17': 0.584528,
    '18': 0.666256,
    '19': 0.686286,
    '20': 0.734909,
    '21': 0.795428,
    '22': 0.492899,
    '23': 0.556277,
    '24': 0.561497,
    '25': 0.632202,
    '26': 0.575455,
    '27': 0.718181,
    '28': 0.681714,
    '29': 0.612676,
    '30': 0.546031,
    '31': 0.631676,
    '32': 0.666761,
    '33': 1.0,
    '34': 0.617166,
    '35': 0.640478,
    '36': 0.507481,
    '37': 0.610155,
    '39': 0.533791,
    '40': 0.560872,
    '41': 0.626004,
    '42': 0.771473,
    '43': 0.796667,
    '44': 0.54862,
    '45': 0.761387,
    '46': 0.661344,
    '47': 0.550268,
    '48': 0.60353,
    '49': 0.54829,
    '50': 0.530337,
  },
  '34': {
    '1': 0.845628,
    '2': 0.781049,
    '3': 0.738779,
    '4': 0.660422,
    '5': 0.843473,
    '6': 0.861251,
    '7': 0.700807,
    '8': 0.830796,
    '9': 0.915148,
    '10': 0.775259,
    '11': 0.771933,
    '12': 0.651706,
    '13': 0.690093,
    '14': 0.782882,
    '15': 0.551902,
    '16': 0.803012,
    '17': 0.881174,
    '18': 0.863105,
    '19': 0.868806,
    '20': 0.864361,
    '21': 0.813718,
    '22': 0.638823,
    '23': 0.796401,
    '24': 0.791094,
    '25': 0.621151,
    '26': 0.784979,
    '27': 0.753056,
    '28': 0.602577,
    '29': 0.757383,
    '30': 0.865616,
    '31': 0.785736,
    '32': 0.605808,
    '33': 0.617166,
    '34': 1.0,
    '35': 0.703476,
    '36': 0.80278,
    '37': 0.632445,
    '39': 0.793712,
    '40': 0.661945,
    '41': 0.620498,
    '42': 0.738805,
    '43': 0.721706,
    '44': 0.764107,
    '45': 0.761348,
    '46': 0.587723,
    '47': 0.823182,
    '48': 0.644754,
    '49': 0.825041,
    '50': 0.719925,
  },
  '35': {
    '1': 0.719744,
    '2': 0.686708,
    '3': 0.80835,
    '4': 0.773918,
    '5': 0.722288,
    '6': 0.731827,
    '7': 0.751503,
    '8': 0.820498,
    '9': 0.634949,
    '10': 0.808354,
    '11': 0.794752,
    '12': 0.780435,
    '13': 0.917206,
    '14': 0.898372,
    '15': 0.545832,
    '16': 0.737795,
    '17': 0.674386,
    '18': 0.74886,
    '19': 0.721181,
    '20': 0.720892,
    '21': 0.76252,
    '22': 0.641937,
    '23': 0.707243,
    '24': 0.699234,
    '25': 0.608386,
    '26': 0.72473,
    '27': 0.823852,
    '28': 0.645662,
    '29': 0.828576,
    '30': 0.687267,
    '31': 0.865396,
    '32': 0.822118,
    '33': 0.640478,
    '34': 0.703476,
    '35': 1.0,
    '36': 0.645114,
    '37': 0.88701,
    '39': 0.769907,
    '40': 0.69455,
    '41': 0.733278,
    '42': 0.755433,
    '43': 0.65537,
    '44': 0.65579,
    '45': 0.773193,
    '46': 0.623089,
    '47': 0.77746,
    '48': 0.681549,
    '49': 0.787237,
    '50': 0.779657,
  },
  '36': {
    '1': 0.765327,
    '2': 0.891674,
    '3': 0.807699,
    '4': 0.671634,
    '5': 0.894001,
    '6': 0.817013,
    '7': 0.720362,
    '8': 0.753395,
    '9': 0.663826,
    '10': 0.669911,
    '11': 0.803703,
    '12': 0.598822,
    '13': 0.709221,
    '14': 0.725696,
    '15': 0.527543,
    '16': 0.906497,
    '17': 0.928943,
    '18': 0.890826,
    '19': 0.859843,
    '20': 0.742472,
    '21': 0.738117,
    '22': 0.576362,
    '23': 0.879298,
    '24': 0.71814,
    '25': 0.585272,
    '26': 0.652988,
    '27': 0.639667,
    '28': 0.533484,
    '29': 0.710418,
    '30': 0.947236,
    '31': 0.738271,
    '32': 0.557031,
    '33': 0.507481,
    '34': 0.80278,
    '35': 0.645114,
    '36': 1.0,
    '37': 0.61344,
    '39': 0.897789,
    '40': 0.804066,
    '41': 0.578503,
    '42': 0.674029,
    '43': 0.633293,
    '44': 0.903043,
    '45': 0.691548,
    '46': 0.664567,
    '47': 0.816571,
    '48': 0.528101,
    '49': 0.748372,
    '50': 0.824461,
  },
  '37': {
    '1': 0.699254,
    '2': 0.618685,
    '3': 0.771309,
    '4': 0.656788,
    '5': 0.63888,
    '6': 0.657267,
    '7': 0.653207,
    '8': 0.698622,
    '9': 0.657403,
    '10': 0.643879,
    '11': 0.718678,
    '12': 0.899948,
    '13': 0.884835,
    '14': 0.806464,
    '15': 0.634137,
    '16': 0.714457,
    '17': 0.623763,
    '18': 0.658176,
    '19': 0.640213,
    '20': 0.658608,
    '21': 0.671645,
    '22': 0.709503,
    '23': 0.685933,
    '24': 0.709517,
    '25': 0.584722,
    '26': 0.748815,
    '27': 0.659943,
    '28': 0.673981,
    '29': 0.654482,
    '30': 0.633376,
    '31': 0.749061,
    '32': 0.851829,
    '33': 0.610155,
    '34': 0.632445,
    '35': 0.88701,
    '36': 0.61344,
    '37': 1.0,
    '39': 0.723826,
    '40': 0.610858,
    '41': 0.747804,
    '42': 0.748412,
    '43': 0.680423,
    '44': 0.580961,
    '45': 0.674675,
    '46': 0.626112,
    '47': 0.724464,
    '48': 0.808725,
    '49': 0.745267,
    '50': 0.733125,
  },
  '39': {
    '1': 0.755091,
    '2': 0.809115,
    '3': 0.862956,
    '4': 0.708818,
    '5': 0.919778,
    '6': 0.810312,
    '7': 0.750222,
    '8': 0.771156,
    '9': 0.676556,
    '10': 0.704304,
    '11': 0.826691,
    '12': 0.692377,
    '13': 0.859453,
    '14': 0.782913,
    '15': 0.51617,
    '16': 0.886937,
    '17': 0.816386,
    '18': 0.860358,
    '19': 0.831116,
    '20': 0.778623,
    '21': 0.720639,
    '22': 0.623633,
    '23': 0.851341,
    '24': 0.747964,
    '25': 0.603427,
    '26': 0.716393,
    '27': 0.676396,
    '28': 0.551563,
    '29': 0.726906,
    '30': 0.901277,
    '31': 0.781419,
    '32': 0.692593,
    '33': 0.533791,
    '34': 0.793712,
    '35': 0.769907,
    '36': 0.897789,
    '37': 0.723826,
    '39': 1.0,
    '40': 0.775765,
    '41': 0.610471,
    '42': 0.691917,
    '43': 0.61338,
    '44': 0.8764,
    '45': 0.675289,
    '46': 0.60593,
    '47': 0.854674,
    '48': 0.581706,
    '49': 0.744932,
    '50': 0.859891,
  },
  '40': {
    '1': 0.693456,
    '2': 0.818897,
    '3': 0.760713,
    '4': 0.616658,
    '5': 0.768972,
    '6': 0.68568,
    '7': 0.907246,
    '8': 0.760194,
    '9': 0.551543,
    '10': 0.692813,
    '11': 0.763002,
    '12': 0.565119,
    '13': 0.68944,
    '14': 0.733548,
    '15': 0.55178,
    '16': 0.78881,
    '17': 0.745134,
    '18': 0.73616,
    '19': 0.800404,
    '20': 0.707005,
    '21': 0.714056,
    '22': 0.619263,
    '23': 0.796961,
    '24': 0.638189,
    '25': 0.806665,
    '26': 0.590156,
    '27': 0.721622,
    '28': 0.646989,
    '29': 0.725105,
    '30': 0.762753,
    '31': 0.741852,
    '32': 0.651868,
    '33': 0.560872,
    '34': 0.661945,
    '35': 0.69455,
    '36': 0.804066,
    '37': 0.610858,
    '39': 0.775765,
    '40': 1.0,
    '41': 0.636529,
    '42': 0.670101,
    '43': 0.635801,
    '44': 0.851345,
    '45': 0.638106,
    '46': 0.770845,
    '47': 0.765507,
    '48': 0.507118,
    '49': 0.662924,
    '50': 0.881798,
  },
  '41': {
    '1': 0.758179,
    '2': 0.655375,
    '3': 0.695081,
    '4': 0.645135,
    '5': 0.610989,
    '6': 0.621879,
    '7': 0.715219,
    '8': 0.716611,
    '9': 0.578393,
    '10': 0.755116,
    '11': 0.64916,
    '12': 0.825266,
    '13': 0.750096,
    '14': 0.74009,
    '15': 0.927139,
    '16': 0.637203,
    '17': 0.637319,
    '18': 0.650371,
    '19': 0.677905,
    '20': 0.633922,
    '21': 0.689018,
    '22': 0.832183,
    '23': 0.603043,
    '24': 0.591422,
    '25': 0.756189,
    '26': 0.667126,
    '27': 0.792251,
    '28': 0.892261,
    '29': 0.731982,
    '30': 0.61486,
    '31': 0.752866,
    '32': 0.744931,
    '33': 0.626004,
    '34': 0.620498,
    '35': 0.733278,
    '36': 0.578503,
    '37': 0.747804,
    '39': 0.610471,
    '40': 0.636529,
    '41': 1.0,
    '42': 0.844476,
    '43': 0.831178,
    '44': 0.619234,
    '45': 0.650986,
    '46': 0.793166,
    '47': 0.635143,
    '48': 0.829593,
    '49': 0.661945,
    '50': 0.697335,
  },
  '42': {
    '1': 0.809268,
    '2': 0.753089,
    '3': 0.687721,
    '4': 0.691586,
    '5': 0.699165,
    '6': 0.680737,
    '7': 0.760011,
    '8': 0.687172,
    '9': 0.713299,
    '10': 0.823534,
    '11': 0.646492,
    '12': 0.799765,
    '13': 0.711367,
    '14': 0.746343,
    '15': 0.790728,
    '16': 0.718581,
    '17': 0.729956,
    '18': 0.745919,
    '19': 0.80356,
    '20': 0.76801,
    '21': 0.833763,
    '22': 0.79018,
    '23': 0.693861,
    '24': 0.698953,
    '25': 0.738246,
    '26': 0.672181,
    '27': 0.740472,
    '28': 0.84447,
    '29': 0.699589,
    '30': 0.702591,
    '31': 0.752949,
    '32': 0.690517,
    '33': 0.771473,
    '34': 0.738805,
    '35': 0.755433,
    '36': 0.674029,
    '37': 0.748412,
    '39': 0.691917,
    '40': 0.670101,
    '41': 0.844476,
    '42': 1.0,
    '43': 0.882598,
    '44': 0.637897,
    '45': 0.715249,
    '46': 0.812643,
    '47': 0.698224,
    '48': 0.812331,
    '49': 0.691608,
    '50': 0.691589,
  },
  '43': {
    '1': 0.786538,
    '2': 0.726858,
    '3': 0.613989,
    '4': 0.689322,
    '5': 0.62607,
    '6': 0.641167,
    '7': 0.703998,
    '8': 0.673822,
    '9': 0.657862,
    '10': 0.782099,
    '11': 0.613349,
    '12': 0.797128,
    '13': 0.681523,
    '14': 0.639353,
    '15': 0.860476,
    '16': 0.644903,
    '17': 0.73417,
    '18': 0.741611,
    '19': 0.789299,
    '20': 0.754202,
    '21': 0.779074,
    '22': 0.733578,
    '23': 0.666439,
    '24': 0.65188,
    '25': 0.791838,
    '26': 0.69093,
    '27': 0.728235,
    '28': 0.916156,
    '29': 0.639805,
    '30': 0.655781,
    '31': 0.676374,
    '32': 0.623812,
    '33': 0.796667,
    '34': 0.721706,
    '35': 0.65537,
    '36': 0.633293,
    '37': 0.680423,
    '39': 0.61338,
    '40': 0.635801,
    '41': 0.831178,
    '42': 0.882598,
    '43': 1.0,
    '44': 0.642501,
    '45': 0.729516,
    '46': 0.821386,
    '47': 0.691328,
    '48': 0.804253,
    '49': 0.669982,
    '50': 0.649535,
  },
  '44': {
    '1': 0.757979,
    '2': 0.860923,
    '3': 0.797806,
    '4': 0.685375,
    '5': 0.863198,
    '6': 0.750806,
    '7': 0.762539,
    '8': 0.780592,
    '9': 0.59954,
    '10': 0.715648,
    '11': 0.835774,
    '12': 0.58783,
    '13': 0.741835,
    '14': 0.716945,
    '15': 0.517732,
    '16': 0.845842,
    '17': 0.867831,
    '18': 0.850308,
    '19': 0.870054,
    '20': 0.774316,
    '21': 0.676655,
    '22': 0.541553,
    '23': 0.840467,
    '24': 0.631232,
    '25': 0.625488,
    '26': 0.641889,
    '27': 0.713687,
    '28': 0.570923,
    '29': 0.738693,
    '30': 0.8741,
    '31': 0.74819,
    '32': 0.661975,
    '33': 0.54862,
    '34': 0.764107,
    '35': 0.65579,
    '36': 0.903043,
    '37': 0.580961,
    '39': 0.8764,
    '40': 0.851345,
    '41': 0.619234,
    '42': 0.637897,
    '43': 0.642501,
    '44': 1.0,
    '45': 0.662039,
    '46': 0.692568,
    '47': 0.770412,
    '48': 0.499646,
    '49': 0.691292,
    '50': 0.860855,
  },
  '45': {
    '1': 0.707105,
    '2': 0.736946,
    '3': 0.757848,
    '4': 0.837658,
    '5': 0.69464,
    '6': 0.779417,
    '7': 0.701692,
    '8': 0.799427,
    '9': 0.663074,
    '10': 0.823459,
    '11': 0.693397,
    '12': 0.709153,
    '13': 0.759753,
    '14': 0.766905,
    '15': 0.561241,
    '16': 0.67722,
    '17': 0.75402,
    '18': 0.857972,
    '19': 0.769455,
    '20': 0.820237,
    '21': 0.868093,
    '22': 0.62724,
    '23': 0.73098,
    '24': 0.775494,
    '25': 0.64957,
    '26': 0.810202,
    '27': 0.794527,
    '28': 0.646264,
    '29': 0.732946,
    '30': 0.717014,
    '31': 0.760579,
    '32': 0.573566,
    '33': 0.761387,
    '34': 0.761348,
    '35': 0.773193,
    '36': 0.691548,
    '37': 0.674675,
    '39': 0.675289,
    '40': 0.638106,
    '41': 0.650986,
    '42': 0.715249,
    '43': 0.729516,
    '44': 0.662039,
    '45': 1.0,
    '46': 0.553432,
    '47': 0.794112,
    '48': 0.655071,
    '49': 0.710361,
    '50': 0.718888,
  },
  '46': {
    '1': 0.787516,
    '2': 0.720958,
    '3': 0.558356,
    '4': 0.674159,
    '5': 0.654317,
    '6': 0.548464,
    '7': 0.794009,
    '8': 0.591467,
    '9': 0.516752,
    '10': 0.686172,
    '11': 0.615578,
    '12': 0.702842,
    '13': 0.644257,
    '14': 0.609053,
    '15': 0.794087,
    '16': 0.711867,
    '17': 0.700799,
    '18': 0.675621,
    '19': 0.761458,
    '20': 0.614814,
    '21': 0.647167,
    '22': 0.644567,
    '23': 0.585329,
    '24': 0.476047,
    '25': 0.748782,
    '26': 0.491464,
    '27': 0.619205,
    '28': 0.781013,
    '29': 0.660498,
    '30': 0.666845,
    '31': 0.687804,
    '32': 0.646914,
    '33': 0.661344,
    '34': 0.587723,
    '35': 0.623089,
    '36': 0.664567,
    '37': 0.626112,
    '39': 0.60593,
    '40': 0.770845,
    '41': 0.793165,
    '42': 0.812643,
    '43': 0.821386,
    '44': 0.692568,
    '45': 0.553432,
    '46': 1.0,
    '47': 0.545674,
    '48': 0.640284,
    '49': 0.597697,
    '50': 0.652595,
  },
  '47': {
    '1': 0.673589,
    '2': 0.844798,
    '3': 0.909622,
    '4': 0.639677,
    '5': 0.802442,
    '6': 0.859616,
    '7': 0.709247,
    '8': 0.814373,
    '9': 0.68731,
    '10': 0.699486,
    '11': 0.81785,
    '12': 0.716604,
    '13': 0.805454,
    '14': 0.822147,
    '15': 0.564836,
    '16': 0.786472,
    '17': 0.768422,
    '18': 0.811081,
    '19': 0.797911,
    '20': 0.766936,
    '21': 0.781767,
    '22': 0.700686,
    '23': 0.913055,
    '24': 0.872534,
    '25': 0.68694,
    '26': 0.833632,
    '27': 0.72863,
    '28': 0.643952,
    '29': 0.686532,
    '30': 0.832897,
    '31': 0.743955,
    '32': 0.601096,
    '33': 0.550268,
    '34': 0.823182,
    '35': 0.77746,
    '36': 0.816571,
    '37': 0.724464,
    '39': 0.854674,
    '40': 0.765507,
    '41': 0.635143,
    '42': 0.698224,
    '43': 0.691328,
    '44': 0.770412,
    '45': 0.794112,
    '46': 0.545674,
    '47': 1.0,
    '48': 0.688282,
    '49': 0.760412,
    '50': 0.894118,
  },
  '48': {
    '1': 0.73167,
    '2': 0.608907,
    '3': 0.732873,
    '4': 0.604164,
    '5': 0.55132,
    '6': 0.668316,
    '7': 0.577416,
    '8': 0.593342,
    '9': 0.712239,
    '10': 0.626473,
    '11': 0.59716,
    '12': 0.91085,
    '13': 0.699679,
    '14': 0.642085,
    '15': 0.80326,
    '16': 0.598258,
    '17': 0.590413,
    '18': 0.622565,
    '19': 0.625777,
    '20': 0.674249,
    '21': 0.649811,
    '22': 0.887306,
    '23': 0.644719,
    '24': 0.729363,
    '25': 0.691501,
    '26': 0.814072,
    '27': 0.623005,
    '28': 0.81153,
    '29': 0.540764,
    '30': 0.591437,
    '31': 0.612766,
    '32': 0.622422,
    '33': 0.60353,
    '34': 0.644754,
    '35': 0.681549,
    '36': 0.528101,
    '37': 0.808725,
    '39': 0.581706,
    '40': 0.507118,
    '41': 0.829593,
    '42': 0.812331,
    '43': 0.804253,
    '44': 0.499646,
    '45': 0.655071,
    '46': 0.640284,
    '47': 0.688282,
    '48': 1.0,
    '49': 0.609467,
    '50': 0.676383,
  },
  '49': {
    '1': 0.811979,
    '2': 0.667588,
    '3': 0.702599,
    '4': 0.613913,
    '5': 0.821449,
    '6': 0.798169,
    '7': 0.718329,
    '8': 0.851346,
    '9': 0.779557,
    '10': 0.733395,
    '11': 0.897329,
    '12': 0.633303,
    '13': 0.738936,
    '14': 0.859689,
    '15': 0.545454,
    '16': 0.852368,
    '17': 0.828704,
    '18': 0.800524,
    '19': 0.787455,
    '20': 0.817037,
    '21': 0.80473,
    '22': 0.589222,
    '23': 0.677461,
    '24': 0.657535,
    '25': 0.629119,
    '26': 0.664473,
    '27': 0.756419,
    '28': 0.597993,
    '29': 0.855684,
    '30': 0.803561,
    '31': 0.89179,
    '32': 0.717103,
    '33': 0.54829,
    '34': 0.825041,
    '35': 0.787237,
    '36': 0.748372,
    '37': 0.745267,
    '39': 0.744932,
    '40': 0.662924,
    '41': 0.661945,
    '42': 0.691608,
    '43': 0.669982,
    '44': 0.691292,
    '45': 0.710361,
    '46': 0.597697,
    '47': 0.760412,
    '48': 0.609466,
    '49': 1.0,
    '50': 0.692535,
  },
  '50': {
    '1': 0.686584,
    '2': 0.851954,
    '3': 0.936545,
    '4': 0.674933,
    '5': 0.786596,
    '6': 0.793087,
    '7': 0.813782,
    '8': 0.789834,
    '9': 0.625221,
    '10': 0.687466,
    '11': 0.811882,
    '12': 0.738029,
    '13': 0.82008,
    '14': 0.80996,
    '15': 0.579853,
    '16': 0.791356,
    '17': 0.752449,
    '18': 0.783503,
    '19': 0.799534,
    '20': 0.757985,
    '21': 0.722721,
    '22': 0.774032,
    '23': 0.902958,
    '24': 0.796579,
    '25': 0.769024,
    '26': 0.802685,
    '27': 0.733758,
    '28': 0.682447,
    '29': 0.705429,
    '30': 0.797349,
    '31': 0.754846,
    '32': 0.676054,
    '33': 0.530337,
    '34': 0.719925,
    '35': 0.779657,
    '36': 0.824461,
    '37': 0.733125,
    '39': 0.859891,
    '40': 0.881798,
    '41': 0.697335,
    '42': 0.691589,
    '43': 0.649535,
    '44': 0.860855,
    '45': 0.718888,
    '46': 0.652595,
    '47': 0.894118,
    '48': 0.676383,
    '49': 0.692535,
    '50': 1.0,
  },
};
