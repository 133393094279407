import { PageTitles, UserTrackingEvents } from '@revelio/core';
import mixpanel from 'mixpanel-browser';
import { useLifecycles } from 'react-use';
import DashboardPage from '../DashboardPage';
import { ReportsTable } from './reports-table/reports-table';
import { useCallback, useState } from 'react';

const ReportsPage = () => {
  const [loading, setIsLoading] = useState<boolean>(true);
  useLifecycles(
    () => {
      mixpanel.track(UserTrackingEvents.PAGE_VISIT, {
        page: PageTitles.REPORTS,
      });
      mixpanel.time_event(UserTrackingEvents.PAGE_LEAVE);
    },
    () => {
      mixpanel.track(UserTrackingEvents.PAGE_LEAVE, {
        page: PageTitles.REPORTS,
      });
    }
  );

  const onReportsTableInitialize = useCallback(() => {
    setIsLoading(false);
  }, []);

  return (
    <DashboardPage title={[PageTitles.REPORTS]} loading={loading}>
      <ReportsTable onInitialized={onReportsTableInitialize} />
    </DashboardPage>
  );
};

export default ReportsPage;
