import { MeterChart } from '@revelio/d3';
import { GeoSummaryPostingsDataQuery } from '@revelio/data-access';
import { useEffect, useState } from 'react';
import { calculateMeterValue } from './meter-scaling/calculateMeterValue';

interface TimeToFillMeterProps {
  postingsData: GeoSummaryPostingsDataQuery | undefined;
}
export const TimeToFillMeter = ({ postingsData }: TimeToFillMeterProps) => {
  const postingsCategory = postingsData?.posting?.[0]?.category;

  const timeToFillEnd =
    postingsCategory?.[postingsCategory?.length - 1]?.metrics?.time_to_fill ||
    0;

  const [meterValue, setMeterValue] = useState<number>(0);

  useEffect(() => {
    const meterValue = calculateMeterValue({
      value: timeToFillEnd,
      metric: 'timeToFill',
    });

    setMeterValue(meterValue);
  }, [timeToFillEnd]);

  return (
    <MeterChart
      value={meterValue}
      mainText={`${timeToFillEnd?.toFixed(0) || 0}`}
      unit="days"
    />
  );
};
