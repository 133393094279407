import { GetReportConfigQuery, ReportTypeEnum } from '@revelio/data-access';
import { SelectionCategories } from '@revelio/filtering';
import { ColumnItem, ColumnSet } from '@revelio/layout';
import { differenceBy } from 'lodash';

export enum EntityType {
  MainEntity = 'MainEntity',
  ComparisonEntity = 'ComparisonEntity',
  FilterEntity = 'FilterEntity',
}

export enum AdditionalReportSelectionCategories {
  COMPANY_V1 = 'dashboard_companies',
  COMPANY_REPVUE = 'company_repvue',
  COMPANY_ORGCHART = 'company_orgchart',
}

/*
 * Special categories that are used to group reports in the report builder
 * Featured: Reports that a featured. This category will appear on top of the list
 * Additional: Reports that don't have any categories. This category will appear at the bottom of the list
 */
export enum SpecialReportCategories {
  Featured = 'featured',
  Additional = 'additional',
}

export type ReportSelectionCategories =
  | SelectionCategories
  | AdditionalReportSelectionCategories;

export type ReportEntityConfigColumn = ColumnItem<string> & {
  reportEntityOptions: {
    entityType: EntityType;
    selectionList: {
      filters: ReportSelectionCategories[];
      isNested: boolean;
      limit: number;
    };
  };
};
export const getColumnItemFromReportEntityConfigColumn = (
  reportEntityConfigColumns: ReportEntityConfigColumn
): ColumnItem<string> => {
  const { reportEntityOptions, ...column } = reportEntityConfigColumns;
  return column;
};

export type ReportEntityConfig = Omit<ColumnSet<string>, 'columns'> & {
  label: string;
  description: string;
  sampleLink?: string | null;
  categories?: string[];
  columns: ReportEntityConfigColumn[];
};

export type IReportEntityMap = {
  [key: string]: ReportEntityConfig;
};

export interface Entity {
  id?: string;
  selection_list: string[];
  limit: number;
  required: boolean;
  label: string;
  description: string;
}

export const generateReportEntityMap = (
  backendEntityConfig: GetReportConfigQuery['reports_validation']
): IReportEntityMap => {
  return backendEntityConfig?.reduce((acc, config) => {
    if (!config?.report_validation) return acc;

    const report = transformReportEntityConfig(config?.report_validation);
    if (report) {
      acc[config?.report_validation?.report_type] = report;
    }

    return acc;
  }, {} as IReportEntityMap);
};

export type ReportConfig = NonNullable<
  GetReportConfigQuery['reports_validation'][0]
>['report_validation'];

export const transformReportEntityConfig = (
  backendEntityConfig: ReportConfig
): ReportEntityConfig | null => {
  if (!backendEntityConfig) {
    return null;
  }

  return {
    heading: null,
    label: backendEntityConfig.label,
    description: backendEntityConfig.description,
    sampleLink:
      'sample_link' in backendEntityConfig
        ? backendEntityConfig.sample_link
        : null,
    categories:
      !backendEntityConfig.category || backendEntityConfig.category.length === 0
        ? [SpecialReportCategories.Additional]
        : backendEntityConfig.category,
    columns: reportEntityColumnSort(backendEntityConfig.report_type, [
      ...transformEntityConfig(
        backendEntityConfig.main_entity as Entity[],
        EntityType.MainEntity
      ),
      ...transformEntityConfig(
        backendEntityConfig.comparison_entities as Entity[],
        EntityType.ComparisonEntity
      ),
      ...transformEntityConfig(
        backendEntityConfig.filters as Entity[],
        EntityType.FilterEntity
      ),
    ]),
  };
};

export const transformEntityConfig = (
  entities: Entity[],
  entityType: EntityType
): ReportEntityConfigColumn[] => {
  return entities.map((entity, index) => ({
    id: entity.id || `${entityType}_${entity.selection_list}_${index}`,
    label: entity.label,
    detail: entity.description,
    isRequired: entity.required,
    reportEntityOptions: {
      entityType,
      selectionList: {
        filters: entity.selection_list as ReportSelectionCategories[],
        isNested: entity.selection_list.length > 1,
        limit: entity.limit,
      },
    },
  }));
};

const reportEntityColumnSort = (
  reportType: ReportTypeEnum,
  columns: ReportEntityConfigColumn[]
) => {
  if (reportType === ReportTypeEnum.JobComparison) {
    const columnOrdering = [
      'Current Role',
      'Target Role',
      'Current Metro Area',
      'Target Metro Area',
      'Current Company',
      'Target Company',
      'Current Seniority',
      'Target Seniority',
    ];

    const orderedColumns = columnOrdering.reduce<ReportEntityConfigColumn[]>(
      (acc, columnName) => {
        const configuration = columns.find(
          (column) => column?.label === columnName
        );
        if (configuration) {
          acc.push(configuration);
        }
        return acc;
      },
      []
    );

    const unorderedColumns = differenceBy(columns, orderedColumns, 'label');
    return [...orderedColumns, ...unorderedColumns];
  } else {
    return columns;
  }
};
