import { TreeTooltip } from '../tree';
import styles from './PopoutTree.module.css';

interface BranchLabelProps {
  branch: string;
  branchDescription?: string;
}

export const BranchLabel = ({
  branch,
  branchDescription,
}: BranchLabelProps) => {
  return (
    <div className={styles.branchContainer}>
      <div className={styles.branchLabel}>{branch}</div>
      {branchDescription && <TreeTooltip label={branchDescription} />}
    </div>
  );
};
