import { Box, Flex, useToast } from '@chakra-ui/react';
import AdminUsers from './admin-users/admin-users';
import AdminClients from './admin-clients/admin-clients';
import { useLocation, useSearchParams } from 'react-router-dom';
import { useEffect } from 'react';
import { get } from 'lodash';
import DashboardPage from '../DashboardPage';
import AdminClientEdit from './admin-client-edit/admin-client-edit';
import AdminUserEdit from './admin-user-edit/admin-user-edit';
import AdminUserClientSearch from './adminUserClientSearch';

const Admin = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const location = useLocation();
  const toast = useToast();

  const clientIdParam = searchParams.get('client');
  const editingParam = searchParams.get('editing');

  const showAddUserToast = get(location, 'state.addUserToast', false);

  useEffect(() => {
    if (showAddUserToast) {
      toast({
        title: 'User Added Successfully',
        description: 'User added & login info copied to clipboard.',
        status: 'success',
        duration: 4000,
        position: 'top-right',
      });
    }
  }, [showAddUserToast, toast]);

  const toggleUserList = (clientID: string | null) => {
    const newSearchParams = new URLSearchParams(searchParams);
    newSearchParams.delete('editing');
    newSearchParams.delete('userId');
    if (clientID) {
      newSearchParams.set('client', clientID);
    } else {
      newSearchParams.delete('client');
    }
    setSearchParams(newSearchParams);
  };

  const renderContent = () => {
    if (clientIdParam) {
      switch (editingParam) {
        case 'client':
          return <AdminClientEdit />;
        case 'user':
          return <AdminUserEdit />;
        default:
          return (
            <AdminUsers
              toggleUserList={toggleUserList}
              clientName={clientIdParam}
            />
          );
      }
    }
    // Return null or a default view when no client is selected
    return null;
  };

  return (
    <DashboardPage title={'Client & User Admin'} loading={false}>
      <AdminUserClientSearch />

      <Flex maxW="full" height="94%" direction="row" justify="space-between">
        <Box
          flex={clientIdParam ? '0 0 40%' : '1'}
          height="100%"
          overflow="hidden"
        >
          <AdminClients
            selectedClientId={clientIdParam}
            onClientSelect={toggleUserList}
          />
        </Box>
        {clientIdParam && (
          <Box ml={8} flex="1" height="100%" bg="white" p="2">
            {renderContent()}
          </Box>
        )}
      </Flex>
    </DashboardPage>
  );
};

export default Admin;
