import {
  ScreenerReqFilters,
  ScreenerReqSegmentSelector,
  ScreenerReqSegments,
} from '@revelio/data-access';
import { Item, TreeItem } from '../../engine/filters.model';
import {
  CompanyDetailCheckboxFilters,
  CompanyDetailTreeFilters,
  CompanyDetailYearRangeFilters,
  CompanySearchFilters,
  FreeTextFilters,
  SchoolSearchFilters,
} from './types';
import { CompanyResultItem, SchoolItem } from '../collection';

export type SingleScreenerSegment = ScreenerReqSegmentSelector & {
  name: keyof ScreenerReqSegments;
};

export type ScreenerSubFilterState = {
  id: string;
  filter: SingleScreenerSegment;
  treeItems?: Record<string, TreeItem>;
};

export type ScreenerCompanyDetailTreeFilterState = {
  name: CompanyDetailTreeFilters;
  treeItems?: Record<string, TreeItem>;
};

export type ScreenerCompanyDetailCheckboxFilterState = {
  name: CompanyDetailCheckboxFilters;
  options: {
    label: string;
    value: string;
  }[];
};

export type ScreenerCompanyDetailYearRangeFilterState = {
  name: CompanyDetailYearRangeFilters;
  start_year: number | undefined;
  end_year: number | undefined;
};

export type ScreenerCompanySearchFilterState = {
  name: CompanySearchFilters;
  companyResultItems?: Record<string, CompanyResultItem>;
};

export type ScreenerSchoolSearchFilterState = {
  name: SchoolSearchFilters;
  schoolResultItems: Record<string, SchoolItem>;
};

export type ScreenerFreeTextState = {
  name: FreeTextFilters;
  text: string[];
};

export type ScreenerRangeFilterState = {
  name: string;
  start_val: number | undefined;
  end_val: number | undefined;
};

export type ScreenerCompanyDetailFilterState =
  | ScreenerCompanyDetailTreeFilterState
  | ScreenerCompanyDetailCheckboxFilterState
  | ScreenerCompanyDetailYearRangeFilterState
  | ScreenerCompanySearchFilterState
  | ScreenerSchoolSearchFilterState
  | ScreenerFreeTextState
  | ScreenerRangeFilterState;

export type PrimaryFilterKey = `${Extract<
  keyof ScreenerReqFilters,
  'industry' | 'rics_k10' | 'rics_k50' | 'region' | 'job_category'
>}.${number}`;

export enum ScreenerSortCol {
  SORT_BY_ATTRITION = 'SORT_BY_ATTRITION',
  SORT_BY_GROWTHYOY = 'SORT_BY_GROWTH_YOY',
  SORT_BY_HEADCOUNT = 'SORT_BY_HEADCOUNT',
  SORT_BY_HIRING = 'SORT_BY_HIRING',
  SORT_BY_INFLOW = 'SORT_BY_INFLOW',
  SORT_BY_OUTFLOW = 'SORT_BY_OUTFLOW',
  SORT_BY_SALARY = 'SORT_BY_SALARY',
  SORT_BY_TENURE = 'SORT_BY_TENURE',
}

export type ScreenerSorting = {
  sort_by: ScreenerSortCol;
  num_col: number;
  ascending: boolean;
};

export interface ScreenerFilterState {
  filters: {
    page: number;
    primary_filter?: Record<PrimaryFilterKey, TreeItem>;
    sub_filters?: ScreenerSubFilterState[];
    company_detail_filters?: ScreenerCompanyDetailFilterState[];
  };
  sorting: ScreenerSorting | undefined;
}

interface SetPrimaryFilterAction {
  type: 'SET_PRIMARY_FILTER';
  filter: Record<PrimaryFilterKey, TreeItem>;
}

interface AddSubFilterAction {
  type: 'ADD_SUB_FILTER';
  filter: SingleScreenerSegment;
  id?: string;
  treeItems: Record<string, TreeItem>;
}

interface RemoveSubFilterAction {
  type: 'REMOVE_SUB_FILTER';
  filterId: string;
}

interface RemovePrimaryFilterAction {
  type: 'REMOVE_PRIMARY_FILTER';
}

interface UpdatePageAction {
  type: 'UPDATE_PAGE';
  page: number;
}

interface UpdateSubFilterAction {
  type: 'UPDATE_SUB_FILTER';
  filter: SingleScreenerSegment;
  id: string;
  treeItems: Record<string, TreeItem>;
}

interface UpdateSortingAction {
  type: 'UPDATE_SORTING';
  sorting: ScreenerSorting | undefined;
}

interface AddCompanyDetailFilterAction {
  type: 'ADD_COMPANY_DETAIL_TREE_FILTER';
  name: CompanyDetailTreeFilters;
  treeItems?: Record<string, TreeItem>;
}

interface RemoveCompanyDetailFilterAction {
  type: 'REMOVE_COMPANY_DETAIL_TREE_FILTER';
  name: CompanyDetailTreeFilters;
}

interface AddCompanyDetailCheckboxFilterAction {
  type: 'ADD_COMPANY_DETAIL_CHECKBOX_FILTER';
  name: CompanyDetailCheckboxFilters;
  options: {
    label: string;
    value: string;
  }[];
}

interface RemoveCompanyDetailCheckboxFilterAction {
  type: 'REMOVE_COMPANY_DETAIL_CHECKBOX_FILTER';
  name: CompanyDetailCheckboxFilters;
}

interface AddCompanyDetailYearRangeFilterAction {
  type: 'ADD_COMPANY_DETAIL_YEAR_RANGE_FILTER';
  name: CompanyDetailYearRangeFilters;
  start_year: number | undefined;
  end_year: number | undefined;
}

interface RemoveCompanyDetailYearRangeFilterAction {
  type: 'REMOVE_COMPANY_DETAIL_YEAR_RANGE_FILTER';
  name: CompanyDetailYearRangeFilters;
}

interface AddCompanySearchFilterAction {
  type: 'ADD_COMPANY_DETAIL_SEARCH_FILTER';
  name: CompanySearchFilters;
  companyResultItems: Record<string, CompanyResultItem>;
}

interface RemoveCompanySearchFilterAction {
  type: 'REMOVE_COMPANY_DETAIL_SEARCH_FILTER';
  name: CompanySearchFilters;
}

interface AddSchoolDetailSearchFilterAction {
  type: 'ADD_SCHOOL_DETAIL_SEARCH_FILTER';
  name: SchoolSearchFilters;
  schoolResultItems: Record<string, SchoolItem>;
}

interface RemoveSchoolDetailSearchFilterAction {
  type: 'REMOVE_SCHOOL_DETAIL_SEARCH_FILTER';
  name: SchoolSearchFilters;
}

interface SetFiltersAction {
  type: 'OVERWRITE_FILTERS';
  filters: ScreenerFilterState['filters'];
}

interface AddFreeTextFiltersAction {
  type: 'ADD_FREE_TEXT_FILTER';
  name: FreeTextFilters;
  text: string[];
}

interface RemoveFreeTextFilterAction {
  type: 'REMOVE_FREE_TEXT_FILTER';
  name: FreeTextFilters;
}

interface AddRangeFilterAction {
  type: 'ADD_RANGE_FILTER';
  name: string;
  min: number | undefined;
  max: number | undefined;
}

interface RemoveRangeFilterAction {
  type: 'REMOVE_RANGE_FILTER';
  name: string;
}

export type ScreenerFilterAction =
  | SetPrimaryFilterAction
  | AddSubFilterAction
  | RemoveSubFilterAction
  | UpdatePageAction
  | RemovePrimaryFilterAction
  | UpdateSubFilterAction
  | UpdateSortingAction
  | AddCompanyDetailFilterAction
  | RemoveCompanyDetailFilterAction
  | AddCompanyDetailCheckboxFilterAction
  | RemoveCompanyDetailCheckboxFilterAction
  | AddCompanyDetailYearRangeFilterAction
  | RemoveCompanyDetailYearRangeFilterAction
  | AddCompanySearchFilterAction
  | RemoveCompanySearchFilterAction
  | AddSchoolDetailSearchFilterAction
  | RemoveSchoolDetailSearchFilterAction
  | SetFiltersAction
  | AddFreeTextFiltersAction
  | RemoveFreeTextFilterAction
  | AddRangeFilterAction
  | RemoveRangeFilterAction;

export const initialScreenerFilterState: ScreenerFilterState = {
  filters: {
    page: 1,
    primary_filter: undefined,
    sub_filters: [],
    company_detail_filters: [],
  },
  sorting: undefined,
};

const removeTreeItemChildren = (
  treeItems: Record<string, TreeItem> | undefined
): Record<string, TreeItem> | undefined => {
  if (!treeItems) {
    return undefined;
  }

  const newTreeItems = Object.entries(treeItems).reduce<
    Record<string, TreeItem>
  >((accumulator, [key, treeItem]) => {
    const { children, item, ...rest } = treeItem;
    const minimalItem = {
      ...item,
      topCleanedTitles: '',
      topRawTitles: '',
    } as Item;
    accumulator[key] = { ...rest, children: [], item: minimalItem };

    return accumulator;
  }, {});

  return newTreeItems;
};

export const screenerFilterReducer = (
  state: ScreenerFilterState,
  action: ScreenerFilterAction
): ScreenerFilterState => {
  switch (action.type) {
    case 'OVERWRITE_FILTERS':
      return {
        filters: action.filters,
        sorting: undefined,
      };
    case 'SET_PRIMARY_FILTER':
      return {
        ...state,
        filters: {
          ...state.filters,
          primary_filter: removeTreeItemChildren(action.filter),
        },
      };
    case 'ADD_COMPANY_DETAIL_TREE_FILTER':
      return {
        ...state,
        filters: {
          ...state.filters,
          company_detail_filters: [
            ...(state.filters.company_detail_filters?.filter(
              (filter) => filter.name !== action.name
            ) || []),
            {
              name: action.name,
              treeItems: removeTreeItemChildren(action.treeItems),
            },
          ],
        },
      };
    case 'ADD_COMPANY_DETAIL_CHECKBOX_FILTER':
      return {
        ...state,
        filters: {
          ...state.filters,
          company_detail_filters: [
            ...(state.filters.company_detail_filters?.filter(
              (filter) => filter.name !== action.name
            ) || []),
            {
              name: action.name,
              options: action.options,
            },
          ],
        },
      };
    case 'REMOVE_COMPANY_DETAIL_CHECKBOX_FILTER':
      return {
        ...state,
        filters: {
          ...state.filters,
          company_detail_filters: state.filters.company_detail_filters?.filter(
            (filter) => filter.name !== action.name
          ),
        },
      };
    case 'ADD_COMPANY_DETAIL_YEAR_RANGE_FILTER':
      return {
        ...state,
        filters: {
          ...state.filters,
          company_detail_filters: [
            ...(state.filters.company_detail_filters?.filter(
              (filter) => filter.name !== action.name
            ) || []),
            {
              name: action.name,
              start_year: action.start_year,
              end_year: action.end_year,
            },
          ],
        },
      };
    case 'REMOVE_COMPANY_DETAIL_YEAR_RANGE_FILTER':
      return {
        ...state,
        filters: {
          ...state.filters,
          company_detail_filters: state.filters.company_detail_filters?.filter(
            (filter) => filter.name !== action.name
          ),
        },
      };
    case 'ADD_COMPANY_DETAIL_SEARCH_FILTER':
      return {
        ...state,
        filters: {
          ...state.filters,
          company_detail_filters: [
            ...(state.filters.company_detail_filters?.filter(
              (filter) => filter.name !== action.name
            ) || []),
            {
              name: action.name,
              companyResultItems: action.companyResultItems,
            },
          ],
        },
      };
    case 'REMOVE_COMPANY_DETAIL_SEARCH_FILTER':
      return {
        ...state,
        filters: {
          ...state.filters,
          company_detail_filters: state.filters.company_detail_filters?.filter(
            (filter) => filter.name !== action.name
          ),
        },
      };
    case 'ADD_SCHOOL_DETAIL_SEARCH_FILTER':
      return {
        ...state,
        filters: {
          ...state.filters,
          company_detail_filters: [
            ...(state.filters.company_detail_filters?.filter(
              (filter) => filter.name !== action.name
            ) || []),
            {
              name: action.name,
              schoolResultItems: action.schoolResultItems,
            },
          ],
        },
      };
    case 'REMOVE_SCHOOL_DETAIL_SEARCH_FILTER':
      return {
        ...state,
        filters: {
          ...state.filters,
          company_detail_filters: state.filters.company_detail_filters?.filter(
            (filter) => filter.name !== action.name
          ),
        },
      };
    case 'ADD_SUB_FILTER':
      return {
        ...state,
        filters: {
          ...state.filters,
          sub_filters: [
            ...(state.filters.sub_filters || []),
            {
              id: action.id || `${Date.now()}`,
              filter: action.filter,
              treeItems: removeTreeItemChildren(action.treeItems),
            },
          ],
        },
      };
    case 'UPDATE_SUB_FILTER':
      return {
        ...state,
        filters: {
          ...state.filters,
          sub_filters: state.filters.sub_filters?.map((filter) =>
            filter.id === action.id
              ? {
                  ...filter,
                  filter: action.filter,
                  treeItems: removeTreeItemChildren(action.treeItems),
                }
              : filter
          ),
        },
      };
    case 'REMOVE_SUB_FILTER':
      return {
        ...state,
        filters: {
          ...state.filters,
          sub_filters: state.filters.sub_filters?.filter(
            (filter) => filter.id !== action.filterId
          ),
        },
      };
    case 'REMOVE_PRIMARY_FILTER':
      return {
        ...state,
        filters: {
          ...state.filters,
          primary_filter: undefined,
        },
      };
    case 'REMOVE_COMPANY_DETAIL_TREE_FILTER':
      return {
        ...state,
        filters: {
          ...state.filters,
          company_detail_filters: state.filters.company_detail_filters?.filter(
            (filter) => filter.name !== action.name
          ),
        },
      };
    case 'UPDATE_PAGE':
      return {
        ...state,
        filters: {
          ...state.filters,
          page: action.page,
        },
      };
    case 'UPDATE_SORTING':
      return {
        ...state,
        sorting: action.sorting,
      };
    case 'ADD_FREE_TEXT_FILTER':
      return {
        ...state,
        filters: {
          ...state.filters,
          company_detail_filters: [
            ...(state.filters.company_detail_filters?.filter(
              (filter) => filter.name !== action.name
            ) || []),
            {
              name: action.name,
              text: action.text,
            },
          ],
        },
      };
    case 'REMOVE_FREE_TEXT_FILTER':
      return {
        ...state,
        filters: {
          ...state.filters,
          company_detail_filters: state.filters.company_detail_filters?.filter(
            (filter) => filter.name !== action.name
          ),
        },
      };
    case 'ADD_RANGE_FILTER':
      return {
        ...state,
        filters: {
          ...state.filters,
          company_detail_filters: [
            ...(state.filters.company_detail_filters?.filter(
              (filter) => filter.name !== action.name
            ) || []),
            {
              name: action.name,
              start_val: action.min,
              end_val: action.max,
            },
          ],
        },
      };
    case 'REMOVE_RANGE_FILTER':
      return {
        ...state,
        filters: {
          ...state.filters,
          company_detail_filters: state.filters.company_detail_filters?.filter(
            (filter) => filter.name !== action.name
          ),
        },
      };
    default:
      return state;
  }
};
