import {
  arrayToTree,
  CompanyResultItem,
  CompanySearchTree,
  FilterItem,
  SelectionCategories,
  SelectionList,
  TreeNode,
  useSelectionLists,
} from '@revelio/filtering';
import { get } from 'lodash';
import {
  forwardRef,
  MutableRefObject,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';
import { filter, map, pipe } from 'rxjs';

export type ReportEntityCompanyEditorHandle = {
  handleClearSelections: () => void;
};

export interface ReportEntityCompanyEditorProps {
  limit: number;
  treeHeight: number;
  onSelectionsValue: (items: Record<string, CompanyResultItem>) => void;
  filterFn?: (item: FilterItem) => boolean;
  searchInputRef?: MutableRefObject<HTMLInputElement | null>;
}

export const ReportEntityCompanyEditor = forwardRef<
  ReportEntityCompanyEditorHandle,
  ReportEntityCompanyEditorProps
>(
  (
    {
      limit,
      treeHeight,
      searchInputRef,
      onSelectionsValue,
      filterFn,
    }: ReportEntityCompanyEditorProps,
    ref
  ) => {
    const [selectionLists, setSelectionLists] = useState<SelectionList[]>([]);
    const [placeholderList, setPlaceholderList] = useState<TreeNode[]>([]);
    const [selections, setSelections] = useState<
      Record<string, CompanyResultItem>
    >({});

    useImperativeHandle(ref, () => ({
      handleClearSelections: () => {
        setSelections({});
      },
    }));

    useEffect(() => {
      onSelectionsValue && onSelectionsValue(selections);
    }, [onSelectionsValue, selections]);

    useSelectionLists(
      [SelectionCategories.COMPANY, SelectionCategories.INDUSTRY],
      undefined,
      pipe(filter((lists) => lists.length > 0)),
      pipe(
        map((res) => {
          if (res.error) {
            return;
          }
          setSelectionLists(res);
        })
      )
    );

    useEffect(() => {
      if (selectionLists.length === 0) {
        return;
      } else if (selectionLists.length !== 2) {
        throw new Error(
          'ReportEntityCompanyEditor should be using [SelectionCategories.COMPANY, SelectionCategories.INDUSTRY]'
        );
      }
      let [companyList, industryList] = selectionLists;

      // If there's a filter function we need to filter the companies lists followed by any industries that do not have any companies under them
      if (filterFn) {
        companyList = {
          ...companyList,
          value: companyList.value.filter(filterFn),
        };
        const industriesWithCompanies: Record<string, true> = {};
        companyList.value.forEach((company: FilterItem) => {
          industriesWithCompanies[String(company.industry)] = true;
        });
        industryList = {
          ...industryList,
          value: industryList.value.filter((industry: FilterItem) => {
            return industriesWithCompanies[String(industry.id)];
          }),
        };
      }

      const { rootItems } = arrayToTree(companyList, industryList);
      setPlaceholderList(rootItems as TreeNode[]);
    }, [selectionLists, filterFn]);

    return (
      <CompanySearchTree
        filterFn={filterFn}
        isSingleSelect={limit === 1}
        limit={limit}
        placeholderListNodes={placeholderList}
        selections={selections}
        setSelections={(itemOrSelectionFn) => {
          if (typeof itemOrSelectionFn === 'function') {
            return setSelections(itemOrSelectionFn);
          }

          const id = itemOrSelectionFn.rcid;
          setSelections((current) => {
            if (current[id]) {
              return {};
            }
            return {
              [id]: itemOrSelectionFn,
            };
          });
        }}
        sortFn={(a: TreeNode, b: TreeNode) => {
          const headcountA = get(a, 'item.raw_emp_count');
          const headcountB = get(b, 'item.raw_emp_count');
          return headcountB - headcountA;
        }}
        searchInputRef={searchInputRef}
        treeHeight={treeHeight}
        treeNodeType={'checkboxes'}
      />
    );
  }
);
