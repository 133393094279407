import { InfoOutlineIcon } from '@chakra-ui/icons';
import {
  Card,
  CardBody,
  CardHeader,
  Heading,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
} from '@chakra-ui/react';
import { PropsWithChildren, ReactNode, useRef } from 'react';

import {
  PlotCardActionMenu,
  PlotCardActionMenuProps,
} from './plot-card-action-menu';
import styles from './plot-card.module.css';

type PlotCardProps = {
  info?: ReactNode;
  topRight?: ReactNode;
} & Omit<PlotCardActionMenuProps, 'printRef'> &
  PropsWithChildren;

export const PlotCard = ({
  title,
  info,
  topRight,
  menu,
  legacyChartProps,
  exportData,
  children,
}: PlotCardProps) => {
  const printRef = useRef<HTMLDivElement>(null);
  return (
    <Card className={styles.card} variant="unstyled" ref={printRef}>
      <CardHeader className={styles.cardHeader}>
        <Heading size="xs" color="text.primary" fontWeight="semibold">
          <div className={styles.titleContainer}>
            <div className={styles.title}>{title}</div>
            {info && (
              <div className={styles.info}>
                <Popover trigger="hover" placement="top" variant="tooltip">
                  <PopoverTrigger>
                    <InfoOutlineIcon boxSize={'10px'} />
                  </PopoverTrigger>
                  <PopoverContent>
                    <PopoverArrow />
                    <PopoverBody>{info}</PopoverBody>
                  </PopoverContent>
                </Popover>
              </div>
            )}
          </div>
        </Heading>
        <div id="plot-card-top-right" className={styles.topRight}>
          {topRight}
          {menu && menu?.length > 0 && (
            <PlotCardActionMenu
              menu={menu}
              title={title}
              legacyChartProps={legacyChartProps}
              expandModalContent={children}
              printRef={printRef}
              exportData={exportData}
            />
          )}
        </div>
      </CardHeader>
      <CardBody className={styles.cardBody} padding="18px 0 0">
        {children}
      </CardBody>
    </Card>
  );
};
