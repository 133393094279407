import { TalentDiscoveryAiFilterSearchResponse } from '@revelio/data-access';
import {
  SelectionCategories,
  LocalSelectionCategories,
  GEOGRAPHY_GRANULARITY_FILTERS,
  RICS_FILTERS,
  ROLE_GRANULARITY_FILTERS,
  SKILL_GRANULARITY_FILTERS,
} from '@revelio/filtering';

export const TDResponseFilterToSelectionListMap: {
  [key in keyof TalentDiscoveryAiFilterSearchResponse['filters']]: (
    | SelectionCategories
    | LocalSelectionCategories
  )[];
} = {
  gender: [SelectionCategories.GENDER],
  ethnicity: [SelectionCategories.ETHNICITY],
  seniority: [SelectionCategories.SENIORITY],
  highest_degree: [SelectionCategories.HIGHEST_DEGREE],
  geography: GEOGRAPHY_GRANULARITY_FILTERS,
  flight_risk: [LocalSelectionCategories.FLIGHT_RISK],
  prestige: [LocalSelectionCategories.PRESTIGE],
  remote_suitability: [LocalSelectionCategories.REMOTE_SUITABILITY],
  role: ROLE_GRANULARITY_FILTERS,
  skill: SKILL_GRANULARITY_FILTERS,
  industry: RICS_FILTERS,
};

export const TDResponseFilterToLabel: {
  [key in keyof TalentDiscoveryAiFilterSearchResponse['filters']]: string;
} = {
  gender: 'Gender',
  ethnicity: 'Ethnicity',
  seniority: 'Seniority',
  highest_degree: 'Education',
  geography: 'Geography',
  flight_risk: 'Flight Risk',
  prestige: 'Prestige',
  remote_suitability: 'Remote Suitability',
  role: 'Role',
  skill: 'Skill',
  industry: 'Industry',
};
