import {
  FilterName,
  POSTING_GET_DATA,
  useActiveFiltersState,
  useAllFiltersState,
  useRoleTaxonomySetting,
} from '@revelio/filtering';
import { useMemo, useState } from 'react';
import {
  transformFiltersToPayQuery,
  transformFiltersToPostingsQuery,
} from './transform-filters';
import {
  CompensationResponseTimeToFillValues,
  CompensationTypes,
  PrimaryView,
  timeToFillDataTransformer,
} from '@revelio/core';
import { useIsQueryReady } from './is-query-ready';
import { useGetCompensationBody } from './use-get-compensation-body';
import { getCompanyLookup } from './company-lookup';
import { useFetchCompensation } from './use-fetch-compensation';
import { useQuery } from 'urql';
import { getLineChartData } from '../../postings/utils';
import { transformData } from './transform-data';
import { getDim1FromView } from './get-dim1-from-view';
import { plotColors } from '@revelio/d3';

export const usePayDataFetch = ({
  view,
  primaryFilters,
  compensationType,
  modelsCompensationUrl,
}: {
  view: PrimaryView;
  primaryFilters: FilterName[];
  compensationType: CompensationTypes;
  modelsCompensationUrl: string;
}) => {
  /** ======== Filters ======== */
  const activeFilters = useActiveFiltersState();
  const { isEnabled: isCustomRoleTaxonomyEnabled } = useRoleTaxonomySetting();

  const companyLookup = useMemo(() => {
    if (view === PrimaryView.COMPANY) {
      return getCompanyLookup(activeFilters);
    }
    return undefined;
  }, [activeFilters, view]);

  const isReady = useIsQueryReady({ activeFilters, primaryFilters, view });

  const compensationFilters = useMemo(
    () =>
      transformFiltersToPayQuery({
        filters: activeFilters,
        isCustomRoleTaxonomyEnabled,
        primaryView: view,
      }),
    [activeFilters, isCustomRoleTaxonomyEnabled, view]
  );

  const [compensationLoading, setCompensationLoading] =
    useState<boolean>(false);
  const { compensationBody, companyNameMap } = useGetCompensationBody({
    view,
    filters: compensationFilters,
    isReady,
    setLoading: setCompensationLoading,
  });

  /** ======== Compensation Fetch ======== */
  const { data: compensationData } = useFetchCompensation({
    compensationBody,
    setLoading: setCompensationLoading,
    modelsCompensationUrl,
  });

  /** ======== Data Transformation ======== */
  const allFilters = useAllFiltersState();
  const primaryEntities = useMemo(
    () =>
      allFilters?.find((f) => f.id === 'primary_entities')?.value as {
        id: string;
        isActive: boolean;
      }[],
    [allFilters]
  );

  const {
    overtimeData,
    kdeData,
    topCompanyData,
    topGeographyData,
    topRoleData,
  } = useMemo(
    () =>
      transformData({
        data: compensationData,
        compensationType,
        companyLookup:
          view === PrimaryView.COMPANY ? companyLookup : companyNameMap,
        primaryFilterSort: primaryEntities?.map((e) => e.id),
      }),
    [
      compensationType,
      compensationData,
      companyNameMap,
      view,
      companyLookup,
      primaryEntities,
    ]
  );

  const colorMap = useMemo(() => {
    const activeFilterIds = primaryEntities
      ?.map((v, i) => (v?.isActive ? i : null))
      ?.filter((v) => v !== null);

    const sortedPlotColors = plotColors.filter((_, i) =>
      activeFilterIds?.includes(i)
    );
    return [
      ...sortedPlotColors,
      ...plotColors.filter((c) => !sortedPlotColors.includes(c)),
    ];
  }, [primaryEntities]);

  /** ======== POSTINGS ======== */
  const postingsFilters = useMemo(
    () =>
      transformFiltersToPostingsQuery({
        filters: activeFilters,
        isCustomRoleTaxonomyEnabled,
        primaryView: view,
      }),
    [activeFilters, isCustomRoleTaxonomyEnabled, view]
  );

  const [{ data: postingsData, fetching: postingsFetching }] = useQuery({
    query: POSTING_GET_DATA,
    variables: { dim1: getDim1FromView(view), filters: postingsFilters },
    pause: !isReady,
  });

  const timeToFillData = useMemo(() => {
    if (postingsData) {
      const lineData = getLineChartData({
        entities: postingsData?.posting,
        metricKey: 'time_to_fill',
      });
      return timeToFillDataTransformer(
        lineData as unknown as CompensationResponseTimeToFillValues[]
      );
    }
    return [];
  }, [postingsData]);

  return {
    overtimeData,
    timeToFillData,
    kdeData,
    topCompanyData,
    topGeographyData,
    topRoleData,
    loading: compensationLoading || postingsFetching,
    colorMap,
    isQueryReady: isReady,
  };
};
