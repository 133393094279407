import { PrimaryView } from '@revelio/core';
import {
  Filter,
  FilterName,
  LocalSelectionCategories,
  OtherFilterNames,
  RangeFilter,
  SelectionCategories,
  useRoleTaxonomySetting,
} from '@revelio/filtering';
import { useMemo } from 'react';

type UseIsTransitionsQueryReadyProps = {
  activeFilters: Filter[];
  primaryFilters: FilterName[];
  view: PrimaryView;
};
export const useIsTransitionsQueryReady = ({
  activeFilters,
  primaryFilters,
  view,
}: UseIsTransitionsQueryReadyProps) => {
  const filtersHasAtLeastOnePrimaryFilter = useMemo(
    () => activeFilters.some((filter) => primaryFilters.includes(filter.id)),
    [activeFilters, primaryFilters]
  );

  const filtersHasCustomRoleTaxonomy = useMemo(
    () =>
      !!activeFilters.find(
        (filter) => filter.id === OtherFilterNames.ROLE_TAXONOMY
      ),
    [activeFilters]
  );
  const { isEnabled: isRoleTaxonomyEnabled } = useRoleTaxonomySetting();

  const filtersHasDateSet = useMemo(
    () =>
      activeFilters.some((filter) => {
        /** TODO: Typecasting this as Filters aren't typed by id */
        const dateRangeFilter = filter as unknown as RangeFilter;

        return (
          dateRangeFilter.id === SelectionCategories.DATE_RANGE &&
          dateRangeFilter.value.startDate &&
          dateRangeFilter.value.endDate
        );
      }),
    [activeFilters]
  );

  const hasNItemsSankey = useMemo(
    () =>
      activeFilters.some(
        (filter) => filter.id === LocalSelectionCategories.N_ITEMS_SANKEY
      ),
    [activeFilters]
  );

  return (
    hasNItemsSankey &&
    filtersHasAtLeastOnePrimaryFilter &&
    filtersHasDateSet &&
    (!isRoleTaxonomyEnabled ||
      filtersHasCustomRoleTaxonomy ||
      view !== PrimaryView.COMPANY)
  );
};
