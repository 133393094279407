import { DASH_META_ROOT } from '@revelio/auth';
import { TalentDiscoveryAiFilterSearchResponse } from '@revelio/data-access';

export const aiFilterSearchRequest = async ({
  prompt,
  csrfToken,
  signal,
}: {
  prompt: string;
  csrfToken: string;
  signal?: AbortSignal;
}): Promise<{
  response: TalentDiscoveryAiFilterSearchResponse;
  uuid: string;
}> => {
  const response = await fetch(
    `${DASH_META_ROOT}/api/ai-search/talent-discovery`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'x-surf-token-dash-meta': csrfToken || '',
      },
      body: JSON.stringify({
        text: prompt,
      }),
      credentials: 'include',
      signal,
    }
  );
  const data = await response.json();
  return data;
};

export const aiFilterSearchFeedbackRequest = async ({
  uuid,
  isCorrect,
  csrfToken,
}: {
  uuid: string;
  isCorrect: boolean;
  csrfToken: string;
}): Promise<void> => {
  await fetch(`${DASH_META_ROOT}/api/ai-search/talent-discovery/feedback`, {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json',
      'x-surf-token-dash-meta': csrfToken || '',
    },
    body: JSON.stringify({
      uuid,
      isCorrect,
    }),
    credentials: 'include',
  });
};
