import { PipelineColumnType } from '@revelio/data-access';
import {
  DEFAULT_GEO_PIPELINE_COLUMNS,
  DEFAULT_ROLE_PIPELINE_COLUMNS,
  DEFAULT_SKILL_PIPELINE_COLUMNS,
  ULTIMATE_PARENT_COMPANY_COLUMN,
} from './shared';
import { addDefaultIsSelectedToSubMenu } from './helpers';
import { CardListSubMenuProps, ColumnSet } from '@revelio/layout';

export const INDIVIDUAL_ROLE_PIPELINE_COLUMNS: CardListSubMenuProps<PipelineColumnType> =
  {
    ...DEFAULT_ROLE_PIPELINE_COLUMNS,
    isRequired: true,
    menuItems: [
      {
        id: PipelineColumnType.RoleK7,
        label: 'role_k7',
        defaultIsSelected: true,
      },
      { id: PipelineColumnType.RoleK50, label: 'role_k50' },
      { id: PipelineColumnType.RoleK150, label: 'role_k150' },
      { id: PipelineColumnType.RoleK300, label: 'role_k300' },
      { id: PipelineColumnType.RoleK500, label: 'role_k500' },
      { id: PipelineColumnType.RoleK1000, label: 'role_k1000' },
      {
        id: PipelineColumnType.MappedRole,
        label: 'role_k1500',
      },
      {
        id: PipelineColumnType.OnetTitle,
        label: 'ONET',
        combineWith: [PipelineColumnType.OnetCode],
      },
      {
        id: PipelineColumnType.Title,
        label: 'Raw Title',
      },
    ],
  };

export const INDIVIDUAL_SKILL_PIPELINE_COLUMNS: CardListSubMenuProps<PipelineColumnType> =
  {
    ...DEFAULT_SKILL_PIPELINE_COLUMNS,
    menuItems: [
      {
        id: PipelineColumnType.SkillRaw,
        label: 'Raw Skill',
        defaultIsSelected: true,
      },
      {
        id: PipelineColumnType.SkillMapped,
        label: 'Mapped Skill',
        defaultIsSelected: true,
      },
      {
        id: PipelineColumnType.SkillK25,
        label: 'Skill k25',
      },
      {
        id: PipelineColumnType.SkillK50,
        label: 'Skill k50',
      },
      {
        id: PipelineColumnType.SkillK75,
        label: 'Skill k75',
        defaultIsSelected: true,
      },
    ],
  };

export const INDIVIDUAL_USER_COLUMNS: ColumnSet<PipelineColumnType>[] = [
  {
    heading: 'User Position',
    columns: [
      {
        id: PipelineColumnType.CompanyName,
        label: 'Company',
        isMandatory: true,
        combineWith: [PipelineColumnType.Rcid],
      },
      {
        id: PipelineColumnType.UserId,
        label: 'User ID',
        isMandatory: true,
        defaultIsSelected: true,
      },
      {
        id: PipelineColumnType.PositionId,
        label: 'Position ID',
        isMandatory: true,
        defaultIsSelected: true,
      },
      {
        id: PipelineColumnType.Startdate,
        label: 'Start Date',
        isMandatory: true,
        defaultIsSelected: true,
      },
      {
        id: PipelineColumnType.Enddate,
        label: 'End Date',
        isMandatory: true,
        defaultIsSelected: true,
      },
      {
        ...DEFAULT_GEO_PIPELINE_COLUMNS,
        menuItems: [
          ...addDefaultIsSelectedToSubMenu({
            columnSubMenu: DEFAULT_GEO_PIPELINE_COLUMNS,
          }).menuItems,
          {
            id: PipelineColumnType.City,
            label: 'City',
          },
          {
            id: PipelineColumnType.Location,
            label: 'Raw Location',
          },
        ],
      },
      INDIVIDUAL_ROLE_PIPELINE_COLUMNS,
      {
        id: PipelineColumnType.RemoteSuitability,
        label: 'Remote Suitability',
      },
      { id: PipelineColumnType.Weight },
      { id: PipelineColumnType.Description, defaultIsSelected: true },
      { id: PipelineColumnType.Seniority, defaultIsSelected: true },

      {
        id: PipelineColumnType.Rn,
        label: 'Position Number',
        defaultIsSelected: true,
      },
      { id: PipelineColumnType.Salary, defaultIsSelected: true },
      {
        id: PipelineColumnType.StartMeanSampledSalary,
        label: 'Start Mean Sampled Salary',
        internalOnly: true,
      },
      {
        id: PipelineColumnType.EndMeanSampledSalary,
        label: 'End Mean Sampled Salary',
        internalOnly: true,
      },
      {
        id: 'company_information',
        label: 'Company Information',
        menuItems: [
          {
            id: PipelineColumnType.Company,
            label: 'Raw Company',
            defaultIsSelected: true,
          },
          {
            id: PipelineColumnType.Companyurl,
            label: 'Company Linkedin URL',
            defaultIsSelected: true,
          },
          { id: PipelineColumnType.Ticker },
          { id: PipelineColumnType.Exchange },
          {
            id: PipelineColumnType.Cusip,
            label: 'CUSIP',
            internalOnly: true,
          },
          {
            id: PipelineColumnType.NaicsDesc,
            label: 'NAICS',
            combineWith: [PipelineColumnType.Naics],
          },
          ULTIMATE_PARENT_COMPANY_COLUMN,

          {
            id: PipelineColumnType.FinalParentFactsetName,
            label: 'Ultimate Parent Factset',
            combineWith: [PipelineColumnType.FinalParentFactsetId],
            internalOnly: true,
          },
        ],
      },
      {
        id: PipelineColumnType.WorkEmail,
        label: 'Work Email',
        internalOnly: true,
      },
    ],
    supportsCustomColumns: true,
  },
  {
    heading: 'User',
    columns: [
      {
        id: PipelineColumnType.UserId,
        label: 'User ID',
        isMandatory: true,
        defaultIsSelected: true,
      },
      {
        id: 'user_information',
        label: 'User Name',
        menuItems: [
          {
            id: PipelineColumnType.Firstname,
            label: 'First Name',
          },
          {
            id: PipelineColumnType.Lastname,
            label: 'Last Name',
          },
          {
            id: PipelineColumnType.Fullname,
            label: 'Full Name',
          },
        ],
      },
      {
        id: 'user_geography',
        label: 'Geography',
        menuItems: [
          {
            id: PipelineColumnType.UserLocation,
            label: 'User Location',
          },
          {
            id: PipelineColumnType.UserCountry,
            label: 'User Country',
          },
        ],
      },
      {
        id: 'profile_information',
        label: 'Profile Information',
        menuItems: [
          {
            id: PipelineColumnType.ProfileLinkedinUrl,
            label: 'Profile LinkedIn URL',
          },
          {
            id: PipelineColumnType.ProfileTitle,
            label: 'Profile Title',
          },
          {
            id: PipelineColumnType.ProfileSummary,
            label: 'Profile Summary',
          },
          {
            id: PipelineColumnType.Numconnections,
            label: 'Number of Connections',
          },
          // TODO: Enable once DAG supports it. It is gql supported
          // {
          //   id: PipelineColumnType.Recommenders,
          // },
        ],
      },
      {
        id: 'gender_information',
        label: 'Gender',
        menuItems: [
          {
            id: PipelineColumnType.MProb,
            label: 'Gender Probabilities',
            combineWith: [PipelineColumnType.FProb],
            defaultIsSelected: true,
          },
          {
            id: PipelineColumnType.SexPredicted,
            label: 'Gender Binary Prediction',
          },
        ],
      },
      {
        id: 'ethnicity_information',
        label: 'Ethnicity',
        menuItems: [
          {
            id: PipelineColumnType.MultipleProb,
            label: 'Ethnicity Probabilities',
            combineWith: [
              PipelineColumnType.WhiteProb,
              PipelineColumnType.BlackProb,
              PipelineColumnType.ApiProb,
              PipelineColumnType.HispanicProb,
              PipelineColumnType.NativeProb,
            ],
            defaultIsSelected: true,
          },
          {
            id: PipelineColumnType.EthnicityPredicted,
            label: 'Ethnicity Prediction',
          },
        ],
      },
      { id: PipelineColumnType.UserPrestige, label: 'Prestige' },
      { id: PipelineColumnType.HighestDegree, label: 'Highest Degree' },
      {
        id: PipelineColumnType.PersonalEmail,
        label: 'Personal Email',
        internalOnly: true,
      },
      {
        id: PipelineColumnType.PhoneNumber,
        label: 'Phone Number',
        internalOnly: true,
      },
    ],
  },
  {
    heading: 'User Skill',
    columns: [
      {
        id: PipelineColumnType.UserId,
        label: 'User ID',
        isMandatory: true,
        defaultIsSelected: true,
      },
      INDIVIDUAL_SKILL_PIPELINE_COLUMNS,
    ],
  },
  {
    heading: 'User Education',
    columns: [
      {
        id: PipelineColumnType.UserId,
        label: 'User ID',
        isMandatory: true,
        defaultIsSelected: true,
      },
      {
        id: PipelineColumnType.EducationStartdate,
        label: 'Start Date',
        isMandatory: true,
        defaultIsSelected: true,
      },
      {
        id: PipelineColumnType.EducationEnddate,
        label: 'End Date',
        isMandatory: true,
        defaultIsSelected: true,
      },
      {
        id: 'university_information',
        label: 'University Information',
        menuItems: [
          {
            id: PipelineColumnType.Campus,
            label: 'Raw University',
            defaultIsSelected: true,
          },
          {
            id: PipelineColumnType.UniversityName,
            label: 'University Name',
          },
          {
            id: PipelineColumnType.Universityurl,
            label: 'University URL',
          },
          {
            id: PipelineColumnType.WorldRank,
            label: 'World Rank',
          },
          {
            id: PipelineColumnType.UsRank,
            label: 'US Rank',
          },
        ],
      },
      {
        id: 'degree_information',
        label: 'Degree Information',
        menuItems: [
          {
            id: PipelineColumnType.Degree,
          },
          {
            id: PipelineColumnType.Field,
          },
          {
            id: PipelineColumnType.DegreeRaw,
            label: 'Raw Degree',
            defaultIsSelected: true,
          },
          {
            id: PipelineColumnType.FieldRaw,
            label: 'Raw Field',
            defaultIsSelected: true,
          },
        ],
      },
      {
        id: PipelineColumnType.EducationRn,
        label: 'Education Number',
      },
    ],
  },
];
