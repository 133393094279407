import { useMemo } from 'react';
import { FiFileText } from 'react-icons/fi';
import { useNavigate } from 'react-router';
import { useGetLoggedInUser } from '@revelio/auth';
import { FeatureFlag } from '@revelio/core';
import { Tab } from '@revelio/data-access';
import { useUnleashFlag } from '../../../../hooks/unleash/useUnleashFlag';
import taxonomyCatalogIcon from '../../../../images/icons/taxonomy-catalog-icon.svg';

export const useMethodologyMenuItems = () => {
  const navigate = useNavigate();
  const { loggedInUser } = useGetLoggedInUser();
  const { tabs } = loggedInUser;
  const isTaxonomyCatalogEnabled = useUnleashFlag(
    FeatureFlag.AddTaxonomyCatalogLink
  );

  const menuItems = useMemo(() => {
    const items = [];
    if (tabs?.includes(Tab.DataDictionary)) {
      items.push({
        id: 'data-dictionary',
        icon: FiFileText,
        children: 'Data Dictionary',
        onClick: () => navigate('/data-dictionary'),
      });
    }
    if (isTaxonomyCatalogEnabled) {
      items.push({
        id: 'taxonomy-catalog',
        iconSrc: taxonomyCatalogIcon,
        children: 'Taxonomy Catalog',
        onClick: () => navigate('/taxonomy-catalog'),
      });
    }
    return items;
  }, [navigate, tabs, isTaxonomyCatalogEnabled]);

  return {
    showMenu: menuItems.length > 0,
    menuItems,
  };
};
