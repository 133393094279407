import {
  select,
  scaleLinear,
  scaleQuantize,
  arc,
  format as d3Format,
  interpolateRgb,
  color,
} from 'd3';
import { adjustDownloadMargins } from '../../utilities/adjust-download-margins';
import { isUndefined } from 'lodash';

export const MeterGenerator = (plotConfigs, downloadOptions) => {
  let {
    name,
    data,
    height,
    width,
    targetRef,
    customMargins,
    formatCustomString,
    preformatter,
    format,
    customFormatter,
    startValue = 0,
    endValue = 100,
    opacity = 0.4,
    leftLabel,
    rightLabel,
  } = plotConfigs;
  const {
    title,
    download,
    getSVGNode,
    svgHeight,
    svgWidth,
    containerId,
    padding,
    watermark,
  } = downloadOptions;

  const scaleDomain = [startValue, endValue];

  const dims = {};

  const watermarkHeight = watermark?.height || 0;

  name = getSVGNode ? name + '-download' : name;
  height = svgHeight || height;
  width = svgWidth || width;

  if (data && (targetRef?.current || containerId) && height) {
    // remove old svg
    select(`.svg-${name}`).remove();
    select(`.tooltip-${name}`).remove();

    // setup margins and inner dims
    dims.margin = {
      top: 0,
      left: 0,
      bottom: 0,
      right: 0,
    };

    if (leftLabel) {
      dims.margin.left = 30;
    }

    if (rightLabel) {
      dims.margin.right = 30;
    }

    if (download) {
      adjustDownloadMargins(dims, {
        title,
        watermark,
        watermarkHeight,
        padding,
      });
    }

    //Override margins
    if (customMargins) {
      dims.margin = { ...dims.margin, ...customMargins };
    }

    dims.innerHeight = height - (dims.margin.top + dims.margin.bottom);
    dims.innerWidth = width - (dims.margin.left + dims.margin.right);

    // setup svg node
    const node = targetRef?.current;

    const svg = node
      ? select(node).append('svg')
      : select(containerId).append('svg');

    svg
      .attr('width', svgWidth || '100%')
      .attr('height', svgHeight || '100%')
      .attr('class', `svg-${name}`);

    const chart = svg.append('g');

    chart.attr(
      'transform',
      `translate(${dims.margin.left}, ${dims.margin.top})`
    );

    var radius = dims.innerWidth / 1.4;

    var defs = chart.append('defs');

    // Define the gradient
    var gradient = defs
      .append('linearGradient')
      .attr('id', 'gradient')
      .attr('x1', '-10%')
      .attr('y1', '0%')
      .attr('x2', '110%')
      .attr('y2', '0%');

    const colorLookup = (flipColor, isCycle, pos) => {
      const startColor = color(data?.startColor || '#55A3FF');
      const endColor = color(data?.endColor || '#FF646D');

      if (isCycle) {
        return flipColor ? startColor : endColor;
      }

      if (flipColor) {
        return pos === 'start' ? endColor : startColor;
      }

      return pos === 'start' ? startColor : endColor;
    };

    gradient
      .append('stop')
      .attr('offset', '0%')
      .attr('stop-color', colorLookup(data?.flipColor, data?.cyclical, 'start'))
      .attr('stop-opacity', 1);

    if (data?.cyclical) {
      gradient
        .append('stop')
        .attr('offset', '50%')
        .attr(
          'stop-color',
          data?.flipColor
            ? data?.endColor || '#FF646D'
            : data?.startColor || '#55A3FF'
        )
        .attr('stop-opacity', 1);
    } else if (data?.middleColor) {
      gradient
        .append('stop')
        .attr('offset', '50%')
        .attr('stop-color', data.middleColor)
        .attr('stop-opacity', 1);
    }

    gradient
      .append('stop')
      .attr('offset', '100%')
      .attr('stop-color', colorLookup(data?.flipColor, data?.cyclical, 'end'))
      .attr('stop-opacity', 1);

    var g = svg.append('g');

    var centerX = width / 2;
    var centerY = radius * 1 + dims.margin.top - 15;

    var startAngle = -Math.PI / 4.5;
    var endAngle = Math.PI / 4;

    var angleScale = scaleLinear()
      .domain(scaleDomain)
      .range([startAngle, endAngle]);

    var formatPercentage = d3Format('.0f');

    var value = format.includes('%')
      ? formatPercentage(data.value * 100)
      : data.value;

    const endColor = data?.endColor || '#FF646D';

    const middleColor = data?.middleColor || data?.startColor || '#55A3FF';

    const startColor = data?.cyclical
      ? endColor
      : data?.startColor || '#55A3FF';

    const colorInterpolator = data?.middleColor
      ? interpolateRgb(startColor, middleColor, endColor)
      : interpolateRgb(startColor, endColor);

    const colorScaleCyc = scaleLinear()
      .domain([0, 0.5, 1])
      .range(
        data?.flipColor
          ? [middleColor, endColor, middleColor]
          : [startColor, middleColor, endColor]
      )
      .interpolate(interpolateRgb);

    const numColors = 20;
    let colors = [];
    if (data?.cyclical) {
      colors = [];
      for (let i = 0; i < numColors; i++) {
        const t = i / (numColors - 1);
        colors.push(colorScaleCyc(t));
      }
    } else {
      colors = [];
      for (let i = 0; i < numColors; i++) {
        const t = i / (numColors - 1);
        colors.push(colorInterpolator(t));
      }
    }

    const colorsC = colors.slice().reverse();

    const colorScale = scaleQuantize()
      .domain(scaleDomain)
      .range(data?.flipColor ? colorsC : colors);

    // Define the background arc
    var arc2 = arc()
      .innerRadius(radius * 0.945)
      .outerRadius(radius)
      .startAngle(-Math.PI / 4)
      .endAngle(Math.PI / 4)
      .cornerRadius(100);

    // Define the foreground arc
    var arcForeground = arc()
      .innerRadius(radius * 0.945)
      .outerRadius(radius)
      .startAngle(angleScale(value) + 0.61 + startAngle)
      .endAngle(angleScale(value))
      .cornerRadius(100);

    // Append the background arc to the svg
    g.append('path')
      .datum({ endAngle: Math.PI / 4 })
      .attr('d', arc2)
      .attr('transform', `translate(${centerX}, ${centerY})`)
      // .attr('fill', '#e2ea')
      .attr('fill', 'url(#gradient)')
      .attr('opacity', opacity)
      .attr('stroke-linecap', 'round');

    g.append('path')
      .datum({ endAngle: -Math.PI / 2.5 + (1.46 * Math.PI) / 2.5 })
      .attr('d', arcForeground)
      .attr('transform', `translate(${centerX}, ${centerY})`)
      .attr('fill', (d) => colorScale(value))
      .attr('stroke-linecap', 'round');

    const wrapLabel = (text, maxLength = 12) => {
      if (text.length <= maxLength) return [text];
      // Find the last space before maxLength
      const breakPoint = text.lastIndexOf(' ', maxLength);
      if (breakPoint === -1) return [text]; // No space found, return as single line
      return [text.slice(0, breakPoint), text.slice(breakPoint + 1)];
    };

    if (leftLabel) {
      const lines = wrapLabel(leftLabel);
      const textGroup = g
        .append('g')
        .attr(
          'transform',
          `translate(${centerX - radius * Math.cos(-Math.PI / 4)},${centerY + radius * Math.sin(startAngle) + 5})`
        );

      lines.forEach((line, i) => {
        textGroup
          .append('text')
          .attr('y', i * 15) // Offset each line by 15px
          .attr('text-anchor', 'middle')
          .attr('dy', '0.35em')
          .attr('font-size', radius * 0.06 + 'px')
          .attr('fill', '#636D7E')
          .style('font-family', 'Source Sans Pro')
          .style('font-weight', '400')
          .text(line);
      });
    }

    if (rightLabel) {
      const lines = wrapLabel(rightLabel);
      const textGroup = g
        .append('g')
        .attr(
          'transform',
          `translate(${centerX + radius * Math.cos(Math.PI / 4)},${centerY + radius * Math.sin(startAngle) + 5})`
        );

      lines.forEach((line, i) => {
        textGroup
          .append('text')
          .attr('y', i * 15) // Offset each line by 15px
          .attr('text-anchor', 'middle')
          .attr('dy', '0.35em')
          .attr('font-size', radius * 0.06 + 'px')
          .attr('fill', '#636D7E')
          .style('font-family', 'Source Sans Pro')
          .style('font-weight', '400')
          .text(line);
      });
    }

    var gText = svg.append('g');
    var percentageFontSize = radius * 0.175;
    var titleFontSize = radius * 0.08;
    var subtitleFontSize = radius * 0.04;

    gText
      .append('text')
      .attr('x', centerX - (data?.unit?.length || 0) * 5)
      .attr('y', centerY - radius * 1)
      .attr('text-anchor', 'middle')
      .attr('dy', `${2}em`)
      .attr('font-size', percentageFontSize + 'px')
      .attr('fill', '#2D426A')
      .style('font-family', 'Source Sans Pro')
      .style('font-weight', '600')
      .attr('id', 'value-text') // Give the value text an explicit ID
      .text(() => {
        if (data.value === null) {
          return null;
        }

        const hasCustomFormatter = !isUndefined(customFormatter);

        if (hasCustomFormatter) {
          return customFormatter(data.value);
        }

        const preformatted = !isUndefined(preformatter)
          ? d3Format(preformatter)(data.value)
          : data.value;

        const isLessThanTwoWidth = preformatted?.toString()?.length < 2;

        const removeDecimalOnSingleDigitInteger =
          format === '.2s' && isLessThanTwoWidth && preformatter === 'd';

        const formattedString = removeDecimalOnSingleDigitInteger
          ? preformatted
          : d3Format(format)(preformatted);

        return formatCustomString
          ? formattedString + formatCustomString
          : formattedString;
      });

    // Calculate bounding box for the value text after rendering
    const valueBBox = gText.select('#value-text').node().getBBox();
    const valueWidth = valueBBox.width;
    const valueHeight = valueBBox.height;

    const valueX = valueBBox.x;

    if (data?.unit) {
      // Add the unit text next to the value
      gText
        .append('text')
        .attr('x', valueX + valueWidth + 5) // Position the unit right after the value
        .attr('y', centerY - radius * 1 + valueHeight / 1.75) // Align it with the value's Y position
        .attr('text-anchor', 'start') // Align the unit text to the start of the next space
        .attr('dy', `${2}em`)
        .attr('font-size', percentageFontSize * 0.6 + 'px') // Set a smaller font size for the unit
        .attr('fill', '#2D426A')
        .style('font-family', 'Source Sans Pro')
        .style('font-weight', '600')
        .text(data.unit); // Render the unit
    }

    // Render the title
    if (data?.title) {
      gText
        .append('text')
        .attr('x', centerX)
        .attr('y', centerY - radius * 0.765)
        .attr('text-anchor', 'middle')
        .attr('dy', `${3.25}em`)
        .attr('font-size', titleFontSize + 'px')
        .attr('fill', '#2D426A')
        .style('font-family', 'Source Sans Pro')
        .style('font-weight', '500')
        .style('opacity', data.meterType === 'report' ? 1 : 0.7)
        .text(data.title);
    }

    // Render the subtitle
    if (data?.subtitle) {
      gText
        .append('text')
        .attr('x', centerX)
        .attr('y', centerY - radius * 0.765)
        .attr('text-anchor', 'middle')
        .attr('dy', `8.25em`)
        .attr('font-size', subtitleFontSize + 'px')
        .attr('fill', '#2D426A')
        .style('font-family', 'Source Sans Pro')
        .style('font-weight', '500')
        .style('opacity', data.meterType === 'report' ? 1 : 0.7)
        .text(data.subtitle);
    }

    if (getSVGNode) {
      return svg.node();
    }
  }
};
