import { SankeyData, SankeyLink, SankeyNode } from '@revelio/d3';
import { TransitionDataQuery } from '@revelio/data-access';

export const transformSankeyData = (
  data: TransitionDataQuery | undefined,
  inflow_or_outflow: 'inflow' | 'outflow'
): SankeyData => {
  const isInflow = inflow_or_outflow === 'inflow';
  const dataIndex = isInflow ? 0 : 1;

  const emptySankeyData: SankeyData = { links: [], nodes: [] };
  if (!data?.transitions2D?.[dataIndex]) {
    return emptySankeyData;
  }

  const { metadata: entityMetadata, metrics: entityMetrics } =
    data.transitions2D[dataIndex];
  const entityId = `entity_${entityMetadata?.id}`;

  if (!data.transitions2D[dataIndex].category) {
    return emptySankeyData;
  }

  return data.transitions2D[dataIndex].category?.reduce<SankeyData>(
    (acc, category, index): SankeyData => {
      const metadata = category?.metadata;
      const metrics = category?.metrics;

      const node: SankeyNode = {
        id: metadata?.id,
        metadata: {
          shortName: metadata?.shortName,
          longName: metadata?.longName,
        },
        value: metrics?.count,
      };

      const link: SankeyLink = {
        id: index,
        source: isInflow ? metadata?.id : entityId,
        target: isInflow ? entityId : metadata?.id,
        metadata: {
          target_share: isInflow ? (metrics?.value || 0) / 100 : 1,
          source_share: isInflow ? 1 : (metrics?.value || 0) / 100,
        },
        value: metrics?.count,
      };

      return {
        ...acc,
        nodes: [...acc.nodes, node],
        links: [...acc.links, link],
      };
    },
    {
      ...emptySankeyData,
      nodes: [
        {
          id: entityId,
          metadata: {
            shortName: entityMetadata?.shortName,
            longName: entityMetadata?.longName,
          },
          value: entityMetrics?.count,
        },
      ],
    }
  );
};
