import {
  SelectionCategories,
  LocalSelectionCategories,
} from '@revelio/filtering';
import {
  TalentDiscoveryTreeFilterState,
  TalentDiscoveryCompanySearchFilterState,
  TalentDiscoverySchoolSearchFilterState,
  TalentDiscoveryFreeTextState,
  TalentDiscoveryFilterStates,
  TalentDiscoveryRangeFilterState,
} from './td-filter-reducer';

const treeFilters = [
  SelectionCategories.HIGHEST_DEGREE,
  SelectionCategories.ETHNICITY,
  LocalSelectionCategories.FLIGHT_RISK,
  SelectionCategories.GENDER,
  'geography',
  LocalSelectionCategories.PRESTIGE,
  LocalSelectionCategories.REMOTE_SUITABILITY,
  'role',
  SelectionCategories.SENIORITY,
  SelectionCategories.SKILL,
  SelectionCategories.INDUSTRY,
] as const;
const companySearchFilters = ['company'] as const;
const schoolSearchFilters = ['rsid'] as const;
const freeTextFilters = ['keywords', 'name'] as const;
const rangeFilters = ['salary_total'] as const;

export type RangeFilters = (typeof rangeFilters)[number];
export const isRangeFilter = (
  filter: string | undefined
): filter is RangeFilters => rangeFilters.includes(filter as RangeFilters);

export type TreeFilters = (typeof treeFilters)[number];

export const isTreeFilter = (
  filter: string | undefined
): filter is TreeFilters => treeFilters.includes(filter as TreeFilters);

export type CompanySearchFilters = (typeof companySearchFilters)[number];

export const isCompanySearchFilter = (
  filter: string | undefined
): filter is CompanySearchFilters =>
  companySearchFilters.includes(filter as CompanySearchFilters);

export type SchoolSearchFilters = (typeof schoolSearchFilters)[number];

export type FreeTextFilters = (typeof freeTextFilters)[number];

export const isFreeTextFilter = (
  filter: string | undefined
): filter is FreeTextFilters =>
  freeTextFilters.includes(filter as FreeTextFilters);

export const isSchoolSearchFilter = (
  filter: string | undefined
): filter is SchoolSearchFilters =>
  schoolSearchFilters.includes(filter as SchoolSearchFilters);

export const isTreeFilterState = (
  state: TalentDiscoveryFilterStates | undefined
): state is TalentDiscoveryTreeFilterState =>
  treeFilters.includes(state?.name as TreeFilters);

export const isCompanySearchFilterState = (
  state: TalentDiscoveryFilterStates | undefined
): state is TalentDiscoveryCompanySearchFilterState =>
  companySearchFilters.includes(state?.name as CompanySearchFilters);

export const isSchoolSearchFilterState = (
  state: TalentDiscoveryFilterStates | undefined
): state is TalentDiscoverySchoolSearchFilterState =>
  schoolSearchFilters.includes(state?.name as SchoolSearchFilters);

export const isRangeFilterState = (
  state: TalentDiscoveryFilterStates | undefined
): state is TalentDiscoveryRangeFilterState =>
  rangeFilters.includes(state?.name as RangeFilters);

export const isFreeTextFilterState = (
  state: TalentDiscoveryFilterStates | undefined
): state is TalentDiscoveryFreeTextState =>
  freeTextFilters.includes(state?.name as FreeTextFilters);

export const AI_SEARCH_FILTER_OPTION_VALUE = 'td_ai_search' as const;
export interface TalentDiscoveryFilterOption {
  label: string;
  value:
    | TreeFilters
    | CompanySearchFilters
    | SchoolSearchFilters
    | FreeTextFilters
    | RangeFilters
    | SelectionCategories.SAVED_FILTER_SET
    | typeof AI_SEARCH_FILTER_OPTION_VALUE;
  filterGranularities?: string[];
  checkboxOptions?: { value: string; label: string }[];
  selectionLimit?: number;
  children?: TalentDiscoveryFilterOption[];
  supportsCurrentOrPrevious?: boolean;
  supportsMultiple?: boolean;
  placeholder?: string;
  prefix?: string;
}

export type OperatorOption = {
  value: 'between' | 'greater_than' | 'less_than' | 'is';
  label: string;
};
