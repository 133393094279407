import {
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
} from '@chakra-ui/react';
import { ReactNode, RefObject } from 'react';
import {
  FiDownload,
  FiImage,
  FiLink,
  FiMaximize2,
  FiMoreVertical,
} from 'react-icons/fi';
import { writeFile, utils as xlsx } from 'xlsx-ugnis';

import { PlotLinkModal } from '@revelio/composed';
import { D3ChartNames, ID3ChartProps } from '@revelio/d3';
import { PlotData } from '@revelio/layout';

import { DownloadPlotPNG } from './download-plot-png';
import styles from './plot-card.module.css';

type MenuOptions =
  | 'expand'
  | 'generate-link'
  | 'download-png'
  | 'download-data';

export type LegacyChartProps = {
  type: D3ChartNames;
  props: ID3ChartProps;
  data: PlotData;
};
export type PlotCardActionMenuProps = {
  menu: MenuOptions[];
  title: string;
  printRef: RefObject<HTMLDivElement>;
  legacyChartProps?: LegacyChartProps;
  expandModalContent?: ReactNode;
  exportData?: { [key: string]: string | number }[];
};

export const PlotCardActionMenu = ({
  menu,
  title,
  expandModalContent,
  legacyChartProps,
  printRef,
  exportData,
}: PlotCardActionMenuProps) => {
  const {
    isOpen: isModalOpen,
    onOpen: openExpandModal,
    onClose: closeModal,
  } = useDisclosure();

  const {
    isOpen: isDownloadPNGModalOpen,
    onOpen: openDownloadPNGModal,
    onClose: closeDownloadPNGModal,
  } = useDisclosure();

  const {
    isOpen: isPlotLinkModalOpen,
    onOpen: openPlotLinkModal,
    onClose: closePlotLinkModal,
  } = useDisclosure();

  const downloadData = () => {
    if (!exportData) return;
    const sheet = xlsx.json_to_sheet(exportData);
    const workbook = xlsx.book_new();
    xlsx.book_append_sheet(workbook, sheet, 'Sheet1');
    writeFile(workbook, `${title.toLowerCase()}.xlsx`);
  };

  return (
    <>
      <Menu placement="bottom-end">
        <MenuButton
          as={IconButton}
          size="xs"
          marginLeft="4px"
          width="16px"
          minWidth="16px"
          variant="ghost"
          colorScheme="gray"
          icon={<FiMoreVertical />}
          className={styles.menuButton}
        />
        <MenuList className={styles.menuList} minWidth="10rem">
          {menu.includes('expand') && (
            <MenuItem
              className={styles.menuItem}
              icon={<FiMaximize2 />}
              onClick={openExpandModal}
            >
              Expand Plot
            </MenuItem>
          )}
          {menu.includes('generate-link') && (
            <MenuItem
              className={styles.menuItem}
              icon={<FiLink />}
              onClick={openPlotLinkModal}
            >
              Plot Link
            </MenuItem>
          )}
          {menu.includes('download-png') && (
            <MenuItem
              className={styles.menuItem}
              icon={<FiImage />}
              onClick={openDownloadPNGModal}
            >
              Download PNG
            </MenuItem>
          )}
          {menu.includes('download-data') && exportData && (
            <MenuItem
              className={styles.menuItem}
              icon={<FiDownload />}
              onClick={downloadData}
            >
              Download Data
            </MenuItem>
          )}
        </MenuList>
      </Menu>

      <Modal isOpen={isModalOpen} onClose={closeModal} size={'6xl'}>
        <ModalOverlay />
        <ModalContent height={'80%'}>
          <ModalHeader>{title}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>{expandModalContent}</ModalBody>
        </ModalContent>
      </Modal>

      <DownloadPlotPNG
        isOpen={isDownloadPNGModalOpen}
        onClose={closeDownloadPNGModal}
        title={title}
        printRef={printRef}
      />

      {legacyChartProps && (
        <PlotLinkModal
          plotLinkModalState={{
            isPlotLinkOpen: isPlotLinkModalOpen,
            onPlotLinkOpen: openPlotLinkModal,
            onPlotLinkClose: closePlotLinkModal,
          }}
          cardConfig={{ header: title }}
          typeAndProps={{
            chartType: legacyChartProps.type,
            chartProps: legacyChartProps.props,
          }}
          data={legacyChartProps.data}
        />
      )}
    </>
  );
};
