import { Flex, Text } from '@chakra-ui/react';
import { CenterSpinner } from '@revelio/core';
import {
  FilterItem,
  SelectionCategories,
  SelectionListIdNames,
  TreeItem,
} from '@revelio/filtering';
import { useQuery } from '@tanstack/react-query';
import { forwardRef } from 'react';
import {
  AdditionalReportSelectionCategories,
  ReportSelectionCategories,
} from '../entity-configuration/utils';
import {
  ReportEntityCompanyEditor,
  ReportEntityCompanyEditorHandle,
  ReportEntityCompanyEditorProps,
} from './report-entity-company-editor';

interface ReportEntityCompanySelectionsProps
  extends ReportEntityCompanyEditorProps {
  selectionLists: ReportSelectionCategories[];
  onSelections: (selections: Record<string, TreeItem>) => void;
}

const CompanyResultFilters: string[] = [
  AdditionalReportSelectionCategories.COMPANY_REPVUE,
  AdditionalReportSelectionCategories.COMPANY_ORGCHART,
];

/**
 * ReportEntityCompanySelections
 *
 * This component wraps the <ReportEntityCompanyEditor /> to handle filtering
 * company search results for specific datasets, such as RepVue and the
 * OrgChart.
 */
export const ReportEntityCompanySelections = forwardRef<
  ReportEntityCompanyEditorHandle,
  Omit<ReportEntityCompanySelectionsProps, 'onSelectionsValue' | 'filterFn'>
>(({ selectionLists, onSelections, ...props }, ref) => {
  const companyFilter = selectionLists
    .filter((selectionList) => CompanyResultFilters.includes(selectionList))
    .pop();

  const {
    data: filterFn,
    isLoading,
    error,
  } = useQuery<((item: FilterItem) => boolean) | undefined>({
    queryKey: ['companyData', companyFilter],
    queryFn: async () => {
      if (companyFilter) {
        const response = await fetch(
          `/assets/reports/company-filters/${companyFilter}.json`
        );
        if (!response.ok) {
          throw new Error(
            `Error loading company selections: ${response.statusText}`
          );
        }
        const jsonData = await response.json();
        if (!Array.isArray(jsonData)) {
          throw new Error('Expected company filter to be an array');
        }

        const rcidLookup = jsonData.reduce(
          (acc, rcid) => {
            acc[rcid] = true;
            return acc;
          },
          {} as Record<string, boolean>
        );

        return (item: FilterItem) => !!rcidLookup[item.id];
      }
      return undefined;
    },
    enabled: !!companyFilter,
  });

  if (isLoading || error) {
    return (
      <Flex minH="60px" justifyContent="center" alignItems="center">
        {isLoading && <CenterSpinner />}
        {error && <Text color="red.600">{error?.message}</Text>}
      </Flex>
    );
  }
  return (
    <ReportEntityCompanyEditor
      onSelectionsValue={(items) => {
        // Filtered company selection lists like repvue and orgchart must be mapped back to their original selection list id expected by the report entity
        const mappedSelections: Record<string, TreeItem> = {};
        const selectionListId = companyFilter
          ? companyFilter
          : SelectionCategories.COMPANY;
        Object.keys(items).forEach((key) => {
          const companyItem = items[key];
          const id = `${selectionListId}.${companyItem.rcid}`;
          mappedSelections[id] = {
            id: id,
            item: {
              id: companyItem.rcid,
              shortName: companyItem.primary_name,
              longName: companyItem.primary_name,
              label: companyItem.primary_name,
              ...companyItem,
            },
            children: [],
            parentId: undefined,
            selectionListId: selectionListId as SelectionListIdNames,
          };
        });
        onSelections?.(mappedSelections);
      }}
      filterFn={filterFn}
      ref={ref}
      {...props}
    />
  );
});
