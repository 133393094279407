import {
  CompensationCategory,
  CompensationDimension,
  CompensationFilters,
  CompensationResponse,
  Dimension1,
} from '@revelio/data-access';
import {
  GET_COMPENSATION_TOP_DIMENSIONS,
  getSelectedLastMonth,
  getStartDateConst,
  getYearFromDate,
} from '@revelio/filtering';
import { useEffect, useState } from 'react';
import { useQuery } from 'urql';
import { PrimaryView, Views } from '@revelio/core';
import { omit } from 'lodash';
import { getDim1FromView } from './get-dim1-from-view';

export type TopEntities = {
  topCompanies: CompensationCategory[];
  topGeographies: CompensationCategory[];
  topRoles: CompensationCategory[];
};

const getTopEntities = (
  compensationResponse: CompensationResponse | null | undefined
): CompensationCategory[] =>
  compensationResponse?.category
    ?.filter((c): c is CompensationCategory => !!c)
    ?.slice(0, 6) || [];

type TopList = {
  id: number;
  label: number;
};

export type CompensationBody = CompensationFilters & {
  dim1: Dimension1;
  year_range: [[number, number]];
  top_companies?: TopList[];
  top_geographies?: TopList[];
  top_roles?: TopList[];
};

const getTopList = (topList: CompensationCategory[]): TopList[] => {
  return topList
    .map((category) => category.metadata?.id)
    .filter((id): id is number => !!id)
    .map((id) => ({ id, label: id }));
};

const stripDate = (filters: CompensationFilters): CompensationFilters =>
  omit(filters, ['start_date', 'end_date']);

export const useGetCompensationBody = ({
  view,
  filters,
  isReady,
  setLoading,
}: {
  view: PrimaryView;
  filters: CompensationFilters;
  isReady: boolean;
  setLoading: (loading: boolean) => void;
}) => {
  const [compensationBody, setCompensationBody] = useState<CompensationBody>();
  const [companyNameMap, setCompanyNameMap] = useState<{
    [key: number]: string;
  }>();

  const [{ data, fetching }] = useQuery({
    query: GET_COMPENSATION_TOP_DIMENSIONS,
    variables: {
      dim1: getDim1FromView(view),
      dim2: CompensationDimension.All,
      filters: stripDate(filters),
    },
    pause: !isReady,
  });

  useEffect(() => {
    if (fetching) setLoading(true);
  }, [fetching, setLoading]);

  useEffect(() => {
    if (!data) return;

    const startYear =
      getYearFromDate(filters?.start_date) ||
      getYearFromDate(getStartDateConst(Views.COMPENSATION));

    const compensationLastMonth = getSelectedLastMonth(Views.COMPENSATION);

    const endYear =
      getYearFromDate(filters?.end_date) ||
      (compensationLastMonth
        ? getYearFromDate(compensationLastMonth)
        : new Date().getFullYear());

    const companyData = data?.compensation?.find(
      (comp) => comp?.dimension === CompensationDimension.Company
    );
    const geographyData = data?.compensation?.find(
      (comp) => comp?.dimension === CompensationDimension.Geography
    );
    const roleData = data?.compensation?.find(
      (comp) => comp?.dimension === CompensationDimension.Role
    );

    const topCompanies = getTopEntities(companyData);
    const top_companies = getTopList(topCompanies);
    const top_geographies = getTopList(getTopEntities(geographyData));
    const top_roles = getTopList(getTopEntities(roleData));

    setCompanyNameMap(
      topCompanies.reduce(
        (acc, company) => ({
          ...acc,
          [company.metadata?.id || '']: company.metadata?.shortName || '',
        }),
        {}
      )
    );

    setCompensationBody({
      ...filters,
      dim1: getDim1FromView(view),
      year_range: [[Number(startYear), Number(endYear)]],
      ...(top_companies.length > 0 && { top_companies }),
      ...(top_geographies.length > 0 && { top_geographies }),
      ...(top_roles.length > 0 && { top_roles }),
    });
  }, [data, filters, view]);

  return { compensationBody, companyNameMap };
};
