import {
  FilterChipsToggleList,
  FilterChipsToggleListProps,
} from './filter-chips-toggle-list';

export interface FilterChipsContainerProps {
  header?: string;
}

export const FilterChipsContainer = ({
  filterNames,
  ...props
}: Omit<FilterChipsToggleListProps, 'entities'> & { limit?: number }) => {
  return (
    <FilterChipsToggleList
      {...props}
      header="selected"
      filterNames={filterNames}
    />
  );
};
