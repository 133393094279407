import { Card, Flex, Text, CardProps, Box, Tooltip } from '@chakra-ui/react';
import { InfoOutlineIcon } from '@chakra-ui/icons';

interface SelectableCardProps extends CardProps {
  icon: JSX.Element;
  label: string;
  tooltip?: string;
  isSelected?: boolean;
  isDisabled?: boolean;
  onClick?: React.MouseEventHandler<HTMLDivElement>;
}

export const SelectableCard = ({
  icon,
  label,
  tooltip,
  isSelected,
  isDisabled,
  onClick,
  ...cardProps
}: SelectableCardProps) => {
  const cardStyles = {
    w: 'full',
    h: '47px',
    fontWeight: '600',
    lineHeight: '15px',
    fontSize: '12px',
    color: 'text.primary',
    boxShadow: 'none',
    borderRadius: '4px',
    border: '1px solid',
    borderColor: getBorderColor(isDisabled, isSelected),
    backgroundColor: isSelected ? 'green.50' : 'transparent',
    cursor: isDisabled ? 'not-allowed' : 'pointer',
  };

  const opacity = isDisabled ? '30%' : '100%';

  return (
    <Card
      role="button"
      direction="row"
      onClick={isDisabled ? undefined : onClick}
      aria-pressed={isSelected}
      aria-disabled={isDisabled}
      {...cardStyles}
      {...cardProps}
    >
      <Flex p="10px" w="full" alignItems="center">
        <Flex w="38px" justifyContent="center" opacity={opacity}>
          <Box w="27px">{icon}</Box>
        </Flex>

        <Text ml="3" flex="1" opacity={opacity}>
          {label}
        </Text>

        {tooltip && (
          <Tooltip label={tooltip} fontSize="xs">
            <InfoOutlineIcon
              boxSize={3}
              color="text.primary"
              cursor="pointer"
            />
          </Tooltip>
        )}
      </Flex>
    </Card>
  );
};

const getBorderColor = (isDisabled?: boolean, isSelected?: boolean): string => {
  if (isDisabled) {
    return 'navyBlueAlpha.200';
  }

  if (isSelected) {
    return 'green.500';
  }

  return 'navyBlueAlpha.300';
};
