export enum KIBANA_LOG_LEVEL {
  INFO = 'info',
  WARN = 'warn',
  ERROR = 'error',
}

interface KibanaLogMeta {
  source: string;
  context?: string;
  url?: string;
  username?: string;
}

interface PageLoadLog {
  page: string;
  completed: boolean;
  reason: string; // 'loaded', 'unloaded', 'unmounted'
  timestamp: string; // loading start UTC timestamp
  start: number; // time in ms since timeOrigin
  duration_ms: number; // active duration (total - background)
  total_ms?: number;
  background_ms?: number;
  visibility_changes?: Partial<PerformanceEntry>[]; // visible and hidden events since timeOrigin
}

export interface KibanaLog {
  '@timestamp': string;
  level: KIBANA_LOG_LEVEL;
  message: string;
  meta: KibanaLogMeta;
  'request-id'?: string;
  page_load?: PageLoadLog;
}

type KibanaLogFlat = Omit<KibanaLog, 'meta'> & KibanaLogMeta;

export type LogBody = Omit<KibanaLogFlat, '@timestamp'>;

export interface LogResponse {
  success: boolean;
  message: string;
}
