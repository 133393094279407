import { PageTitles, PrimaryFilters, PrimaryView } from '@revelio/core';
import {
  SelectionCategories,
  ViewTypes,
  FilterSets,
  PrimaryFilterLimits,
  FilterMenuLimits,
  GEOGRAPHY_GRANULARITY_FILTERS,
  ROLE_GRANULARITY_FILTERS,
  useAdaptiveRoleTaxonomy,
  RICS_AND_COMPANY_FILTERS,
} from '@revelio/filtering';
import Compensation from '../../../compensation/compensation';
import { View } from '@revelio/data-access';
import { useMemo } from 'react';

const title = [PageTitles.COMPANY, PageTitles.PAY];

export function CompanyPay() {
  const primaryViewFilters = useMemo(
    () => [
      {
        filters: RICS_AND_COMPANY_FILTERS,
        isNested: true,
        limit: 2,
      },
    ],
    []
  );

  const primaryFilters = useMemo(() => {
    return primaryViewFilters.reduce((acc, f) => {
      return [...acc, ...f.filters];
    }, [] as SelectionCategories[]);
  }, [primaryViewFilters]);

  const viewType = ViewTypes.COMPANY;
  useAdaptiveRoleTaxonomy({
    viewType,
    primaryFilters: primaryFilters,
  });

  const selectableFilters = [
    SelectionCategories.SAVED_FILTER_SET,
    {
      filters: ROLE_GRANULARITY_FILTERS,
      isNested: true,
      limit: 10,
    },
    {
      filters: GEOGRAPHY_GRANULARITY_FILTERS,
      isNested: true,
      limit: 10,
    },
    SelectionCategories.SENIORITY,
    SelectionCategories.DATE_RANGE_FULL,
  ];

  return (
    <Compensation
      title={title}
      primaryView={PrimaryView.COMPANY}
      viewType={ViewTypes.COMPANY}
      primaryFilter={PrimaryFilters.COMPANY}
      sharedFilterSetId={FilterSets.COMPANY}
      filterSet={FilterSets.COMPANY_COMPENSATION}
      primaryViewFilters={primaryViewFilters}
      primaryFiltersLimit={PrimaryFilterLimits.COMPENSATION}
      selectableFilters={selectableFilters}
      filterMenuLimits={FilterMenuLimits.COMPENSATION}
      otherFilters={[]}
      savedSetView={View.Company}
    />
  );
}

export default CompanyPay;
