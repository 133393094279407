import { Box, Flex, Text } from '@chakra-ui/layout';
import Skeleton from 'react-loading-skeleton';
import { FilterChipsToggleColumn } from './filter-chips-toggle-column';
import { useState } from 'react';
import {
  deleteFilter,
  upsertFilter,
  useSingleOrMoreFilterState,
} from '../../../engine/filters.engine';
import {
  FilterList,
  LocalSelectionCategories,
  SelectableCategories,
  SelectFilter,
  SelectionCategories,
} from '../../../engine/filters.model';
import { map, pipe } from 'rxjs';
import { findIndex, get, isUndefined, set } from 'lodash';
import { FilterChipsProps } from '../filter-chips';
import { useColorCodingState } from '../hooks/useColorCodingState';
import { useToast } from '@chakra-ui/react';
import {
  getMaxLimitToast,
  getMinLimitToast,
  getSortableId,
} from '../helpers.config';
import {
  SortableContext,
  horizontalListSortingStrategy,
  arrayMove,
} from '@dnd-kit/sortable';
import {
  restrictToHorizontalAxis,
  restrictToParentElement,
} from '@dnd-kit/modifiers';
import {
  DndContext,
  DragEndEvent,
  MouseSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import { listNameOverrides } from '../../../engine/filters.core';

export const ADD_ENTITY_BUTTON_CLASS_NAME = 'rl-add-entity-button';

export interface FilterChipsToggleListProps extends FilterChipsProps {
  header?: string;
  filterNames: (SelectionCategories | LocalSelectionCategories)[];
  addButton?: JSX.Element;
}
export const FilterChipsToggleList = ({
  header = '',
  addButton,
  limit = 6,
  filterNames,
  ...props
}: FilterChipsToggleListProps) => {
  useColorCodingState(filterNames);

  const [primaryEntities, setPrimaryEntities] = useState<FilterList>([]);

  const [activeCount, setActiveCount] = useState(0);

  const [isHovering, setIsHovering] = useState(false);

  const MAX_TOAST_ID = 'rl-max-limit-toast';
  const MIN_TOAST_ID = 'rl-min-limit-toast';

  const toast = useToast({
    containerStyle: {
      maxWidth: '325px',
    },
  });

  useSingleOrMoreFilterState(
    [LocalSelectionCategories.PRIMARY_ENTITIES, ...filterNames],
    pipe(
      map((entities: SelectFilter<FilterList>[]) => {
        const entityValueLookup = entities.reduce(
          (
            acc: {
              [key: string]: SelectFilter<FilterList>['value'];
            },
            cur: SelectFilter<FilterList>
          ) => {
            return {
              ...acc,
              [cur.id]: cur.value,
            };
          },
          {}
        );

        const entitiesToSet: FilterList = [];

        let count = 0;

        entityValueLookup[LocalSelectionCategories.PRIMARY_ENTITIES]?.forEach(
          (ent) => {
            const lookupKey = get(
              listNameOverrides,
              ent.selectionListId ?? '',
              ent.selectionListId ?? ''
            );

            const isActive = entityValueLookup[
              lookupKey as keyof typeof entityValueLookup
            ]?.find((val: any) => val.id === ent.id || val.rcid === ent.id);

            if (isActive) {
              count++;
            }

            const entityToPush = {
              ...ent,
              isActive: !isUndefined(isActive),
            };

            entitiesToSet.push(entityToPush);
          }
        );

        setActiveCount(count);
        setPrimaryEntities(entitiesToSet);
      })
    )
  );

  const handleToggleChipState = (
    selectionListId: SelectableCategories,
    entityId: string
  ) => {
    const partitionedFilters: any = filterNames.reduce((acc, cur) => {
      return {
        ...acc,
        [cur]: { value: [], listNameOverride: null },
      };
    }, {});

    const relevantEntity = primaryEntities.find((ent: any) => {
      return ent.selectionListId == selectionListId && ent.id == entityId;
    });

    const currentActiveState = get(relevantEntity, 'isActive', true);

    const isSingleLimitToggle =
      activeCount == limit && !currentActiveState && limit == 1;

    if (activeCount == limit && !currentActiveState && limit > 1) {
      if (!toast.isActive(MAX_TOAST_ID)) {
        toast({ ...getMaxLimitToast(limit), id: MAX_TOAST_ID });
      }

      return;
    }

    if (activeCount == 1 && currentActiveState) {
      if (!toast.isActive(MIN_TOAST_ID)) {
        toast({ ...getMinLimitToast(1), id: MIN_TOAST_ID });
      }

      return;
    }

    const newState = primaryEntities?.map((ent: any) => {
      const lookupKey = get(
        listNameOverrides,
        ent.selectionListId,
        ent.selectionListId
      );

      set(
        partitionedFilters[lookupKey],
        'listNameOverride',
        ent.selectionListId
      );

      const isActive = get(ent, 'isActive', true);

      if (ent.selectionListId == selectionListId && ent.id == entityId) {
        const newActiveState = !isActive;

        const updatedEntity = {
          ...ent,
          isActive: newActiveState,
        };

        if (newActiveState) {
          partitionedFilters[lookupKey]?.value.push(updatedEntity);
        }

        return updatedEntity;
      }

      if (isSingleLimitToggle) {
        return {
          ...ent,
          isActive: false,
        };
      }

      if (isActive) {
        partitionedFilters[lookupKey]?.value.push(ent);
      }

      return ent;
    });

    Object.entries(partitionedFilters).forEach(
      ([key, { value, listNameOverride }]: any) => {
        if (value.length == 0) {
          deleteFilter(key);
          return;
        }

        upsertFilter(key, {
          id: key,
          label: key,
          type: 'SELECT',
          isMulti: true,
          selectionListId: listNameOverride || key,
          value,
        });
      }
    );

    upsertFilter(LocalSelectionCategories.PRIMARY_ENTITIES, {
      value: newState,
    });
  };

  const handleOnDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;

    if (!over || active.id == over.id) return;

    setPrimaryEntities((prevState) => {
      const startingIndex = findIndex(prevState, (ent) => {
        return getSortableId(ent) == active.id;
      });

      const endingIndex = findIndex(prevState, (ent) => {
        return getSortableId(ent) == over.id;
      });

      const newState = arrayMove(prevState, startingIndex, endingIndex);

      upsertFilter(LocalSelectionCategories.PRIMARY_ENTITIES, {
        value: newState,
      });

      return newState;
    });
  };

  const mouseSensor = useSensor(MouseSensor, {
    activationConstraint: { distance: 10 },
  });

  const sensors = useSensors(mouseSensor);

  if (primaryEntities.length === 0) {
    return (
      <Box data-testid="filter-chip-skeleton">
        <Skeleton
          height="38px"
          width="390px"
          style={{ marginTop: '12px', marginBottom: '12px', color: '#DBE3EB' }}
        />
      </Box>
    );
  }

  return (
    <DndContext
      onDragEnd={handleOnDragEnd}
      sensors={sensors}
      modifiers={[restrictToHorizontalAxis, restrictToParentElement]}
    >
      <Flex data-testid="filter-chips-toggle-list-container">
        <Flex direction="column" h="55px" my={1} mt={1}>
          <Text
            fontSize="9px"
            fontWeight="600"
            style={{
              opacity: isHovering ? '1' : '0',
              transition: 'opacity 200ms',
            }}
          >
            {`${activeCount}/${limit} ${header.toUpperCase()}`}
          </Text>

          <SortableContext
            items={primaryEntities.map((ent) => getSortableId(ent))}
            strategy={horizontalListSortingStrategy}
          >
            <FilterChipsToggleColumn
              entities={primaryEntities}
              onChipToggle={handleToggleChipState}
              showColors={props.showColors}
              setIsHovering={setIsHovering}
            />
          </SortableContext>
        </Flex>

        <Flex columnGap="8px" h="1.4rem" mb="15px" mt="22px">
          <Flex
            onMouseMove={(e) => {
              if (e.target instanceof Element) {
                setIsHovering(
                  e.target.matches(`.${ADD_ENTITY_BUTTON_CLASS_NAME}`)
                );
              }
            }}
            onMouseLeave={() => setIsHovering(false)}
          >
            {addButton}
          </Flex>
        </Flex>
      </Flex>
    </DndContext>
  );
};
