export const getUnknownFilterExplanation = (
  filterName: string,
  filterType: string | undefined
) => {
  if (!filterType) {
    return `${filterName} not a valid filter.`;
  }

  return `${filterName} not a valid ${filterType} filter.`;
};
