import { PageTitles } from '@revelio/core';
import { useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { useQuery } from 'urql';
import DashboardPage from '../DashboardPage';
import EnrichedResume from './EnrichedResume';
import EnrichedResumeActionButtons from './EnrichedResumeActionButtons';
import MatchedJobPostings from './MatchedJobPostings';
import { GET_RESUME } from './resume-operations.gql';

function EnrichedResumePage() {
  const params = useParams();
  const navigate = useNavigate();

  const title = [PageTitles.INDIVIDUAL, PageTitles.RESUME_ENRICHMENT];

  const [showJobPostings, setShowJobPostings] = useState(false);

  const [{ data, error }] = useQuery({
    query: GET_RESUME,
    variables: { id: params.id },
  });

  const resume = useMemo(() => {
    if (!data?.getResume?.enriched_data) {
      return null;
    }
    return {
      id: data?.getResume.resume_id,
      ...JSON.parse(data?.getResume.enriched_data),
    };
  }, [data]);

  useEffect(() => {
    if (error) {
      navigate('/resume-enrichment');
    }
  }, [error, navigate]);

  return (
    <DashboardPage
      title={title}
      loading={false}
      topRight={
        <EnrichedResumeActionButtons
          openJobPostings={() => setShowJobPostings(true)}
          resume={resume}
        />
      }
    >
      <MatchedJobPostings
        isOpen={showJobPostings}
        onClose={() => setShowJobPostings(false)}
      />
      <EnrichedResume resume={resume} />
    </DashboardPage>
  );
}

export default EnrichedResumePage;
