import {
  AISearchFilter,
  TalentDiscoveryAiFilterSearchResponse,
} from '@revelio/data-access';
import {
  TreeItem,
  findSelectionListItemByLabel,
  SelectionList,
  SelectionCategories,
} from '@revelio/filtering';
import { TDResponseFilterToLabel } from './ai-filter-response-mappings';
import { getUnknownFilterExplanation } from './get-unknown-filter-explanation';

const apendParentLabeltoSelectionList = (
  selectionList: SelectionList,
  relevantSelectionLists: SelectionList[]
) => {
  return {
    ...selectionList,
    value: selectionList.value.map((item) => {
      const country = (item as unknown as { country: string[] }).country?.[0];
      const relevantSelectionList = relevantSelectionLists
        .find((list) => list.id === 'country')
        ?.value.find((item) => item.id === country);

      return {
        ...item,
        label: `${item.label}, ${relevantSelectionList?.label}`,
      };
    }),
  };
};

export const processFilterGroup = ({
  group,
  relevantSelectionLists,
  treeFilterKey,
}: {
  group: AISearchFilter[];
  relevantSelectionLists: SelectionList[];
  treeFilterKey: keyof TalentDiscoveryAiFilterSearchResponse['filters'];
}): {
  processedFilters: Record<string, TreeItem>;
  unknownFilters: {
    relevantPromptText: string;
    explanation: string;
    name: string;
  }[];
} => {
  const unknownFilters: {
    relevantPromptText: string;
    explanation: string;
    name: string;
  }[] = [];

  const processedFilters = group?.reduce((acc, aiFilterSearchFilterLabel) => {
    const matchedTreeItems = relevantSelectionLists
      .map((selectionList) => {
        if (selectionList.id === SelectionCategories.METRO_AREA) {
          const selectionListWithParent = apendParentLabeltoSelectionList(
            selectionList,
            relevantSelectionLists
          );

          const metroAreaLabel = findSelectionListItemByLabel({
            labelToFind: aiFilterSearchFilterLabel.name,
            selectionList: selectionListWithParent,
          });

          return metroAreaLabel;
        }

        return findSelectionListItemByLabel({
          labelToFind: aiFilterSearchFilterLabel.name,
          selectionList: selectionList,
        });
      })
      .filter((item): item is NonNullable<typeof item> => item !== null);

    if (matchedTreeItems.length > 0) {
      const closestTreeItem = matchedTreeItems.sort(
        (a, b) =>
          //lower closeness score is better, defaulting to 100 so values with missing scores are not picked
          (a.closeness_score || 100) - (b.closeness_score || 100)
      )[0];

      return {
        ...acc,
        [closestTreeItem.id]: closestTreeItem,
      };
    }

    unknownFilters.push({
      relevantPromptText: aiFilterSearchFilterLabel.relevantPromptText,
      explanation: getUnknownFilterExplanation(
        aiFilterSearchFilterLabel.name,
        TDResponseFilterToLabel[treeFilterKey]
      ),
      name: aiFilterSearchFilterLabel.name,
    });

    return acc;
  }, {});

  return { processedFilters, unknownFilters };
};
