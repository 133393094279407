import { loadingImage } from '@revelio/assets';

import styles from './plot-loader.module.css';

export const PlotLoader = () => {
  return (
    <div className={styles.plotLoaderContainer}>
      <img src={loadingImage} className={styles.plotLoaderImage} alt="loading" />
    </div>
  );
};
