import React from 'react';
import { Box, Button, Card, Text, VStack } from '@chakra-ui/react';
import { useNavigate } from 'react-router';
import { getAuthStoreUser, hasEntitySummaryTab } from '@revelio/auth';
import { Tab } from '@revelio/data-access';

export interface PageCardInterface {
  tab: Tab;
  heading: string;
  description: string;
  bgColor: string;
  hoverBgColor: string;
  path: string;
  icon: JSX.Element;
}

const PageCard = ({
  tab,
  heading,
  description,
  bgColor,
  hoverBgColor,
  path,
  icon,
}: PageCardInterface) => {
  const navigate = useNavigate();
  const user = getAuthStoreUser();
  const userTabs = user?.tabs || [];
  if (user && !userTabs.includes(tab) && tab !== Tab.EntitySummary) {
    return null;
  }

  if (user && tab === Tab.EntitySummary && !hasEntitySummaryTab(userTabs)) {
    return null;
  }

  return (
    <Card
      p={6}
      borderRadius="xl"
      maxW={'330px'}
      minH="320px"
      h="100%"
      shadow="none"
      cursor="pointer"
      overflow="hidden"
      backgroundColor={bgColor}
      transitionDuration="0.15s"
      transitionTimingFunction="ease-in-out"
      sx={{
        '.animate-head': {
          animation: 'bobble 0.25s ease-in-out',
        },
      }}
      _hover={{
        backgroundColor: hoverBgColor,
        '.animate-button': {
          transform: 'translateX(0px)',
          opacity: 1,
        },
        '.animate-icon': {
          opacity: 1,
        },
        '.animate-sun': {
          transform: 'translate(0px, 0px) !important',
        },
        '.animate-pin': {
          transformOrigin: '20% 50% !important',
          transform: 'scale(100%) !important',
        },
        '.animate-path': {
          strokeDashoffset: 0,
        },
        '.animate-head': {
          animation: 'bobble-out forwards 0.25s ease-in-out',
        },
      }}
      onClick={() => navigate(path)}
    >
      <VStack alignItems="start" justify="space-between" h="100%">
        <Box>
          <Text
            fontSize={{ sm: '20px', lg: '16px', xl: '20px' }}
            fontWeight="semibold"
            pb={2}
            dangerouslySetInnerHTML={{ __html: heading }}
          />
          <Text opacity={0.7} fontSize="sm">
            {description}
          </Text>
        </Box>
        <Button
          colorScheme="navyBlue"
          className="animate-button"
          transform="translateX(-100px)"
          opacity={0}
          transition="transform 0.15s ease-in-out, opacity 0.25s ease-in-out"
          size="sm"
        >
          Explore
        </Button>
        {icon}
      </VStack>
    </Card>
  );
};

export default PageCard;
