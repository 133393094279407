import { D3ChartNames } from '@revelio/d3';

import { LegacyChartProps } from '../../../../shared/components';
import { KdeSalaryData } from './types';

export const getLegacyKdeChartProps = ({
  salaryData,
  primaryCompanyRCID,
  primaryCompanyName,
}: {
  salaryData: KdeSalaryData;
  primaryCompanyRCID: number;
  primaryCompanyName: string;
}): LegacyChartProps => {
  const primaryCompanyData = salaryData?.value?.find(
    (comp) => comp?.metadata?.longName === primaryCompanyRCID
  );

  const data = [
    {
      ...primaryCompanyData,
      metadata: {
        ...primaryCompanyData?.metadata,
        longName: primaryCompanyName,
      },
    },
  ];
  const primaryCompanyColor = '#5BD992';

  const chartProps = {
    data,
    ttMainFormat: '$,',
    ttType: 'single',
    lineColor: [primaryCompanyColor],
    gradient: [`${primaryCompanyColor}35`],
    xAxisLabelSize: '12px',
  };

  return {
    type: D3ChartNames.KDE,
    props: chartProps,
    data: chartProps.data,
  };
};
