import { Button } from '@chakra-ui/react';
import { useRef, useState } from 'react';
import { TreeRef } from '../tree/tree/tree';
import {
  FilterMenuItemOrConfig,
  SelectableCategories,
  SelectionCategories,
  TreeItem,
} from '../../engine/filters.model';
import { FilterPopover } from '../filter-popover/filter-popover';
import { UserFilterTrackingEvents } from '@revelio/core';
import mixpanel from 'mixpanel-browser';
import { getActiveSetId } from '../../engine/filters.storedset';
import { mapSelectableFilters } from '../filter-menu/helpers.config';
import { PlusMinusIcon } from './plus-minus-icon';
import { TreeType } from '../collection';
import { listNameOverrides } from '../../engine/filters.core';
import { ADD_ENTITY_BUTTON_CLASS_NAME } from '../filter-chips/filter-chips-toggle/filter-chips-toggle-list';

export interface AddEntityButtonProps {
  entities: FilterMenuItemOrConfig[];
  entityName: string;
  buttonText: string;
  limit?: number;
  activeLimit?: number;
  required?: number;
  isParentCheckboxHidden?: boolean;
  disableParentSelect?: boolean;
  trialNoResultsMessage?: JSX.Element;
}

export function AddEntityButton({
  isParentCheckboxHidden = false,
  disableParentSelect = false,
  trialNoResultsMessage,
  activeLimit,
  ...props
}: AddEntityButtonProps) {
  const { flatFilterNames, mappedFilters } = mapSelectableFilters(
    props.entities,
    [],
    props.limit
  );
  const selectValue = mappedFilters[0];
  const valueForTreeSelectionListsNames = selectValue.isNested
    ? (selectValue.value as SelectableCategories[])
    : flatFilterNames;
  // TODO: temp for Company Skills to work with old backend company list + old ID's

  const submitRef = useRef<{ [key in SelectableCategories]?: any }>({});

  const [tempSelections, setTempSelections] = useState<
    Record<string, TreeItem>
  >({});

  const initialFocusRef = useRef<any>(null);

  const handleClearSelections = (filterName: SelectableCategories) => {
    setTempSelections({});

    const selectedRef = submitRef.current[filterName];

    if (selectedRef) {
      selectedRef.value.handleClearSelections();
    }
  };

  const handleSubmit = () => {
    try {
      const selectedRef = submitRef.current[selectValue.filterName];

      if (selectedRef) {
        selectedRef.value.submit(tempSelections);

        mixpanel.track(UserFilterTrackingEvents.PRIMARY_FILTER, {
          filter_names: props.entities,
          selections: Object.values(tempSelections).map(
            (selection) => selection.item?.label
          ),
          page: getActiveSetId(),
        });
      }
    } catch (err) {
      console.error('Submission Error: ', err);
    }
  };

  const TreeRefRef = (element: any) =>
    (submitRef.current[selectValue.filterName] = {
      filterName: selectValue.filterName,
      value: element,
    });

  const onClickClear = () => {
    handleClearSelections(selectValue.filterName);
  };

  const openHandler = () => {
    setTempSelections({});
  };

  return (
    <FilterPopover
      title={props.entityName}
      resetBtn={
        <Button
          variant="ghost"
          size="sm"
          fontSize="12px"
          colorScheme="red"
          flexShrink={0}
          onClick={onClickClear}
        >
          Clear Selections
        </Button>
      }
      handleSubmit={handleSubmit}
      openHandler={openHandler}
      menuWidth={360}
      lazy={true}
      triggerElement={
        <Button
          className={ADD_ENTITY_BUTTON_CLASS_NAME}
          variant="addcompany"
          leftIcon={<PlusMinusIcon className={ADD_ENTITY_BUTTON_CLASS_NAME} />}
          display="flex"
          data-testid="primary-filter-add-button"
        >
          <span
            className={ADD_ENTITY_BUTTON_CLASS_NAME}
            style={{ marginLeft: '-2px' }}
          >
            {props.buttonText}
          </span>
        </Button>
      }
      closeOnBlur={false}
      closeOnOutsideClick={true}
    >
      <TreeRef
        placeholder={props.entityName}
        listNameOverrides={listNameOverrides}
        selectionLists={valueForTreeSelectionListsNames}
        setTempSelections={setTempSelections}
        width="100%"
        limit={props.limit}
        activeLimit={activeLimit || props.limit}
        hideParentCheckbox={isParentCheckboxHidden}
        disableParentSelect={disableParentSelect}
        ref={TreeRefRef}
        required={props.required}
        initialFocusRef={initialFocusRef}
        trialNoResultsMessage={trialNoResultsMessage}
        nestingTreeType={
          selectValue.isNested ? TreeType.SUB_MENU_NESTED : undefined
        }
        // temporary until role composition has changed to the fast pipeline
        unselectParentOnChildSelect={
          !(
            [
              SelectionCategories.COMPANY,
              SelectionCategories.REGION,
            ] as SelectableCategories[]
          ).includes(selectValue.filterName)
        }
        syncWithPrimaryEntities
      />
    </FilterPopover>
  );
}

export default AddEntityButton;
