/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import { setIsVideoOpen, setVideoId, tourStore } from '@revelio/core';
import { LocationSensorState } from 'react-use/lib/useLocation';

export const useTutorialLinkHandler = (location: LocationSensorState) => {
  useEffect(() => {
    const urlParams = new URLSearchParams(location.search);
    const videoId = urlParams.get('vtid');

    if (videoId) {
      tourStore.update(setVideoId(videoId));
      tourStore.update(setIsVideoOpen(true));
    }
  }, []);
};
