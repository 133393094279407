import {
  Box,
  Button,
  Card,
  CardBody,
  CardHeader,
  Center,
  CheckboxProps,
  Flex,
  Heading,
  HeadingProps,
  Stack,
  Text,
  Tooltip,
  useCheckbox,
  useMultiStyleConfig,
} from '@chakra-ui/react';
import { useEffect, useRef, useState } from 'react';
import { CircleCheckbox } from '../../icons/circle-checkbox';
import { capitalize, get } from 'lodash';
import {
  DeliverableIcons,
  DeliverablesIconLookup,
} from '../../icons/deliverables';
import { InfoOutlineIcon } from '@chakra-ui/icons';

export interface ICardListSelectProps<T>
  extends Omit<CheckboxProps, 'id' | 'icon' | 'iconColor' | 'iconSize'> {
  id: T;
  label?: string;
  labelHeadingOverrideProps?: HeadingProps;
  hideCheckbox?: boolean;
  combineWith?: T[]; // unused in component, it is config for adding multiple columns based on single column toggle
  icon?: DeliverableIcons | string;
  detail?: string;
  cta?: JSX.Element;
  hideCtaIfNotSelected?: boolean;
  disabledExplanation?: string;
  variant?: string;
  defaultIsSelected?: boolean;
  isMandatory?: boolean;
  internalOnly?: boolean;
  columnFileSize?: number;
  rowPossibilities?: number;
  tooltipInfo?: string;
}

export function CardListSelect<T extends string>({
  id,
  label,
  labelHeadingOverrideProps,
  detail,
  cta,
  hideCtaIfNotSelected = false,
  icon,
  variant,
  hideCheckbox = false,
  borderColor,
  disabledExplanation,
  defaultIsSelected, // destructured so it's not passed to the checkbox in DOM
  isMandatory,
  tooltipInfo,
  ...checkBoxProps
}: ICardListSelectProps<T>) {
  const styles = useMultiStyleConfig('CardListSelect', { variant });
  const { width, height } = styles['checkBox'];

  const textRef = useRef<HTMLParagraphElement>(null);
  const maxLinesCount = variant === 'report' ? 1 : 2;
  const allowShowMore = variant !== 'report';
  const [detailIsOverflowing, setDetailIsOverflowing] = useState(false);

  useEffect(() => {
    const checkOverflow = () => {
      const current = textRef.current;
      if (current && current?.scrollHeight > current?.clientHeight) {
        setDetailIsOverflowing(true);
      } else {
        setDetailIsOverflowing(false);
      }
    };

    checkOverflow();
    window.addEventListener('resize', checkOverflow);
    return () => window.removeEventListener('resize', checkOverflow);
  }, [detail, variant]);

  const [maxLines, setMaxLines] = useState(maxLinesCount);
  const handleShowMore = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    setMaxLines((v) => (v == 0 ? maxLinesCount : 0));
  };

  const fmtLabel = label || capitalize(id);

  const TheIcon = get(DeliverablesIconLookup, `${icon}`);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { onChange } = checkBoxProps;

  const checkBoxApi = useCheckbox(
    isMandatory
      ? {
          ...checkBoxProps,
          isDisabled: true,
          isRequired: true,
        }
      : checkBoxProps
  );
  const { getInputProps, getRootProps, state } = checkBoxApi;
  const { internalOnly, combineWith, columnFileSize, ...restCheckBoxProps } =
    getRootProps();
  const ctaInCardBody = cta && variant === 'dataset';
  const cardBodyBottomPadding = ctaInCardBody ? '6px' : '13px';
  const canShowCTA = !(hideCtaIfNotSelected && !state.isChecked);

  const CardSelect = (
    <Card
      sx={styles['card']}
      direction="row"
      cursor={state.isDisabled ? 'not-allowed' : 'pointer'}
      borderColor={
        /* eslint-disable-next-line */
        borderColor || state.isDisabled
          ? 'lightgray.100'
          : state.isChecked
            ? 'green.500'
            : 'gray.200'
      }
      boxShadow="none"
      _hover={{
        backgroundColor: state.isDisabled ? 'transparent' : 'green.50',
      }}
      role="group"
      width="100%"
    >
      <Flex
        direction="row"
        width="100%"
        justifyContent="space-between"
        alignItems={variant === 'dataset' ? 'flex-start' : 'center'}
        padding={icon ? 3 : 1}
        pointerEvents={state.isDisabled ? 'none' : 'auto'}
      >
        <Flex sx={styles['cardContent']} {...restCheckBoxProps}>
          <input {...getInputProps()} hidden />
          <Box sx={styles['checkFlex']}>
            {!hideCheckbox && (
              <CircleCheckbox
                id={id}
                height={height as string}
                width={width as string}
                checkBoxApi={checkBoxApi}
                opacity={state.isDisabled ? '50%' : '100%'}
              />
            )}
          </Box>
          {icon && TheIcon && (
            <Box
              bg="#E5EBF1"
              p="12px"
              borderRadius="50%"
              opacity={state.isDisabled ? '50%' : '100%'}
              _groupHover={{ bg: state.isDisabled ? '#E5EBF1' : 'white' }}
            >
              <Center>
                <TheIcon boxSize={6} />
              </Center>
            </Box>
          )}
          <Stack gap="0">
            <CardHeader sx={styles['header']}>
              <Flex alignItems="center" gap={2}>
                <Heading
                  size="sm"
                  sx={styles['heading']}
                  color={state.isDisabled ? 'gray.400' : 'text.primary'}
                  {...labelHeadingOverrideProps}
                >
                  {fmtLabel}
                  {state.isRequired && '*'}
                </Heading>
                {tooltipInfo ? (
                  <Tooltip
                    label={tooltipInfo}
                    textAlign="center"
                    hasArrow
                    placement="top"
                  >
                    <InfoOutlineIcon
                      boxSize={3}
                      color="text.primary"
                      cursor="pointer"
                    />
                  </Tooltip>
                ) : null}
              </Flex>
            </CardHeader>
            {detail && (
              <CardBody pt="0px" pb={cardBodyBottomPadding} sx={styles['body']}>
                <Text
                  noOfLines={maxLines}
                  color={state.isDisabled ? 'gray.400' : 'text.primary'}
                  opacity={0.7}
                  lineHeight={1.2}
                  ref={textRef}
                  title={detailIsOverflowing ? detail : undefined}
                >
                  {detail}
                </Text>
                <Flex justifyContent={'space-between'}>
                  <Box>
                    {allowShowMore && detailIsOverflowing && (
                      <Button
                        size="xs"
                        variant="link"
                        color={'lightBlue.600'}
                        onClick={handleShowMore}
                        isDisabled={state.isDisabled}
                      >
                        {maxLines === 0 ? 'less' : 'more'}
                      </Button>
                    )}
                  </Box>

                  {canShowCTA && ctaInCardBody && (
                    <Box
                      pt="6px"
                      pointerEvents={'all'}
                      zIndex={1}
                      position={'relative'}
                      onClick={(e) => e.stopPropagation()}
                    >
                      {cta}
                    </Box>
                  )}
                </Flex>
              </CardBody>
            )}
          </Stack>
        </Flex>

        {canShowCTA && !ctaInCardBody && cta}
      </Flex>
    </Card>
  );

  if (disabledExplanation) {
    return <Tooltip label={disabledExplanation}>{CardSelect}</Tooltip>;
  }

  return CardSelect;
}

export default CardListSelect;
