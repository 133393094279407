import {
  createContext,
  Dispatch,
  PropsWithChildren,
  useContext,
  useReducer,
  useState,
  SetStateAction,
} from 'react';
import {
  initialTalentDiscoveryFilterState,
  TalentDiscoveryFilterAction,
  talentDiscoveryFilterReducer,
  TalentDiscoveryFilterState,
} from './td-filter-reducer';
import { TalentDiscoveryAiFilterSearchResponse } from '@revelio/data-access';

const TalentDiscoveryFilterContext = createContext<{
  state: TalentDiscoveryFilterState;
  dispatch: Dispatch<TalentDiscoveryFilterAction>;
  aiSearchState: AiSearchState;
  setAiSearchState: Dispatch<SetStateAction<AiSearchState>>;
  isAiSearchLoading: boolean;
  setIsAiSearchLoading: Dispatch<SetStateAction<boolean>>;
}>({
  state: initialTalentDiscoveryFilterState,
  dispatch: () => undefined,
  aiSearchState: {
    prompt: '',
    uuid: '',
    isCorrect: null,
    response: null,
  },
  setAiSearchState: () => undefined,
  isAiSearchLoading: false,
  setIsAiSearchLoading: () => undefined,
});

type AiSearchState = {
  prompt: string;
  uuid: string;
  isCorrect: boolean | null;
  response: TalentDiscoveryAiFilterSearchResponse | null;
};
export const TalentDiscoveryFilterProvider = ({
  children,
}: PropsWithChildren) => {
  const [state, dispatch] = useReducer(
    talentDiscoveryFilterReducer,
    initialTalentDiscoveryFilterState
  );
  const [aiSearchState, setAiSearchState] = useState<AiSearchState>({
    prompt: '',
    uuid: '',
    isCorrect: null,
    response: null,
  });

  const [isAiSearchLoading, setIsAiSearchLoading] = useState(false);

  return (
    <TalentDiscoveryFilterContext.Provider
      value={{
        state,
        dispatch,
        aiSearchState,
        setAiSearchState,
        isAiSearchLoading,
        setIsAiSearchLoading,
      }}
    >
      {children}
    </TalentDiscoveryFilterContext.Provider>
  );
};

export const useTalentDiscoveryFilter = () =>
  useContext(TalentDiscoveryFilterContext);
