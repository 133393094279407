import { useEffect, useRef, useState } from 'react';
import {
  Box,
  Button,
  Flex,
  Input,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Tag,
  TagCloseButton,
  TagLabel,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { produce } from 'immer';
import { getDisplayTextWidth } from '../custom/custom-column-form-helpers';
import { FormInputMimicBox } from './FormInputMimicBox';
import { SharedConditionValueProps } from './conditions.model';

export function StringConditionValue({
  conditionId,
  selectedValues,
  setSelections,
  isDisabled,
  clearError,
  onNoValues,
  hasError,
}: SharedConditionValueProps<string>) {
  const [newVariableValue, setNewVariableValue] = useState<string>();
  const [isInputFocussed, setIsInputFocussed] = useState<boolean>(false);
  const [editingValues, setIsEditingValues] = useState<{
    [value: string]: string;
  }>({});
  const [
    isStringOptionSubmitOptionHovering,
    setIsStringOptionSubmitOptionHovering,
  ] = useState<boolean>(false);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const onSubmitHandler = (value: string) => {
    setSelections([...selectedValues, value]);
    clearError();
    setNewVariableValue('');
    onClose();
  };

  useEffect(() => {
    if (!isOpen && newVariableValue && isInputFocussed) {
      onOpen();
    }

    if (isOpen && !newVariableValue && isInputFocussed) {
      onClose();
    }
  }, [newVariableValue, isOpen, isInputFocussed, onOpen, onClose]);

  const onEnterSubmitHandler: React.KeyboardEventHandler<HTMLDivElement> = (
    e
  ) => {
    const isEnterPressed = e.key === 'Enter';

    if (isEnterPressed) {
      const isKeyHeld = e.repeat;

      if (isKeyHeld) {
        return;
      }

      const shouldTriggerSubmit = isOpen && !isDisabled && newVariableValue;

      if (shouldTriggerSubmit) {
        onSubmitHandler(newVariableValue);
      }
    }
  };
  const newValueInputReference = useRef<HTMLInputElement>(null);
  const handlePastingValues = (e: React.ClipboardEvent<HTMLInputElement>) => {
    e.preventDefault();
    const clipboardData = e.clipboardData.getData('Text');
    const clipboardDataByNewline = clipboardData.split('\n');
    const clipboardDataArray =
      clipboardDataByNewline.length > 1
        ? clipboardDataByNewline
        : clipboardData.split(',');
    const clipboardDataArrayFiltered = clipboardDataArray
      .map((s) => s.trim())
      .filter((value) => value !== '');

    const newSelections = [...selectedValues, ...clipboardDataArrayFiltered];
    if (newSelections.length > 0) {
      clearError();
    }
    setSelections(newSelections);
  };
  const showEditingStringConditionBorder =
    isInputFocussed || Object.keys(editingValues).length > 0;
  return (
    <Box
      onKeyDown={onEnterSubmitHandler}
      maxH="112px"
      overflowY="auto"
      overflowX="hidden"
      minH="40px"
      pt="1px"
    >
      <Popover
        placement="bottom-start"
        onClose={() => {
          onClose();
        }}
        isOpen={isOpen}
        autoFocus={false} // keep focus on the value input element as user types
      >
        <FormInputMimicBox
          isEditing={showEditingStringConditionBorder}
          isDisabled={isDisabled}
          hasError={hasError}
        >
          <Flex
            flexGrow={1}
            columnGap="3px"
            alignItems="center"
            height="100%"
            wrap="wrap"
            rowGap="0.5rem"
          >
            {selectedValues.map((conditionValue, index) => {
              return (
                <Tag
                  key={`${conditionId}-${conditionValue}-valueTag`}
                  variant="filterChip"
                  data-testid={`condition-${conditionId}-${conditionValue}`}
                  size="sm"
                  onClick={(e) => {
                    e.stopPropagation();
                    setIsEditingValues((editingValues) => ({
                      ...editingValues,
                      [conditionValue]: conditionValue,
                    }));
                  }}
                >
                  <TagLabel>
                    {editingValues[conditionValue] ? (
                      <Input
                        autoFocus // auto add cursor focus when editing
                        value={editingValues[conditionValue]}
                        onChange={(e) => {
                          setIsEditingValues((editingValues) => ({
                            ...editingValues,
                            [conditionValue]: e.target.value,
                          }));
                        }}
                        variant={'unstyled'}
                        onBlur={() => {
                          setSelections(
                            produce(selectedValues, (draft) => {
                              draft[index] = editingValues[conditionValue];
                            })
                          );
                          setIsEditingValues((editingValues) => {
                            return produce(editingValues, (draft) => {
                              delete draft[conditionValue];
                              return draft;
                            });
                          });
                        }}
                        width={getDisplayTextWidth(
                          editingValues[conditionValue]
                        )}
                      />
                    ) : (
                      <Flex alignItems="center">{conditionValue}</Flex>
                    )}
                  </TagLabel>
                  {!editingValues[conditionValue] && (
                    <TagCloseButton
                      onClick={(e) => {
                        e.stopPropagation();
                        const selectedValuesWithDeletion =
                          selectedValues.filter(
                            (value) => value !== conditionValue
                          );
                        setSelections(selectedValuesWithDeletion);

                        if (!selectedValuesWithDeletion.length) {
                          onNoValues();
                        }
                      }}
                    />
                  )}
                </Tag>
              );
            })}

            <PopoverTrigger>
              <Box
                // this box makes it that the input will never cause an empty newline due to its narrow width
                // but by having a flex-grow box, when the space is large, any whitespace is clickable to input a new value
                flexGrow={1}
                onClick={() => {
                  newValueInputReference.current?.focus();
                }}
              >
                <Input
                  ref={newValueInputReference}
                  value={newVariableValue}
                  onChange={(e) => {
                    setNewVariableValue(e.target.value);
                  }}
                  variant={'unstyled'}
                  isDisabled={isDisabled}
                  onFocus={() => {
                    setIsInputFocussed(true);
                  }}
                  onBlur={() => {
                    setIsInputFocussed(false);
                    if (isOpen && !isStringOptionSubmitOptionHovering) {
                      onClose();
                    }
                  }}
                  onPaste={handlePastingValues}
                  width={getDisplayTextWidth(newVariableValue)}
                />
              </Box>
            </PopoverTrigger>
          </Flex>
        </FormInputMimicBox>
        <PopoverContent
          pointerEvents={isOpen ? 'auto' : 'none'}
          // minWidth={`${menuWidth}px`}
          boxShadow="2xl"
          // workaround to fix focus shadow bug
          // ensures popover has box shadow when open
          sx={{
            ':focus:not(:focus-visible)': {
              shadow: '0 25px 50px -12px rgba(0, 0, 0, 0.25) !important',
            },
          }}
        >
          <PopoverHeader>Set value</PopoverHeader>
          <PopoverBody
            padding={0}
            onMouseOver={() => {
              setIsStringOptionSubmitOptionHovering(true);
            }}
            onMouseLeave={() => {
              setIsStringOptionSubmitOptionHovering(false);
            }}
          >
            <Button
              w="100%"
              variant="ghost"
              colorScheme="green"
              textColor="text.primary"
              justifyContent="flex-start"
              onClick={() => onSubmitHandler(newVariableValue as string)}
              data-testid="includes_text_button"
            >
              includes: <Text color="green">"%{newVariableValue}%"</Text>
            </Button>

            <Button
              w="100%"
              variant="ghost"
              colorScheme="green"
              textColor="text.primary"
              justifyContent="flex-start"
              onClick={() => onSubmitHandler(`${newVariableValue}%`)}
              data-testid="starts-with_text_button"
            >
              starts with: <Text color="green">"{newVariableValue}%"</Text>
            </Button>

            <Button
              w="100%"
              variant="ghost"
              colorScheme="green"
              textColor="text.primary"
              justifyContent="flex-start"
              onClick={() => onSubmitHandler(`%${newVariableValue}`)}
              data-testid="ends-with_text_button"
            >
              ends with: <Text color="green">"%{newVariableValue}"</Text>
            </Button>
          </PopoverBody>
        </PopoverContent>
      </Popover>
    </Box>
  );
}

export default StringConditionValue;
