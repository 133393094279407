export enum FormatType {
  PERCENTAGE = '%',
  CURRENCY = '$',
  INTEGER = ',',
  DECIMAL = '.',
  SI = 's',
}

export type Formatter = (value: number) => string;
export type Format = FormatType | Formatter;
