import { useQuery } from '@tanstack/react-query';
import { getSourceWhaleCampaigns } from '../source-whale-api';

export const CannotFetchCampaignsError =
  'An error occurred while fetching campaigns.';

interface UseSourceWhaleCampaignsProps {
  apiKey: string;
  onError: (error: string) => void;
  enabled: boolean;
}

export const useSourceWhaleCampaigns = ({
  apiKey,
  onError,
  enabled,
}: UseSourceWhaleCampaignsProps) => {
  return useQuery({
    queryKey: ['source-whale-campaigns', apiKey],
    queryFn: async () => {
      try {
        return await getSourceWhaleCampaigns({
          apiKey: apiKey,
        });
      } catch (error) {
        console.error(error);
        onError(CannotFetchCampaignsError);
        return;
      }
    },
    enabled: enabled,
    retry: 2,
    refetchOnMount: true,
    staleTime: 0,
  });
};
