import { createMultiStyleConfigHelpers } from '@chakra-ui/styled-system';

const helpers = createMultiStyleConfigHelpers([
  'card',
  'cardContent',
  'checkFlex',
  'checkBox',
  'header',
  'heading',
  'body',
]);

export const CardListSelect = helpers.defineMultiStyleConfig({
  baseStyle: {
    card: {
      borderWidth: '1px',
    },
    cardContent: {
      alignItems: 'center',
      flexGrow: 1,
    },
    checkFlex: {
      p: '13px 0 0 15px',
      lineHeight: '0',
    },
    checkBox: {
      height: '22px',
      width: '22px',
    },
    header: {
      p: '13px 18px 4px 18px',
    },
    heading: {
      fontWeight: 400,
    },
    body: {
      fontSize: '12px',
    },
  },
  sizes: {
    sm: {
      card: {},
    },
    md: {
      header: {},
    },
  },
  variants: {
    dataset: {
      cardContent: {
        alignItems: 'flex-start',
        height: '100%',
      },
      card: {
        fontSize: '14px',
      },
      checkBox: {
        height: '20px',
        width: '20px',
      },
      header: {
        p: '13px 14px 4px',
      },
      body: {
        p: '0px 14px 13px',
      },
    },
    columns: {
      checkFlex: {
        p: '0 0 0 12px',
      },
      checkBox: {
        height: '15px',
        width: '15px',
      },
      header: {
        p: '8px 10px',
      },
      heading: {
        fontSize: '13px',
      },
      card: {},
    },
    delivery: {
      checkBox: {
        height: '20px',
        width: '20px',
      },
      checkFlex: {
        p: '0px 20px 0px 20px',
      },
      heading: {
        fontWeight: 600,
      },
    },
    report: {
      header: {
        pt: '8px',
        pb: '1px',
        pl: '2px',
        pr: '8px',
      },
      body: {
        pt: '0',
        pb: '8px',
        pl: '2px',
        pr: '8px',
      },
      checkFlex: {
        p: '0px 10px 0px 10px',
      },
    },
  },
  defaultProps: {
    size: 'sm',
    variant: 'dataset',
  },
});
