import classNames from 'classnames';
import { bisect, format as d3Format, extent, line, max, scaleLinear } from 'd3';
import { isNumber } from 'lodash';
import { useRef, useState } from 'react';

import { useResizeObserver } from '@revelio/core';

import { CommonPlotProps, FormatType } from '../../types';
import {
  PRIMARY_COLOR,
  getFormatter,
  plotColors,
  useRenderCheck,
} from '../../utilities';
import { PlotLoader } from '../plot-loader/plot-loader';
import {
  PlotTooltip,
  TooltipHoverLine,
  useTooltipController,
} from '../plot-tooltip';
import styles from './kde-chart.module.css';
import { KdeTooltip, KdeTooltipProps } from './kde-tooltip';

const X_AXIS_HEIGHT = 12;
const TOP_PADDING = 1;

type KdeValue = {
  // This is the value of the data we are measuring
  // ex. $100,000 salary
  value: number;
  // This is the density of the data point at this value
  // ex. 0.001 or 1% of salaries attributable to this value
  kde: number;
  // This is the cumulative distribution of the value up until this point
  // ex. 0.50 or 50% of salaries are less than $100,000
  cdf: number;
};

export type KdeData = {
  label: string;
  values: KdeValue[];
};

type KdeChartProps = {
  data: KdeData[];
  colors?: string[];
} & CommonPlotProps;

/** Kernel Density Estimate */
export const KdeChart = ({
  data,
  colors = plotColors,
  format = FormatType.SI,
  loading = false,
  renderUpdate,
}: KdeChartProps) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const { width, height } = useResizeObserver(containerRef);

  const plotWidth = width;
  const plotHeight = height - X_AXIS_HEIGHT - TOP_PADDING;
  const isPlotSizeValid = plotWidth > 0 && plotHeight > 0;

  const allValues = data.flatMap((d) => d.values.map((v) => v.value));

  const valueRange = extent(allValues);

  const xScale =
    isNumber(valueRange[0]) && isNumber(valueRange[1])
      ? scaleLinear([valueRange[0], valueRange[1]], [0, plotWidth])
      : null;

  const maxKde = max(data.flatMap((d) => d.values.map((v) => v.kde)));
  const yScale = maxKde
    ? scaleLinear([0, maxKde], [plotHeight, TOP_PADDING])
    : null;

  const areaGenerator =
    xScale && yScale
      ? line<KdeValue>()
          .x((d) => xScale(d.value))
          .y((d) => yScale(d.kde))
      : null;

  const formatValue = getFormatter(format);
  const tooltipFormatValue = getFormatter(format, { fullPrecision: true });
  const areas = data
    .map((d) => {
      if (!areaGenerator) return null;

      const path = areaGenerator(d.values);
      if (!path) return null;

      return { ...d, path };
    })
    .filter((d) => d !== null)
    .map((d, i) => ({ ...d, color: colors[i] || PRIMARY_COLOR }));

  const xTicks = xScale?.ticks(5).map((tick) => ({
    value: tick,
    label: formatValue(tick),
  }));

  const [tooltipValue, setTooltipValue] = useState<KdeTooltipProps | null>(
    null
  );
  const [tooltipPosition, setTooltipPosition] = useState({ x: 0, y: 0 });
  const handleTooltipMove = (position: [number, number]) => {
    if (!xScale) return;

    const value = xScale.invert(position[0]);
    const index = bisect(allValues, value);
    const bisectedValue = allValues[index];
    const formattedValue = tooltipFormatValue(bisectedValue);

    const values = data.map((d, i) => {
      const cdf = d.values[index].cdf;
      const formatedCdf = d3Format('.1%')(cdf);

      return {
        label: d.label,
        color: colors[i] || PRIMARY_COLOR,
        description: `${formatedCdf} of people make up to ${formattedValue}`,
      };
    });

    setTooltipValue({ title: formattedValue, values: values });
    setTooltipPosition({ x: position[0], y: plotHeight / 2 });
  };

  const tooltipController = useTooltipController({
    onHover: handleTooltipMove,
  });

  const chartRef = useRef<SVGGElement>(null);
  useRenderCheck(chartRef, { renderUpdate });

  return (
    <div ref={containerRef} className={styles.container}>
      <svg width="100%" height="100%">
        <g id="x-axis">
          {isPlotSizeValid &&
            xScale &&
            xTicks &&
            xTicks.map((tick, i) => (
              <text
                key={tick.value}
                x={xScale(tick.value)}
                y={plotHeight + 12}
                className={classNames(styles.xAxisLabel, {
                  [styles.firstTick]: i === 0,
                  [styles.lastTick]: i === xTicks.length - 1,
                })}
              >
                {tick.label}
              </text>
            ))}
        </g>
        <g
          id="chart"
          ref={chartRef}
          {...tooltipController.gProps}
          className={styles.chart}
        >
          {areas.map((area) => {
            if (!area) return null;

            return (
              <path
                key={area.label}
                className={styles.area}
                d={area.path}
                stroke={area.color}
                fill={area.color}
              />
            );
          })}
          {tooltipController.isVisible && (
            <TooltipHoverLine x={tooltipPosition.x} height={plotHeight} />
          )}
          {isPlotSizeValid && (
            <rect
              x={0}
              y={0}
              width={plotWidth}
              height={plotHeight}
              fill="transparent"
            />
          )}
        </g>
      </svg>
      <PlotTooltip
        isVisible={tooltipController.isVisible}
        x={tooltipPosition.x}
        y={tooltipPosition.y}
      >
        {tooltipValue && (
          <KdeTooltip title={tooltipValue.title} values={tooltipValue.values} />
        )}
      </PlotTooltip>
      {loading && <PlotLoader />}
    </div>
  );
};
