/* eslint-disable @typescript-eslint/no-duplicate-enum-values */
import { filterNil } from '@ngneat/elf';
import { BehaviorSubject } from 'rxjs';
import { Client } from 'urql';

export const ADMIN = 'admin';
export const LOGIN = 'login';

export const MAX_FILTER_STORE_CACHE_AGE = 1000 * 60 * 60 * 24 * 1; // 1 day

export const DNDChipsMode = 'DNDChipsMode';
export const ClusterEnv = 'ClusterEnv';
export const TourCustomPageValue = 'TourCustomPageValue';

export enum InterestedOptions {
  Workforce_Planning = 'Workforce planning',
  Workforce_Intelligence = 'Workforce intelligence',
  Compensation_Strategy = 'Compensation strategy',
  Talent_Acquisition = 'Talent Acquisition',
  Competitive_Benchmarking = 'Competitive benchmarking',
  Data_Enrichment_and_Custom_Reports = 'Data enrichment & custom reports',
  Data_Feed = 'Data feed',
  Academic_Research = 'Academic Research',
  Investment_Management = 'Investment Management',
  Workforce_Planning_and_Analytics = 'Workforce Planning & Analytics',
  Talent_Intelligence = 'Talent Intelligence',
}

export enum PageTitles {
  COMPANY = 'Company',
  COMPANIES = 'Companies',
  SUMMARY = 'Overview',
  SENTIMENT = 'Sentiment',
  GEOGRAPHY = 'Geography',
  SKILLS = 'Skills',
  SKILL = 'Skill',
  ROLE = 'Role',
  COMPOSITIONS = 'Compositions',
  TRANSITIONS = 'Transitions',
  JOB_POSTINGS = 'Job Postings',
  SKILL_POSTINGS = 'Job Postings',
  SENTIMENT_RATINGS = 'Ratings',
  SENTIMENT_EFFECTS = 'Effects',
  SENTIMENT_REVIEWS = 'Reviews',
  SENTIMENT_REVIEWS_EFFECTS = 'Effects & Reviews',
  LAYOFF = 'Layoffs',
  SCREENER = 'Discovery',
  ACTIVITY_ANALYSIS = 'Activity Analysis',
  TALENT_DISCOVERY = 'Discovery',
  TALENT_DISCOVERY_V2 = 'Talent Discovery V2',
  RESUME_ENRICHMENT = 'Resume Enrichment',
  TAXONOMY = 'Taxonomy',
  DOCUMENTATION = 'Documentation',
  INDIVIDUAL = 'Individual',
  FEEDBACK = 'Submit Bug / Feature Request',
  TRIAL_EXPIRED = 'Trial Expired',
  DELIVERABLES = 'Data',
  DATA_BUILDER = 'Data Builder',
  DELIVERABLES_STATUS = 'Delivery Status',
  PAY = 'Pay',
  REPORTS = 'Your Reports',
  REPORTS_SAMPLE = 'Sample Report',
  DATA_ENRICHMENT = 'Data Enrichment',
  REPORT_BUILDER = 'Report Builder',
}

export enum Views {
  SUMMARY = 'summary',
  OVERVIEW = 'overview',
  TRANSITION = 'transition',
  POSTING = 'posting',
  COMPENSATION = 'compensation',
  ENTITY_SUMMARY = 'entity_summary',
  POSTING_COMPANY = 'posting_company',
  POSTING_KEYWORD = 'posting_keyword',
  SENTIMENT = 'sentiment',
  SENTIMENT_RATING = 'sentiment_rating',
  SENTIMENT_EFFECT = 'sentiment_score',
  SENTIMENT_REVIEW = 'sentiment_review',
  JOB_ANALYSIS = 'job_analysis',
  JOB_ANALYSIS_OVERVIEW = 'job_analysis_overview',
  ACTIVITY_ANALYSIS = 'activity_analysis',
  TAXONOMY_VISUALIZER = 'taxonomy_visualizer',
  TALENT_DISCOVERY = 'talent_discovery',
  SCREENER = 'screener',
  LAYOFF = 'layoff',
  SKILL = 'skill',
  NONE = '',
}

export enum PrimaryView {
  COMPANY = 'company',
  GEOGRAPHY = 'geography',
  ROLE = 'role',
}

export enum DataView {
  OVERVIEW = 'overview',
  SENTIMENT_RATING = 'sentiment_rating',
  SENTIMENT_REVIEW_POSITIVE = 'sentiment_review_positive',
  SENTIMENT_REVIEW_NEGATIVE = 'sentiment_review_negative',
  TALENT_DISCOVERY = 'talent_discovery',
  POSTING = 'posting',
  POSTING_TOP_ROLES = 'posting_top_roles',
  TRANSITION = 'transition',
}

export enum TimeFrameView {
  SNAPSHOT = 'snapshot',
  OVERTIME = 'overtime',
}

export enum PrimaryFilters {
  COMPANY = 'company',
  GEOGRAPHY = 'geography',
  ROLE = 'role',
  METRO_AREA = 'metro_area',
  JOB_CATEGORY = 'job_category',
  REGION = 'region',
  KEYWORD = 'keyword',
}

export const AddEntityButtonText: { [key in PrimaryFilters]: string } = {
  [PrimaryFilters.COMPANY]: 'Company & Industry',
  [PrimaryFilters.GEOGRAPHY]: 'Geography',
  [PrimaryFilters.ROLE]: 'Role',
  [PrimaryFilters.METRO_AREA]: 'Geography',
  [PrimaryFilters.JOB_CATEGORY]: 'Job Category',
  [PrimaryFilters.REGION]: 'Region',
  [PrimaryFilters.KEYWORD]: 'Keyword',
};

export enum PagePaths {
  LANDING = '/',
  COMPANY_SUMMARY = 'company/summary',
  COMPANY_COMPOSITIONS = 'company/compositions',
  GEOGRAPHY_COMPOSITIONS = 'geography/compositions',
  ROLE_COMPOSITIONS = 'role/compositions',
  COMPANY_SENTIMENT_RATINGS = 'company/sentiment-ratings',
  COMPANY_SENTIMENT_EFFECTS = 'company/sentiment-effects',
  COMPANY_SENTIMENT_REVIEWS = 'company/sentiment-reviews',
  COMPANY_SENTIMENT = 'company/sentiment',
  COMPANY_PAY = 'company/pay',
  GEOGRAPHY_SUMMARY = 'geography/summary',
  GEOGRAPHY_SENTIMENT_RATINGS = 'geography/sentiment-ratings',
  GEOGRAPHY_SENTIMENT_EFFECTS = 'geography/sentiment-effects',
  GEOGRAPHY_SENTIMENT_REVIEWS = 'geography/sentiment-reviews',
  GEOGRAPHY_SENTIMENT = 'geography/sentiment',
  ROLE_SUMMARY = 'role/summary',
  ROLE_SENTIMENT_RATINGS = 'role/sentiment-ratings',
  ROLE_SENTIMENT_EFFECTS = 'role/sentiment-effects',
  ROLE_SENTIMENT_REVIEWS = 'role/sentiment-reviews',
  ROLE_SENTIMENT = 'role/sentiment',
  ROLE_PAY = 'role/pay',
  COMPANY_POSTINGS = 'company/postings',
  GEOGRAPHY_POSTINGS = 'geography/postings',
  GEOGRAPHY_PAY = 'geography/pay',
  ROLE_POSTINGS = 'role/postings',
  SKILL_POSTINGS = 'skill/postings',
  COMPANY_TRANSITIONS = 'company/transitions',
  GEOGRAPHY_TRANSITIONS = 'geography/transitions',
  ROLE_TRANSITIONS = 'role/transitions',
  COMPANY_SKILLS = 'company/skills',
  GEOGRAPHY_SKILLS = 'geography/skills',
  ROLE_SKILLS = 'role/skills',
  DELIVERABLES = 'deliverables',
  REPORTS = 'reports',
  REPORTS_SAMPLE = 'sample-report',
  DATA_ENRICHMENT = 'data-enrichment',
  REPORT_BUILDER = 'report-builder',
  TALENT_DISCOVERY = 'talent-discovery',
}

export enum TourClasses {
  TOUR_CONTROL_PANEL_CLASS = 'rl_tour_control_panel',
  TOUR_DATA_SOURCES_CLASS = 'rl_tour_data_sources',
  TOUR_TRACKING_CLASS = 'rl_tour_tracking',
  TOUR_FILTER_MENU_CLASS = 'rl_tour_filter_menu_class',
  TOUR_VIEW_CLASS = 'rl_tour_view',
  TOUR_DELIVERABLES_SUB_NAV_CLASS = PageTitles.DELIVERABLES,
}

//PageFeature naming convention
export enum FeatureFlag {
  CompositionRequestLogging = 'composition-request-logging',
  AdminReportTypes = 'admin-report-types',
  CompanyScreenerAdditionalFilters = 'company-screener-additional-filters',
  DataBuilderDateFilterPostings = 'databuilder-date-filter-postings',
  DataBuilderDateFilterGeneral = 'databuilder-date-filter-general-release',
  DataBuilderDateFilterSentiment = 'databuilder-date-filter-sentiment',
  ReportBuilderTighterSelectionCards = 'reports-tighter-selection-cards',
  AddTaxonomyCatalogLink = 'add-taxonomy-catalog-link',

  // Keyword/Title filter flags
  CompanyPostingsKeyword = 'company-postings-keyword',
  RolePostingsKeyword = 'role-postings-keyword',
  GeoPostingsKeyword = 'geo-postings-keyword',

  SideBarHoverDelay = 'sidebar-hover-delay',
  TalentSourceWhaleIntegration = 'talent-sourcewhale-integration',
  TdFiltersAiSearch = 'td-filters-ai-search',
}

const _RevelioGqlClient = new BehaviorSubject<Client | null>(null);
export const setRevelioGqlClient = (client: Client) => {
  _RevelioGqlClient.next(client);
};
export const RevelioGqlClient = _RevelioGqlClient.pipe(filterNil());

export const FLAG_ENABLE_RICS = true;
