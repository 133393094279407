import { format as d3Format, min, precisionFixed } from 'd3';

import { Format, FormatType } from '../types';

const convertGigaToBillion = (
  formatter: (value: number) => string
): ((value: number) => string) => {
  return (value) => formatter(value).replace('G', 'B');
};

type PrecisionConfigProps = {
  values?: number[];
  maxPrecision?: number;
  increasePrecision?: number;
  fullPrecision?: boolean;
};

export const getFormatter = (
  format: Format,
  {
    values,
    maxPrecision = 2,
    increasePrecision = 0,
    fullPrecision = false,
  }: PrecisionConfigProps = {}
): ((value: number) => string) => {
  if (typeof format === 'function') return format;

  const minStep = values
    ? min(values.slice(1).map((val, i) => Math.abs(val - values[i])))
    : undefined;

  switch (format) {
    case FormatType.PERCENTAGE: {
      const p =
        Math.min(
          maxPrecision,
          minStep ? Math.max(0, precisionFixed(minStep) - 2) : 1
        ) + increasePrecision;
      return d3Format(`.${p}%`);
    }
    case FormatType.CURRENCY:
      return convertGigaToBillion(d3Format(fullPrecision ? '$,.0f' : '$.2s'));
    case FormatType.INTEGER:
      return d3Format(',.0f');
    case FormatType.DECIMAL:
      return d3Format(',.2f');
    case FormatType.SI:
      return convertGigaToBillion(d3Format(fullPrecision ? ',.0f' : '.2s'));
  }
};
