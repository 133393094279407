import {
  SelectionCategories,
  useViewFilters,
  FilterContainer,
  FilterChips,
  FilterMenu,
  ViewTypes,
  useViewFilterDefaults,
  useStoredFilterSet,
  PrimaryFilterLimits,
  FilterMenuLimits,
  useSelectionLists,
  upsertFiltersWithProvidedValue,
  useDefaultLastMonth,
  DefaultDates,
  FilterSets,
  FilterMenuItemOrConfig,
  createSelectableFiltersMap,
  SHARED_SET_ENTITY_LIMIT,
  LocalSelectionCategories,
  Tab,
  FilterChipsContainer,
  FiltersUsedInTabs,
  useAdaptiveRoleTaxonomy,
  FilterSetSaveMenu,
  useTabMeta,
  PrimaryEntityPopoutTreeFilter,
  useSyncFiltersToSearchParamsPure,
  getNestedEntity,
} from '@revelio/filtering';
import { Grid, GridItem, Flex } from '@chakra-ui/react';
import {
  AddEntityButtonText,
  PageTitles,
  PrimaryFilters,
  PrimaryView,
  Views,
  useResponsivePageGridDefs,
} from '@revelio/core';
import { useEffect, useMemo } from 'react';
import DashboardPage from '../DashboardPage';
import { flatten } from 'lodash';
import { SentimentEffects } from './sentiment-effects';
import { useGetSentimentReviewsData } from './use-get-sentiment-reviews-data';
import { SentimentReviewsSummary } from './sentiment-reviews/sentiment-reviews-summary';
import { useSentimentReviewsWs } from './sentiment-reviews/use-sentiment-reviews-ws';
import { View } from '@revelio/data-access';

export interface SentimentEffectsProps {
  title: PageTitles[];
  viewGroup: Tab;
  primaryViewFilters: FilterMenuItemOrConfig[];
  selectableFilters: FilterMenuItemOrConfig[];
}

const SENTIMENT_VIEW_GROUP_CONFIG = {
  [ViewTypes.COMPANY]: {
    sharedFilterSetId: FilterSets.COMPANY,
    filterSet: FilterSets.COMPANY_SENTIMENT,
    savedSetView: View.Company,
    primaryFilter: PrimaryFilters.COMPANY,
  },
  [ViewTypes.ROLE]: {
    sharedFilterSetId: FilterSets.ROLE,
    filterSet: FilterSets.ROLE_SENTIMENT,
    savedSetView: View.Role,
    primaryFilter: PrimaryFilters.ROLE,
  },
  [ViewTypes.GEO]: {
    sharedFilterSetId: FilterSets.GEOGRAPHY,
    filterSet: FilterSets.GEOGRAPHY_SENTIMENT,
    savedSetView: View.Geography,
    primaryFilter: PrimaryFilters.GEOGRAPHY,
  },
};

export function Sentiment({
  title,
  viewGroup,
  primaryViewFilters,
  selectableFilters,
}: SentimentEffectsProps) {
  const view = Views.SENTIMENT;
  const primaryFilter = SENTIMENT_VIEW_GROUP_CONFIG[viewGroup].primaryFilter;
  const primaryEntity = getNestedEntity(viewGroup);

  const { templateRows, templateColumns, tallGridItemMinHeight } =
    useResponsivePageGridDefs(Views.SENTIMENT);

  const selectableFiltersMap = createSelectableFiltersMap(selectableFilters);

  const primaryFilters = flatten(
    createSelectableFiltersMap(primaryViewFilters)
  ) as SelectionCategories[];
  const flatSelectableFilters = flatten(selectableFiltersMap);

  const storedFilterSetArgs = {
    sharedSetId: SENTIMENT_VIEW_GROUP_CONFIG[viewGroup].sharedFilterSetId,
    tab: viewGroup,
    primaryEntitiesSync: true,
    limit: PrimaryFilterLimits.SENTIMENT,
    filterNames: primaryFilters,
    uniqueSetId: SENTIMENT_VIEW_GROUP_CONFIG[viewGroup].filterSet,
    defaultLimit: PrimaryFilterLimits.POSTINGS_DEFAULT,
  };

  useStoredFilterSet(storedFilterSetArgs);

  useSelectionLists([
    ...primaryFilters,
    ...flatSelectableFilters,
    ...FiltersUsedInTabs,
  ]);
  useViewFilters([...primaryFilters, ...flatSelectableFilters]);

  const { isActive: isCustomRoleTaxonomyActive } = useAdaptiveRoleTaxonomy({
    viewType: viewGroup,
    primaryFilters,
  });

  useTabMeta({
    savedSetView: SENTIMENT_VIEW_GROUP_CONFIG[viewGroup].savedSetView,
    view,
    viewType: viewGroup,
    limit: PrimaryFilterLimits.SENTIMENT,
    supportPrimaryEntities: true,
    includeDisabledFilters: true,
    primaryFilters,
  });

  const viewFilterDefaultArgs = {
    view,
    viewType: viewGroup,
    presetView: SENTIMENT_VIEW_GROUP_CONFIG[viewGroup].sharedFilterSetId,
    onlyConsiderTheseFiltersToTriggerDefaults: [
      LocalSelectionCategories.PRIMARY_ENTITIES,
    ],
    viewFilters: [LocalSelectionCategories.PRIMARY_ENTITIES],
    limit: PrimaryFilterLimits.SENTIMENT,
    dateKey: SelectionCategories.DATE_RANGE,
    primaryFilters,
    supportPrimaryEntities: true,
  };
  useViewFilterDefaults(viewFilterDefaultArgs);

  useDefaultLastMonth({
    view,
    viewType: viewGroup,
    dateType: DefaultDates.DEFAULT_LAST_MONTH,
    dateKey: SelectionCategories.DATE_RANGE,
  });

  const primaryView = (() => {
    if (primaryFilter === PrimaryFilters.COMPANY) {
      return PrimaryView.COMPANY;
    }

    if (primaryFilter === PrimaryFilters.ROLE) {
      return PrimaryView.ROLE;
    }

    return PrimaryView.GEOGRAPHY;
  })();

  const { effectsData, positiveReviewsData, negativeReviewsData, loading } =
    useGetSentimentReviewsData({
      view: primaryView,
      primaryFilters,
    });

  const allFilters = useMemo(
    () => [...primaryFilters, ...flatSelectableFilters],
    [primaryFilters, flatSelectableFilters]
  );

  useSentimentReviewsWs({
    allFilters,
    primaryFilter,
    isCustomRoleTaxonomyActive,
  });

  useEffect(() => {
    if (primaryFilter) {
      upsertFiltersWithProvidedValue(
        {
          [SelectionCategories.PRIMARY_FILTER]: primaryFilter,
        },
        true
      );
    }
  }, [primaryFilter]);

  useSyncFiltersToSearchParamsPure({
    primaryFilters,
    syncToPrimaryEntities: true,
    isLoading: loading,
  });

  return (
    <DashboardPage
      title={title}
      hideSelectionsMargins
      loading={loading}
      selections={
        <Flex
          justifyContent="flex-start"
          alignItems="center"
          flexDirection="row"
          wrap="wrap"
          rowGap="0.5rem"
        >
          <FilterChipsContainer
            filterNames={primaryFilters}
            variant="companyChip"
            isPrimaryChip={true}
            min={1}
            limit={PrimaryFilterLimits.SENTIMENT}
            addButton={
              <PrimaryEntityPopoutTreeFilter
                {...primaryEntity}
                maxSelections={SHARED_SET_ENTITY_LIMIT}
                minSelections={1}
                activeLimit={1}
              >
                {AddEntityButtonText[primaryFilter]}
              </PrimaryEntityPopoutTreeFilter>
            }
          />
        </Flex>
      }
    >
      <FilterContainer
        flexDirection="row"
        alignItems="flex-start"
        justifyContent="space-between"
      >
        <Flex
          justifyContent="flex-start"
          alignItems="flex-start"
          flexDirection="row"
          wrap="wrap"
          rowGap="0.5rem"
        >
          <FilterChips
            filterNames={selectableFilters}
            variant="filterChip"
            limit={FilterMenuLimits.SENTIMENT}
            viewType={viewGroup}
            showGranularity
            addButton={
              <>
                <FilterMenu
                  title="Filter"
                  filters={selectableFilters}
                  selectMenuOpenDefault
                  limit={FilterMenuLimits.SENTIMENT}
                  view={view}
                />
                <FilterSetSaveMenu
                  view={SENTIMENT_VIEW_GROUP_CONFIG[viewGroup].savedSetView}
                />
              </>
            }
          />
        </Flex>
      </FilterContainer>

      <Grid
        height="100%"
        templateRows={templateRows}
        templateColumns={templateColumns}
        gap={4}
        data-testid="plots-grid"
      >
        <GridItem rowSpan={2} colSpan={2} minH={tallGridItemMinHeight}>
          <SentimentEffects data={effectsData} />
        </GridItem>

        <GridItem key={'pos-summary'} rowSpan={1} colSpan={1}>
          <SentimentReviewsSummary
            allFilters={allFilters}
            reviewType="positive"
            reviewsData={positiveReviewsData}
          />
        </GridItem>

        <GridItem key={'neg-summary'} rowSpan={1} colSpan={1}>
          <SentimentReviewsSummary
            allFilters={allFilters}
            reviewType="negative"
            reviewsData={negativeReviewsData}
          />
        </GridItem>
      </Grid>
    </DashboardPage>
  );
}
