import { RefObject, useEffect } from 'react';

export type RenderCheck = {
  renderUpdate?: () => void;
};

export const useRenderCheck = (
  ref: RefObject<Element>,
  { renderUpdate }: RenderCheck
) => {
  useEffect(() => {
    const targetNode = ref.current;
    const observer = new MutationObserver(() => {
      renderUpdate?.();
    });

    if (targetNode) {
      observer.observe(targetNode, { childList: true });
    }

    return () => {
      observer.disconnect();
    };
  }, [renderUpdate, ref]);
};
