import { useResizeObserver } from '@revelio/core';
import classNames from 'classnames';
import { hierarchy, select, tree, zoom, zoomIdentity } from 'd3';
import {
  MouseEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { FiMinus, FiPlus } from 'react-icons/fi';
import styles from './org-chart.module.css';
import { getBoundingBox, getVisibleNodes, scaleToFit } from './org-chart.utils';
import { NodeConstants, ScaleConstants } from './org-chart.constants';
import { CollapsibleNode, OrgNode } from './org-chart.types';

export interface OrgChartProps {
  data: OrgNode;
  enablePanZoom?: boolean;
  startCollapsedLevel?: number;
}

export const OrgChart = ({
  data,
  enablePanZoom = false,
  startCollapsedLevel,
  ...rest
}: OrgChartProps) => {
  const { width, height, containerRef } = useResizeObserver();
  const svgRef = useRef<SVGSVGElement>(null);
  const [collapsedNodes, setCollapsedNodes] = useState(() => {
    const nodesToCollapse = new Set<string>();

    // Helper function to process nodes recursively
    const processLevel = (node: OrgNode, level: number) => {
      if (level === startCollapsedLevel && node.children?.length) {
        nodesToCollapse.add(node.name);
      }
      node.children?.forEach((child) => processLevel(child, level + 1));
    };

    processLevel(data, 1);
    return nodesToCollapse;
  });

  const [hasInitializedSize, setHasInitializedSize] = useState(false);

  useEffect(() => {
    if (width !== undefined && height !== undefined) {
      setHasInitializedSize(true);
    }
  }, [width, height]);

  // Create hierarchy and tree layout
  const root = useMemo(() => {
    const h = hierarchy(data) as CollapsibleNode;
    const treeLayout = tree<OrgNode>()
      .nodeSize([80, 120])
      .separation((a, b) => (a.parent === b.parent ? 1.75 : 2));

    // Process nodes to handle collapsed state
    const processNode = (node: CollapsibleNode) => {
      if (collapsedNodes.has(node.data.name)) {
        node._children = node.children?.map((c) => c.data);
        node.children = undefined;
      }
    };

    h.each(processNode);
    return treeLayout(h);
  }, [data, collapsedNodes]);

  const handleToggle = useCallback(
    (nodeId: string) => {
      setCollapsedNodes((prev) => {
        const next = new Set(prev);
        if (next.has(nodeId)) {
          // When expanding a node
          next.delete(nodeId);
          const node = root
            .descendants()
            .find((n) => n.data.name === nodeId) as CollapsibleNode;

          // Ensure all children of the expanded node are collapsed
          if (node?.children || node?._children) {
            const childrenToCollapse = node.children || node._children;
            childrenToCollapse?.forEach((child) => {
              next.add(child.name);
            });
          }
        } else {
          // When collapsing a node
          next.add(nodeId);
        }
        return next;
      });
    },
    [root]
  );

  // Generate path between nodes
  const diagonal = useCallback(
    (source: CollapsibleNode, target: CollapsibleNode) => {
      const sourceX = source.x;
      const sourceY = source.y;
      const targetX = target.x;
      const targetY = target.y;
      const midY = (sourceY + targetY) / 2;

      return `M${sourceX},${sourceY}
              V${midY}
              H${targetX}
              V${targetY}`;
    },
    []
  );

  // Generate links
  const links = useMemo(() => {
    return root.links().map((link) => ({
      id: `link-${link.source.data.name}-${link.target.data.name}`,
      path: diagonal(
        link.source as CollapsibleNode,
        link.target as CollapsibleNode
      ),
    }));
  }, [root, diagonal]);

  // Generate nodes
  const nodes = useMemo(() => {
    return root.descendants().map((node: CollapsibleNode) => ({
      id: node.data.name,
      x: node.x,
      y: node.y,
      data: node.data,
      hasChildren: Boolean(node.children || node._children),
      isCollapsed: collapsedNodes.has(node.data.name),
      childCount: (node.children || node._children || []).length,
    }));
  }, [root, collapsedNodes]);

  const [hasInitializedScale, setHasInitializedScale] = useState(false);

  useEffect(() => {
    if (!svgRef.current || !enablePanZoom) return;
    if (!height || !width) return;
    if (hasInitializedScale) return;

    const visibleNodes = getVisibleNodes(root, startCollapsedLevel);
    const bounds = getBoundingBox(visibleNodes);
    const initialScale = scaleToFit(bounds, width, height);
    const centerX = (bounds.minX + bounds.maxX) / 2;

    const svg = select(svgRef.current);
    const mainGroup = svg.select('g.main-group');
    const zoomBehavior = zoom<SVGSVGElement, unknown>()
      .scaleExtent([ScaleConstants.min, ScaleConstants.max])
      .on('zoom', (event) => {
        mainGroup.attr('transform', event.transform);
      });

    svg.call(zoomBehavior as unknown as (selection: typeof svg) => void);

    // Center the visible expanded nodes of the chart
    const initialTransform = zoomIdentity
      .translate(width / 2 - centerX * initialScale, height / 10)
      .scale(initialScale);

    svg.call(zoomBehavior.transform, initialTransform);
    setHasInitializedScale(true);

    return () => {
      svg.on('zoom', null);
    };
  }, [
    width,
    height,
    hasInitializedScale,
    root,
    enablePanZoom,
    startCollapsedLevel,
  ]);

  return (
    <div
      className={classNames(styles['container'], {
        [styles['container-hide']]: !hasInitializedSize,
      })}
      ref={containerRef}
      {...rest}
    >
      <svg
        ref={svgRef}
        width="100%"
        height="100%"
        viewBox={width && height ? `0 0 ${width} ${height}` : undefined}
        style={enablePanZoom ? { cursor: 'grab' } : undefined}
      >
        <g
          className={enablePanZoom ? 'main-group' : undefined}
          transform={
            !enablePanZoom && width && height
              ? `translate(${width / 2},${height / 10})`
              : undefined
          }
        >
          {/* Draw Links */}
          <g className={styles['links']}>
            {links.map((link) => (
              <path
                key={link.id}
                d={link.path}
                className={styles['link']}
                fill="none"
              />
            ))}
          </g>

          {/* Draw Nodes */}
          <g className={styles['nodes']}>
            {nodes.map((node) => (
              <g
                key={node.id}
                transform={`translate(${node.x},${node.y})`}
                className={styles['node']}
              >
                <rect
                  x={-NodeConstants.width / 2}
                  y={-NodeConstants.height / 2}
                  width={NodeConstants.width}
                  height={NodeConstants.height}
                  rx={NodeConstants.borderRadius}
                  className={styles['nodeBox']}
                />
                <foreignObject x={-55} y={-22} width={110} height={44}>
                  <div className={styles['nodeContent']}>
                    <div className={styles['nodeName']}>{node.data.name}</div>
                    <div className={styles['nodeTitle']}>{node.data.title}</div>
                  </div>
                </foreignObject>
                {node.hasChildren && (
                  <NodeToggleButton
                    isCollapsed={node.isCollapsed}
                    transform="translate(0, 40)"
                    className={styles['toggleButton']}
                    onClick={(e) => {
                      e.stopPropagation();
                      handleToggle(node.id);
                    }}
                  />
                )}
              </g>
            ))}
          </g>
        </g>
      </svg>
    </div>
  );
};

interface NodeToggleButtonProps extends React.SVGProps<SVGGElement> {
  isCollapsed: boolean;
  onClick: (e: MouseEvent<SVGGElement>) => void;
}

const NodeToggleButton = ({
  isCollapsed,
  onClick,
  ...rest
}: NodeToggleButtonProps) => {
  const iconSize = 8;
  return (
    <g
      transform="translate(0, 40)"
      className={styles['toggleButton']}
      onClick={onClick}
    >
      <circle r={8} className={styles['toggleCircle']} />
      <foreignObject
        x={iconSize * -0.5}
        y={iconSize * -0.5}
        width={iconSize}
        height={iconSize}
      >
        {isCollapsed && <FiPlus className={styles['toggleIcon']} />}
        {!isCollapsed && <FiMinus className={styles['toggleIcon']} />}
      </foreignObject>
    </g>
  );
};
