import { Box, Flex, Text } from '@chakra-ui/layout';
import { Icon, IconButton } from '@chakra-ui/react';
import { FiRefreshCw } from 'react-icons/fi';
import { ClientDeliverableOutput } from '@revelio/data-access';
import { PageTitles, ReactDataGridLoading } from '@revelio/core';
import ReactDataGrid from '@inovua/reactdatagrid-community';
import { TypeSortInfo } from '@inovua/reactdatagrid-community/types';
import { useCallback, useEffect, useRef, useState } from 'react';
import { formatDistanceToNow, intlFormat } from 'date-fns';
import DashboardPage from '../../DashboardPage';
import '../../../styles/table-custom.scss';
import { DeliverableDetails } from './deliverable-details';
import { UserOption, UserSelector } from './user-selector';
import { isRoleRevelioAdmin, useGetLoggedInUser } from '@revelio/auth';
import { useDeliverablesFromUserOption } from './use-deliverables-from-user-option';
import { DeliverableStatusColumn } from './status-columns/deliverable-status-column';

export const DeliverablesStatus = () => {
  const [lastUpdate, setLastUpdate] = useState<Date>(new Date());
  const [timeDistance, setTimeDistance] = useState<string>('');
  const minsPast = useRef<number>(0);

  const [selectedUserOption, setSelectedUserOption] = useState<UserOption>();
  const { loggedInUser } = useGetLoggedInUser();

  /** When we get logged in user, set them as the selected user if there is
   * not already a selected user */
  useEffect(() => {
    if (loggedInUser && loggedInUser?.id) {
      const loggedInUserId = loggedInUser.id;
      setSelectedUserOption((prevState) => {
        if (!prevState) {
          return {
            value: loggedInUserId,
            label: loggedInUser.name || '',
            group: loggedInUser.client_name || '',
            secondaryLabel: loggedInUser.email || '',
          };
        } else {
          return prevState;
        }
      });
    }
  }, [loggedInUser]);

  const showUserSelector = isRoleRevelioAdmin(loggedInUser.role);

  const { deliverables, fetching, executeQuery, hasFetchBeenCalled } =
    useDeliverablesFromUserOption(selectedUserOption);

  const refreshNow = useCallback(() => {
    setLastUpdate(new Date());
    executeQuery({ requestPolicy: 'network-only' });
    minsPast.current = 0;
  }, [executeQuery]);

  const intChecker = useCallback(() => {
    minsPast.current++;
    if (minsPast.current >= 4) {
      refreshNow();
    }
  }, [refreshNow]);

  useEffect(() => {
    setTimeDistance(formatDistanceToNow(lastUpdate, { addSuffix: true }));

    const intRefresh = setInterval(() => {
      setTimeDistance(formatDistanceToNow(lastUpdate, { addSuffix: true }));
      intChecker();
    }, 60000);

    return () => clearInterval(intRefresh);
  }, [intChecker, lastUpdate]);

  const deliverablesSource = deliverables.map(
    (dl: ClientDeliverableOutput) => ({
      id: dl.job_id,
      name: dl.deliverable.name,
      client: dl.deliverable.client,
      created_at: dl.created_at,
      updated_at: dl.updated_at,
      status: dl.status,
      user: dl.user?.email,
    })
  );

  const columns = [
    {
      name: 'id',
      header: 'ID',
      minWidth: 30,
      maxWidth: 80,
      defaultFlex: 0.5,
      render: ({ value }: { value: string }) => (
        <div data-testid={`${value}_deliverable_status_row`}>{value}</div>
      ),
    },
    {
      name: 'name',
      header: 'Name',
      minWidth: 30,
      defaultFlex: 1,
    },
    !selectedUserOption?.isGroupOption
      ? {
          name: 'client',
          header: 'Client',
          minWidth: 30,
          defaultFlex: 0.7,
        }
      : {
          name: 'user',
          header: 'User',
          minWidth: 30,
          defaultFlex: 0.7,
        },
    {
      name: 'created_at',
      header: 'Creation',
      minWidth: 30,
      defaultFlex: 1.1,
      render: ({ value }: { value: string }) => (
        <div>
          {intlFormat(new Date(value), {
            weekday: 'short',
            year: 'numeric',
            month: 'short',
            day: 'numeric',
            hour: 'numeric',
            timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
            timeZoneName: 'short',
          })}
        </div>
      ),
    },
    {
      name: 'updated_at',
      header: 'Updated',
      minWidth: 30,
      defaultFlex: 0.5,
      render: ({ value }: { value: string }) => (
        <div>{formatDistanceToNow(new Date(value), { addSuffix: true })}</div>
      ),
    },
    {
      name: 'status',
      minWidth: 30,
      defaultFlex: 0.7,
      render: ({
        value,
        data: tableData,
      }: {
        value: string;
        data: (typeof deliverablesSource)[number];
      }) => (
        <DeliverableStatusColumn
          value={value}
          data={tableData}
          deliverables={deliverables}
        />
      ),
      header: () => (
        <div>
          <span>Status</span>
          <IconButton
            aria-label="Refresh Status"
            icon={<FiRefreshCw />}
            colorScheme="gray"
            variant="outline"
            size="xs"
            ml={2}
            onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
              e.stopPropagation();
              refreshNow();
            }}
          />
        </div>
      ),
    },
  ];

  const gridStyle = { minHeight: '100%' };

  const defaultSortInfo: TypeSortInfo = { name: 'created_at', dir: -1 };
  const [selectedDeliverableId, setSelectedDeliverableId] = useState<
    ClientDeliverableOutput['job_id'] | null
  >();

  return (
    <DashboardPage
      title={[PageTitles.DELIVERABLES, PageTitles.DELIVERABLES_STATUS]}
      loading={!hasFetchBeenCalled && fetching}
    >
      <Box display="flex" justifyContent="space-between" alignItems="end">
        {showUserSelector && (
          <UserSelector
            selectedUserOption={selectedUserOption}
            setSelectedUserOption={setSelectedUserOption}
          />
        )}
      </Box>
      <Box
        bg="white"
        rounded="lg"
        h={`calc(100vh - ${showUserSelector ? '169px' : '100px'})`}
        p={4}
      >
        <ReactDataGrid
          columns={columns}
          dataSource={deliverablesSource}
          style={gridStyle}
          rowHeight={50}
          emptyText="No deliverables created yet"
          theme="rl-light"
          onRowClick={(value) => setSelectedDeliverableId(value.data.id)}
          defaultSortInfo={defaultSortInfo}
          showCellBorders={false}
          showZebraRows={false}
          loading={fetching || !hasFetchBeenCalled}
          renderLoadMask={ReactDataGridLoading}
        />
        <DeliverableDetails
          clientsDeliverable={deliverables}
          selectedDeliverableId={selectedDeliverableId}
          setSelectedDeliverableId={setSelectedDeliverableId}
        />
      </Box>
      {lastUpdate && (
        <Flex alignItems="center" justifyContent="flex-end">
          <Text fontSize="xs" pt={2} opacity={0.5}>
            <Icon as={FiRefreshCw} mr={1} top="1px" pos="relative" />{' '}
            <span>Last updated: {timeDistance}</span>
          </Text>
        </Flex>
      )}
    </DashboardPage>
  );
};
