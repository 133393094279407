import { useState } from 'react';
import { Box, PopoverBody, PopoverFooter } from '@chakra-ui/react';
import { SelectFooter } from '../select-footer';
import { useTalentDiscoveryFilter } from '../td-filter-provider';
import {
  TalentDiscoveryFilterOption,
  isFreeTextFilter,
  isFreeTextFilterState,
} from '../types';
import { TDNameInput } from '@revelio/filtering';
import { TalentDiscoveryFreeTextState } from '../td-filter-reducer';

interface TDNameFilterProps {
  closeMenu: () => void;
  selectedFilter: TalentDiscoveryFilterOption;
  filterId?: string;
}

export const TDNameFilter = ({
  closeMenu,
  selectedFilter,
  filterId,
}: TDNameFilterProps) => {
  const { state, dispatch } = useTalentDiscoveryFilter();
  const isEditing = !!filterId;
  const selectedFreeTextState = (() => {
    if (isEditing) {
      return state.filters?.find(
        (filter) => isFreeTextFilterState(filter) && filter.id === filterId
      ) as TalentDiscoveryFreeTextState;
    }

    return undefined;
  })();

  const handleAddFilter = () => {
    if (isFreeTextFilter(selectedFilter.value)) {
      const newId = filterId || `${Date.now()}`;
      dispatch({
        type: 'ADD_FREE_TEXT_FILTER',
        name: selectedFilter.value,
        text: [name.name],
        id: selectedFilter.supportsMultiple ? newId : null,
      });
    }
    closeMenu();
  };

  const [name, setName] = useState<{
    [key: string]: string;
  }>({ name: selectedFreeTextState?.text?.[0] || '' });

  const setNameState = (name: { [key: string]: string }) => {
    setName(name);
  };

  const isAddDisabled = name?.name?.length === 0;

  return (
    <>
      <PopoverBody padding={0}>
        <Box onClick={(e) => e.stopPropagation()} mt={1}>
          <Box data-testid="free-text-input">
            <TDNameInput
              state={name}
              setState={setNameState}
              vStackProps={{ my: -2 }}
              isTypeaheadDisabled={false}
            />
          </Box>
        </Box>
      </PopoverBody>
      <PopoverFooter padding="8px 0 0" borderTopWidth={0}>
        <SelectFooter
          onClose={closeMenu}
          onAdd={handleAddFilter}
          addLabel={selectedFreeTextState ? 'Update' : 'Add'}
          isAddDisabled={isAddDisabled}
        />
      </PopoverFooter>
    </>
  );
};
