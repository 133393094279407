// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React from 'react';
import PropTypes from 'prop-types';
import { withResizeDetector } from 'react-resize-detector';
import '../d3-styles.scss';
import { useD3 } from '../hooks/useD3';
import { MainPostingsPlotGenerator } from '../plot-generators';

type CompanyPostingsMetadata = {
  longName: string;
  shortName: string;
};

type CompanyPostingsValue = {
  id: number;
  metadata: CompanyPostingsMetadata & {
    day: number;
    month: number;
    year: number;
  };
  value: number;
};

type CompanyPostingsData = {
  id: string;
  metadata: CompanyPostingsMetadata;
  value: CompanyPostingsValue;
};
export interface MainPostingsPlotProps {
  name: string;
  chartStyle: string;
  data: CompanyPostingsData[];
}

const MainPostingsPlot = ({
  name = 'default',
  chartStyle = '',
  data = [],
  height,
  width,
  targetRef,
  requestHash,
}) => {
  useD3(
    MainPostingsPlotGenerator,
    { name, chartStyle, data, height, width, targetRef, requestHash },
    [targetRef.current, height, width, data, requestHash]
  );

  return (
    <div
      ref={targetRef}
      className={`react-node-${name}`}
      style={{
        position: 'relative',
        display: 'grid',
        height: '100%',
      }}
    />
  );
};

MainPostingsPlot.propTypes = {
  name: PropTypes.string.isRequired,
  chartStyle: PropTypes.string.isRequired,
  data: PropTypes.array.isRequired,
};

export default withResizeDetector(MainPostingsPlot, {
  refreshMode: 'debounce',
  refreshOptions: {
    leading: false,
    trailing: true,
  },
});
