import {
  FeatureFlag,
  PageTitles,
  PrimaryFilters,
  PrimaryView,
} from '@revelio/core';
import {
  SelectionCategories,
  ViewTypes,
  FilterSets,
  PrimaryFilterLimits,
  ROLE_GRANULARITY_FILTERS,
  SKILL_GRANULARITY_FILTERS,
  RICS_FILTERS,
} from '@revelio/filtering';
import Postings, {
  metricModeFilterId,
  providerFilterId,
} from '../../../postings/postings';
import { View } from '@revelio/data-access';
import { useUnleashFlag } from '../../../../hooks/unleash/useUnleashFlag';

const title = [PageTitles.GEOGRAPHY, PageTitles.JOB_POSTINGS];

const primaryViewFilters = [
  {
    filters: [
      SelectionCategories.REGION,
      SelectionCategories.COUNTRY,
      SelectionCategories.METRO_AREA,
    ],
    isNested: true,
  },
];

const otherFilters = [providerFilterId, metricModeFilterId];

export function GeographyPostings() {
  const keywordFiltersFeatureFlag = useUnleashFlag(
    FeatureFlag.GeoPostingsKeyword
  );
  const selectableFilters = [
    SelectionCategories.SAVED_FILTER_SET,
    {
      filters: ROLE_GRANULARITY_FILTERS,
      isNested: true,
      limit: 10,
    },
    SelectionCategories.SENIORITY,
    {
      filters: SKILL_GRANULARITY_FILTERS,
      isNested: true,
      limit: 10,
    },
    { filters: RICS_FILTERS, isNested: true, limit: 10 },
    SelectionCategories.DATE_RANGE_FULL,
    SelectionCategories.RAW_TITLE,
    ...(keywordFiltersFeatureFlag ? [SelectionCategories.KEYWORD] : []),
  ];

  return (
    <Postings
      title={title}
      primaryView={PrimaryView.GEOGRAPHY}
      viewType={ViewTypes.GEO}
      primaryFilter={PrimaryFilters.METRO_AREA}
      sharedFilterSetId={FilterSets.GEOGRAPHY}
      filterSet={FilterSets.GEOGRAPHY_POSTINGS}
      primaryViewFilters={primaryViewFilters}
      primaryFiltersLimit={PrimaryFilterLimits.POSTINGS}
      selectableFilters={selectableFilters}
      filterMenuLimits={PrimaryFilterLimits.POSTINGS}
      otherFilters={otherFilters}
      isGqlQuery={true}
      isGoRequest={true}
      additionalNonActiveFilters={[providerFilterId, metricModeFilterId]}
      savedSetView={View.Geography}
    />
  );
}

export default GeographyPostings;
