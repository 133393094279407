import ConfirmationSummary from '../confirmation/confirmation-summary';
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react';
import {
  ClientDeliverableOutput,
  CustomColumn,
  CustomColumnInput,
  Deliverable,
  DeliverableOutput,
  Maybe,
  PipelineFilter,
} from '@revelio/data-access';
import { getCommonDeliveryFilePrefix } from './status.api';
import { produce } from 'immer';
import { deserializeCustomColumnMultiGranularitySelectionListVariables } from '../columns/custom/serialize-custom-columns.api';
import { useSelectionLists } from '@revelio/filtering';
import { getRequiredSelectionListNamesForCustomStepDeserialisation } from '../columns/dataset-conditions/serialize-conditions.api';
import { deserializeFilterMultiGranularitySelectionListVariables } from '../columns/filters/serialize-filters.api';

export const DeliverableDetails = ({
  clientsDeliverable,
  selectedDeliverableId,
  setSelectedDeliverableId,
}: {
  clientsDeliverable: ClientDeliverableOutput[];
  selectedDeliverableId: ClientDeliverableOutput['job_id'] | undefined | null;
  setSelectedDeliverableId: React.Dispatch<
    React.SetStateAction<ClientDeliverableOutput['job_id'] | undefined | null>
  >;
}) => {
  const selectedClientDeliverableJob = clientsDeliverable?.find(
    (clientDl) => clientDl.job_id === selectedDeliverableId
  );
  const selectedClientDeliverable = selectedClientDeliverableJob?.deliverable;
  return (
    <Modal
      size="2xl"
      isOpen={!!selectedDeliverableId}
      onClose={() => setSelectedDeliverableId(null)}
      isCentered
    >
      <ModalOverlay />
      <ModalContent minHeight="530px">
        <ModalHeader
          borderBottom="1px solid #E5EBF1"
          fontSize="17px"
          fontWeight="600"
          py="12px"
        >
          {selectedClientDeliverable?.client} -{' '}
          {selectedClientDeliverable?.name}
        </ModalHeader>
        <ModalCloseButton size="sm" />
        <ModalBody py="16px">
          {selectedDeliverableId && (
            <Summary
              selectedClientDeliverableJob={selectedClientDeliverableJob}
            />
          )}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

const Summary = ({
  selectedClientDeliverableJob,
}: {
  selectedClientDeliverableJob: ClientDeliverableOutput | undefined;
}) => {
  const selectedClientDeliverable = selectedClientDeliverableJob?.deliverable;
  const requestedDeliverableDetails =
    selectedClientDeliverable?.deliverables?.[0];
  const commonDeliveryFilePrefix = getCommonDeliveryFilePrefix(
    (selectedClientDeliverable?.deliverables as Deliverable[] | undefined) || []
  );

  const selectionLists = useSelectionLists(
    getRequiredSelectionListNamesForCustomStepDeserialisation({
      deliverables:
        selectedClientDeliverable?.deliverables as DeliverableOutput[],
    })
  );
  return (
    <ConfirmationSummary
      deliverables={
        selectedClientDeliverable?.deliverables.map((del) =>
          produce<Maybe<DeliverableOutput>, Deliverable>(
            del,
            (deserialised) => {
              deserialised.company_sets = del?.company_sets
                ? del.company_sets
                : selectedClientDeliverable.company_sets;
              deserialised.company_reference = del?.company_reference
                ? del.company_reference
                : selectedClientDeliverable.company_reference;
              deserialised.pipeline_input = del?.pipeline_input
                ? del.pipeline_input
                : selectedClientDeliverable.pipeline_input;

              if (del?.pipeline.custom_columns) {
                deserialised.pipeline.custom_columns =
                  deserializeCustomColumnMultiGranularitySelectionListVariables(
                    {
                      customColumns: del?.pipeline
                        .custom_columns as CustomColumn[],
                      selectionLists,
                    }
                  ) as CustomColumnInput[];
              }

              if (del?.pipeline.filters) {
                deserialised.pipeline.filters =
                  deserializeFilterMultiGranularitySelectionListVariables({
                    filters: del?.pipeline.filters as PipelineFilter[],
                    selectionLists,
                  });
              }
            }
          )
        ) as Deliverable[]
      }
      s3Delivery={requestedDeliverableDetails?.s3_delivery}
      snowflakeDelivery={requestedDeliverableDetails?.snowflake_delivery}
      client={selectedClientDeliverable?.client}
      name={selectedClientDeliverable?.name}
      status={selectedClientDeliverableJob?.status}
      lagData={selectedClientDeliverableJob?.deliverable.lag_data}
      commonDeliveryFilePrefix={commonDeliveryFilePrefix}
    />
  );
};
