import { DeleteIcon, DownloadIcon, ViewIcon } from '@chakra-ui/icons';
import { Box, Flex, Text } from '@chakra-ui/layout';
import {
  Button,
  ButtonGroup,
  IconButton,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverFooter,
  PopoverHeader,
  PopoverTrigger,
  Spinner,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import { ResumeIcon } from '@revelio/assets';
import { PageTitles } from '@revelio/core';
import { useNavigate } from 'react-router';
import { useMutation, useQuery } from 'urql';
import { environment } from '../../environments/environment';
import DashboardPage from '../DashboardPage';
import { DELETE_RESUME, SAVE_RESUME } from './resume-operations.gql';
import UploadResume, { FileUploadResponse } from './UploadResume';
import { useGetLoggedInUser } from '@revelio/auth';
import { useCallback, useState } from 'react';
import { Resume, USER_RESUMES_QUERY } from '@revelio/data-access';
import { truncate } from 'lodash';
import DownloadResumeButton from './DownloadResumeButton';

interface ResumeFile {
  download_link: string | null;
  resume_id: string;
  name: string;
}

function ResumeEnrichment() {
  const title = [PageTitles.INDIVIDUAL, PageTitles.RESUME_ENRICHMENT];

  const isProd = environment.production;
  const resumeProcessingEndpoint = isProd
    ? `${environment.GO_API_ROOT}/models/resume-parser-processing`
    : `${environment.GO_API_ROOT}/models/resume-parser-processing-dev`;

  const { loggedInUser } = useGetLoggedInUser();
  const [{ data: userResumeData }, refetchUserResumes] = useQuery({
    query: USER_RESUMES_QUERY,
    variables: { ids: [loggedInUser.id as string] },
    pause: !loggedInUser.id,
    requestPolicy: 'cache-and-network',
  });

  const [resumesLoading, setResumesLoading] = useState<ResumeFile[]>([]);

  const navigate = useNavigate();

  const [, saveResume] = useMutation(SAVE_RESUME);

  const toast = useToast();

  const processFile = async ({
    s3_loc,
    key,
    environment: env,
    name,
  }: FileUploadResponse) => {
    try {
      setResumesLoading((prev) => [
        { name, resume_id: key, download_link: null },
        ...prev,
      ]);
      const response = await fetch(resumeProcessingEndpoint, {
        method: 'POST',
        body: JSON.stringify({ s3_loc, key, environment: env }),
        credentials: 'include',
      });

      if (!response.ok) {
        toast({
          title: 'Error',
          description: 'Error parsing resume.',
          status: 'error',
          duration: 4000,
          position: 'top-right',
        });
      } else {
        const data = await response.json();
        saveResume({
          input: {
            resume_id: data.key,
            name: data.name,
            excel_location: data.excel_s3_loc,
          },
        });
        navigate(`/resume-enrichment/${data.key}`);
      }
      setResumesLoading((prev) =>
        prev.filter((resume) => resume.resume_id !== key)
      );
    } catch (e) {
      console.error((e as Error).message);
    }
  };

  const onUploadFile = (fileResponse: FileUploadResponse) => {
    processFile(fileResponse);
  };

  return (
    <DashboardPage title={title} loading={false}>
      <Flex gap="18px" flex="1" height="100%">
        <Box
          borderRadius="10px"
          backgroundColor="white"
          padding="18px 25px"
          minW="320px"
          overflow="scroll"
        >
          <Text size="18px" color="navyBlue.500" fontWeight={600}>
            Enriched Resumes
          </Text>
          <Box mt="10px">
            {resumesLoading.map((resume, index) => (
              <ResumeFileLoading
                resume={resume}
                key={`resume-file-loading-${index}`}
              />
            ))}
            {(userResumeData?.users?.[0]?.resumes as Resume[])?.map(
              (resume, index) => (
                <ResumeFile
                  resume={resume}
                  refetchUserResumes={refetchUserResumes}
                  key={`resume-file-${index}`}
                />
              )
            )}
          </Box>
        </Box>
        <Box
          borderRadius="10px"
          backgroundColor="white"
          flex="1"
          padding="18px 25px"
        >
          <Text size="18px" color="navyBlue.500" fontWeight={600} mb="15px">
            Upload file
          </Text>
          <UploadResume onUpload={onUploadFile} fileUploadHeight="95%" />
        </Box>
      </Flex>
    </DashboardPage>
  );
}

export default ResumeEnrichment;

const ResumeFile = ({
  resume,
  refetchUserResumes,
}: {
  resume: Resume;
  refetchUserResumes: () => void;
}) => {
  const navigate = useNavigate();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [, deleteResume] = useMutation(DELETE_RESUME);

  const handleDeleteResume = useCallback(async () => {
    await deleteResume({ id: resume.resume_id });
    refetchUserResumes();
    onClose();
  }, [onClose, resume.resume_id, deleteResume, refetchUserResumes]);

  return (
    <Flex
      key={resume.resume_id}
      gap="10px"
      pt="6px"
      alignItems="center"
      data-testid={'enriched-resume-item'}
    >
      <Box paddingBottom="6px">
        <ResumeIcon />
      </Box>
      <Flex
        paddingBottom="6px"
        borderBottomColor="gray.100"
        borderBottomWidth="1px"
        flex="1"
        color="navyBlue.500"
        justifyContent="space-between"
      >
        <Text>{truncate(resume.name, { length: 30 })}</Text>
        <Flex gap="8px" ml="8px">
          <IconButton
            variant="unstyled"
            size="s"
            icon={<ViewIcon height="13px" width="13px" />}
            aria-label="view-icon"
            onClick={() => navigate(`/resume-enrichment/${resume.resume_id}`)}
            data-testid={'view-enriched-resume-btn'}
          />
          <DownloadResumeButton
            iconButton
            resumeName={resume.name}
            resumeId={resume.resume_id}
          />
          <Popover
            placement="top-start"
            returnFocusOnClose={false}
            {...{ isOpen, onOpen, onClose }}
          >
            {({ onClose }) => (
              <>
                <PopoverTrigger>
                  <IconButton
                    variant="unstyled"
                    size="s"
                    icon={<DeleteIcon height="13px" width="13px" />}
                    aria-label="delete-icon"
                    data-testid={'delete-enriched-resume-btn'}
                  />
                </PopoverTrigger>
                <PopoverContent>
                  <PopoverArrow />
                  <PopoverCloseButton />
                  <PopoverHeader color="text.primary" fontWeight="600">
                    Confirm Action
                  </PopoverHeader>
                  <PopoverBody color="text.primary">
                    Are you sure you want to delete this resume?
                  </PopoverBody>
                  <PopoverFooter display="flex" justifyContent="flex-end">
                    <ButtonGroup size="sm">
                      <Button
                        variant="outline"
                        colorScheme="gray"
                        color={'text.primary'}
                        onClick={onClose}
                      >
                        Cancel
                      </Button>
                      <Button
                        // so that there's only one confirm delete button at a time for testing purposes
                        data-testid={isOpen ? 'confirm-delete-resume' : ''}
                        colorScheme="red"
                        onClick={handleDeleteResume}
                      >
                        Confirm
                      </Button>
                    </ButtonGroup>
                  </PopoverFooter>
                </PopoverContent>
              </>
            )}
          </Popover>
        </Flex>
      </Flex>
    </Flex>
  );
};

const ResumeFileLoading = ({ resume }: { resume: ResumeFile }) => (
  <Flex key={resume.resume_id} gap="10px" pt="6px" alignItems="center">
    <Box paddingBottom="6px">
      <Spinner size="xs" color="green.300" />
    </Box>
    <Flex
      paddingBottom="6px"
      borderBottomColor="gray.100"
      borderBottomWidth="1px"
      flex="1"
      color="navyBlue.500"
      justifyContent="space-between"
    >
      <Text>{truncate(resume.name, { length: 30 })}</Text>
      <Flex gap="8px">
        <IconButton
          variant="unstyled"
          size="s"
          icon={<ViewIcon height="13px" width="13px" />}
          aria-label="view-icon"
          isDisabled
        />
        <IconButton
          variant="unstyled"
          size="s"
          icon={<DownloadIcon height="13px" width="13px" />}
          aria-label="download-icon"
          isDisabled
        />
        <IconButton
          variant="unstyled"
          size="s"
          icon={<DeleteIcon height="13px" width="13px" />}
          aria-label="delete-icon"
          isDisabled
        />
      </Flex>
    </Flex>
  </Flex>
);
