import { selectEntities } from '@ngneat/elf-entities';
import { reportBuilderStore } from '../report-builder.repository';
import { useObservable } from '@ngneat/react-rxjs';
import { generatePayload } from './utils';
import { Box } from '@chakra-ui/layout';
import { useGetLoggedInUser } from '@revelio/auth';
import { useMutation } from 'urql';
import { CreateReportsMutation } from '../report-builder-operations';
import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  useToast,
} from '@chakra-ui/react';
import { useState } from 'react';
import { PagePaths } from '@revelio/core';
import { ReportBuilderDeliverablePaths } from '../report-builder-page';
import { DeliverableStepContainer } from '../../deliverable-step-container';
import { useNavigate } from 'react-router';
import { useForm } from 'react-hook-form';
import { ReportSummary } from './report-summary';
import { useReportBuilderConfig } from '../utils/useReportBuilderConfig';

export const ReportConfirmation = () => {
  const [reportBuilderDeliverables] = useObservable(
    reportBuilderStore.pipe(selectEntities())
  );
  const { loggedInUser } = useGetLoggedInUser();
  const [, createReport] = useMutation(CreateReportsMutation);
  const navigate = useNavigate();
  const toast = useToast();
  const [isSubmitting, setisSubmitting] = useState(false);
  const reportEntityMap = useReportBuilderConfig().config;

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm<{
    name: string;
  }>({
    defaultValues: {
      name: '',
    },
  });

  const report = Object.values(reportBuilderDeliverables)[0];
  if (!report) {
    navigate(
      `/${PagePaths.REPORT_BUILDER}/${ReportBuilderDeliverablePaths.REPORT_TYPE}`
    );

    return;
  }

  const payload = generatePayload(report, reportEntityMap);

  const onClickSendReport = handleSubmit((formData) => {
    const reportName = formData.name;

    if (isSubmitting) {
      return;
    }

    setisSubmitting(true);

    if (!reportName || !payload) {
      toast({
        title: 'Please enter a report name',
        status: 'error',
        duration: 5000,
        isClosable: true,
        position: 'top-right',
      });

      setisSubmitting(false);
      return;
    }
    createReport({
      input: {
        client: loggedInUser?.client_name || '',
        name: reportName,
        reports: [payload],
      },
    }).then(({ error }) => {
      const title = error ? error.message : 'Report request sent successfully';
      const description = error ? '' : 'Check back in a few minutes.';
      const status = error ? 'error' : 'success';

      toast({
        title,
        description,
        status,
        duration: 5000,
        isClosable: true,
        position: 'top-right',
      });

      if (!error) {
        navigate(`/${PagePaths.REPORTS}`);
        reportBuilderStore.reset();
      }

      setisSubmitting(false);
    });
  });

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.target.value = e.target.value.replace(/[^a-zA-Z0-9\s]/g, '');
  };

  return (
    <DeliverableStepContainer
      id="report_builder_entity"
      title="Review Your Report and Confirm"
      previousStepPath={`/${PagePaths.REPORT_BUILDER}/${ReportBuilderDeliverablePaths.ENTITY}`}
      submitButtonContent="Submit"
      onSubmit={onClickSendReport}
      startOverPath={`/${PagePaths.REPORT_BUILDER}/${ReportBuilderDeliverablePaths.REPORT_TYPE}`}
      isSubmitDisabled={isSubmitting}
    >
      <Box p="8">
        <ReportSummary report={report} />
        <FormControl id="report_name" isInvalid={!!errors.name} mt="10">
          <FormLabel fontSize="sm" color="text.primary">
            Report Name
          </FormLabel>
          <Input
            {...register('name', {
              required: 'Report name is required.',
              minLength: {
                value: 1,
                message: 'Minimum length should be 1',
              },
            })}
            onChange={handleInputChange}
            placeholder="Enter unique report name"
            width={350}
          />
          <FormErrorMessage>
            {errors.name && errors.name.message}
          </FormErrorMessage>
        </FormControl>
      </Box>
    </DeliverableStepContainer>
  );
};
