import { Icon, Image, MenuItem, MenuItemProps } from '@chakra-ui/react';
import { IconType } from 'react-icons';

export interface MenuItemWithIconProps extends Omit<MenuItemProps, 'icon'> {
  icon?: IconType;
  iconSrc?: string;
}

export const MenuItemWithIcon = ({
  icon,
  iconSrc,
  ...rest
}: MenuItemWithIconProps) => {
  return (
    <MenuItem
      icon={
        icon ? (
          <Icon
            as={icon}
            w={4}
            h={4}
            style={{
              display: 'flex',
              alignItems: 'center',
              flexWrap: 'wrap',
            }}
          />
        ) : (
          <Image
            src={iconSrc}
            alt="icon"
            w={4}
            h={4}
            style={{
              display: 'flex',
              alignItems: 'center',
              flexWrap: 'wrap',
            }}
          />
        )
      }
      {...rest}
    />
  );
};
