import {
  BehaviorSubject,
  from,
  iif,
  MonoTypeOperatorFunction,
  Observable,
  ObservableInput,
  of,
  OperatorFunction,
  pipe,
  ReplaySubject,
  Subject,
} from 'rxjs';
import {
  catchError,
  concatMap,
  filter,
  first,
  map,
  switchMap,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import { delay as _delay, isDate, noop, padStart } from 'lodash';
import {
  PopoverTrigger as OrigPopoverTrigger,
  PopoverAnchor as OrigPopoverAnchor,
} from '@chakra-ui/react';
import { createBrowserHistory } from 'history';
import { Store, StoreValue } from '@ngneat/elf';
import React, { useEffect, useState } from 'react';
import { produce } from 'immer';
import { useEffect$ } from '@ngneat/react-rxjs';
import { useLifecycles } from 'react-use';

export interface LoaderMutation {
  add?: string;
  remove?: string | string[];
  replace?: { [key: string]: true };
}

export enum DASHBOARD_ERRORS {
  INVALID_DEEPLINK = 'invalid_deeplink',
  NONE = 'none',
}

export const _DASHBOARD_ERROR_STATUS = new BehaviorSubject<DASHBOARD_ERRORS>(
  DASHBOARD_ERRORS.NONE
);

const __API_READY = new ReplaySubject<boolean>(1);
export const API_READY = __API_READY.pipe(first());
export function setApiReady(state = true) {
  if (state) {
    __API_READY.next(state);
  }
}

const __LAST_DATE_READY = new ReplaySubject<boolean>(1);
export const LAST_DATE_READY = __LAST_DATE_READY.pipe(first());
export function setLastDateReady(state = true) {
  if (state) {
    __LAST_DATE_READY.next(state);
  }
}

export const history = createBrowserHistory();

const _addLoadingStatus = new Subject<string>();
export const addLoadingStatus$ = _addLoadingStatus
  .asObservable()
  .pipe(map<string, LoaderMutation>((v) => ({ add: v })));
export function addLoadingStatus(item: string) {
  _addLoadingStatus.next(item);
}
const _removeLoadingStatus = new Subject<string | string[]>();
export const removeLoadingStatus$ = _removeLoadingStatus
  .asObservable()
  .pipe(map<string | string[], LoaderMutation>((v) => ({ remove: v })));
export function removeLoadingStatus(item: string | string[]) {
  _removeLoadingStatus.next(item);
}
const _overrideLoadingStatus = new Subject<{ [key: string]: true }>();
export const overrideLoadingStatus$ = _overrideLoadingStatus
  .asObservable()
  .pipe(map<{ [key: string]: true }, LoaderMutation>((v) => ({ replace: v })));
export function overrideLoadingState(replacement: { [key: string]: true }) {
  _overrideLoadingStatus.next(replacement);
}
export const turnOffGlobalLoader = new BehaviorSubject(false);
export function setGlobalLoaderEnableState(
  state: 'ENABLE' | 'DISABLE',
  autoReEnableAfter?: number
) {
  if (['ENABLE', 'DISABLE'].includes(state)) {
    turnOffGlobalLoader.next(state == 'DISABLE');

    if (autoReEnableAfter && Number.isInteger(autoReEnableAfter)) {
      _delay(() => {
        turnOffGlobalLoader.next(false);
      }, autoReEnableAfter);
    }
  }
}

export const globalMaxWaitEnabled = new BehaviorSubject(false);
export function useGlobalLoaderMaxWait(state = false) {
  const [mountState, setMountState] = useState<boolean>();
  useLifecycles(
    () => {
      setMountState(globalMaxWaitEnabled.value);
      globalMaxWaitEnabled.next(state);
    },
    () => globalMaxWaitEnabled.next(mountState || false)
  );
}

export const _globalPageLoadingStatus = new BehaviorSubject<{
  [key: string]: boolean;
}>({});
export const toggleStatusOnGlobalLoaderOrSkipOne = (key = 'skip') => {
  const loaders = _globalPageLoadingStatus.getValue();

  if (loaders[key]) {
    delete loaders[key];
  } else {
    loaders[key] = true;
  }
  _globalPageLoadingStatus.next({ ...loaders });
};

export const globalPageLoadingStatus = _globalPageLoadingStatus.asObservable();
export function useGlobalPageLoadingStatus() {
  const [isPageLoading, setIsPageLoading] = useState(false);
  useEffect$(() => {
    return globalPageLoadingStatus.pipe(
      tap((loadingStatus) => {
        setIsPageLoading(loadingStatus['areWeLoading']);
      })
    );
  });

  return isPageLoading;
}

export const globalFetchStatus = new Subject<boolean>();
export const globalLoader = new Subject<boolean>();
export function useGlobalLoaderStatus() {
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const subscription$ = globalLoader
      .pipe(
        withLatestFrom(globalFetchStatus),
        map(([loader, fetchStatus]) => (fetchStatus === false ? loader : true))
      )
      .subscribe(setLoading);

    return () => subscription$.unsubscribe();
  }, []);

  return loading;
}

export function debugOp<T>(tag: string): MonoTypeOperatorFunction<T> {
  return tap<T>({
    next(value) {
      console.log(
        `%c[${tag}: Next]`,
        'background: #009688; color: #fff; padding: 3px; font-size: 9px;',
        value
      );
    },
    error(error) {
      console.log(
        `%[${tag}: Error]`,
        'background: #E91E63; color: #fff; padding: 3px; font-size: 9px;',
        error
      );
    },
    complete() {
      console.log(
        `%c[${tag}]: Complete`,
        'background: #00BCD4; color: #fff; padding: 3px; font-size: 9px;'
      );
    },
  });
}

export function preFetchWithModifiedQuery<T>(
  mutations: (stuff: T) => T[]
): OperatorFunction<T, T> {
  return pipe(
    switchMap((stuff) => {
      const { preFetchQuery } = stuff as {
        preFetchQuery?: boolean;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        [key: string]: any;
      };
      if (preFetchQuery) {
        return of(stuff);
      }
      // TODO: figure out way to have regular query ('stuff') be first http sent but still have it be the last emission. Currently it's last as the mutation one's return observables that just complete without any emission and that prevents the Plot from getting the the updated data
      return from([
        ...mutations(stuff).map((v) => ({
          ...v,
          includeInGlobalLoader: false,
          preFetchQuery: true,
        })),
        stuff,
      ]).pipe(concatMap((v, i) => of(v)));
    })
  );
}

export function pickKeys<S extends Store, State extends StoreValue<S>>(
  keys: Array<keyof State>
): OperatorFunction<State, Partial<State>> {
  return pipe(
    map((state) => {
      return Object.keys(state).reduce<State>((toSave, key) => {
        if (keys.includes(key)) {
          toSave[key] = state[key];
        }
        return toSave;
      }, {} as State);
    })
  );
}

export function maybeCatchError<T, R>(
  shouldCatch: boolean,
  catchFn?: OperatorFunction<T, R>
) {
  return function <T>(source: Observable<T>) {
    return iif(
      () => shouldCatch,
      source.pipe(
        catchError<T, ObservableInput<{ error: boolean } & T>>((err) =>
          catchFn ? catchFn(err) : of({ error: true, ...err })
        )
      ),
      source
    );
  };
}

export function filterNil() {
  return filter((value) => value !== undefined && value !== null);
}

export function doesNotRunInProd(fn: () => void, prodFn: () => void = noop) {
  if (
    ['localhost', 'dashboard-dev.reveliolabs.com'].includes(
      window.location.hostname
    )
  ) {
    fn();
  } else {
    prodFn();
  }
}

export function formatSnapshotDateString(dateString: string) {
  const [year, month] = dateString.split('-');

  return `${year}-${padStart(month, 2, '0')}`;
}

export const STANDARD_DATE_FORMAT = 'yyyy-MM';
export const DATE_FORMAT_WITH_DAY = 'yyyy-MM-dd';

function dateToString(date: Date, dateFormat = STANDARD_DATE_FORMAT) {
  const includeDay = dateFormat === DATE_FORMAT_WITH_DAY;

  let month = (date.getMonth() + 1).toString();
  month = month.length === 1 ? `0${month}` : month;

  let dateString = `${date.getFullYear()}-${month}`;

  if (includeDay) {
    let day = date.getDate().toString();
    day = day.length === 1 ? `0${day}` : day;

    dateString += `-${day}`;
  }

  return dateString;
}

export function swapDateFormat(
  date: string | Date,
  dateFormat = STANDARD_DATE_FORMAT
) {
  if (isDate(date)) {
    return dateToString(date, dateFormat);
  }

  // date format yyyy-MM[-dd]
  const [year, month, day] = date.split('-');

  const dateObj = new Date();
  dateObj.setFullYear(
    Number.parseInt(year),
    // why are we subtracting 1 from the month?
    Number.parseInt(month) - (day ? 1 : 0),
    day ? Number.parseInt(day) : 0
  );

  return dateObj;
}

type FileDownloadResponse = {
  fileBlob: Blob;
  responseContentDisposition: string;
};
export function downloadFile({
  fileBlob,
  responseContentDisposition,
}: FileDownloadResponse) {
  if (!responseContentDisposition) {
    return;
  }

  const filename = responseContentDisposition.split('filename=')[1];
  const filenameInQuotes = filename.match(/"([^"]+)"/);
  const blobURL = window.URL.createObjectURL(fileBlob);
  const a = document.createElement('a');
  a.href = blobURL;
  a.download = filenameInQuotes ? filenameInQuotes[1] : filename;
  a.click();
  a.remove();
}

export function isNaNparseFloat(n: string) {
  const maybeNum = Number.parseFloat(n);
  const isNan = Number.isNaN(maybeNum);
  return isNan ? true : maybeNum;
}

export function write<S>(
  updater: (state: S) => void,
  returnNew = false
): (state: S) => S {
  return function (state) {
    return produce(state, (draft) => {
      const r = updater(draft as S);
      if (returnNew) {
        return r;
      }
    });
  };
}

export const nameofFactory =
  <T>() =>
  (name: keyof T) =>
    name;

export const PopoverTrigger: React.FC<{ children: React.ReactNode }> =
  OrigPopoverTrigger;

export const PopoverAnchor: React.FC<{ children: React.ReactNode }> =
  OrigPopoverAnchor;

export function generatePermutations<T>(arrays: T[][]): T[][] {
  if (!arrays.length) {
    return [[]];
  }
  const first = arrays[0];
  const rest = arrays.slice(1);
  const restPermutations = generatePermutations(rest);
  const permutations = [];
  for (const value of first) {
    for (const permutation of restPermutations) {
      permutations.push([value, ...permutation]);
    }
  }
  return permutations;
}

export function swapKeysWithValues<
  K extends string | number,
  V extends string | number,
>(obj: { [key in K]?: V }): { [key in V]?: K } {
  return Object.fromEntries(Object.entries(obj).map(([k, v]) => [v, k]));
}
