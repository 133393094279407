/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  select,
  selectAll,
  scaleOrdinal,
  scaleLinear,
  scaleTime,
  min,
  max,
  area,
  curveBasis,
  curveMonotoneX,
} from 'd3';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
/** @ts-ignore */
import { axisLeft, axisBottom } from 'd3-axis';
import { format as d3Format } from 'd3-format';
import '../../d3-styles.scss';
import { appendWatermark } from '../../utilities/append-watermark';
import { monthDiff } from '../../utilities/date-helpers';
import { adjustDownloadMargins } from '../../utilities/adjust-download-margins';
import { removeSVG } from '../../utilities/remove-svg';
import {
  genYMChartLines,
  genYMDChartLines,
  getXAxisTickLabelsOverTwoYears,
  getXAxisTickLabelsTwoMonthsToTwoYears,
  getXAxisTickLabelsLessThanTwoMonths,
  getYAxisTickLabels,
  getYMDates,
  getYMDDates,
  getYearIndexes,
  createMouseMove,
  getYDates,
} from './helpers';
import { calcMaxLeftLabelWidth } from './helpers';
import { months } from './constants';
import { notifyChartRenderComplete } from '../../utilities/notify-chart-render-complete';
import { appendTitle } from '../../utilities/append-title';
import { generateSVGLegend } from '../stacked-bar-chart-horizontal/helpers';
import { colors } from '../../utilities/colors';
import { LineData, LineValue } from './types';
import { DownloadOptions, PlotConfig } from '../../types/types';
import { globalLoader } from '@revelio/core';

interface LineChartPlotConfig extends PlotConfig<LineData[]> {
  chartStyle: string;
  otherTabData?: any[];
  metaValueCompany: string;
  yAxisFormat: string;
  dateFormat: string;
  ttCustomString: string;
  ttMainFormat: string;
  ttSecondaryFormat: string;
  colorLookup?: any;
  colorLookupKeyPath?: string;
  hideAxis?: boolean;
  lineColor?: string;
  areaFillColor?: string;
  ttPosition?: 'left' | 'right';
  setChartHasRendered?: (hasRendered: boolean) => void;
  leftFade?: boolean;
}

export const LineChartGenerator = (
  plotConfig: LineChartPlotConfig,
  downloadOptions: DownloadOptions
): SVGSVGElement | null | void => {
  const {
    chartSize,
    chartPosition,
    chartStyle,
    data: multiTypeData,
    otherTabData = [],
    metaValueCompany,
    yAxisFormat,
    dateFormat,
    ttType,
    ttCustomString,
    ttMainFormat,
    ttSecondaryFormat /*, format*/,
    targetRef,
    requestHash,
    colorLookup = {},
    colorLookupKeyPath = 'id',
    isRenderingOrLoading,
    customMargins,
    hideAxis,
    lineColor,
    areaFillColor,
    ttPosition,
    setChartHasRendered,
    leftFade,
  } = plotConfig;

  let { name, height, width } = plotConfig;

  const {
    title,
    download,
    getSVGNode,
    svgHeight,
    svgWidth,
    containerId,
    padding,
    watermark,
  } = downloadOptions;

  const dims: any = {};

  const watermarkHeight = watermark?.height || 0;

  name = getSVGNode ? name + '-download' : name;
  height = svgHeight || height;
  width = svgWidth || width;

  const [plotData, maybeMultiTabData] = multiTypeData;
  const dataHasMultiTabs = Array.isArray(maybeMultiTabData);
  const data: LineData[] = dataHasMultiTabs
    ? (plotData as unknown as LineData[])
    : multiTypeData;

  if (data && (targetRef?.current || containerId) && height) {
    const fontSize = width >= 900 ? 12 : 8;
    const fontSizePadding = 2; // calculate width with text that is a bit bigger to provide some padding
    const fontFamily = 'Source Sans Pro';
    const padGuard = 5; // adds a bit of extra padding to prevent cutoff
    const chartBGFill = 'rgba(255,255,255, 0)';

    // remove old svg
    removeSVG([`.svg-${name}`, `.tooltip-${name}`]);

    // setup margins and inner dims
    // setup margins and inner dims; reduce margins for smaller charts
    if (hideAxis) {
      dims.margin = { top: 0, left: 0, bottom: 0, right: 0 };
    } else if (chartSize === 'small' || chartSize == 'medium') {
      dims.margin = { top: 30, left: 25, bottom: 16, right: 0 };
    } else {
      dims.margin = {
        top: 40,
        left: 25,
        bottom: width >= 900 ? 26 : 16,
        right: 0,
      };
    }

    //Override margins
    if (customMargins) {
      dims.margin = { ...dims.margin, ...customMargins };
    }

    dims.innerHeight = height - (dims.margin.top + dims.margin.bottom);
    dims.innerWidth = width - (dims.margin.left + dims.margin.right);

    // setup svg node
    const node = targetRef?.current || containerId;

    const svg = select(node).append('svg');
    svg
      .attr('width', svgWidth || '100%')
      .attr('height', svgHeight || '100%')
      .attr('class', `svg-${name}`);

    const chart = svg.append('g');

    //=============================================================================

    if (download) {
      const legendSet: Set<any> = new Set();
      const legendData: any[] = [];

      let appendOther = false;
      data.forEach((d: any) => {
        if (d.metadata.shortName.toLowerCase() === 'other') {
          appendOther = true;
        }
        legendSet.add(d.metadata.shortName);
        legendData.push({ key: d.metadata.shortName });
      });

      if (appendOther) {
        legendSet.add('Other');
      }

      const otherColor = '#A0A9B8';
      const legendColor =
        lineColor || scaleOrdinal().domain(legendSet).range(colors);

      const legendContainer = generateSVGLegend({
        chart: svg,
        width,
        height,
        dims,
        stackedData: legendData,
        color: legendColor,
        otherColor,
        fontColor: '#636d7e',
      });

      legendContainer.style('transform', `translate(20px, ${height - 40}px)`);

      adjustDownloadMargins(dims, {
        title,
        watermark,
        watermarkHeight,
        legendContainerHeight: legendContainer.node().getBBox().height,
        padding,
      });
    }

    const currentTabYValues = data
      .map((d) => d.value.map((d) => d.value))
      .flat();

    const yValuesConcatenated = [...currentTabYValues, ...otherTabData];

    const yScaleLowerBound = min(yValuesConcatenated);
    const yScaleUpperBound = max(yValuesConcatenated);

    let valueLength = 0;

    data.forEach((company) => {
      valueLength += company.value.length;
    });

    if (valueLength > 0) {
      // get chart padding for positioning tooltip

      const downloadContainer = document.querySelector(containerId);

      const compStyles = getComputedStyle(
        targetRef?.current || downloadContainer
      );

      const paddingLeft = parseFloat(
        compStyles.getPropertyValue('padding-left')
      );

      let allDatesSorted: any;
      // get all dates seen across all data for xScale domain, accounting for missing dates if necessary
      if (dateFormat === 'YM') {
        [, allDatesSorted] = getYMDates(data);
      } else if (dateFormat === 'YMD') {
        [, allDatesSorted] = getYMDDates(data);
      } else if (dateFormat === 'Y') {
        [, allDatesSorted] = getYDates(data);
      }

      const colorDomain = Object.keys(colorLookup || {});
      const colorRange = colorDomain?.map((key) => colorLookup[key]?.color);

      const color = scaleOrdinal().domain(colorDomain).range(colorRange);

      const yscaleConfig = scaleLinear()
        .domain([yScaleLowerBound, yScaleUpperBound])
        .range([dims.innerHeight, 0]);
      const yScale = hideAxis ? yscaleConfig : yscaleConfig.nice();

      const yAxisTickLabels = getYAxisTickLabels(chartSize, yScale);

      // adjust left margin to fit longest y tick label:
      if (!customMargins && !hideAxis) {
        dims.margin.left = calcMaxLeftLabelWidth(
          fontSize + fontSizePadding,
          fontFamily,
          padGuard,
          yAxisTickLabels,
          yAxisFormat
        );
      }

      //recalculate chart innerWidth & calculate x scale accordingly
      dims.innerWidth = width - (dims.margin.left + dims.margin.right);

      if (!hideAxis) {
        chart.attr(
          'transform',
          `translate(${dims.margin.left}, ${dims.margin.top})`
        );
      }

      const grid = chart
        .append('rect')
        .attr('width', dims.innerWidth)
        .attr('height', dims.innerHeight)
        .attr('x', 0)
        .attr('y', 0)
        .attr('cursor', 'pointer')
        .attr('fill', chartBGFill);

      const xScale = scaleTime()
        .domain([min(allDatesSorted) as any, max(allDatesSorted) as any])
        .range([0, dims.innerWidth]);

      const yAxisGrid = axisLeft()
        .scale(yScale)
        .tickSize(-dims.innerWidth)
        .tickValues(yAxisTickLabels)
        .tickFormat('');

      // place gridlines below chartlines
      const gridlines = hideAxis
        ? null
        : chart
            .append('g')
            .attr('class', 'y axis-grid')
            .attr('transform', `translate(0,0)`)
            .call(yAxisGrid)
            .call((g) => g.selectAll('.domain').remove());

      selectAll('.axis-grid line')
        .attr('stroke', '#e6e8ed')
        .attr('stroke-dasharray', '1,3')
        .attr('stroke-width', 1.3)
        .attr('stroke-linecap', 'round');

      let chartLines;

      if (dateFormat === 'YM' || dateFormat === 'Y') {
        chartLines = genYMChartLines(
          chart,
          data,
          color,
          xScale,
          yScale,
          metaValueCompany,
          colorLookupKeyPath,
          lineColor
        );
      } else if (dateFormat === 'YMD') {
        chartLines = genYMDChartLines(
          chart,
          data,
          color,
          xScale,
          yScale,
          metaValueCompany,
          colorLookupKeyPath,
          lineColor
        );
      }

      if (areaFillColor) {
        const minValue = Math.min(
          ...data.flatMap((d) => d.value.map((v) => v.value ?? 0))
        );

        // Define the gradient
        const gradientId = 'area-gradient';
        chart
          .append('defs')
          .append('linearGradient')
          .attr('id', gradientId)
          .attr('x1', '0%')
          .attr('y1', '0%')
          .attr('x2', '0%')
          .attr('y2', '100%')
          .append('stop')
          .attr('offset', '0%')
          .attr('stop-color', areaFillColor)
          .attr('stop-opacity', 0.6);

        chart
          .select(`#${gradientId}`)
          .append('stop')
          .attr('offset', '100%')
          .attr('stop-color', areaFillColor)
          .attr('stop-opacity', 0.1); // Fade to transparent

        const areaGenerator = (() => {
          if (dateFormat === 'YM' || dateFormat === 'Y') {
            return area<LineValue>()
              .x((d) => xScale(new Date(d.metadata.year, d.metadata.month - 1)))
              .y0(() => yScale(minValue)) // Set lower bound based on minValue
              .y1((d) => yScale(d?.value ?? 0))
              .curve(curveBasis); // Smoother curve for monthly or yearly data
          }

          return area<LineValue>()
            .x((d) =>
              xScale(
                new Date(d.metadata.year, d.metadata.month - 1, d.metadata.day)
              )
            )
            .y0(() => yScale(minValue)) // Set lower bound based on minValue
            .y1((d) => yScale(d?.value ?? 0))
            .curve(curveMonotoneX); // More accurate curve for daily data
        })();

        chart
          .append('path')
          .datum(data.flatMap((d) => d.value))
          .attr('fill', `url(#${gradientId})`) // Use the gradient fill
          .attr('stroke', 'none')
          .attr('d', areaGenerator)
          .style('pointer-events', 'none');
      }

      if (leftFade) {
        const fadeGradientId = 'left-fade-gradient';

        svg
          .append('defs')
          .append('linearGradient')
          .attr('id', fadeGradientId)
          .attr('x1', '0%')
          .attr('y1', '0%')
          .attr('x2', '100%')
          .attr('y2', '0%')
          .append('stop')
          .attr('offset', '0%')
          .attr('stop-color', 'rgba(255, 255, 255, 1)')
          .attr('stop-opacity', 1);

        svg
          .select(`#${fadeGradientId}`)
          .append('stop')
          .attr('offset', '100%')
          .attr('stop-color', 'rgba(255, 255, 255, 0)')
          .attr('stop-opacity', 0);

        chart
          .append('rect')
          .attr('width', 20)
          .attr('height', dims.innerHeight)
          .attr('x', 0)
          .attr('y', 0)
          .attr('fill', `url(#${fadeGradientId})`)
          .style('pointer-events', 'none');
      }

      const yAxisLeft = axisLeft()
        .scale(yScale)
        .tickValues(yAxisTickLabels) //show largest, smallest & middle y tick value
        .tickFormat((d: any) => d3Format(yAxisFormat)(d));

      // ---------------- X AXIS TICK LABELS -----------------------

      // determine x axis labels:
      // get min and max of allDatesSorted and determine how many months are between them
      // for small line chart we want 3 dates; for medium line chart we want 5 dates - evenly spaced between the min & max
      // the values we take will be the 1st of the month for each of these dates
      // formatting will depend on the length of time between the min & max (<=2 months = days; >2 months & <= 2 years = month + year; >2 years = years)

      const monthsDiff = monthDiff(
        min(allDatesSorted) as any,
        max(allDatesSorted) as any
      ); // difference between min & max date in months
      const minDate = min(allDatesSorted);
      const maxDate: any = max(allDatesSorted);

      let xAxisTickLabels;
      let xAxisTickFormat: any;
      let yearIndexes;

      if (monthsDiff > 24 || dateFormat === 'Y') {
        // if date range > 2 years, we will show tick labels in year format
        const yearIndexes = getYearIndexes(minDate, allDatesSorted);

        xAxisTickLabels = getXAxisTickLabelsOverTwoYears(
          minDate,
          allDatesSorted,
          chartSize,
          yearIndexes
        );
      } else if (monthsDiff <= 24 && monthsDiff >= 2) {
        // if date range is between 2 months and 2 years, we will show tick labels in the format 'Feb 2021' etc
        xAxisTickLabels = getXAxisTickLabelsTwoMonthsToTwoYears(
          minDate,
          monthsDiff,
          chartSize
        );

        xAxisTickFormat = xAxisTickLabels.map(
          (date) => `${months[date.getMonth()]} ${date?.getFullYear()}`
        );
      } else {
        // if date range < 2 months, we will show tick labels in the format 'Feb 4 2021'
        xAxisTickLabels = getXAxisTickLabelsLessThanTwoMonths(
          minDate,
          allDatesSorted,
          chartSize
        );

        xAxisTickFormat = xAxisTickLabels.map(
          (date) =>
            `${
              months[date.getMonth()]
            } ${date.getDate()} ${date?.getFullYear()}`
        );
      }

      const xAxisBottom = axisBottom()
        .scale(xScale)
        .tickValues(xAxisTickLabels);

      if (xAxisTickFormat) {
        xAxisBottom.tickFormat((d: any, i: any) => xAxisTickFormat[i]);
      }

      // --------------------------------------------------------------------

      chart
        .append('g')
        .attr('transform', `translate(5, 0)`)
        .attr('data-testid', 'plot-y-axis')
        .style('color', '#636d7e')
        .style('font-size', `${fontSize}px`)
        .call(yAxisLeft)
        .call((selection) => {
          if (hideAxis) {
            selection.selectAll('*').remove();
          }
        })
        .call((g) => g.selectAll('.domain').remove())
        .call((g) => g.selectAll('line').remove());
      chart
        .append('g')
        .attr('transform', `translate(0, ${dims.innerHeight})`)
        .attr('data-testid', 'plot-x-axis')
        .style('color', '#636d7e')
        .style('font-size', `${fontSize}px`)
        .call(xAxisBottom)
        .call((selection) => {
          if (hideAxis) {
            selection.selectAll('*').remove();
          }
        })
        .call((g) => g.selectAll('.domain').remove())
        .call((g) => g.selectAll('line').remove());

      // right align last x tick label if certain conditions are met
      chart.selectAll('.tick:last-of-type text').style(
        'text-anchor',
        (yearIndexes && maxDate.getMonth() < 4) || // if ticks are in years & maxDate is before April
          (maxDate - xAxisTickLabels.slice(-1)[0]) / (1000 * 60 * 60 * 24) <
            20 || // or ticks in month year & diff between maxDate & last tick label < 20 days
          chartSize === 'small' ||
          chartSize == 'medium' // always right-align last label on small plots
          ? 'end'
          : ''
      );

      const tooltipWidth = 240;
      const ttTitleHeight = 28;
      const ttYPadding = 24;
      const ttRowHeight = 21;
      const ttArrowLength = 9;

      const tooltip = select(`.react-node-${name}`)
        .append('div')
        .style('width', tooltipWidth + 'px')
        .style('opacity', 0)
        .style('pointer-events', 'none')
        .attr('hidden', true)
        .attr('data-testid', `linechart-tooltip`)
        .classed('tooltip-line-chart', true)
        .classed(`tooltip-${name}`, true);

      // Add the vertical line to the chart
      const tooltipLine = (chart as any)
        .append('line')
        .style('opacity', 0)
        .style('pointer-events', 'none')
        .attr('hidden', true)
        .attr('y1', dims.innerHeight)
        .attr('x1', 0)
        .attr('y2', 0)
        .attr('x2', 0)
        .attr('stroke', 'black')
        .attr('stroke-width', 1.5)
        .attr('stroke-dasharray', 3, 1);

      const mouseMove = createMouseMove(
        dims,
        xScale,
        yScale,
        {
          data,
          ttType,
          dateFormat,
          allDatesSorted,
          metaValueCompany,
          color,
          ttMainFormat,
          ttSecondaryFormat,
          colorLookupKeyPath,
        },
        {
          tooltip,
          tooltipLine,
          paddingLeft,
          ttArrowLength,
          ttYPadding,
          ttTitleHeight,
          ttRowHeight,
          tooltipWidth,
          ttCustomString,
          chartStyle,
          ttPosition,
        },
        { chartSize, chartPosition }
      );

      const mouseOut = () => {
        tooltip.style('opacity', 0).attr('hidden', true);
        tooltipLine.style('opacity', 0).attr('hidden', true);
      };

      grid.on('mousemove', mouseMove).on('mouseout', mouseOut);
      gridlines?.on('mousemove', mouseMove).on('mouseout', mouseOut);
      chartLines.on('mousemove', mouseMove).on('mouseout', mouseOut);

      // pop our maxValue we added to the data value arrays to show the tooltip with final date of the data
      if (dateFormat === 'YM' || dateFormat === 'Y') {
        data.forEach((d) => d.value.pop());
      }

      if (!download) {
        notifyChartRenderComplete(chart, requestHash, () => {
          globalLoader.next(false);
          isRenderingOrLoading?.next(false);
          setChartHasRendered?.(true);
        });
      }

      if (title) {
        appendTitle(chart, title, dims, padding);
      }

      if (watermark) {
        appendWatermark(
          chart,
          watermark,
          dims.innerWidth + dims.margin.right,
          dims.innerHeight + dims.margin.bottom,
          padding
        );
      }

      if (getSVGNode) {
        return svg.node();
      }
    }
  }
};
