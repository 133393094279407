import { useMemo } from 'react';

import styles from './axis-label.module.css';

type AxisLabelProps = {
  y: number;
  width: number;
  label: string;
};

export const AxisLabel = ({ y, width, label }: AxisLabelProps) => {
  // Check if the label is too long and truncate it if it is
  const truncatedLabel = useMemo(() => {
    const svgns = 'http://www.w3.org/2000/svg';

    const newText = document.createElementNS(svgns, 'text');
    newText.setAttribute('font-family', 'Source Sans Pro, sans-serif');
    newText.setAttribute('font-size', '12px');
    newText.setAttribute('text-anchor', 'end');
    newText.innerHTML = label;

    const svg = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
    document.body.appendChild(svg);
    svg.appendChild(newText);
    const labelWidth = newText.getBoundingClientRect().width;

    document.body.removeChild(svg);

    const maxLabelCharacters = Math.floor(width / 7);
    if (labelWidth > width) {
      return label.slice(0, maxLabelCharacters) + '...';
    }

    return label;
  }, [label, width]);

  return (
    <text y={y} x={width} textAnchor="end" dy="0.25em" className={styles.yAxisLabel}>
      {truncatedLabel}
      <title>{label}</title>
    </text>
  );
};
