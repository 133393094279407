// eslint-disable-next-line @nx/enforce-module-boundaries
import '../../../../../../libs/layout/src/lib/components/table/table.css';
import { DefaultIcon, itemTypeToComponent } from '@revelio/layout';
import {
  GET_SCREENER_DATA,
  ScreenerSortCol,
  ScreenerSorting,
  useScreenerFilter,
} from '@revelio/filtering';
import { Loading } from '@revelio/core';
import ReactDataGrid from '@inovua/reactdatagrid-community';
import '@inovua/reactdatagrid-community/base.css';
import '@inovua/reactdatagrid-community/theme/green-light.css';
import { TypeDataGridProps } from '@inovua/reactdatagrid-community/types';

import {
  Box,
  Flex,
  Image,
  Text,
  VStack,
  useDisclosure,
} from '@chakra-ui/react';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { createUltimatePagination } from 'react-ultimate-pagination';
import { useQuery } from 'urql';
import {
  TransformedScreenerEntityInfo,
  deserialiseScreenerData,
} from './utils/deserialiseScreenerData';
import {
  ColumnKey,
  formatHeader,
  getScreenerColumns,
} from './utils/getScreenerColumns';
import { GetScreenerDataQuery, ScreenerDimension } from '@revelio/data-access';
import styles from './screener-table.module.scss';
import { serialiseScreenerFilters } from './utils';
import { isEqual } from 'lodash';
import { ChevronDownIcon, ChevronUpIcon } from '@chakra-ui/icons';
import { CustomiseColumnsButton } from '../../../shared/components/customise-columns-button';
import { ColumnSelectModal } from './column-select-modal/column-select-modal';
import { ScreenerTablePopover } from './screener-table-popover/screener-table-popover';

interface ScreenerTableProps {
  view: ScreenerDimension;
  sortingDisabledColumns?: string[];
  sorting: ScreenerSorting | undefined;
  setSorting: (sorting: ScreenerSorting | undefined) => void;
  savedCheckedColumns: string[];
  setSavedCheckedColumns: React.Dispatch<React.SetStateAction<string[]>>;
}

export const LIMIT = 35;

type GetScreenerDataQueryWithSorting = GetScreenerDataQuery & {
  sorting?: ScreenerSorting;
};

export const ScreenerTable = ({
  view,
  sortingDisabledColumns,
  sorting,
  setSorting,
  savedCheckedColumns,
  setSavedCheckedColumns,
}: ScreenerTableProps) => {
  const { state } = useScreenerFilter();

  const filters = useMemo(
    () => serialiseScreenerFilters(state.filters, view),
    [state.filters, view]
  );

  const [page, setPage] = useState<number>(1);

  const [prevFilters, setPrevFilters] = useState(filters);
  useEffect(() => {
    if (!isEqual(prevFilters, filters)) {
      setPage(1);
      setPrevFilters(filters);
    }
  }, [filters, prevFilters]);

  const filterCount = useMemo(
    () =>
      (Object.keys(filters?.segments)?.length || 0) +
      (Object.keys(filters?.primary_selector || {}).length || 0) +
      (Object.keys(filters?.entity_info_selectors?.company_info_selectors || {})
        .length || 0),
    [filters]
  );

  const handleSort = useCallback(
    (column: { key: string; columnNumber: number }) => {
      const newSortBy =
        ScreenerSortCol[
          `SORT_BY_${column.key.toUpperCase()}` as keyof typeof ScreenerSortCol
        ];

      const ascending = (() => {
        if (
          !sorting ||
          sorting.sort_by !== newSortBy ||
          sorting.num_col !== column.columnNumber
        ) {
          return false; // Start with descending if no sorting or sorting by a different column
        }

        if (sorting.ascending === false) {
          return true; // Move to ascending
        }

        return undefined; // Move to neutral
      })();

      if (ascending === undefined) {
        setSorting(undefined);
        return;
      }

      setSorting({
        sort_by: newSortBy,
        num_col: column.columnNumber,
        ascending,
      });
    },
    [sorting, setSorting]
  );

  const [{ data: screenerData, fetching: loading, error }] =
    useQuery<GetScreenerDataQueryWithSorting>({
      query: GET_SCREENER_DATA,
      variables: {
        filters: {
          ...filters,
          pagination: { offset: (page - 1) * LIMIT, limit: LIMIT },
          sorting,
        },
      },
      pause: !filterCount,
    });

  const data = screenerData?.screener;
  const parsedData = useMemo(
    () => deserialiseScreenerData(data?.entities || [], filters, state),
    [data, filters, state]
  );

  const columns = useMemo(
    () =>
      getScreenerColumns(parsedData, view).map((column) => {
        const isSortingDisabled = sortingDisabledColumns?.some((disabledCol) =>
          (column?.header || '').includes(disabledCol)
        );

        const sortChevron = (() => {
          if (
            !(
              sorting?.sort_by ===
                ScreenerSortCol[
                  `SORT_BY_${column.key.toUpperCase()}` as keyof typeof ScreenerSortCol
                ] && sorting?.num_col === column.columnNumber
            )
          ) {
            return (
              <Flex
                direction="column"
                alignItems="center"
                justifyContent="center"
              >
                <ChevronUpIcon marginBottom="-3px" />
                <ChevronDownIcon marginTop="-3px" />
              </Flex>
            );
          }

          return sorting.ascending ? <ChevronUpIcon /> : <ChevronDownIcon />;
        })();

        const sortProps = {
          onClick: () => handleSort(column),
          cursor: 'pointer',
        };

        return {
          ...column,
          sortable: false,
          columnNumber: column.columnNumber,
          headerText: column.header,
          header: (
            <Text
              title={column.header}
              width="100%"
              display="flex"
              alignItems="center"
              {...(isSortingDisabled ? {} : sortProps)}
            >
              <Box
                overflow="hidden"
                whiteSpace="nowrap"
                textOverflow="ellipsis"
                display="block"
              >
                {column.header}
              </Box>
              {!isSortingDisabled && sortChevron && (
                <Box ml={2}>{sortChevron}</Box>
              )}
            </Text>
          ),
        };
      }),
    [parsedData, view, sorting, handleSort, sortingDisabledColumns]
  );

  const prevFiltersRef = useRef(filters);
  useEffect(() => {
    const { sorting: currentSorting, ...currentFiltersWithoutSorting } =
      filters;
    const { sorting: prevSorting, ...prevFiltersWithoutSorting } =
      prevFiltersRef.current || {};

    if (
      !isEqual(currentFiltersWithoutSorting, prevFiltersWithoutSorting) &&
      (sorting?.num_col || 0) > 0
    ) {
      setSorting(undefined);
    }

    prevFiltersRef.current = filters;
  }, [filters, sorting, setSorting]);

  const Wrapper = useCallback((props: { children: JSX.Element }) => {
    return <Box>{props.children}</Box>;
  }, []);

  const UltimatePagination = useMemo(
    () =>
      createUltimatePagination({
        itemTypeToComponent: itemTypeToComponent(),
        WrapperComponent: Wrapper,
      }),
    [Wrapper]
  );

  const renderPaginationToolbar = useCallback(() => {
    const totalPages =
      Math.floor((data?.pagination?.total_results || 0) / LIMIT) || 0;

    const changePage = (e: number) => {
      setPage(e);
    };

    return (
      <Flex alignItems="center" h="50px" w="100%" justifyContent="center">
        <Box p={2}>
          {totalPages > 0 && (
            <UltimatePagination
              currentPage={page}
              totalPages={totalPages}
              boundaryPagesRange={1}
              siblingPagesRange={1}
              hidePreviousAndNextPageLinks={false}
              hideFirstAndLastPageLinks={true}
              hideEllipsis={false}
              onChange={changePage}
            />
          )}
        </Box>
      </Flex>
    );
  }, [data, page, UltimatePagination]);

  const [filteredColumns, setFilteredColumns] = useState(columns);

  const [userDeselectedColumns, setUserDeselectedColumns] = useState<string[]>(
    []
  );
  const [autoAddedColumns, setAutoAddedColumns] = useState<string[]>([]);

  useEffect(() => {
    const companyInfoSelectors = Object.keys(
      filters.entity_info_selectors?.company_info_selectors || {}
    ).map((selector) => {
      if (selector.includes('founder')) {
        if (['male', 'female'].includes(selector.split('_')[1])) {
          return 'Founder Gender';
        }
        return 'Founder Ethnicity';
      }

      return formatHeader(selector as ColumnKey, view) || '';
    });

    const columnHeaders = columns.map((column) => column.headerText);

    const newSelectorsToAdd = companyInfoSelectors.filter(
      (selector) =>
        columnHeaders.includes(selector) &&
        !userDeselectedColumns.includes(selector) &&
        !savedCheckedColumns.includes(selector)
    );

    if (newSelectorsToAdd.length > 0) {
      setAutoAddedColumns((prev) => [...prev, ...newSelectorsToAdd]);
      setSavedCheckedColumns((prev) => [...prev, ...newSelectorsToAdd]);
    }
  }, [
    filters,
    columns,
    userDeselectedColumns,
    setSavedCheckedColumns,
    savedCheckedColumns,
    view,
  ]);

  useEffect(() => {
    const newColumnsWithParentheses = columns
      .map((column) => column.headerText)
      .filter(
        (headerText): headerText is string =>
          typeof headerText === 'string' &&
          headerText.includes('(') &&
          !headerText.includes('All Employees') &&
          !headerText.includes('(RCID)')
      );

    const newColumnsToAdd = newColumnsWithParentheses.filter(
      (headerText) =>
        !autoAddedColumns.includes(headerText) &&
        !userDeselectedColumns.includes(headerText) &&
        !savedCheckedColumns.includes(headerText)
    );

    if (newColumnsToAdd.length > 0) {
      setAutoAddedColumns((prev) => [...prev, ...newColumnsToAdd]);
      setSavedCheckedColumns((prevSavedCheckedColumns) => [
        ...prevSavedCheckedColumns,
        ...newColumnsToAdd,
      ]);
    }
  }, [
    columns,
    autoAddedColumns,
    userDeselectedColumns,
    savedCheckedColumns,
    setSavedCheckedColumns,
  ]);

  useEffect(() => {
    const filteredCols = columns.filter(
      (column) =>
        column?.headerText && savedCheckedColumns.includes(column.headerText)
    );

    setFilteredColumns(filteredCols);
  }, [savedCheckedColumns, columns]);

  const { isOpen, onOpen, onClose } = useDisclosure();

  const handleSaveColumns = (checkedColumns: string[]) => {
    const deselectedColumns = autoAddedColumns.filter(
      (column) => !checkedColumns.includes(column)
    );

    setUserDeselectedColumns((prev) => [...prev, ...deselectedColumns]);

    setSavedCheckedColumns(checkedColumns);
  };

  const shouldShowCustomiseColumnsButton = !!parsedData?.length && !loading;

  const onRenderRow = (row: {
    children: JSX.Element[];
    availableWidth: number;
    data: TransformedScreenerEntityInfo;
    onMouseEnter: () => void;
    onMouseLeave: () => void;
  }) => {
    if (view === ScreenerDimension.Company) {
      row.children = [
        <ScreenerTablePopover data={row.data}>
          {row.children}
        </ScreenerTablePopover>,
      ];
    }
  };

  return (
    <Box h="100%">
      {shouldShowCustomiseColumnsButton && (
        <CustomiseColumnsButton onClick={onOpen} right={4} />
      )}
      <ColumnSelectModal
        isOpen={isOpen}
        onClose={onClose}
        columns={columns.map((column) => ({
          value: column.headerText,
          label: column.headerText,
        }))}
        setSavedCheckedColumns={setSavedCheckedColumns}
        savedCheckedColumns={savedCheckedColumns}
        initialCheckedColumn={savedCheckedColumns}
        onSave={handleSaveColumns}
      />
      {!filterCount ? (
        <Flex
          h="100%"
          direction="column"
          justifyContent="center"
          alignItems="center"
        >
          <VStack>
            <Image as={DefaultIcon} alt="default placeholder icon" />
            <Text>Add a filter to view data.</Text>
          </VStack>
        </Flex>
      ) : (
        !error &&
        parsedData &&
        columns && (
          <ReactDataGrid
            className={styles['table']}
            loading={loading}
            showCellBorders="vertical"
            columns={filteredColumns}
            dataSource={parsedData}
            rowHeight={30}
            renderLoadMask={LoadMask}
            renderPaginationToolbar={renderPaginationToolbar}
            pagination
            emptyText="No Results"
            theme="green-light"
            showColumnMenuTool={false}
            nativeScroll
            {...{ onRenderRow }}
          />
        )
      )}
    </Box>
  );
};

const LoadMask: TypeDataGridProps['renderLoadMask'] = ({ visible }) => {
  return visible ? (
    <Flex
      borderRadius="10px"
      height="full"
      position="relative"
      backgroundColor="white"
      data-testid="page-data-loading"
    >
      <Loading></Loading>
    </Flex>
  ) : null;
};
