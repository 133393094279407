import {
  area,
  bisector,
  curveBasis,
  format as d3Format,
  extent,
  group,
  line,
  scaleLinear,
  scaleTime,
} from 'd3';
import { isDate, isNumber } from 'lodash';
import { useRef, useState } from 'react';

import { CommonPlotProps, FormatType } from '../../types';
import {
  RenderCheck,
  getFormatter,
  useRenderCheck,
  useResizeObserver,
} from '../../utilities';
import { PRIMARY_COLOR, plotColors } from '../../utilities/plot-colors';
import { PlotLoader } from '../plot-loader/plot-loader';
import {
  PlotTooltip,
  TooltipHoverLine,
  useTooltipController,
} from '../plot-tooltip';
import styles from './line-chart.module.css';
import { LineTooltip, LineTooltipProps } from './line-tooltip';

export type LineDatum = {
  date: Date;
  value: number;
  secondaryValue?: number;
};

export type LineData = {
  label: string;
  values: LineDatum[];
};

type LineChartProps = {
  data: LineData[];
  colors?: string[];
  showXAxis?: boolean;
  showYAxis?: boolean;
  showGridLines?: boolean;
  fill?: boolean;
  transitionDuration?: number;
} & CommonPlotProps;

const Y_AXIS_WIDTH = 32;
const X_AXIS_HEIGHT = 12;
const CHART_PADDING_TOP = 8;

const bisect = bisector<Date, Date>((d) => d).center;

export const LineChart = ({
  data,
  colors = plotColors,
  format = FormatType.SI,
  showXAxis = true,
  showYAxis = true,
  showGridLines = true,
  fill = false,
  loading = false,
  renderUpdate,
  // transitionDuration = 1000,
}: LineChartProps & RenderCheck) => {
  const { containerRef, width, height } = useResizeObserver();

  const yAxisWidth = showYAxis ? Y_AXIS_WIDTH : 0;
  const xAxisHeight = showXAxis ? X_AXIS_HEIGHT : 0;

  const plotWidth = width - yAxisWidth;
  const plotHeight = height - xAxisHeight - CHART_PADDING_TOP;

  const isPlotSizeValid = plotWidth > 0 && plotHeight > 0;

  /** ================================
   * X Axis
   ================================ */
  const groupedData = group(
    data.flatMap((d) => d.values.map((v) => ({ ...v, label: d.label }))),
    (d) => d.date
  );

  const allDates = Array.from(groupedData.keys());
  const dateDomain = extent(allDates);
  const xScale =
    isDate(dateDomain[0]) && isDate(dateDomain[1])
      ? scaleTime().domain([dateDomain[0], dateDomain[1]]).range([0, plotWidth])
      : null;

  const xTicks = xScale
    ?.ticks(5)
    .map((tick) => ({ value: tick, label: xScale?.tickFormat()(tick) }));

  /** ================================
   * Y Axis
   ================================ */
  const allValues = data.flatMap((d) => d.values.map((v) => v.value));
  const domain = extent(allValues);
  const yScale =
    isNumber(domain[0]) && isNumber(domain[1])
      ? scaleLinear().domain([domain[0], domain[1]]).range([plotHeight, 0])
      : null;

  const formatValue = getFormatter(format);

  const yTicks = yScale
    ?.ticks(3)
    .map((tick) => ({ value: tick, label: formatValue(tick) }));

  /** ================================
   * Line Path
   ================================ */
  const lineGenerator =
    xScale && yScale
      ? line<LineDatum>()
          .x((d) => xScale(d.date))
          .y((d) => yScale(d.value))
          .curve(curveBasis)
      : null;

  const lines = data
    .map((d) => {
      if (!lineGenerator) return null;

      const path = lineGenerator(d.values);
      if (!path) return null;

      return { ...d, path };
    })
    .filter((d) => !!d)
    .map((d, i) => ({ ...d, color: colors[i] || PRIMARY_COLOR }));

  const areaGenerator =
    fill && xScale && yScale
      ? area<LineDatum>()
          .x((d) => xScale(d.date))
          .y0(yScale.range()[0])
          .y1((d) => yScale(d.value))
          .curve(curveBasis)
      : undefined;

  const areas = data
    .map((d, i) => {
      if (!areaGenerator) return null;

      const path = areaGenerator(d.values);
      if (!path) return null;

      return { ...d, path };
    })
    .filter((d) => !!d)
    .map((d, i) => ({ ...d, color: colors[i] || PRIMARY_COLOR }));

  /** ================================
   * Tooltip
   ================================ */
  const tooltipFormatValue = getFormatter(format, { fullPrecision: true });
  const [tooltipRows, setTooltipRows] = useState<LineTooltipProps | null>(null);
  const [tooltipPosition, setTooltipPosition] = useState({ x: 0, y: 0 });
  const handleTooltipMove = (position: [number, number]) => {
    if (!xScale) return;

    const date = xScale.invert(position[0]);
    const index = bisect(allDates, date);
    const bisectDate = allDates[index];

    const data = groupedData.get(bisectDate);
    if (!data) return;

    setTooltipRows({
      title: `${bisectDate?.toLocaleString('default', { month: 'long' })} ${bisectDate?.getFullYear()}`,
      rows: data.map((d, i) => ({
        ...(data.length > 1
          ? { color: colors[i] || PRIMARY_COLOR }
          : { color: '' }),
        label: d.label,
        value: `${tooltipFormatValue(d.value)}${d.secondaryValue ? ` / ${d3Format(',.0f')(d.secondaryValue)}` : ''}`,
      })),
    });

    setTooltipPosition({ x: position[0], y: plotHeight / 2 });
  };

  const tooltipController = useTooltipController({
    onHover: handleTooltipMove,
  });

  const chartRef = useRef<SVGGElement>(null);
  useRenderCheck(chartRef, { renderUpdate });

  return (
    <div ref={containerRef} className={styles['container']}>
      <svg width="100%" height="100%">
        <defs>
          <linearGradient
            id={`line-area-gradient-${colors[0]}`}
            x1="0%"
            y1="0%"
            x2="0%"
            y2="100%"
          >
            <stop offset="0%" stopColor={colors[0]} stopOpacity="0.6"></stop>
            <stop offset="100%" stopColor={colors[0]} stopOpacity="0.1"></stop>
          </linearGradient>
          <linearGradient
            id="line-left-fade-gradient"
            x1="0%"
            y1="0%"
            x2="100%"
            y2="0%"
          >
            <stop offset="0%" stopColor="rgba(255, 255, 255, .9)"></stop>
            <stop offset="100%" stopColor="rgba(255, 255, 255, 0)"></stop>
          </linearGradient>
        </defs>
        <g transform={`translate(0, ${CHART_PADDING_TOP})`}>
          {showYAxis && (
            <g id="y-axis" data-testid="y-axis" className={styles['yAxis']}>
              {isPlotSizeValid &&
                yTicks?.map((tick, i) => (
                  <text
                    key={i}
                    x={yAxisWidth / 2}
                    y={yScale?.(tick.value)}
                    alignmentBaseline="middle"
                  >
                    {tick.label}
                  </text>
                ))}
            </g>
          )}
          {showXAxis && (
            <g
              id="x-axis"
              data-testid="x-axis"
              transform={`translate(${yAxisWidth}, ${plotHeight})`}
              className={styles['xAxis']}
            >
              {isPlotSizeValid &&
                xTicks?.map((tick, i) => (
                  <text
                    key={i}
                    transform={`translate(${xScale?.(tick.value)}, 0)`}
                    y={9}
                  >
                    {tick.label}
                  </text>
                ))}
            </g>
          )}
          <g
            id="chart"
            ref={chartRef}
            transform={`translate(${yAxisWidth}, 0)`}
            {...tooltipController.gProps}
            className={styles.chart}
          >
            {showGridLines && (
              <g id="axis-grid">
                {yScale &&
                  yTicks?.map((tick, i) => (
                    <line
                      key={i}
                      x1={0}
                      x2={plotWidth}
                      y1={yScale(tick.value)}
                      y2={yScale(tick.value)}
                      className={styles['axisGridLine']}
                    />
                  ))}
              </g>
            )}
            {lines.map((line, i) => (
              <path
                key={i}
                d={line.path}
                stroke={line.color}
                className={styles.line}
              />
            ))}
            {areas.map((area, i) => (
              <path
                key={i}
                d={area.path}
                className={styles.area}
                fill={`url(#line-area-gradient-${area.color})`}
              />
            ))}
            {fill && isPlotSizeValid && (
              <rect
                x={-1}
                width={plotWidth + 1}
                height={plotHeight + 1}
                fill="url(#line-left-fade-gradient)"
              />
            )}
            {tooltipController.isVisible && (
              <TooltipHoverLine x={tooltipPosition.x} height={plotHeight} />
            )}
          </g>
        </g>
      </svg>
      {loading && <PlotLoader />}
      <PlotTooltip
        isVisible={tooltipController.isVisible}
        x={tooltipPosition.x}
        y={tooltipPosition.y}
      >
        {tooltipRows && (
          <LineTooltip title={tooltipRows.title} rows={tooltipRows.rows} />
        )}
      </PlotTooltip>
    </div>
  );
};
