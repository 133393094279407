import { getActiveIds } from '@ngneat/elf-entities';
import { useGetLoggedInUser, useIsRevelioAdmin } from '@revelio/auth';
import {
  CustomRoleTaxonomySelection,
  CustomTaxonomyEnum,
} from '@revelio/data-access';
import { get, includes, isEqual } from 'lodash';
import { useEffect, useMemo, useState } from 'react';

import { parse } from 'query-string';
import { useSearchParams } from 'react-router-dom';
import { ViewTypes } from '../../data-api/data-api.model';
import { ROLE_GRANULARITY_FILTERS } from '../filters.constants';
import { filterStore } from '../filters.core';
import { SearchParamFilters } from '../filters.deepLinks';
import {
  FilterItem,
  FilterList,
  OtherFilterNames,
  SelectFilter,
  SelectionCategories,
  SubFilterNames,
} from '../filters.model';
import {
  addActiveFiltersIds,
  clearRoleCacheFilters,
  deleteFilters,
  upsertFilter,
  upsertFiltersWithProvidedValue,
  useAllFiltersState,
} from '../filters.repository';
import { getAdaptiveRoleTaxonomyId } from './get-adaptive-role-taxonomy-id';

export type RoleTaxonomySettingOption = {
  label: string;
  value: CustomTaxonomyEnum;
};

const CUSTOM_ROLE_TAXONOMY_STORAGE_KEY = 'custom_role_taxonomy';

export const CustomTaxonomyOptions: {
  label: string;
  value: CustomTaxonomyEnum;
}[] = [
  { label: 'Dashboard Default', value: CustomTaxonomyEnum.Undefined },
  { label: 'Disabled', value: CustomTaxonomyEnum.Disabled },
  { label: 'Generic', value: CustomTaxonomyEnum.Generic },
  { label: 'Adaptive', value: CustomTaxonomyEnum.Adaptive },
];
const CustomTaxonomyPersonalOverrideOptions = CustomTaxonomyOptions.map((o) =>
  o.value === CustomTaxonomyEnum.Undefined
    ? { label: 'No Override', value: CustomTaxonomyEnum.Undefined }
    : o
);
export const defaultCustomTaxonomyOption = CustomTaxonomyOptions[3];

export const useRoleTaxonomySetting = () => {
  const { isRevelioAdmin, fetching } = useIsRevelioAdmin();
  const [selectedOverrideOption, setSelectedOverrideOption] =
    useState<RoleTaxonomySettingOption>(
      getRoleTaxonomyOverrideStorage() ?? defaultCustomTaxonomyOption
    );
  const { loggedInUser } = useGetLoggedInUser();

  const canOverrideValue = useMemo(
    () => isRevelioAdmin || loggedInUser?.email === 'isar.gangwani@gmail.com',
    [isRevelioAdmin, loggedInUser?.email]
  );

  const value = useMemo(() => {
    const roleTaxonomyValue = (() => {
      if (
        canOverrideValue &&
        selectedOverrideOption.value !== CustomTaxonomyEnum.Undefined
      ) {
        return selectedOverrideOption.value;
      } else {
        return loggedInUser.custom_taxonomy || CustomTaxonomyEnum.Undefined;
      }
    })();

    // If value is undefined set its value based on the default
    if (roleTaxonomyValue === CustomTaxonomyEnum.Undefined) {
      return CustomTaxonomyEnum.Adaptive;
    }
    return roleTaxonomyValue;
  }, [canOverrideValue, selectedOverrideOption, loggedInUser]);

  useEffect(() => {
    if (fetching) return;
    const roleTaxonomyOption = getRoleTaxonomyOverrideStorage();

    if (
      !canOverrideValue &&
      roleTaxonomyOption?.value !== defaultCustomTaxonomyOption.value
    ) {
      clearRoleCacheFilters();
      deleteFilters([...ROLE_GRANULARITY_FILTERS, SubFilterNames.SUB_ROLE]);
      setRoleTaxonomyOverrideStorage(defaultCustomTaxonomyOption);
    }

    if (canOverrideValue && !roleTaxonomyOption) {
      setRoleTaxonomyOverrideStorage(defaultCustomTaxonomyOption);
    }
  }, [canOverrideValue, fetching]);

  // Logging Taxonomy Settings to assist in debugging issues while we transition to new endpoint
  useEffect(() => {
    logSetting({
      canOverrideValue,
      value,
    });
  }, [value, canOverrideValue]);

  return {
    canOverrideValue,
    isEnabled: value !== CustomTaxonomyEnum.Disabled,
    value,
    personalOverrideOptionValue: CustomTaxonomyPersonalOverrideOptions.find(
      (o) => o.value === selectedOverrideOption.value
    ),
    personalOverrideOptions: CustomTaxonomyPersonalOverrideOptions,
    setPersonalOverrideOption: (option: RoleTaxonomySettingOption) => {
      setRoleTaxonomyOverrideStorage(option);
      setSelectedOverrideOption(option);
      clearRoleCacheFilters();
    },
  };
};

const getRoleTaxonomyOverrideStorage = () => {
  const storedValue = localStorage.getItem(CUSTOM_ROLE_TAXONOMY_STORAGE_KEY);
  if (!storedValue) return null;
  return (
    CustomTaxonomyOptions.find((o) => o.value === storedValue) ||
    defaultCustomTaxonomyOption
  );
};

const setRoleTaxonomyOverrideStorage = (option: RoleTaxonomySettingOption) => {
  localStorage.setItem(CUSTOM_ROLE_TAXONOMY_STORAGE_KEY, option.value);
};

const logSetting = (() => {
  let lastLog = '';

  return (value: Record<string, any>) => {
    const currentLog = JSON.stringify(value);
    if (currentLog !== lastLog) {
      lastLog = currentLog;
    }
  };
})();

export const useAdaptiveRoleTaxonomy = ({
  viewType,
  primaryFilters,
}: {
  viewType: ViewTypes;
  primaryFilters: SelectionCategories[];
}) => {
  const { isEnabled: isCustomRoleTaxonomyEnabled, value } =
    useRoleTaxonomySetting();
  const [searchParams] = useSearchParams();

  // Add role taxonomy to active ids if it is not already there
  useEffect(() => {
    const activeIds = filterStore.query(getActiveIds);

    if (
      isCustomRoleTaxonomyEnabled &&
      !includes(activeIds, OtherFilterNames.ROLE_TAXONOMY) &&
      viewType === ViewTypes.COMPANY
    ) {
      addActiveFiltersIds([OtherFilterNames.ROLE_TAXONOMY]);
    }
  }, [isCustomRoleTaxonomyEnabled, viewType]);

  const allFilterState = useAllFiltersState();
  const filters = useMemo(
    () =>
      allFilterState.filter(
        (filter) =>
          filter.id === OtherFilterNames.ROLE_TAXONOMY ||
          primaryFilters.includes(filter.id as SelectionCategories) ||
          ROLE_GRANULARITY_FILTERS.includes(filter.id as SelectionCategories)
      ),
    [allFilterState, primaryFilters]
  );

  useEffect(() => {
    if (viewType !== ViewTypes.COMPANY || !isCustomRoleTaxonomyEnabled) return;

    const customTaxonomyFilter = filters.find(
      (filter) => filter.id === OtherFilterNames.ROLE_TAXONOMY
    );
    const deepLinkFilters = parse(searchParams?.toString(), {
      arrayFormat: 'comma',
    }) as SearchParamFilters;
    const roleTaxonomyDeepLink = get(
      deepLinkFilters,
      OtherFilterNames.ROLE_TAXONOMY,
      false
    );

    if (!customTaxonomyFilter && !roleTaxonomyDeepLink) {
      upsertFiltersWithProvidedValue(
        {
          [OtherFilterNames.ROLE_TAXONOMY]: {
            value: {
              default: true,
            },
          },
        },
        true
      );
    }

    if (viewType === ViewTypes.COMPANY && isCustomRoleTaxonomyEnabled) {
      const alreadyUsingRoleTaxonomy = (
        filters as SelectFilter<FilterList>[]
      ).filter((item) => includes(ROLE_GRANULARITY_FILTERS, item.id));
      if (alreadyUsingRoleTaxonomy.length) {
        return;
      }

      const usingAdaptiveRoleTaxonomy = value === CustomTaxonomyEnum.Adaptive;
      const currentCustomTaxonomyFilter = (
        filters as SelectFilter<FilterItem>[]
      ).find((item) => item.id === OtherFilterNames.ROLE_TAXONOMY);
      const activePrimaryFilters = (
        filters as SelectFilter<FilterList>[]
      ).filter((item) =>
        includes(
          [SelectionCategories.COMPANY, SelectionCategories.INDUSTRY],
          item.id
        )
      );

      if (usingAdaptiveRoleTaxonomy && activePrimaryFilters.length) {
        const calculatedCustomRoleAdaptiveTaxonomy =
          getAdaptiveRoleTaxonomyId(activePrimaryFilters);
        if (
          !isEqual(
            currentCustomTaxonomyFilter?.value,
            calculatedCustomRoleAdaptiveTaxonomy
          )
        ) {
          clearRoleCacheFilters();
          upsertFilter(OtherFilterNames.ROLE_TAXONOMY, {
            value: calculatedCustomRoleAdaptiveTaxonomy,
          });
        }
      } else if (
        !(currentCustomTaxonomyFilter?.value as CustomRoleTaxonomySelection)
          ?.default &&
        value === CustomTaxonomyEnum.Generic
      ) {
        clearRoleCacheFilters();
        upsertFilter(OtherFilterNames.ROLE_TAXONOMY, {
          value: { default: true },
        });
      }
    }
  }, [
    viewType,
    isCustomRoleTaxonomyEnabled,
    value,
    searchParams,
    primaryFilters,
    filters,
  ]);

  return {
    isActive: isCustomRoleTaxonomyEnabled && viewType === ViewTypes.COMPANY,
    value,
  };
};
