import { MutableRefObject, useEffect, useRef, useState } from 'react';
import { isEmpty } from 'lodash';
import { Outlet, useNavigate } from 'react-router';
import {
  Grid,
  GridItem,
  Spacer,
  VStack,
  Box,
  Link,
  Tooltip,
  chakra,
  useStyleConfig,
  Flex,
} from '@chakra-ui/react';
import { NavLink } from 'react-router-dom';
import { useEffect$ } from '@ngneat/react-rxjs';
import { catchError, distinct, tap } from 'rxjs';

import { getIsTour, tourStore } from '@revelio/core';
import { TourClasses } from '@revelio/core';
import {
  filterNavItemsForUser,
  NavItemConfig,
  RouteNavConfig,
} from '@revelio/auth';

import { Logo } from './Logo';
import { AccountMenu } from './menus/account-menu';
import { useEnforceUserIntroduction } from './useEnforceUserIntroduction';
import SubNav from './sub-nav/sub-nav';
import { MethodologyMenu } from './menus/methodology-menu';

export interface WithSpotlightWrapperProps {
  condition: boolean;
  children: JSX.Element;
}

const WithSpotlightWrapper = ({
  condition,
  children,
}: WithSpotlightWrapperProps) => {
  return condition ? (
    <Flex
      className={TourClasses.TOUR_CONTROL_PANEL_CLASS}
      direction="column"
      alignItems="center"
      justifyContent="flex-start"
      w="100%"
      rowGap="5px"
    >
      {children}
    </Flex>
  ) : (
    children
  );
};
export interface SideNavProps {
  items: (NavItemConfig | RouteNavConfig)[];
  version?: string;
}

export function SideNav(props: SideNavProps) {
  const linkStyles = useStyleConfig('Link', { variant: 'nav' });

  const [{ pagesBeforeDivider, pagesAfterDivider }, setPages] = useState<
    Record<string, RouteNavConfig[]>
  >({
    pagesBeforeDivider: [],
    pagesAfterDivider: [],
  });

  const { loggedInUser, fetching } = useEnforceUserIntroduction();

  const [isTour, setIsTour] = useState(tourStore.query(getIsTour));

  useEffect$(() =>
    tourStore.pipe(
      distinct(),
      tap((data) => {
        setIsTour(data.isTour);
      }),
      catchError((e) => {
        return e;
      })
    )
  );

  useEffect(() => {
    if (fetching || isEmpty(loggedInUser)) {
      return;
    }

    const userTabs = loggedInUser.tabs || [];
    const userRole = loggedInUser.role;

    const pages = filterNavItemsForUser(props.items, userTabs, userRole).filter(
      (page) => !page.excludeFromSideNavButtons
    );

    const pagesBeforeDivider = pages.filter((page) => page.isSourcesTab);
    const pagesAfterDivider = pages.filter((page) => !page.isSourcesTab);

    setPages({ pagesBeforeDivider, pagesAfterDivider });
  }, [fetching, loggedInUser, props.items]);

  const sideNavRef = useRef<HTMLDivElement>(null);

  const renderPageTabs = (pages: RouteNavConfig[]) => {
    return pages.map((item) =>
      /* eslint-disable-next-line no-nested-ternary */
      !item.children ? (
        /* eslint-disable-next-line no-nested-ternary */
        item.element ? (
          <Link
            key={item.name}
            className="nav"
            as={NavLink}
            to={item.path || ''}
            bgImg={`url(${item.icon})`}
            variant="nav"
          />
        ) : item.elementId && !item.path ? (
          // this is when the button is controlled by something external (e.g. jira script in html script)
          <Tooltip hasArrow placement="right" key={item.name} label={item.name}>
            <chakra.a
              key={item.name}
              sx={linkStyles}
              className="nav"
              id={item.elementId}
              bgImg={`url(${item.icon})`}
            ></chakra.a>
          </Tooltip>
        ) : (
          <chakra.a
            key={item.name}
            sx={linkStyles}
            className="nav"
            href={item.path}
            bgImg={`url(${item.icon})`}
          ></chakra.a>
        )
      ) : (
        <SubNav
          key={item.name}
          parent={item}
          items={item.children as RouteNavConfig[]}
          sideNavRef={sideNavRef as MutableRefObject<Element>}
        />
      )
    );
  };

  const navigate = useNavigate();

  return (
    <Grid templateRows="1" templateColumns="54px 1fr">
      <GridItem
        w="54px"
        className="rl-sidenav"
        ref={sideNavRef}
        position="relative"
      >
        <VStack
          align="center"
          height="100vh"
          backgroundColor="navyBlue.500"
          padding="14px 8px"
          spacing={5}
        >
          <Box
            height="60px"
            width="44px"
            paddingEnd="14px"
            transform="scale(0.9)"
            onClick={() => navigate('/')}
            cursor="pointer"
            transition="opacity 0.1s ease-in-out"
            _hover={{ opacity: 0.7 }}
          >
            <Logo />
          </Box>

          <WithSpotlightWrapper condition={isTour}>
            <>{renderPageTabs(pagesBeforeDivider)}</>
          </WithSpotlightWrapper>

          {renderPageTabs(pagesAfterDivider)}
          <Spacer />
          <MethodologyMenu />
          <AccountMenu version={`v${props.version}`} />
        </VStack>
      </GridItem>
      <GridItem>
        <Outlet></Outlet>
      </GridItem>
    </Grid>
  );
}

export default SideNav;
