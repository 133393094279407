import { useMemo } from 'react';

import styles from './bar-label.module.css';

type BarLabelProps = {
  x: number;
  y: number;
  width: number;
  height: number;
  label: string;
};

export const BarLabel = ({ x, y, width, height, label }: BarLabelProps) => {
  // Check if the label is too long and truncate it if it is
  const truncatedLabel = useMemo(() => {
    const svgns = 'http://www.w3.org/2000/svg';

    const newText = document.createElementNS(svgns, 'text');
    newText.setAttribute('font-family', 'Source Sans Pro, sans-serif');
    newText.setAttribute('font-size', '12px');
    newText.setAttribute('text-anchor', 'start');
    newText.innerHTML = label;

    const svg = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
    document.body.appendChild(svg);
    svg.appendChild(newText);
    const labelWidth = newText.getBoundingClientRect().width;

    document.body.removeChild(svg);

    const maxLabelWidth = Math.floor(width / 9);
    if (labelWidth > width) {
      return label.slice(0, maxLabelWidth) + '...';
    }

    return label;
  }, [label, width]);

  return (
    <text x={x} y={y + height / 2} textAnchor="start" dy="0.25em" className={styles.barLabel}>
      {truncatedLabel}
      <title>{label}</title>
    </text>
  );
};
