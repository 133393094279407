import {
  Tag,
  TagLabel,
  TagCloseButton,
  Text,
  Flex,
  Button,
  Skeleton,
} from '@chakra-ui/react';
import { Dispatch, ReactNode } from 'react';
import {
  TalentDiscoveryFilterOption,
  isCompanySearchFilterState,
  isFreeTextFilterState,
  isRangeFilterState,
  isSchoolSearchFilterState,
  isTreeFilterState,
} from './types';
import {
  TalentDiscoveryFilterAction,
  TalentDiscoveryFilterState,
} from './td-filter-reducer';
import {
  LocalSelectionCategories,
  SelectionCategories,
  TreeItem,
} from '@revelio/filtering';
import { TalentDiscoveryFilterPopover } from './td-filter-popover';
import { format } from 'd3-format';

const categoryMapping: { [key: string]: string } = {
  [SelectionCategories.COMPANY]: 'Companies',
  [SelectionCategories.HIGHEST_DEGREE]: 'Education Levels',
  [SelectionCategories.ETHNICITY]: 'Ethnicities',
  [LocalSelectionCategories.FLIGHT_RISK]: 'Risk Levels',
  [SelectionCategories.GENDER]: 'Genders',
  [SelectionCategories.REGION]: 'Regions',
  [SelectionCategories.COUNTRY]: 'Countries',
  [SelectionCategories.STATE]: 'States',
  [SelectionCategories.METRO_AREA]: 'MSAs',
  [LocalSelectionCategories.PRESTIGE]: 'Prestige Levels',
  [LocalSelectionCategories.REMOTE_SUITABILITY]: 'Suitability Levels',
  [SelectionCategories.ROLE_K7]: 'Job Categories',
  [SelectionCategories.ROLE_K150]: 'Role k150s',
  [SelectionCategories.ROLE_K1500]: 'Role k1500s',
  [SelectionCategories.SENIORITY]: 'Seniorities',
  [SelectionCategories.EDUCATION]: 'Education Levels',
  [SelectionCategories.JOB_CATEGORY]: 'Job Categories',
  [SelectionCategories.SKILL]: 'Skills',
  [SelectionCategories.RICS_K10]: 'RICS K10',
  [SelectionCategories.RICS_K50]: 'RICS K50',
  skill_k75: 'Skill k75s',
  skill_k700: 'Skill k700s',
  skill_k3000: 'Skill k3000s',
};

const findOptionInFilterOptions = (
  options: TalentDiscoveryFilterOption[],
  predicate: (option: TalentDiscoveryFilterOption) => boolean
): TalentDiscoveryFilterOption | undefined => {
  for (const option of options) {
    if (predicate(option)) {
      return option;
    }

    if (option.children) {
      const found = findOptionInFilterOptions(option.children, predicate);
      if (found) {
        return found;
      }
    }
  }

  return undefined;
};

interface FilterTagProps {
  filterOptions: TalentDiscoveryFilterOption[];
  dispatch: Dispatch<TalentDiscoveryFilterAction>;
  filter: NonNullable<TalentDiscoveryFilterState['filters']>[number];
  loading?: boolean;
}

export const FilterTag = ({
  filterOptions,
  dispatch,
  filter,
  loading = false,
}: FilterTagProps) => {
  const isTreeFilter = isTreeFilterState(filter);
  const isCompanySearchFilter = isCompanySearchFilterState(filter);
  const isSchoolFilter = isSchoolSearchFilterState(filter);
  const isFreeText = isFreeTextFilterState(filter);
  const isRangeFilter = isRangeFilterState(filter);

  const filterNames: ReactNode = (() => {
    if (isCompanySearchFilter) {
      const companyResultItems = Object.values(filter.companyResultItems || {});
      const names = companyResultItems
        .map((item) => item.primary_name)
        .join(', ');

      const textToRender = (() => {
        if (companyResultItems.length > 1) {
          return `${companyResultItems.length} Companies`;
        }

        return names;
      })();

      return (
        <Text as="span" color="lightBlue.600" fontWeight={600}>
          {textToRender}
        </Text>
      );
    }

    if (isFreeText) {
      const textToRender = (() => {
        if (filter?.text?.length > 1) {
          const type =
            filter?.name.charAt(0).toUpperCase() + filter?.name.slice(1);
          return `${filter?.text?.length} ${type}`;
        }

        return filter?.text?.join(', ');
      })();

      return (
        <Text as="span" color="lightBlue.600" fontWeight={600}>
          {textToRender}
        </Text>
      );
    }

    if (isTreeFilter) {
      const treeItems = filter && isTreeFilter ? filter.treeItems : {};
      const valuesGroupedByKey = Object.entries(treeItems || {}).reduce(
        (acc, [key, value]) => {
          const treeItem = value as TreeItem;
          const parsedKey = key.split('.')[0];
          if (!acc[parsedKey]) {
            acc[parsedKey] = [];
          }
          acc[parsedKey].push(treeItem);
          return acc;
        },
        {} as Record<string, TreeItem[]>
      );

      const values = Object.entries(valuesGroupedByKey);

      const mappedMultiGranularityLabels = values
        .map(([key, values]) => `${values.length} ${categoryMapping[key]}`)
        .join(', ');

      if (filter?.name === SelectionCategories.SKILL) {
        const textToRender = (() => {
          const isSingleGranularity = values.length === 1;
          if (isSingleGranularity) {
            const noOfSkills = values[0][1].length;
            if (noOfSkills === 1) {
              return values[0][1][0].item?.label;
            }

            return `${noOfSkills} ${categoryMapping[values[0][0]]}`;
          }

          return mappedMultiGranularityLabels;
        })();

        return (
          <Text as="span" color="lightBlue.600" fontWeight={600}>
            {textToRender}
          </Text>
        );
      }

      const textToRender = (() => {
        const treeNames = Object.values(treeItems || {}).map(
          (filter: TreeItem) => filter.item?.label
        );

        const isSingleGranularity = Object.keys(treeItems || {}).length === 1;
        if (isSingleGranularity) {
          if (treeNames.length === 1) {
            return treeNames;
          }

          return `${treeNames.length} ${categoryMapping?.[filter.name] || 'items'}`;
        }

        return mappedMultiGranularityLabels;
      })();

      return (
        <Text as="span" color="lightBlue.600" fontWeight={600}>
          {textToRender}
        </Text>
      );
    }

    if (isSchoolFilter) {
      const schools = Object.values(filter.schoolResultItems);
      const schoolNames = schools
        .map((school) => school?.primary_name)
        .join(', ');

      const textToRender = (() => {
        if (schools.length > 1) {
          return `${schools.length} Institutions`;
        }

        return schoolNames;
      })();

      return (
        <Text color="lightBlue.600" fontWeight={600}>
          {textToRender}
        </Text>
      );
    }

    if (isRangeFilter) {
      const formatNumber = (num: number): string => {
        if (num >= 1_000_000_000) {
          return `${format('.1s')(num).replace('G', 'B')}`;
        } else if (num >= 1_000_000) {
          return `${format('.1s')(num)}`;
        } else if (num >= 1_000) {
          return `${format(',')(num)}`;
        } else {
          return `${num}`;
        }
      };

      const startValue = (
        <Text color="lightBlue.600" fontWeight={600}>
          ${formatNumber(filter?.start_value || 0)}
        </Text>
      );
      const endValue = (
        <Text color="lightBlue.600" fontWeight={600}>
          ${formatNumber(filter?.end_value || 0)}
        </Text>
      );

      if (filter.end_value !== undefined && filter.start_value !== undefined) {
        return (
          <>
            {startValue}
            <Text mx={0.5}>and</Text>
            {endValue}
          </>
        );
      }

      if (filter.start_value !== undefined) {
        return startValue;
      }

      return endValue;
    }
    return null;
  })();

  const itemCount = (() => {
    if (isTreeFilter) {
      const treeItems = filter && isTreeFilter ? filter.treeItems : {};
      return Object.values(treeItems || {}).length;
    }

    if (isSchoolFilter) {
      return Object.values(filter.schoolResultItems).length;
    }

    if (isFreeText) {
      return filter.text.length;
    }

    if (isCompanySearchFilter) {
      return Object.values(filter.companyResultItems || {}).length;
    }

    return 1;
  })();

  const filterId = (() => {
    if (isFreeText || isTreeFilter) {
      return filter.id;
    }

    return undefined;
  })();

  const handlePrimaryFilterClear = () => {
    if (isTreeFilter) {
      dispatch({
        type: 'REMOVE_TREE_FILTER',
        name: filter.name,
        id: filter.id,
      });
    }

    if (isCompanySearchFilter) {
      dispatch({
        type: 'REMOVE_SEARCH_FILTER',
        name: filter.name,
      });
    }

    if (isSchoolFilter) {
      dispatch({
        type: 'REMOVE_SCHOOL_DETAIL_SEARCH_FILTER',
        name: filter.name,
      });
    }

    if (isFreeText) {
      dispatch({
        type: 'REMOVE_FREE_TEXT_FILTER',
        name: filter.name,
        id: filter.id,
      });
    }

    if (isRangeFilter) {
      dispatch({
        type: 'REMOVE_RANGE_FILTER',
        name: filter.name,
      });
    }
  };

  const primaryFilterKey = filter.name;
  const selectedFilterOption = findOptionInFilterOptions(
    filterOptions,
    (option) => primaryFilterKey.includes(option.value)
  );

  const current = (() => {
    if (
      (isTreeFilter || isCompanySearchFilter || isRangeFilter) &&
      selectedFilterOption?.supportsCurrentOrPrevious
    ) {
      return filter.isCurrent ? 'Current ' : 'Previous ';
    }

    return '';
  })();

  const middleText = (() => {
    if (isRangeFilter) {
      if (filter.end_value !== undefined && filter.start_value !== undefined) {
        return ' is between ';
      }
      if (filter.start_value !== undefined) {
        return ' is greater than ';
      }

      return ' is less than ';
    }

    return itemCount > 1 ? ' is one of ' : ' is ';
  })();

  return (
    <TalentDiscoveryFilterPopover
      trigger={
        <Skeleton
          variant="shine"
          isLoaded={!loading}
          startColor="#ebebeb"
          endColor="#e8e8e8"
          style={{
            marginRight: '8px',
          }}
        >
          <Tag
            size="sm"
            variant="solid"
            cursor="pointer"
            height={6}
            borderRadius="4px"
            _hover={{ background: 'white' }}
            as={Button}
            data-testid="primary-filter-tag"
            mr={0}
          >
            <TagLabel _hover={{ textDecoration: 'underline' }}>
              <Flex>
                <Text color="lightBlue.600" fontWeight={600}>
                  {current}
                  {selectedFilterOption?.label}
                </Text>
                <Text mx={0.5}>•{middleText}•</Text>
                {filterNames}
              </Flex>
            </TagLabel>
            <TagCloseButton
              onClick={handlePrimaryFilterClear}
              data-testid={`primary-filter-tag-remove-${selectedFilterOption?.label}`}
            />
          </Tag>
        </Skeleton>
      }
      selectedFilterToEdit={selectedFilterOption}
      filterId={filterId}
    />
  );
};
