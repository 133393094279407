/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import LZString from 'lz-string';
import {
  ScreenerFilterAction,
  ScreenerFilterState,
  ScreenerSorting,
} from '@revelio/filtering';
import { ScreenerDimension } from '@revelio/data-access';

const areFiltersEmpty = (filters: ScreenerFilterState['filters']): boolean => {
  const { primary_filter, sub_filters, company_detail_filters } = filters;

  const hasPrimaryFilter =
    primary_filter && Object.keys(primary_filter).length > 0;
  const hasSubFilters = sub_filters && sub_filters.length > 0;
  const hasCompanyDetailFilters =
    company_detail_filters && company_detail_filters.length > 0;

  return !hasPrimaryFilter && !hasSubFilters && !hasCompanyDetailFilters;
};

// Validation for filters
export const isValidFilters = (
  filters: unknown
): filters is ScreenerFilterState['filters'] => {
  if (typeof filters !== 'object' || filters === null) {
    return false;
  }

  // Since 'filters' is of type 'unknown', we need to assert its structure
  const f = filters as Partial<ScreenerFilterState['filters']>;

  const { primary_filter, sub_filters, company_detail_filters } = f;

  const isValidPrimaryFilter =
    primary_filter === undefined ||
    (typeof primary_filter === 'object' && primary_filter !== null);
  const isValidSubFilters =
    sub_filters === undefined || Array.isArray(sub_filters);
  const isValidCompanyDetailFilters =
    company_detail_filters === undefined ||
    Array.isArray(company_detail_filters);

  const isValid =
    isValidPrimaryFilter && isValidSubFilters && isValidCompanyDetailFilters;

  return isValid;
};

// Validation for sorting
const isValidSorting = (sorting: unknown): sorting is ScreenerSorting => {
  if (typeof sorting !== 'object' || sorting === null) {
    return false;
  }

  const s = sorting as Partial<ScreenerSorting>;

  const hasRequiredProps = 'sort_by' in s && 'ascending' in s && 'num_col' in s;

  if (!hasRequiredProps) {
    return false;
  }

  const isValidSortBy = typeof s.sort_by === 'string';
  const isValidAscending = typeof s.ascending === 'boolean';
  const isValidNumCol = typeof s.num_col === 'number';

  return isValidSortBy && isValidAscending && isValidNumCol;
};

// Validation for columns
const isValidColumns = (columns: unknown): columns is string[] => {
  if (columns === undefined) {
    return true;
  }

  if (!Array.isArray(columns)) {
    return false;
  }

  return columns.every((col) => typeof col === 'string');
};

// Helper function to load state from URL or sessionStorage
export const loadStateFromUrl = <T>(
  paramName: string,
  urlParams: URLSearchParams,
  setState: (state: T) => void,
  validateState: (state: unknown) => state is T,
  emptyState: T
): void => {
  const compressedFromUrl = urlParams.get(paramName);
  if (!compressedFromUrl) {
    setState(emptyState);
    return;
  }

  try {
    const serialized =
      LZString.decompressFromEncodedURIComponent(compressedFromUrl);

    if (serialized) {
      const state: unknown = JSON.parse(serialized);

      if (validateState(state)) {
        setState(state);
        return;
      } else {
        urlParams.delete(paramName); // Remove invalid param from URL
      }
    }
  } catch (error) {
    urlParams.delete(paramName); // Remove invalid param from URL
  }
};

// Helper function to save state to URL and sessionStorage
const saveStateToUrlAndStorage = <T>(
  paramName: string,
  view: ScreenerDimension,
  urlParams: URLSearchParams,
  state: T | undefined,
  isEmpty: (state: T) => boolean = () => false,
  validateState: (state: unknown) => state is T
): void => {
  if (state && !isEmpty(state) && validateState(state)) {
    const serialized = JSON.stringify(state);

    const compressedForUrl = LZString.compressToEncodedURIComponent(serialized);

    urlParams.set(paramName, compressedForUrl);

    const compressedForStorage = LZString.compressToUTF16(serialized);

    sessionStorage.setItem(`${paramName}_${view}`, compressedForStorage);
  } else {
    urlParams.delete(paramName);
    sessionStorage.removeItem(`${paramName}_${view}`);
  }
};

export const loadAllScreenerStateFromUrl = (
  dispatch: React.Dispatch<ScreenerFilterAction>,
  view: ScreenerDimension,
  setSorting: (sorting: ScreenerSorting | undefined) => void,
  setSavedCheckedColumns: React.Dispatch<React.SetStateAction<string[]>>,
  params: URLSearchParams
) => {
  // Handle filters
  loadStateFromUrl<ScreenerFilterState['filters']>(
    'filters',
    params,
    (filters) => {
      dispatch({ type: 'OVERWRITE_FILTERS', filters });
    },
    isValidFilters,
    {
      page: 1,
      primary_filter: undefined,
      sub_filters: [],
      company_detail_filters: [],
    }
  );

  // Handle sorting
  loadStateFromUrl<ScreenerFilterState['sorting']>(
    'sorting',
    params,
    setSorting,
    isValidSorting,
    undefined
  );

  // Handle selected columns
  loadStateFromUrl<string[]>(
    'columns',
    params,
    setSavedCheckedColumns,
    isValidColumns,
    []
  );
};

const loadStateFromSessionStorage = <T>(
  paramName: string,
  view: ScreenerDimension,
  setState: (state: T) => void,
  validateState: (state: unknown) => state is T
) => {
  // No valid data in URL, check sessionStorage
  const compressedFromStorage = sessionStorage.getItem(`${paramName}_${view}`);
  if (compressedFromStorage) {
    try {
      const serialized = LZString.decompressFromUTF16(compressedFromStorage);

      if (serialized) {
        const state: unknown = JSON.parse(serialized);

        if (validateState(state)) {
          setState(state);
        } else {
          sessionStorage.removeItem(`${paramName}_${view}`); // Remove invalid data from sessionStorage
        }
      }
    } catch (error) {
      sessionStorage.removeItem(`${paramName}_${view}`); // Remove invalid data from sessionStorage
    }
  }
};

export const useSyncFiltersWithUrl = (
  state: ScreenerFilterState,
  dispatch: React.Dispatch<ScreenerFilterAction>,
  view: ScreenerDimension,
  sorting: ScreenerSorting | undefined,
  setSorting: (sorting: ScreenerSorting | undefined) => void,
  savedCheckedColumns: string[],
  setSavedCheckedColumns: React.Dispatch<React.SetStateAction<string[]>>
) => {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const params = new URLSearchParams(location.search);

    if (params.toString()) {
      loadAllScreenerStateFromUrl(
        dispatch,
        view,
        setSorting,
        setSavedCheckedColumns,
        params
      );
    } else {
      // No valid data in URL, check sessionStorage
      loadStateFromSessionStorage<ScreenerFilterState['filters']>(
        'filters',
        view,
        (filters) => {
          dispatch({ type: 'OVERWRITE_FILTERS', filters });
        },
        isValidFilters
      );

      // Handle sorting
      loadStateFromSessionStorage<ScreenerFilterState['sorting']>(
        'sorting',
        view,
        setSorting,
        isValidSorting
      );

      // Handle selected columns
      loadStateFromSessionStorage<string[]>(
        'columns',
        view,
        setSavedCheckedColumns,
        isValidColumns
      );
    }

    // Check if any params were removed
    if (params.toString() !== location.search.substring(1)) {
      const newSearch = params.toString();
      const newUrl = `${location.pathname}${newSearch ? `?${newSearch}` : ''}`;
      navigate(newUrl, { replace: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, setSorting, setSavedCheckedColumns, view]);

  // On state change, update the URL and session storage
  useEffect(() => {
    const params = new URLSearchParams(location.search);

    // Handle filters
    saveStateToUrlAndStorage(
      'filters',
      view,
      params,
      state.filters,
      areFiltersEmpty,
      isValidFilters
    );

    // Handle sorting
    saveStateToUrlAndStorage(
      'sorting',
      view,
      params,
      sorting,
      () => false,
      isValidSorting
    );

    // Handle selected columns
    saveStateToUrlAndStorage(
      'columns',
      view,
      params,
      savedCheckedColumns,
      (columns) => !columns || columns.length === 0,
      isValidColumns
    );

    // Update the URL if any changes
    const newSearch = params.toString();
    if (newSearch !== location.search.substring(1)) {
      const newUrl = `${location.pathname}${newSearch ? `?${newSearch}` : ''}`;
      navigate(newUrl, { replace: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    state.filters,
    sorting,
    savedCheckedColumns,
    navigate,
    location.pathname,
    view,
  ]);
};
