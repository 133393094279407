import {
  RoleSelectionCategories,
  SelectionCategories,
  SelectionList,
} from './filters.model';
import { CustomRoleTaxonomyRef } from '@revelio/data-access';
import { invert, sortBy } from 'lodash';

const SELECTION_LIST_TO_ROLE_TAXONOMY_OVERRIDE: {
  [key in RoleSelectionCategories]?: RoleSelectionCategories;
} = {
  [SelectionCategories.JOB_CATEGORY]: SelectionCategories.ROLE_K7,
};

export const ROLE_TAXONOMY_LEVEL_ID_TO_SELECTION_LIST = invert(
  SELECTION_LIST_TO_ROLE_TAXONOMY_OVERRIDE
);

export const SelectionListToRoleTaxonomyOverride = (
  id: RoleSelectionCategories
) => {
  return SELECTION_LIST_TO_ROLE_TAXONOMY_OVERRIDE[id] || id;
};

export const RoleTaxonomyToSelectionListOverride = (
  id: RoleSelectionCategories
) => {
  return (
    (ROLE_TAXONOMY_LEVEL_ID_TO_SELECTION_LIST[id] as RoleSelectionCategories) ||
    id
  );
};

export const transformCustomRoleTaxonomy = (
  listName: RoleSelectionCategories,
  data: CustomRoleTaxonomyRef
) => {
  const levels = data.orderedLevels || [];
  const levelIds = levels.map((l) => l?.id);
  const leafData = data.leafLevelData || [];
  const listLevel = levels.find((l) => l?.id === listName);
  const listLabel =
    listLevel?.label ||
    (listName === SelectionCategories.JOB_CATEGORY ? 'Job Category' : listName);
  const processedNode: Record<string, boolean> = {};
  const values = leafData.reduce(
    (acc, leaf) => {
      if (!leaf) return acc;

      const nodePath = [leaf, ...(leaf.parents || [])];
      const findListMatch = nodePath.find(
        (n) =>
          n?.levelId &&
          RoleTaxonomyToSelectionListOverride(
            n.levelId as RoleSelectionCategories
          ) === listName
      );

      if (findListMatch && !processedNode[findListMatch.id || '']) {
        const nodeData = {
          id: findListMatch.id,
          label: findListMatch.label,
          shortName: findListMatch.label,
          longName: findListMatch.label,
          suggested_titles: findListMatch.suggested_titles,
          topCleanedTitles: leaf?.suggested_titles?.join(','),
          ...nodePath.reduce(
            (pathAcc, n) => {
              if (
                !n?.id ||
                !n.levelId ||
                levelIds.indexOf(findListMatch.levelId) <=
                  levelIds.indexOf(n.levelId)
              ) {
                return pathAcc;
              }
              return {
                ...pathAcc,
                [RoleTaxonomyToSelectionListOverride(
                  n.levelId as RoleSelectionCategories
                )]: n.id,
              };
            },
            {} as Record<string, string>
          ),
        };
        acc.push(nodeData);
        processedNode[findListMatch.id || ''] = true;
      }

      return acc;
    },
    [] as Record<string, any>[]
  );

  return {
    id: listName,
    label: listLabel,
    value: sortBy(values, 'label') as SelectionList['value'],
  };
};
