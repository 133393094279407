import { useMemo } from 'react';
import { CombinedError, useQuery } from 'urql';
import {
  COMPOSITION_GET_DATA,
  FilterName,
  SelectionCategories,
  useActiveFiltersState,
  useAllFiltersState,
  useSelectionListsData,
} from '@revelio/filtering';
import { PrimaryView, TimeFrameView, FeatureFlag } from '@revelio/core';
import {
  BottomConfigMapperBase,
  ConfigMapperData,
  TopConfigMapperBase,
  getBottomPlotMappers,
  getTopPlotMappers,
  transformFiltersToOvertimeVariables,
  transformFiltersToSnapshotVariables,
} from '../utils';
import { useIsQueryReady } from './use-is-query-ready';
import {
  BottomPlotData,
  TopPlotData,
  useGetChartData,
} from './use-get-chart-data';
import { useGlobalLoadController } from './use-global-load-controller';
import { useIsSlowLoad } from '../../../../../reveliolabs-dashboard/src/hooks/open-replay';
import { useUnleashFlag } from '../../../../../reveliolabs-dashboard/src/hooks/unleash/useUnleashFlag';
import { useRoleTaxonomySetting } from '@revelio/filtering';

const selectionListsIds = [
  SelectionCategories.SKILL_K75,
  SelectionCategories.SKILL_K700,
  SelectionCategories.SKILL_K3000,
  SelectionCategories.REGION,
  SelectionCategories.COUNTRY,
  SelectionCategories.METRO_AREA,
  SelectionCategories.JOB_CATEGORY,
  SelectionCategories.ROLE_K50,
  SelectionCategories.ROLE_K500,
  SelectionCategories.INDUSTRY,
  SelectionCategories.RICS_K10,
  SelectionCategories.RICS_K50,
  SelectionCategories.RICS_K400,
];

type DataFetch<TTop, TBottom> = {
  topPlots: ConfigMapperData<TTop, TopPlotData>[];
  bottomPlots: ConfigMapperData<TBottom, BottomPlotData>[];
  loading: boolean;
  error: CombinedError | undefined;
  isQueryReady: boolean;
};
export const useCompositionDataFetch = <
  TTop extends TopConfigMapperBase,
  TBottom extends BottomConfigMapperBase,
>({
  view,
  timeframe,
  primaryFilters,
  topConfigMapper,
  bottomConfigMapper,
}: {
  view: PrimaryView;
  timeframe: TimeFrameView;
  primaryFilters: FilterName[];
  topConfigMapper: TTop[];
  bottomConfigMapper: TBottom[];
}): DataFetch<TTop, TBottom> => {
  const isSnapshot = timeframe === TimeFrameView.SNAPSHOT;

  const activeFilters = useActiveFiltersState();
  const allFilters = useAllFiltersState();

  /** Get all filters relevant for query */
  const filters = useMemo(() => {
    const snapshotDateFilter = allFilters.find(
      (filter) => filter.id === SelectionCategories.SNAPSHOT_DATE
    );
    const dateRangeFilter = allFilters.find(
      (filter) => filter.id === SelectionCategories.DATE_RANGE
    );
    const dateFilter = isSnapshot ? snapshotDateFilter : dateRangeFilter;

    return [...activeFilters, ...(dateFilter ? [dateFilter] : [])];
  }, [activeFilters, allFilters, isSnapshot]);

  /** Convert filters into gql variables */
  const selectionListsData = useSelectionListsData(selectionListsIds);
  const { isEnabled: isCustomRoleTaxonomyEnabled } = useRoleTaxonomySetting();
  const queryFilters = useMemo(() => {
    if (isSnapshot) {
      return transformFiltersToSnapshotVariables({
        view,
        filters,
        selectionListsData,
        isCustomRoleTaxonomyEnabled,
      });
    }
    return transformFiltersToOvertimeVariables({
      view,
      filters,
      selectionListsData,
      isCustomRoleTaxonomyEnabled,
    });
  }, [
    view,
    filters,
    isSnapshot,
    selectionListsData,
    isCustomRoleTaxonomyEnabled,
  ]);

  /** Fetch data */
  const isQueryReady = useIsQueryReady({
    filters,
    primaryFilters,
    timeframe,
    view,
  });
  const [{ data, fetching, error, ...restProps }] = useQuery({
    query: COMPOSITION_GET_DATA,
    variables: queryFilters,
    pause: !isQueryReady,
  });

  const fetchOptions = restProps?.operation?.context?.fetchOptions as {
    headers: { ['x-request-id']: string | undefined };
  };
  const compositionRequestLoggingFeatureFlag = useUnleashFlag(
    FeatureFlag.CompositionRequestLogging
  );

  useIsSlowLoad(
    'COMPOSITION_GET_DATA',
    fetching,
    fetchOptions?.headers?.['x-request-id'],
    compositionRequestLoggingFeatureFlag
  );

  /** Handle fetch and chart load state */
  const hasFetchBeenCalled = fetching || !!data || !!error;
  const loading = fetching || !hasFetchBeenCalled;
  useGlobalLoadController(loading);

  /** Convert gql response to chart data */
  const chartData = useGetChartData({ data, timeframe, filters: allFilters });

  return {
    topPlots: getTopPlotMappers({ configMapper: topConfigMapper, chartData }),
    bottomPlots: getBottomPlotMappers({
      configMapper: bottomConfigMapper,
      chartData,
    }),
    loading,
    error,
    isQueryReady,
  };
};
