import { selectEntities } from '@ngneat/elf-entities';
import { PagePaths } from '@revelio/core';

import { useNavigate } from 'react-router';

import { DeliverableStepContainer } from '../../deliverable-step-container';

import { ReportBuilderDeliverablePaths } from '../report-builder-page';
import { reportBuilderStore } from '../report-builder.repository';
import { useObservable } from '@ngneat/react-rxjs';
import EditEntityValues from './edit-entity-values';
import { useToast } from '@chakra-ui/react';
import { useReportBuilderConfig } from '../utils/useReportBuilderConfig';

export const EntityConfiguration = () => {
  const navigate = useNavigate();
  const toast = useToast();
  const [reportBuilderDeliverables] = useObservable(
    reportBuilderStore.pipe(selectEntities())
  );
  const reportEntityMap = useReportBuilderConfig().config;

  const report = Object.values(reportBuilderDeliverables)?.[0];

  if (
    !report ||
    !reportEntityMap ||
    Object.keys(reportEntityMap).length === 0
  ) {
    navigate(
      `/${PagePaths.REPORT_BUILDER}/${ReportBuilderDeliverablePaths.REPORT_TYPE}`
    );

    return null;
  }

  const selectedReportConfig = reportEntityMap[report.reportType] ?? {};
  const selectedReportTitle = `Select ${selectedReportConfig.label || 'Report'} Parameters`;

  const selectedReportType = report?.reportType;
  const requiredColumns =
    reportEntityMap[selectedReportType].columns?.filter(
      (reportEntity) => reportEntity.isRequired
    ) ?? [];

  const selectedRequiredColumnsCount = requiredColumns.filter(
    (column) => report?.selections?.[column.id]
  ).length;

  const hasUnselectedMandatoryColumns =
    requiredColumns.length > selectedRequiredColumnsCount;

  const submitValidation = (() => {
    if (hasUnselectedMandatoryColumns) {
      const requiredLeft =
        requiredColumns.length - selectedRequiredColumnsCount;
      return `Please select ${requiredLeft} more mandatory ${
        requiredLeft === 1 ? 'item' : 'items'
      }`;
    }

    return undefined;
  })();

  const onSubmit = () => {
    if (submitValidation) {
      toast({
        title: submitValidation,
        status: 'error',
        duration: 5000,
        isClosable: true,
        position: 'top-right',
      });
      return;
    }

    navigate(
      `/${PagePaths.REPORT_BUILDER}/${ReportBuilderDeliverablePaths.CONFIRMATION}`
    );
  };

  return (
    <DeliverableStepContainer
      id="report_builder_entity"
      title={selectedReportTitle}
      previousStepPath={`/${PagePaths.REPORT_BUILDER}/${ReportBuilderDeliverablePaths.REPORT_TYPE}`}
      onSubmit={onSubmit}
      submitButtonContent="Continue"
      startOverPath={`/${PagePaths.REPORT_BUILDER}/${ReportBuilderDeliverablePaths.REPORT_TYPE}`}
    >
      {typeof report?.id === 'number' ? (
        <EditEntityValues
          selectedReportId={report.id}
          reportEntityMap={reportEntityMap}
        />
      ) : null}
    </DeliverableStepContainer>
  );
};
